import React from 'react';
import { HashRouter } from 'react-router-dom';

import Header from '../common/template/header';
import SideBar from '../common/template/sideBar';
import Messages from '../common/msg/messages';

import Routes from './routes';

import './app.scss';

export default props => (
    <HashRouter>
        <div className='wrapper'>
          <Header />
          <SideBar />
          <Routes />
          <Messages />
        </div>
    </HashRouter>
)
