import axios from 'axios';

const requisition = props => (
  axios({
      //mode: 'no-cors',
      headers: props.header ? props.header : {} ,
      //withCredentials: true,
      //credentials: 'same-origin',
      method: props.type ? props.type : 'post',
      url: props.url,
      data: props.data
    })
    .then(function (response) {
      if(props.complete) props.complete({type:'success'});
      props.success(response);
    })
    .catch(function (error) {
      if(props.complete) props.complete({type:'error'});

      props.error(error);
    })
);

export default requisition;
