import React from 'react';
import CurrencyFormat from 'react-currency-format';

import Grid from '../layout/grid';


export default props => {
  return (
      <Grid cols={props.cols}>
          <div className={`${props.required ? "field-mandatory" : "" } form-group form-group-mask`}>
              <label htmlFor={props.name}>{props.label}</label>

                {props.format ?
                <CurrencyFormat
                  readOnly={props.readOnly}
                  value={props.value}
                  mask={props.mask}
                  className='form-control'
                  format={props.format}
                  onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    props.handleChange({
                      target: {
                        type: 'text',
                        value: value,
                        formattedValue: formattedValue,
                        name: props.name
                      }
                    });
                  }}
                  />
                :
                <CurrencyFormat
                  className='form-control'
                  readOnly={props.readOnly}
                  value={props.value}
                  thousandSeparator={'.'} decimalSeparator={','}
                  prefix={props.prefix}
                  onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    props.handleChange({
                      target: {
                        type: 'text',
                        value: value,
                        formattedValue: formattedValue,
                        name: props.name
                      }
                    });
                  }} />}

          </div>
      </Grid>
  );
}
