import React from 'react';
import If from '../operator/if';
import './pagination.scss';

export default props => {
  if(!props.dados) return null;
  let state = props.dados.current;

  return (
    <If test={props.dados.total}>
      <div className="clearfix pagination-box">
        <div className="btn-group pull-right">
          <p>Exibindo {props.dados.current} de {props.dados.pages} páginas ({props.dados.total} itens)</p>
          <If test={props.dados.current > 1}>
            <button type="button" onClick={() => props.onClick('prev')} className="btn btn-default">Anterior</button>
          </If>
          <If test={props.setPage}>
            <div className="custom-page">
              <form onSubmit={(e)=>{props.onClick(state); e.preventDefault();}}><input className='form-control' onChange={(event)=>{state=event.target.value;}} /></form>
            </div>
          </If>
          <If test={props.dados.current < props.dados.pages}>
            <button type="button" onClick={() => props.onClick('next')} className="btn btn-default">Próximo</button>
          </If>
        </div>
      </div>
    </If>
  )
};
