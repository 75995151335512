import React from 'react';
import Grid from '../layout/grid';
import './groupCheckbox.scss';

export default props => (
  <Grid cols={props.cols}>
    <label htmlFor={props.name}>{props.label}</label>
    <div className='box-body box-body__group-radio'>
      {
        props.options.map((item,i) =>
          <Grid cols={item.cols} key={i}>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input
                    onChange={props.handleChange}
                    value={item.value}
                    name={props.name}
                    type="radio"
                    disabled={props.readOnly}
                    checked={String(props.value) === String(item.value)}
                    />
                  {item.label}
                </label>
              </div>
            </div>
          </Grid>
        )
      }
    </div>
  </Grid>
);
