import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import LabelAndInput from '../../common/form/labelAndInput';
import TitleModal from '../../common/form/titleModal';
import api from '../../api';
import Language from '../../config/language';
import LabelAndSelect from '../../common/form/labelAndSelect';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import CONSTS from '../../config/consts';
import {convertToBr} from '../../utils/dateJS';
import './modal-busca.scss';

ReactModal.setAppElement('#root');

const INITIAL_STATE = {
  cliente:'',
  id: '',
  status: '',
  tipo_venda: '',
  dti: '',
  dtf: '',
  vendedor: '',
  finalizado: ''
};
const optionsStatusVenda = CONSTS.STATUS_VENDAS;
const optionsTipo = CONSTS.TIPOS_VENDA_CORTESIA;
const optionsFinalizado = CONSTS.VENDA_FINALIZADA;

class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      query: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  onAfterOpen(){
    this.setState(INITIAL_STATE);
    this.props.onAfterOpen();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  handleSubmit(e){
    e.preventDefault();

    if(!this.state.finalizado &&!this.state.id_integracao && !this.state.vendedor && !this.state.id && !this.state.cliente && !this.state.status && !this.state.tipo_venda && !this.state.dti && !this.state.dtf){
      toastr.warning(Language.atencao, Language.searchEmpty);
      return false;
    }

    const data = {};

    if(this.state.id){
      data.id = this.state.id;
    }

    if(this.state.cliente){
      data.cliente = this.state.cliente.value;
    }

    if(this.state.status){
      data.status = this.state.status.value;
    }

    if(this.state.tipo_venda){
      data.tipo_venda = this.state.tipo_venda.value;
    }

    if(this.state.vendedor){
      data.vendedor = this.state.vendedor.value;
    }

    if(this.state.dti){
      data.dti = convertToBr(this.state.dti);
    }

    if(this.state.dtf){
      data.dtf = convertToBr(this.state.dtf);
    }

    if(this.state.id_integracao){
      data.id_integracao = this.state.id_integracao;
    }

    if(this.state.finalizado){
      data.finalizado = this.state.finalizado.value;
    }

    data.maxResults = 20;

    const query = { ...data};

    this.props.onSearch({query: query});
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    const urlCliente = `${api.API_URL}${api.API.clientes.autocomplete}`;
    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;


    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <form onSubmit={this.handleSubmit}>
              <div className='box-modal-visualizacao__wrapper'>
                <TitleModal title="Buscar" />
                <LabelAndInput name='id'  type="text" value={this.state.id}  handleChange={this.handleInputChange}
                    label='Id' cols='12 6' placeholder='' />
                <LabelAndInput name='id_integracao'  type="text" value={this.state.id_integracao}  handleChange={this.handleInputChange}
                    label='Id Belle' cols='12 6' placeholder='' />
                <LabelAndSelectAjax
                  name='vendedor'
                  value={this.state.vendedor}
                  onChange={this.handleSelect}
                  url={urlVendedor}
                  token={this.props.token}
                  label='Selecione um vendedor'
                  cols='12 8'
                  query={{tipo:1}}
                  placeholder='Digite aqui' />
                <LabelAndSelect  name='finalizado' options={optionsFinalizado} value={this.state.finalizado}  handleChange={this.handleSelect}
                    label='Finalizado/Em aberto' cols='12 4' placeholder='Digite aqui' />
                <LabelAndSelect name='tipo_venda' options={optionsTipo} value={this.state.tipo_venda}  handleChange={this.handleSelect}
                  label='Parceria/Brinde/Cortesia/Outros' cols='12 6' placeholder='Digite aqui' />
                <LabelAndSelect  name='status' options={optionsStatusVenda} value={this.state.status}  handleChange={this.handleSelect}
                    label='Status da venda' cols='12 6' placeholder='Digite aqui' />
                <LabelAndSelectAjax
                  name='cliente'
                  value={this.state.cliente}
                  onChange={this.handleSelect}
                  url={urlCliente}
                  token={this.props.token}
                  label='Selecione o cliente'
                  cols='12'
                  placeholder='Digite aqui' />
                <LabelAndDate  name='dti' value={this.state.dti}   onChange={date => this.onChangeDate(date, 'dti')}
                    label='Data inicial' cols='12 6' placeholder='Digite aqui' date={this.state.dti} />
                <LabelAndDate  name='dtf' value={this.state.dtf}   onChange={date => this.onChangeDate(date, 'dtf')}
                    label='Data final' cols='12 6' placeholder='Digite aqui' date={this.state.dtf} />

              </div>
              <button type="submit" className="btn btn-block btn-success">Buscar</button>
            </form>
          </div>
        </ReactModal>
      </div>
    )
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Modal);
