import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Grid from '../layout/grid';
import Language from '../../config/language';
import './boxUser.scss';
import api from '../../api';
import {compressImage} from "../../config/methods";
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import ModalLoading from '../../sections/modals/modal-loading';

export default class BoxUser extends Component{
  constructor(props){
    super(props);
    this.state = {file: props.file};
    this.onChange = this.onChange.bind(this);
    this.saveImage = this.saveImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.file !== this.props.file){
      this.setState({file: nextProps.file});
    }
  }


  saveImage(file){
  	var mime_types = [ 'image/jpeg', 'image/png' ];

    if(!file){
      toastr.warning(Language.atencao, Language.noFileAdded);
      return;
    }
  	// Validate MIME type
  	if(mime_types.indexOf(file.type) === -1) {
      toastr.warning(Language.atencao, Language.incorrectTypeFile);
      return;
  	}

    var data = new FormData();
    data.append('file', file);

    //-------
    const token = this.props.token;
    const self = this;
    self.callLoading(true);
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.upload}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({file:data.file});
            self.props.onChange(data.file);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro03);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro04);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  onErrorImage(error){
    toastr.warning(Language.atencao, Language.errorGenerateImage);
  }

  onChange(el){
    compressImage({e: el, onComplete: this.saveImage, onError:this.onErrorImage, maxWidth: 1000});
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  render(){
    return(
      <Grid  cols='12 3' >
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        <div className='box-user'>
          <input type="file" onChange={this.onChange}  accept="image/jpeg, image/png" />
          {
            this.state.file
            ? <img src={this.state.file} alt="Foto" />
            : <i className='fa fa-user-plus'></i>
          }
        </div>
      </Grid>
    );
  }
}
