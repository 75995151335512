import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import CRM from '../reports/vendas-clientes-ativos';
ReactModal.setAppElement('#root');

const INITIAL_STATE = {};

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = INITIAL_STATE;
  }

  render(){
    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.closeHandler}
          shouldCloseOnOverlayClick={false}
        >
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <CRM />
          </div>
        </div>
        </ReactModal>
      </Fragment>
    )
  }
}
