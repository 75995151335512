import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import api from '../../api';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import ModalAgendamento from '../modals/modal-agendamento';
import ModalPlano from '../modals/modal-plano';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';

const INITIAL_STATE_TABS = {
  data_inicial: new Date(),
  data_final: '',
  plano: '',
  vendedor: '',
  finalizado: '',
  tipo_venda: '',
  avaliador: '',
  status: '',
  loaded: false,
  servico: '',
  check_plano: ''
};

const optionsFinalizado = CONSTS.VENDA_FINALIZADA;
const optionsTipo = CONSTS.TIPOS_VENDA_CORTESIA;
const optionsStatusVenda = CONSTS.STATUS_VENDAS;

const optionsPlanosAvulsos = [
  {value: '',label:'Tanto faz'},
  {value:'planos',label:'Planos'},
  {value:'avulsos',label:'Avulsos'},
];

class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.vendas.map((value, i) => [
      value.cliente.nome,
      value.cliente.telefone,
      value.cliente.whatsapp,
      value.data,
      value.criado_por,
      value.avaliado_por,
      value.tipo_venda,
      value.valor.formated
    ]);
    arr.unshift([
      "Cliente",
      "Telefone",
      "Whatsapp",
      "Data",
      "Criado por",
      "Avaliado por",
      "Tipo",
      "Total"
    ]);
    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "vendas.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {};

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    if(this.state.data_final){
      query.data_final = convertToBr(this.state.data_final);
    }

    if(this.state.status){
      query.status = this.state.status.value;
    }

    if(this.state.tipo_venda){
      query.tipo_venda = this.state.tipo_venda.value;
    }

    if(this.state.vendedor){
      query.vendedor = this.state.vendedor.value;
    }

    if(this.state.avaliador){
      query.avaliador = this.state.avaliador.value;
    }

    if(this.state.plano && this.state.plano !== ''){
      query.plano = this.state.plano.value;
    }

    if(this.state.sala && this.state.sala !== ''){
      const salas = this.state.sala.map((v,i)=> v.value);
      query.salas = salas;
    }

    if(this.state.finalizado){
      query.finalizado = this.state.finalizado.value;
    }

    if(this.state.check_plano){
      query.check_plano = this.state.check_plano.value;
    }

    if(this.state.servico && this.state.servico !== ''){
      query.servicos = this.state.servico.value;
    }

    return query;
  }

  loadData(item){

    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroData);
      return;
    }
    if(!this.state.data_final){
      toastr.warning(Language.atencao, 'Selecione a data final');
      return;
    }
    // if(!this.state.plano){
    //   toastr.warning(Language.atencao, 'Selecione o plano');
    //   return;
    // }
    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.vendasGerais}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({total: data.total, vendas: data.vendas, loaded: true});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  getBody(){
    const list = this.state.vendas || [];
    
    return (
      <div>
        <div className='clearfix' style={{paddingBottom: 20}}>
          <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
          <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
        </div>
        <div className='clearfix' style={{paddingBottom: 20}}>
          <div className="total_reports ">Total de vendas: {this.state.total.formated}</div>          
        </div>
        <Box classes="box-success" title="Resultado">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Plano</th>
                        <th>Whatsapp</th>
                        <th>Data</th>
                        <th>Criado por</th>
                        <th>Avaliado por</th>
                        <th>Tipo</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    list.map(bc => <tr>
                      <td>{bc.cliente.nome}</td>
                      <td>{bc.avulso ? 'Não' : 'Sim'}</td>
                      <td>{bc.cliente.whatsapp}</td>
                      <td>{bc.data}</td>
                      <td>{bc.criado_por}</td>
                      <td>{bc.avaliado_por}</td>
                      <td>{bc.tipo_venda}</td>
                      <td>{bc.valor.formated}</td>
                    </tr>)
                  }
                </tbody>
            </table>
          </div>
        </Box>
      </div>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
    const urlplano = `${api.API_URL}${api.API.planos.autocomplete}`;
    const planoQuery = {};
    const urlServico = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const servicoQuery = {};

    return(
      <Fragment>
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          //tipo={'crm_detail'}
        />
        <ModalPlano
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Plano'
          isOpen={this.state['modalPlano']}
          onRequestClose={()=>this.closeModal('modalPlano')}
          dados={this.state.dadosPlano}
        />
        <ContentHeader title='Vendas' small='' />
        
        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate required name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data inicial' cols='12 2' date={this.state.data_inicial} />
            <LabelAndDate required name='data_final' value={this.state.data_final} onChange={data => {this.onChangeDate(data, 'data_final');}}
                label='Data final' cols='12 2' date={this.state.data_final} />
            <LabelAndSelect name='check_plano' options={optionsPlanosAvulsos} value={this.state.check_plano} handleChange={this.handleSelect}
              label='Plano ou avulsos' cols='12 2' placeholder='Digite aqui' />

            <LabelAndSelectAjax
              name='plano'
              value={this.state.plano}
              onChange={this.handleSelect}
              url={urlplano}
              token={this.props.token}
              label='Selecione um plano'
              cols='12 3'
              query={planoQuery}
              placeholder='Digite aqui' />

              <LabelAndSelectAjax
                name='servico'
                value={this.state.servico}
                onChange={this.handleSelect}
                url={urlServico}
                token={this.props.token}
                label='Selecione um serviço'
                cols='12 3'
                
                query={servicoQuery}
                placeholder='Digite aqui' />
<Clearfix />
              <LabelAndSelectAjax
                  name='vendedor'
                  value={this.state.vendedor}
                  onChange={this.handleSelect}
                  url={urlVendedor}
                  token={this.props.token}
                  label='Selecione um vendedor'
                  cols='12 3'
                  query={{tipo:1}}
                  placeholder='Digite aqui' />
                <LabelAndSelectAjax
                  name='avaliador'
                  value={this.state.avaliador}
                  onChange={this.handleSelect}
                  url={urlVendedor}
                  token={this.props.token}
                  label='Selecione um avaliador'
                  cols='12 3'
                  // query={{tipo:1}}
                  placeholder='Digite aqui' />
                <LabelAndSelect name='tipo_venda' options={optionsTipo} value={this.state.tipo_venda}  handleChange={this.handleSelect}
                  label='Parceria/Brinde/Cortesia/Outros' cols='12 3' placeholder='Digite aqui' />
              <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>
          
          {this.state.loaded ? this.getBody() : null}
        </Content>

        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
