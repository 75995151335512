import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import $ from 'jquery';

import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndText from '../../common/form/labelAndText';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import GroupCheckbox from '../../common/form/groupCheckbox';

import MandatoryFields from '../../common/template/mandatoryFields';
import CONSTS from '../../config/consts';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalDelete from '../modals/modal-delete';
import ModalLoading from '../modals/modal-loading';
import Bloqueios from './bloqueios';

const INITIAL_STATE = {
  sala: '',
  tipo: '',
  qnt_atendimentos: '',
  observacoes: '',
  servicos: []
}
const sim_nao = [{value: 0, label:'Sim', id:"tipo"}];

class Form extends Component {

  constructor(props){
    super(props);

    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: !this.props.detail
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.update();
        break;
      default:
        this.create();
    }

    e.preventDefault();
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    this.callLoading(true);
    self.removeModalDeleteItem();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.salas.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {

        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        this.callLoading(false);
      }
    });
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  beforeSend(){
    const data = {...this.state};

    delete data.loaded;
    delete data.servicos;
    delete data.errorLoadingPage;

    return data;
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.salas.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            //self.props.keepCreating(data.data);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.salas.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        this.callLoading(false);
      }
    });
  }

  initData(response){

    this.setState({
      ...response
    });

    if(this.props.detail.statusCreated){
      $('html,body').animate({
        scrollTop: $("#content-ajax").offset().top},
        'slow');
    }
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.salas.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            const response = data.data;
            self.initData(response);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response =>{
        self.loaded();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  getBloqueios(){
    const token= this.props.user;
    const { readOnly } = this.props;
    return <Bloqueios
      readOnly={readOnly}
      key={Math.random()}
      bloqueios={this.state.bloqueios}
      token={token}
      sala={this.props.detail.id}
    />;
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  renderContent() {
    const { readOnly } = this.props;
    const bloqueios = this.props.detail ? this.getBloqueios() : null;
    //const tipo = this.state.tipo ? [{value:1, id:"tipo"}] : [];

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
            <div className='box-body'>
              <Box classes="box-success" title="Dados gerais">
                {/*<GroupCheckbox  onChange={this.handleInputChange} value={tipo} options={sim_nao} label="Sala de avaliação" cols="12" />*/}
                <LabelAndInput required name='sala' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.sala}
                  label='Nome da sala' cols='12 8' placeholder='Informe o nome' />
                <LabelAndInput required type="number" name='qnt_atendimentos' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.qnt_atendimentos}
                  label='Qnt. de atendimentos simultâneos' cols='12 4' />
                <Clearfix />
                <LabelAndText name='observacoes'  type='text' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.observacoes}
                    label='Observações' cols='12 6' placeholder='Digite aqui' />
                <Clearfix />
                <MandatoryFields />
                <div className='box-footer'>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button type='button' className='btn btn-default'
                        onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
                </div>
              </Box>

            </div>
        </form>
        {
          bloqueios
        }
      </div>
    )
  }

  render() {
    const tempBox = this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent();

    return (
        <div>
          <ModalDelete
            onCancel={ () => this.deleteItem() }
            className='Modal_Delete'
            isOpen={this.state['ModalDeleteItem']}
            onRequestClose={()=>this.removeModalDeleteItem()}
          />
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
          {
            this.state.loaded ?
            tempBox
            : <LoadingPage />
          }
        </div>
    )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
