import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import api from '../api';
import CONSTS from '../config/consts';
import Requisition from '../utils/requisitions/requisition';
import Language from '../config/language';
//import {readCookie} from '../utils/cookies';

export function login(values) {
  /*return dispatch => dispatch([
      { type: 'USER_FETCHED', payload: {} }
  ]);*/
    const cookie = localStorage.getItem('app');
    return submit(values, `${api.API_URL}${api.API.login}${cookie ? "?loadedby="+cookie : ""}`);
}

export function signup(values) {
    return submit(values, `${api.API_URL}${api.API.signup}`);
}

function submit(values, url) {
  return dispatch => {
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE
      },
      url: url,
      data: values,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.hello, data.msg);
            dispatch([
                { type: 'USER_FETCHED', payload: response.data.data }
            ]);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }
}

export function logout() {
    return { type: 'TOKEN_VALIDATED', payload: false }
}

export function validateToken(token) {
    return dispatch => {
        if (token) {
            axios.post(`${api.API.API_URL}/validateToken`, { token })
                .then(resp => {
                    dispatch({ type: 'TOKEN_VALIDATED', payload: resp.data.valid })
                })
                .catch(e => dispatch({ type: 'TOKEN_VALIDATED', payload: false }))
        } else {
            dispatch({ type: 'TOKEN_VALIDATED', payload: false })
        }
    }
}
