import React from 'react';
import Grid from '../layout/grid';
import './labelAndInput.scss';

export default props => (
    <Grid cols={props.cols}>
        <div className={`${props.required ? "field-mandatory" : "" } form-group`}>
            <label htmlFor={props.name}>{props.label}</label>
            <input {...props.input} className={`${props.classes} form-control`}
                name={props.name}
                 placeholder={props.placeholder}
                 value={props.value ? props.value : ""}
                 onChange={props.handleChange}
                 readOnly={props.readOnly} type={props.type} />
                 {props.obs ? <span className="obs-input">{props.obs}</span> : ''}
        </div>
    </Grid>
)
