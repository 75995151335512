import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import api from '../../api';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import ModalAgendamento from '../modals/modal-agendamento';
import ModalPlano from '../modals/modal-plano';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
const optionsStatus = CONSTS.STATUS_AGENDAMENTO;
const INITIAL_STATE_TABS = { agendamentos: [], loaded: false, status: optionsStatus[3], totalReceber: '' };
const optionsTiposPagamento = CONSTS.METODOS_PAGAMENTO;

const optionsPlanosAvulsos = [
  {value: '',label:'Tanto faz'},
  {value:'planos',label:'Planos'},
  {value:'avulsos',label:'Avulsos'},
];
class Vendas extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.agendamentos.map((value, i) => [
      value.id_venda,
      value.nome_cliente,
      value.criado_por,
      value.data,
      value.servicos,
      value.valor ? value.valor.formated : '',
      value.avulso
    ]);
    arr.unshift(["Venda","Cliente", "Criado por", "Data", "Serviços", "Valor", "Avulso/Plano"]);

    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "vendas.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {};

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    if(this.state.data_final){
      query.data_final = convertToBr(this.state.data_final);
    }

    if(this.state.cliente){
      query.cliente = this.state.cliente.value;
    }

    if (this.state.tipo_venda) {
      query.tipo_venda = this.state.tipo_venda.value;
    }

    if(this.state.status){
      query.status = this.state.status.value;
    }

    if(this.state.brinde){
      query.brinde = this.state.brinde.value;
    }

    if(this.state.profissional && this.state.profissional !== ''){
      query.profissionais = [this.state.profissional.value];
    }

    if(this.state.servico && this.state.servico !== ''){
      const servicos = this.state.servico.map((v,i)=> v.value);
      query.servicos = servicos;
    }

    if(this.state.sala && this.state.sala !== ''){
      const salas = this.state.sala.map((v,i)=> v.value);
      query.salas = salas;
    }

    if(this.state.equipamento && this.state.equipamento !== ''){
      const equipamentos = this.state.equipamento.map((v,i)=> v.value);
      query.equipamentos = equipamentos;
    }

    if(this.state.check_plano){
      query.check_plano = this.state.check_plano.value;
    }

    if(this.state.vendedor){
      query.vendedor = this.state.vendedor.value;
    }

    if(this.state.tipo_pagamento){
      query.tipo_pagamento = this.state.tipo_pagamento.value;
    }

    return query;
  }

  loadData(item){

    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroDataAll);
      return;
    }
    if(!this.state.data_final){
      toastr.warning(Language.atencao, Language.escolhaFiltroDataAll);
      return;
    }

    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.financeiro}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({agendamentos: data.data, total_vendido: data.total_vendido, loaded: true});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  getBody(){
    return (
      <Content>
        <div className='clearfix' style={{paddingBottom: 20}}>
          <span className='pull-left' style={{fontSize: 20}}>Total vendido: <strong>{`${this.state.total_vendido ? this.state.total_vendido.formated : ''}`}</strong></span>
          <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
          <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
        </div>
        <Box classes="box-success" title="Vendas realizadas">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Venda</th>
                        <th>Cliente</th>
                        <th>Criado por</th>
                        <th>Data</th>
                        <th>Valor</th>
                        <th>Serviços</th>
                        <th>Avulso/Plano</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    this.state.agendamentos.map(bc => (
                      <tr key={bc.id}>

                        <td>{bc.id_venda}</td>
                        <td>{bc.nome_cliente}</td>
                        <td>{bc.criado_por}</td>
                        <td>{bc.data}</td>
                        <td>{bc.valor ? bc.valor.formated : ''}</td>
                        <td>{bc.servicos}</td>
                        <td>{bc.avulso}</td>
                      </tr>
                    ))
                  }
                </tbody>
            </table>
          </div>
        </Box>
      </Content>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
    return(
      <Fragment>
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          //tipo={'crm_detail'}
        />
        <ModalPlano
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Plano'
          isOpen={this.state['modalPlano']}
          onRequestClose={()=>this.closeModal('modalPlano')}
          dados={this.state.dadosPlano}
        />
        <ContentHeader title='Vendas realizadas' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate required name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data inicial' cols='12 2' date={this.state.data_inicial} />
            <LabelAndDate required name='data_final' value={this.state.data_final} onChange={data => {this.onChangeDate(data, 'data_final');}}
                label='Data final' cols='12 2' date={this.state.data_final} />
            <LabelAndSelect name='check_plano' options={optionsPlanosAvulsos} value={this.state.check_plano}handleChange={this.handleSelect}
              label='Plano ou avulsos' cols='12 2' placeholder='Digite aqui' />
            <LabelAndSelectAjax
              name='vendedor'
              value={this.state.vendedor}
              onChange={this.handleSelect}
              url={urlVendedor}
              token={this.props.token}
              label='Selecione um vendedor'
              cols='12 3'
              query={{tipo:1}}
              placeholder='Digite aqui' />
              <LabelAndSelect  name='tipo_pagamento' options={optionsTiposPagamento} value={this.state.tipo_pagamento} handleChange={this.handleSelect}
                label='Tipo de pagamento' cols='12 2' placeholder='Digite aqui' />
            <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : null}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Vendas);
