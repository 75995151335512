import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import Grid from '../../common/layout/grid';
import ModalLoading from '../modals/modal-loading';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import If from '../../common/operator/if';
import Language from '../../config/language';

import './itemFoto.scss';

class ItemFoto extends Component{
  constructor(props){
    super(props);
    this.state = {item:props.data};
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(){
    const id = this.state.item.id;

    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.fotosProcedimentos.delete.replace(/{id}/,id)}`,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.setState({removed: true});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  render(){
    return(
      <If test={!this.state.removed}>
        <Grid cols="3">
          <div className="box-image-user">
            <div className="wrapper-img-user" onClick={()=>this.props.onClick(this.props.index)}>
              <img src={this.props.data.foto} />
            </div>
            <div className="box-image-user__buttons">
              <button onClick={this.clickHandler} type="button" class="btn btn-block btn-danger btn-xs">Remover</button>
            </div>
          </div>
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        </Grid>
      </If>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(ItemFoto);
