import React, {Component} from 'react';
import { maskMoney } from '../../config/methods';
import If from '../../common/operator/if';

import './footerPlan.scss';
const INIT_VALUE = {formated: '00,00', amount: '00.00'};
export default class FooterPlan extends Component{
  constructor(props){
    super(props);
    this.state = {...props};
  }

  componentDidUpdate(prevProps) {
    let final = INIT_VALUE;
    let parcial = INIT_VALUE;

    if (this.props.a_pagar !== prevProps.a_pagar) {
      parcial = this.props.a_pagar;
      this.setState({a_pagar:parcial});
    }
    if (this.props.pago !== prevProps.pago) {
      final = this.props.pago;
      this.setState({pago:final});
    }
  }

  getRestante(){
    const total = (this.state.pago ? this.state.pago.amount : 0);
    const valor = this.state.a_pagar.amount - total;
    return maskMoney(valor.toFixed(2));
  }

  render(){
    const { readOnly } = this.props;

    return(
      <div className='bottom-planos'>
        <div className='bottom-planos__box'>
          <div className='box-body bottom-plano-box-body'>
            <If test={!this.props.tipoVenda}>
              <div className='pull-left box-left-bottom-planos'>
                <div className='pull-left'>
                  <span className='txt-preco-parcial'>A pagar: </span>
                  <span className='txt-preco-pacial-valor'><strong>R$ {this.state.a_pagar ? this.state.a_pagar.formated : INIT_VALUE.formated}</strong></span>
                </div>
                <div className='pull-left'>
                  <span style={{paddingLeft: 10, paddingRight: 10}}>-</span>
                </div>
                <div className='pull-left'>
                  <span className='txt-preco-parcial'>Restante: </span>
                  <span className='txt-preco-pacial-valor'><strong>R$ {this.state.a_pagar ? this.getRestante() : INIT_VALUE.formated}</strong></span>
                </div>
              </div>
            </If>
            <div className="center-bottom-planos">
              <button type="button" className="bounce btn btn-primary btn-warning" onClick={this.props.finalizarVenda}>{`${readOnly ? "Reabrir venda" : "Finalizar venda"}`}</button>
            </div>
            <If test={!this.props.tipoVenda}>
              <div className='pull-right txt-preco-final'>
                <span className=' txt-preco-final_1'>Pago - </span>
                <span><strong>R$ {this.state.pago ? this.state.pago.formated : INIT_VALUE.formated}</strong></span>
              </div>
            </If>
          </div>
        </div>
      </div>
    );
  }
}
