import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import api from '../../api';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import ModalAgendamento from '../modals/modal-agendamento';
import ModalPlano from '../modals/modal-plano';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
import GroupCheckbox from '../../common/form/groupCheckbox';
const INITIAL_STATE_TABS = {
  agendamentos: [],
  loaded: false,
  equipamento: '',
  data_inicial: new Date(),
  data_final: '',
  status: '',
  brinde: '',
  cliente: '',
  profissional: '',
  servico: '',
  sala: '',
  check_plano: '',
  primeira_sessao: false,
  ultima_sessao: false
};

const primeiraSessaoCheckbox = [
  {value: 1, label:'Primeira sessão', id:"primeira_sessao"}
];

const ultimaSessaoCheckbox = [
  {value: 1, label:'Última sessão', id:"ultima_sessao"}
];

class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sanitizeData = this.sanitizeData.bind(this);
  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.agendamentos.map((value, i) => [
      value.cliente + ' '+ value.cliente_sobrenome,
      value.servico,
      value.profissional,
      value.whatsapp,
      value.primeiraSessao ? 'Primeira' : '',
      value.ultimaSessao ? 'Última' : ''
    ]);
    arr.unshift(["Cliente", "Serviço", "Profissional", "Whatsapp", "Primeira/Última"]);
    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "agendamentos.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {};

    if(this.state.data_inicial){
      query.date = convertToBr(this.state.data_inicial);
    }

    if(this.state.primeira_sessao){
      query.primeira_sessao = 1;
    }

    if(this.state.ultima_sessao){
      query.ultima_sessao = 1;
    }

    return query;
  }

  loadData(item){
    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroData);
      return;
    }
    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.agendamentos.list}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.sanitizeData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {console.log(response)
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  sanitizeData(data){
    const self = this;
    const agendamentosArray = [];
    data.map( item => {
      if(!item.agendamentos.length) return null;
      const _agendamentos = item.agendamentos;

      _agendamentos.map( agendamento => {
        if(!agendamento.agendamento) return null;

        const _ag = agendamento.agendamento[0];
        const n_agendamento = parseInt(_ag.n_agendamento);
        const n_sessoes = parseInt(_ag.n_sessoes);

        if(n_sessoes === 1) return null;

        _ag.primeiraSessao = n_agendamento === 1;
        _ag.ultimaSessao = n_agendamento === n_sessoes;

        if(self.state.primeira_sessao && n_agendamento === 1){
          agendamentosArray.push(_ag);
          return null;
        }
        if(self.state.ultima_sessao && n_agendamento === n_sessoes){
          agendamentosArray.push(_ag);
          return null;
        }

        if(!self.state.primeira_sessao && !self.state.ultima_sessao ){
          agendamentosArray.push(_ag);
        }
        
        return null;
      });
      return null;
    });

    this.setState({agendamentos: agendamentosArray, loaded: true});
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getBody(){
    return (
      <Content>
        <div className='clearfix' style={{paddingBottom: 20}}>
          <span className="total_reports pull-left">Total: {this.state.agendamentos.length}</span>
          <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
          <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
        </div>
        <Box classes="box-success" title="Resultado">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Serviço</th>
                        <th>Profissional</th>
                        <th>Whatsapp</th>
                        <th>Primeira/Última</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    this.state.agendamentos.map(bc => (
                      <tr key={bc.id}>
                        <td>{bc.cliente} {bc.cliente_sobrenome}</td>
                        <td>{bc.servico}</td>
                        <td>{bc.profissional}</td>
                        <td>{bc.whatsapp}</td>
                        <td>
                          {bc.primeiraSessao ? 'Primeira' : ''}
                          {bc.ultimaSessao ? 'Última' : ''}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
            </table>
          </div>
        </Box>
      </Content>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    const primeira_sessao = this.state.primeira_sessao ? [{value:1, id:"primeira_sessao"}] : [];
    const ultima_sessao = this.state.ultima_sessao ? [{value:1, id:"ultima_sessao"}] : [];
    return(
      <Fragment>
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          //tipo={'crm_detail'}
        />
        <ModalPlano
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Plano'
          isOpen={this.state['modalPlano']}
          onRequestClose={()=>this.closeModal('modalPlano')}
          dados={this.state.dadosPlano}
        />
        <ContentHeader title='Agendamentos primeira/última sessão' small='' />

        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate required name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data' cols='12 2' date={this.state.data_inicial} />
              <GroupCheckbox  onChange={this.handleInputChange} value={primeira_sessao} options={primeiraSessaoCheckbox} label="" cols="12 3" />
              <GroupCheckbox  onChange={this.handleInputChange} value={ultima_sessao} options={ultimaSessaoCheckbox} label="" cols="12 3" />
              <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : null}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
