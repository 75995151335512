import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import api from '../../api';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import ModalAgendamento from '../modals/modal-agendamento';
import ModalPlano from '../modals/modal-plano';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
const INITIAL_STATE_TABS = { agendamentos: [], loaded: false };

class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.agendamentos.map((value, i) => [
      value.data,
      value.hora_inicial,
      value.hora_final,
      value.profissional ? value.profissional : '',
      value.cliente ? value.cliente : '',
      value.sala ? value.sala : '',
      value.servico ? value.servico : '',
      value.equipamento ? value.equipamento : '',
      value.status.tipo ? value.status.tipo : ''
    ]);
    arr.unshift(["Data", "Hora inicial", "Hora final", "Profissional", "Cliente", "Sala", "Serviço", "Equipamento", "Status"]);
    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "agendamentos.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {};

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    if(this.state.data_final){
      query.data_final = convertToBr(this.state.data_final);
    }

    return query;
  }

  loadData(item){

    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroData);
      return;
    }
    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.aniversariantes}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({agendamentos: [...data.data], loaded: true});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  callWhats(item){
    const url = `https://api.whatsapp.com/send?phone=55${item.whatsapp}&text=/`;
    var node = document.createElement('textarea');
    var selection = document.getSelection();

    node.textContent = url;
    document.body.appendChild(node);

    selection.removeAllRanges();
    node.select();
    document.execCommand('copy');

    selection.removeAllRanges();
    document.body.removeChild(node);

    toastr.success(Language.atencao, "Dados copiados com sucesso");
  }

  getBody(){
    return (
      <Content>
        <div className='clearfix' style={{paddingBottom: 20}}>
          <span className="total_reports pull-left">Total: {this.state.agendamentos.length}</span>
        </div>
        <Box classes="box-success" title="Resultado">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                      <th>Cliente</th>
                      <th>Data</th>
                      <th>Whatsapp</th>
                      <th>Unidade</th>
                      <th className='table-actions-single hideOnprint'></th>
                    </tr>
                </thead>
                <tbody>
                  {
                    this.state.agendamentos.map(bc => (
                      <tr key={bc.id}>
                        <td>{bc.nome} {bc.sobrenome}</td>
                        <td>{bc.data_nascimento}</td>
                        <td>{bc.whatsapp}</td>
                        <td>{bc.unidade}</td>
                        <td className="hideOnprint">
                          {
                            bc.whatsapp ?
                            <button type="button" className='btn btn-success' onClick={() => this.callWhats(bc)}>
                                <i className='fa fa-whatsapp'></i>
                            </button>
                            : ''
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
            </table>
          </div>
        </Box>
      </Content>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    return(
      <Fragment>
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          //tipo={'crm_detail'}
        />
        <ModalPlano
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Plano'
          isOpen={this.state['modalPlano']}
          onRequestClose={()=>this.closeModal('modalPlano')}
          dados={this.state.dadosPlano}
        />
        <ContentHeader title='Aniversariantes' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data inicial' cols='12 2' date={this.state.data_inicial} />
            <LabelAndDate name='data_final' value={this.state.data_final} onChange={data => {this.onChangeDate(data, 'data_final');}}
                label='Data final' cols='12 2' date={this.state.data_final} />
              <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : null}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
