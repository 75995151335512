import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Box from '../../common/template/box';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import ModalRetirada from './modal-retirada';
import ModalLoading from '../modals/modal-loading';
export default class Retiradas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalLoading: false,
      ...props.data
    }
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  componentDidMount() {
  }

  openModal(modal, item) {

    const data = {
      [modal]: true,
      itemModal: item
    };

    this.setState(data);
  }

  afterOpenModal() {
  }

  closeModal(modal, data) {
    const state = { [modal]: false };
    let histUpdated = [];

    if (data && data.servico) {
      if (data.type && data.type === "update") {
        histUpdated = this.state.historico.map(i => {
          return i.id === data.servico.id ? data.servico : i;
        }
        );
      } else {
        histUpdated = [...this.state.historico, data.servico];
      }
      state.historico = histUpdated;
    }
    this.setState(state);
  }


  removeItem(id) {
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY]: CONSTS.API_KEY_VALUE,
        'Auth': 'Bearer ' + token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.estoqueRetiradas.delete.replace(/{id}/, id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if (data) {
          if (data.status) {
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          } else {
            toastr.warning(Language.atencao, data.msg);
          }
        } else {
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeItemItem(id) {
    const arr = this.state.retiradas.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      retiradas: arr
    });
  }

  renderRows() {
    const list = this.state.retiradas || [];
    return list?.map(bc => (
      <tr key={bc.id}>
        <td>{bc.quantidade || '-'}</td>
        <td>{bc.observacoes || '-'}</td>
        <td>
          <div>
            <button type="button" className='btn btn-danger' onClick={() => this.removeItem(bc.id)}>
              <i className='fa fa-trash-o'></i>
            </button>
          </div>
        </td>
      </tr>
    ));
  }

  onCreate(itens) {
    this.setState({ modalCaixa: false, retiradas: itens?.retiradas ? [...itens?.retiradas] : itens });
  }

  render() {
    return (
      <div id="content-ajax">
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        <Box classes="box-success" title="Retiradas">
          <ModalRetirada
            token={this.props.token}
            className='Modal_Caixa'
            isOpen={this.state['modalCaixa']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={() => this.closeModal('modalCaixa')}
            item={this.props.item}
            onCreate={data => this.onCreate(data)}
            id={this.state.itemModal}
          />
          <div>
            {
              !this.props.readOnly ? <button type="button" className="btn btn-block btn-success" onClick={() => this.openModal('modalCaixa')}>
                <i className="fa fa-plus"></i>  Novo item
              </button> : null
            }

            <br />
            <br />
          </div>

          <br />
          <br />
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th className="">Quantidade</th>
                  <th className="">Observações</th>
                  <th className='table-actions'></th>
                </tr>
              </thead>
              <tbody>
                {this.renderRows()}
              </tbody>
            </table>
          </div>
        </Box>

      </div>
    );
  }
}
