import React, { Component } from 'react';
import ReactModal from 'react-modal';
import TitleModal from '../../common/form/titleModal';
import './modal-alert.scss';

ReactModal.setAppElement('#root');

export default class Modal extends Component{

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    return(
      <ReactModal
        portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
      >
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            {this.props.content
            ? this.props.content
            : (
                <div>
                  <TitleModal title={this.props.title} />
                  <div className="box-body">
                    <span style={{fontSize: 20}}>{this.props.message}</span>
                  </div>
                </div>
              )}
          </div>
          <button type="button" onClick={this.props.onRequestClose} className="btn btn-default">Fechar</button>
        </div>
      </ReactModal>
    )
  }
}
