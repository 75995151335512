import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import api from '../../api';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
const optionsTipo = CONSTS.TIPOS_CONTAS;
const optionsStatus = CONSTS.STATUS_AGENDAMENTO;
const INITIAL_STATE_TABS = { pagamentos: [], loaded: false, status: optionsStatus[3], totalReceber: '' };

class Vendas extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.pagamentos.map((value, i) => [
      value.data,
      value.nome,
      value.valor ? value.valor.formated : '',
      value.tipo ? value.tipo.formated : ''
    ]);
    arr.unshift(["Data","Conta", "Valor", "Tipo"]);

    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "relatorio.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {};

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    if(this.state.data_final){
      query.data_final = convertToBr(this.state.data_final);
    }

    if(this.state.profissional && this.state.profissional !== ''){
      query.profissional = this.state.profissional.value;
    }
    if(this.state.colaborador && this.state.colaborador !== ''){
      query.colaborador = this.state.colaborador.value;
    }
    if(this.state.fornecedor && this.state.fornecedor !== ''){
      query.fornecedor = this.state.fornecedor.value;
    }
    if(this.state.tipo_conta && this.state.tipo_conta !== ''){
      query.tipo_conta = this.state.tipo_conta.value;
    }
    return query;
  }

  loadData(item){

    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroDataAll);
      return;
    }
    if(!this.state.data_final){
      toastr.warning(Language.atencao, Language.escolhaFiltroDataAll);
      return;
    }

    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.contas}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({pagamentos: data.data, totalReceber: data.total, loaded: true});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  getBody(){
    return (
      <Content>
        <div className='clearfix' style={{paddingBottom: 20}}>
          <span className='pull-left' style={{fontSize: 20}}>Total: <strong>{`${this.state.totalReceber ? this.state.totalReceber.formated : ''}`}</strong></span>
          <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
          <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
        </div>
        <Box classes="box-success" title="Contas encontradas">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                      <th>Data</th>
                      <th>Conta</th>
                      <th>Valor</th>
                      <th>Tipo</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    this.state.pagamentos.map(bc => (
                      <tr key={bc.id}>
                        <td>{bc.data}</td>
                        <td>{bc.nome}</td>
                        <td>{bc.valor ? bc.valor.formated : ''}</td>
                        <td>{bc.tipo_conta}</td>
                      </tr>
                    ))
                  }
                </tbody>
            </table>
          </div>
        </Box>
      </Content>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
    const urlFornecedor = `${api.API_URL}${api.API.fornecedores.autocomplete}`;

    return(
      <Fragment>
        <ContentHeader title='Relatórios de contas' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate required name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data inicial' cols='12 2' date={this.state.data_inicial} />
            <LabelAndDate required name='data_final' value={this.state.data_final} onChange={data => {this.onChangeDate(data, 'data_final');}}
                label='Data final' cols='12 2' date={this.state.data_final} />
                <LabelAndSelect  name='tipo_conta' options={optionsTipo} value={this.state.tipo_conta} handleChange={this.handleSelect}
                  label='Tipo de conta' cols='12 3' placeholder='Digite aqui' />
                <LabelAndSelectAjax
                  name='profissional'
                  value={this.state.profissional}
                  onChange={this.handleSelect}
                  url={urlVendedor}
                  token={this.props.token}
                  label='Vincular conta a um profissional'
                  cols='12 3'
                  query={{tipo:2}}
                  placeholder='Digite aqui' />
                <LabelAndSelectAjax
                  name='colaborador'
                  value={this.state.colaborador}
                  onChange={this.handleSelect}
                  url={urlVendedor}
                  token={this.props.token}
                  label='Vincular conta a um funcionário'
                  cols='12 3'
                  query={{tipo:1}}
                  placeholder='Digite aqui' />
                <LabelAndSelectAjax
                  name='fornecedor'
                  value={this.state.fornecedor}
                  onChange={this.handleSelect}
                  url={urlFornecedor}
                  token={this.props.token}
                  label='Vincular conta a um fornecedor'
                  cols='12 3'
                  query={{tipo:1}}
                  placeholder='Digite aqui' />
            <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : null}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Vendas);
