import React from 'react';
import Grid from '../layout/grid';
import './groupCheckbox.scss';

export default props => (
  <Grid cols={props.cols}>
    <label htmlFor={props.name}>{props.label}</label>
    <div className='box-body box-body__group-checkbox'>
    {
      props.options.map((item, i) =>{
        let checked = false;

        if(props.value){
          for (var j = 0; j < props.value.length; j++) {
            const el = props.value[j];
            if((String(el.id) === String(item.id)) && el.value){
              checked = 1;
            }
          }
        }
        const readOnly = props.readOnly ? true : item.readOnly;

        return(
          <Grid cols={item.cols} key={i}>
            <div className="form-group">
              <div className="checkbox">
                <label className={`${readOnly ? " cursor-disabled " : ""}`}>
                  <input {...item.input} type="checkbox" checked={checked} name={item.id}
                       placeholder={item.placeholder} onChange={props.onChange}
                       disabled={readOnly} />
                    {item.label}
                </label>
              </div>
            </div>
          </Grid>
          );
        }
      )
    }
    </div>
  </Grid>
);
