import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import { connect } from 'react-redux';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import Pagination from '../../common/template/pagination';
import EmptyList from '../../common/template/emptyList';
import { urlParamsConverter } from '../../config/methods';

const QUERY_INIT = { page: 1 };

class List extends Component {
  constructor(props){
    super(props);

    this.state = {
      loaded: false,
      list: [],
      pageActive: 1,
      query: QUERY_INIT,
      errorLoadingPage: false,
      pagination: CONSTS.PAGINATE
    };
    this.changePage = this.changePage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //existe uma nova query vinda de uma busca
    if (nextProps.query !== this.props.query) {
      this.setState({query: nextProps.query, loaded: false}, () => this.loadPage());
    }
  }

  componentWillMount() {
    this.loadPage();
  }

  loadPage(page){
    const token = this.props.user;
    const self = this;
    const query = `?${urlParamsConverter(this.state.query)}`;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.servicos.list}${query}`,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();

          if(data.status){
            self.setState({
              list: data.data,
              pagination: data.pagination
            });
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setErrorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setErrorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.loaded();
        self.setErrorLoadingPage();
      }
    });
  }

  changePage(goTo){
    let page;
    if (goTo==='next') {
      page = this.state.pagination.next;
    }else if(goTo==='prev'){
      page = this.state.pagination.prev;
    }else{
      page = goTo;
    }

    this.setState({
      ModalLoading: true,
      query: {...this.state.query, page}
    }, () => this.loadPage());
  }

  setErrorLoadingPage(){
    this.setState({
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  renderRows() {
      const list = this.state.list || [];
      return list.map(bc => (
          <tr key={bc.id}>
              <td>{bc.servico}</td>
              <td>{bc.comissao ? bc.comissao : "-"}</td>
              <td>{bc.tipo_comissao ? bc.tipo_comissao.value : "-"}</td>
              <td>
                  <button className='btn btn-warning' onClick={() => this.props.showUpdate(bc)}>
                      <i className='fa fa-pencil'></i>
                  </button>
                  <button className='btn btn-danger' onClick={() => this.props.showDelete(bc)}>
                      <i className='fa fa-trash-o'></i>
                  </button>
              </td>
          </tr>
      ))
  }

  renderTable(){
    return (
      <div>
        {
          this.state.list.length ?
            <div>
              <table className='table'>
                  <thead>
                      <tr>
                          <th>Nome</th>
                          <th>Comissão</th>
                          <th>Tipo de comissão</th>
                          <th className='table-actions'></th>
                      </tr>
                  </thead>
                  <tbody>
                      {this.renderRows()}
                  </tbody>
              </table>
              <Pagination setPage={this.changePage} onClick={this.changePage} dados={this.state.pagination} />
            </div>
          : <EmptyList />
        }
      </div>
    );
  }

  render() {
      const tempBox = this.state.errorLoadingPage ? <ErrorPage /> : this.renderTable();
      return (
          <div>
            {
              this.state.loaded ?
              tempBox
              : <LoadingPage />
            }
          </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(List);
