import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import Box from '../../common/template/box';
import ErrorPage from '../../common/template/errorPage';
import LoadingPage from '../../common/template/loadingPage';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import Requisition from '../../utils/requisitions/requisition';


export default class BoxServicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      fichas:props.fichas
    };
    this.loadSigns = this.loadSigns.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fichas !== prevProps.fichas) {
      if(this.props.fichas){
        this.setState({fichas: this.props.fichas});
      }
    }
  }

  getFichas(){
    const list = this.state.fichas || [];

    return list.map(bc => (
      <div class='ficha-box'>
        {bc.assinatura ? <div class="ficha-assinatura"><img src={bc.assinatura} alt="Assinatura do cliente" /></div> : null }
        <div class="ficha-detalhe">
          <div class="ficha-detalhe__box">
            <div><strong>Data:</strong> {bc.data}</div>
            <div><strong>Hora:</strong> {bc.hora_inicial}</div>
            <div><strong>Tempo:</strong> {bc.tempo_atendimento}</div>
            <div><strong>Serviço:</strong> {bc.servico ? bc.servico.label : ''}</div>
            <div><strong>Profissional:</strong> {bc.profissional ? bc.profissional.label : ''}</div>
          </div>
        </div>
        <div class='clear'></div>
      </div>
    ));
  }

  loadSigns(){
    if (this.state.loading) {
      return;
    }
    const { token } = this.props;
    const self = this;
    const vendaId = this.props.venda;
    self.setState({loading: true});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.assinaturas}?venda=${vendaId}`,
      // data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({fichas: data.data.fichas, loading: false});
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setState({loading: false, error: true});
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setState({loading: false, error: true});
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({loading: false, error: true});
      }
    });
  }

  render(){

    return(
      <Box classes="box-success" title="Assinaturas de Sessões">

        {
          this.state.fichas && this.state.fichas.length ? null :
          <button type="button" className="btn btn-primary btn-primary" onClick={this.loadSigns}>Carregar assinaturas</button>
        }
        {
          this.state.error ? <ErrorPage /> : null
        }
        {
          this.state.loading ? <LoadingPage /> : null
        }
        {this.getFichas()}
      </Box>
    );
  }
}
