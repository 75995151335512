import React, {Component} from 'react';
import { toastr } from 'react-redux-toastr';
import { maskMoney, strPtBrMoneyToNumber } from '../../config/methods';
import CONSTS from '../../config/consts';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';

export default class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = props.data;
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  onBlur(e){

    if (!this.state.updated) {
      return false;
    }

    const self = this;
    const target = e.target;
    const value = strPtBrMoneyToNumber(String(target.value));

    this.callLoading(true);
    const token = this.props.token;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.vendasServicos.updateComissao.replace(/{id}/,this.state.id_venda_servico)}`,
      data: {comissao: value, tipo_comissao: 2},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({ updated: false });
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        this.callLoading(false);
      }
    });
  }

  onChange(e){
    const target = e.target;
    const name = target.name;
    let value = target.value;

    if(name === 'comissao'){
      value = maskMoney(value);
    }

    this.setState({
      [name]: value,
      updated: true
    });
  }

  render(){
    const bc = this.state;
    return(
      <tr key={bc.id}>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        <td>{bc.data}</td>
        <td>{bc.hora_inicial}</td>
        <td>{bc.profissional}</td>
        <td>{bc.cliente} {bc.cliente_sobrenome}</td>
        <td>{bc.servico}</td>
        <td>
          <input style={{width: 70}} value={this.state.comissao} name="comissao" onChange={this.onChange} onBlur={this.onBlur} />
        </td>
        <td>{bc.tipo_comissao ? bc.tipo_comissao.value : ''}</td>
        <td>{bc.status ? bc.status.tipo : ''}</td>
        <td className="hideOnprint">
          <button type="button" className='btn btn-success' onClick={() => this.props.modalHandler('modalAgendamento', bc)}>
              <i className='fa fa-eye'></i>
          </button>
        </td>
      </tr>
    );
  }
}
