export default {
  erro01: 'Ocorreu um erro ao retornar os dados, tente novamente mais tarde (1001)',
  erro02: 'Ocorreu um erro ao retornar os dados, tente novamente mais tarde (1002)',
  erro03: 'Ocorreu um erro ao salvar a imagem, tente novamente mais tarde (1001)',
  erro04: 'Ocorreu um erro ao salvar a imagem, tente novamente mais tarde (1001)',
  atencao: 'Atenção',
  erroTitle: 'Erro',
  hello: 'Olá, seja bem-vindo(a)',
  preenchaOCep: "Digite o CEP!",
  noFileAdded: "Nenhum arquivo adicionado",
  incorrectTypeFile: "Arquivo com extensão incorreta",
  erroDisponibilidadeSala: 'Para verificar a disponibilidade da sala selecione, a data, hora, serviço e tempo de atendimento!',
  camposObrigatorios: 'Preencha os campos obrigatórios',
  preenchaValor: 'Preencha o valor e o tipo de pagamento a ser inserido',
  selecioneValorTipoPagamento: 'Selecione o valor e o tipo de pagamento',
  financeiroObrigatorioAgendamentoAvulso: 'No agendamento avulso, a adição do financeiro é obrigatória! Por favor, selecione o serviço, o valor e o tipo de pagamento!',
  searchEmpty: "A busca não pode ser vazia. Por favor, faça um filtro antes de efetuar a busca!",
  selecioneHorarioDinamico: "Escolha o horário na tabela de profissional, sala, equipamento",
  escolhaFiltroData: "Selecione ao menos a data inicial na busca",
  errorGenerateImage: "Ocorreu um erro ao gerar a imagem",
  agendamentoAvulsoOuVenda: "O agendamento será avulso ou um Plano? Por favor, selecione um dos dois!",
  escolhaDiaSemana: "Escolha ao menos um dia da semana",
  escolhaFiltroDataAll: "Por favor, selecione a data inicial e final!"
};
