const host = window.location.href;
let API_URL;
let AMBIENTE;

if(host.indexOf("http://localhost:3000") > -1)
{
  API_URL = 'http://localhost:8080/v1.0.0/';
  AMBIENTE = 'local';
}
else if(host.indexOf("https://treinamento.clinicaexcelencia.com.br") > -1){
  API_URL = "https://api-treinamento.clinicaexcelencia.com.br/v1.0.0/";
  AMBIENTE = 'treinamento';
}
else if(host.indexOf("sistema-migracao.clinicaexcelencia.com.br") > -1){
  API_URL = "https://migracao.clinicaexcelencia.com.br/v1.0.0/";
  AMBIENTE = 'migracao';
}
else{
  API_URL = "http://excelencia-api-LB-535617012.us-east-1.elb.amazonaws.com/v1.0.0/";
  AMBIENTE = 'producao';
}
API_URL = 'https://api.clinicaexcelencia.com.br/v1.0.0/'
//"http://excelencia-api-LB-535617012.us-east-1.elb.amazonaws.com/v1.0.0/";
// API_URL = "http://localhost:8080/v1.0.0/";

export default {
    AMBIENTE,
    API_URL,
    API: {
      login: 'oauth/usuarios/login/',
      upload: 'upload/',
      areasCorpo: {
        read: 'regioes-corpo/{id}/',
        list: 'regioes-corpo/',
        create: 'regioes-corpo/',
        update: 'regioes-corpo/{id}/',
        delete: 'regioes-corpo/{id}/',
        autocomplete: 'regioes-corpo/autocomplete/'
      },
      patrimoniosAportes: {
        read: 'patrimonios-aportes/{id}/',
        list: 'patrimonios-aportes/',
        create: 'patrimonios-aportes/',
        update: 'patrimonios-aportes/{id}/',
        delete: 'patrimonios-aportes/{id}/',
        autocomplete: 'patrimonios-aportes/autocomplete/',
      },
      caixaItens: {
        read: 'caixa-itens/{id}/',
        list: 'caixa-itens/',
        create: 'caixa-itens/',
        update: 'caixa-itens/{id}/',
        delete: 'caixa-itens/{id}/'
      },
      estoqueItens: {
        read: 'estoque-itens/{id}/',
        list: 'estoque-itens/',
        create: 'estoque-itens/',
        update: 'estoque-itens/{id}/',
        delete: 'estoque-itens/{id}/'
      },
      estoqueRetiradas: {
        list: 'estoque-retiradas/',
        create: 'estoque-retiradas/',
        delete: 'estoque-retiradas/{id}/'
      },
      clientes: {
        read: 'clientes/{id}/',
        list: 'clientes/',
        create: 'clientes/',
        update: 'clientes/{id}/',
        delete: 'clientes/{id}/',
        autocomplete: 'clientes/autocomplete/',
      },
      dashboard: {
        read: "dashboard/"
      },
      planos: {
        read: 'planos/{id}/',
        list: 'planos/',
        create: 'planos/',
        update: 'planos/{id}/',
        delete: 'planos/{id}/',
        autocomplete: "planos/autocomplete/"
      },
      planosServicos: {
        read: 'planos-servicos/{id}/',
        create: 'planos-servicos/',
        update: 'planos-servicos/{id}/',
        delete: 'planos-servicos/{id}/'
      },
      fornecedores: {
        list: 'fornecedores/',
        autocomplete: 'fornecedores/autocomplete/',
        create: "fornecedores/",
        read: 'fornecedores/{id}/',
        update: 'fornecedores/{id}/',
        delete: 'fornecedores/{id}/',
      },
      patrimonioHistorico: {
        create: "patrimonio-historico/",
        read: 'patrimonio-historico/{id}/',
        update: 'patrimonio-historico/{id}/',
        delete: 'patrimonio-historico/{id}/',
      },
      salas: {
        create: "salas/",
        read: 'salas/{id}/',
        update: 'salas/{id}/',
        delete: 'salas/{id}/',
        list: 'salas/',
        autocomplete: 'salas/autocomplete/',
        checkByAgendamento: 'salas/check-by-agendamento/',
        visualizacao:'salas/visualizacao/'
      },
      salasServicos: {
        create: "salas-servicos/",
        delete: 'salas-servicos/{id}/',
        getBySalaAgendamento: 'salas/get-by-sala-agendamento'
      },
      servicos: {
        list: "servicos/",
        create: "servicos/",
        read: 'servicos/{id}/',
        update: 'servicos/{id}/',
        delete: 'servicos/{id}/',
        autocomplete: 'servicos/autocomplete/',
        loadPage: "servicos/load-page/",
      },
      servicosRegioes: {
        create: "servicos-regioes/",
        delete: 'servicos-regioes/{id}/',
      },
      cep : {
        search: 'cep/'
      },
      unidades: {
        list: "unidades/",
        create: "unidades/",
        read: 'unidades/{id}/',
        update: 'unidades/{id}/',
        delete: 'unidades/{id}/',
        autocomplete: 'unidades/autocomplete/',
      },
      servicoEstoques: {
        list: "servico-estoques/",
        create: "servico-estoques/",
        read: 'servico-estoques/{id}/',
        update: 'servico-estoques/{id}/',
        delete: 'servico-estoques/{id}/',
      },
      estoque: {
        list: "estoque/",
        create: "estoque/",
        read: 'estoque/{id}/',
        update: 'estoque/{id}/',
        delete: 'estoque/{id}/',
        autocomplete: 'estoque/autocomplete/',
      },
      
      equipamentos: {
        list: "equipamentos/",
        create: "equipamentos/",
        read: 'equipamentos/{id}/',
        update: 'equipamentos/{id}/',
        delete: 'equipamentos/{id}/',
        autocomplete: 'equipamentos/autocomplete/',
        checkByAgendamento: 'equipamentos/check-by-agendamento/',
        visualizacao:'equipamentos/visualizacao/'
      },
      perfisComissionamento: {
        list: "perfis-comissionamento/",
        create: "perfis-comissionamento/",
        read: 'perfis-comissionamento/{id}/',
        update: 'perfis-comissionamento/{id}/',
        delete: 'perfis-comissionamento/{id}/',
        autocomplete: 'perfis-comissionamento/autocomplete/',
        servicos: 'perfis-comissionamento/servicos/'
      },
      contas: {
        list: "contas/",
        create: "contas/",
        read: 'contas/{id}/',
        update: 'contas/{id}/',
        delete: 'contas/{id}/',
        arquivo: "contas-arquivos/",
        removerArquivo: 'contas-arquivos/',
      },
      usuarios: {
        list: "usuarios/",
        loadPage: "usuarios/load-page/",
        create: "usuarios/",
        read: 'usuarios/{id}/',
        update: 'usuarios/{id}/',
        delete: 'usuarios/{id}/',
        autocomplete: "usuarios/autocomplete/",
        visualizacao: "usuarios/visualizacao/",
      },
      usuariosServicos: {
        addServicosByPerfil: "usuarios-servicos/add-by-perfil/",
        add: "usuarios-servicos/",
        read: 'usuarios-servicos/{id}/',
        update: 'usuarios-servicos/{id}/',
        delete: 'usuarios-servicos/{id}/',
        autocomplete: 'usuarios-servicos/autocomplete/'
      },
      profissionais: {
        list: "profissionais/",
        create: "profissionais/",
        read: 'profissionais/{id}/',
        update: 'profissionais/{id}/',
        delete: 'profissionais/{id}/',
      },
      perfisServicos: {
        create: "perfis-comissionamento-servicos/",
        read: 'perfis-comissionamento-servicos/{id}/',
        update: 'perfis-comissionamento-servicos/{id}/',
        delete: 'perfis-comissionamento-servicos/{id}/'
      },
      vendas: {
        create: "vendas/",
        finalizar: "vendas/finalizar/",
        list: "vendas/",
        read: 'vendas/{id}/',
        update: 'vendas/{id}/',
        delete: 'vendas/{id}/',
        autocomplete: 'vendas/autocomplete/',
        getByClienteAgendamento: 'vendas/get-by-cliente-agendamento/',
        imprimirContrato: 'oauth/vendas/imprimir-contrato/',
        imprimirFicha: 'oauth/vendas/imprimir-ficha/',
        sendMail: 'vendas/send-mail/',
        saveAssinatura: 'vendas/save-assinatura/',
        allByCliente: 'vendas/all-by-cliente/',
        servicos: 'vendas/servicos/',
        financeiro: 'vendas/financeiro/',
        agendamentos: 'vendas/agendamentos/',
        fotosPastas: 'vendas/fotos-pastas/',
        assinaturas: 'vendas/assinaturas/',
      },
      vendasServicos: {
        read: 'vendas-servicos/{id}/',
        create: 'vendas-servicos/',
        update: 'vendas-servicos/{id}/',
        delete: 'vendas-servicos/{id}/',
        addByPlano: 'vendas-servicos/add-by-plano/',
        vendaServico: 'vendas-servicos/venda-servico/',
        imprimirTermoCompromisso: 'oauth/venda-servico/imprimir-termo/',
        updateComissao: 'vendas-servicos/update-comissao/{id}/',
        saveAssinatura: 'vendas-servicos/save-assinatura/',
        removeAssinatura: 'vendas-servicos/remover-assinatura/',
        addDiscount: 'vendas-servicos/addDiscount/{id}/'
      },
      vendasFinanceiro: {
        create: 'vendas-financeiro/'
      },
      contaFinanceiro: {
        create: 'conta-financeiro/'
      },
      agendamentos: {
        create: "agendamentos/",
        read: "agendamentos/{id}/",
        update: "agendamentos/{id}/",
        list: "agendamentos/",
        checkPlaceByDate: "agendamentos/check-place-by-date/",
        checkToolByDate: 'agendamentos/check-tool-by-date/',
        checkPlanoServico: 'agendamentos/check-plano-servico/',
        servicosAutocomplete: 'agendamentos/servicos-autocomplete/',
        info: 'agendamentos/info/',
        allByServico: 'agendamentos/all-by-servico/',
        saveAssinatura: 'agendamentos/save-assinatura/',
        aleatorio: 'agendamentos/aleatorio/',
        removeAssinatura: 'agendamentos/remover-assinatura/',
        buscaLivres: 'agendamentos/busca-livres/',
        detalheAgendamentoDelete: 'agendamentos-detalhes/delete/'
      },
      financeiroParcelas: {
        read: 'financeiro-parcelas/{id}/',
        update: 'financeiro-parcelas/{id}/',
        delete: 'financeiro-parcelas/{id}/',
      },
      contaParcelas: {
        read: 'conta-parcelas/{id}/',
        update: 'conta-parcelas/{id}/',
        delete: 'conta-parcelas/{id}/',
      },
      vendasPastasFotos: {
        read: 'vendas-pastas-fotos/{id}/',
        create: 'vendas-pastas-fotos/',
        update: 'vendas-pastas-fotos/{id}/',
        delete: 'vendas-pastas-fotos/{id}/',
        saveFoto: 'vendas-pastas-fotos/save-foto/',
      },
      fotosProcedimentos: {
        create: 'fotos-procedimentos/',
        delete: 'fotos-procedimentos/{id}/'
      },
      permissoes: {
        list: "permissoes/",
        autocomplete: "permissoes/autocomplete/",
      },
      dadosAcesso: {
        save: "usuarios/dados-acesso/{id}/"
      },
      usuariosHorarios: {
        url: "usuarios-horarios/"
      },
      unidadesHorarios: {
        url: "unidades-horarios/"
      },
      bloqueios: {
        url: "bloqueios/"
      },
      usuariosLiberacoes: {
        url: "usuarios-liberacoes/"
      },
      historicoAgendamento: {
        list: "agendamentos-historico/"
      },
      crm: {
        read: "crm/{id}/"
      },
      reports: {
        vendasGerais: 'reports/vendas-gerais/',
        agendamentos: "reports/agendamentos/",
        aniversariantes: "reports/aniversariantes/",
        procedimentosMaisVendidos: "reports/procedimentos-mais-vendidos",
        planos: "reports/planos/",
        pagamentos: "reports/pagamentos/",
        vendas: "reports/vendas/",
        vendasRealizadas: "reports/vendas-realizadas/",
        pagamentosColaboradores: "reports/pagamentos-colaboradores/",
        pagamentosProfissionais: "reports/pagamentos-profissionais/",
        financeiro: "reports/financeiro/",
        parcerias: "reports/parcerias/",
        contas: "reports/contas/",
        caixa: "reports/caixa/",
        pagamentosFornecedores: "reports/fornecedores/",
        vendasClientes: "reports/vendas-clientes/",
        frequenciaClientes: "reports/frequencia-clientes/",
        retornos: "reports/retornos/",
        procedimentosAtivos: "reports/procedimentos-ativos/",
        servicosByDate: "reports/servicos-vendidos/",
        clientes_ativos: "reports/clientes-ativos/",
        planosVendas: "reports/planos-vendas/",
        clientesClassificacao: "reports/clientes-classificacao/"
      },
      tiposUsuariosPermissoes: {
        read: 'tipos-usuarios-permissoes/{id}/',
        create: 'tipos-usuarios-permissoes/',
        list: 'tipos-usuarios-permissoes/',
        update: 'tipos-usuarios-permissoes/{id}/',
        delete: 'tipos-usuarios-permissoes/{id}/'
      },
      tiposUsuarios: {
        list: 'tipos-usuarios/',
        read: 'tipos-usuarios/{id}/',
        update: 'tipos-usuarios/{id}/'
      },
      tiposProfissionais:{
        list: 'tipos-profissionais/',
        read: 'tipos-profissionais/{id}/',
        update: 'tipos-profissionais/{id}/'
      },
      download: {
        arquivoConta: 'oauth/downloads/conta-arquivo/'
      },
      controle: {
        list: 'controle/'
      },
      caixa: {
        list: "caixa/",
        create: "caixa/",
        read: 'caixa/{id}/',
        update: 'caixa/{id}/',
        delete: 'caixa/{id}/',
        changeStatus: 'caixa/change-status/'
      },
      contrato: {
        read: 'contrato/',
        update: 'contrato/loja/'
      },
    }
}
