import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import api from '../../api';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import Pagination from '../../common/template/pagination';
import EmptyList from '../../common/template/emptyList';
import { urlParamsConverter } from '../../config/methods';
import ModalLoading from '../modals/modal-loading';
import ModalInfo from '../agendamentos/modal-info-agendamento';
import ModalCRM from '../modals/modal-crm';

const QUERY_INIT = { page: 1 };

class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      list: [],
      ModalLoading: false,
      pageActive: 1,
      query: {...QUERY_INIT, ...props.query},
      errorLoadingPage: false,
      pagination: CONSTS.PAGINATE
    };
    this.changePage = this.changePage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.loadPage(this.state.pageActive);
  }

  loadPage(page){
    const token = this.props.token;
    const self = this;
    const query = `?${urlParamsConverter(this.state.query)}`;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.frequenciaClientes}${query}`,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();

          if(data.status){
            self.setState({
              ModalLoading: false,
              list: data.data,
              pagination: data.pagination
            });
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setErrorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setErrorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.loaded();
        self.setErrorLoadingPage();
      }
    });
  }

  changePage(goTo){
    let page;
    if (goTo==='next') {
      page = this.state.pagination.next;
    }else if(goTo==='prev'){
      page = this.state.pagination.prev;
    }else{
      page = goTo;
    }

    this.setState({
      ModalLoading: true,
      query: {...this.state.query, page}
    }, () => this.loadPage());
  }

  setErrorLoadingPage(){
    this.setState({
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  callWhats(item){
    const url = `https://api.whatsapp.com/send?phone=55${item.whatsapp}&text=/`;
    var node = document.createElement('textarea');
    var selection = document.getSelection();

    node.textContent = url;
    document.body.appendChild(node);

    selection.removeAllRanges();
    node.select();
    document.execCommand('copy');

    selection.removeAllRanges();
    document.body.removeChild(node);

    toastr.success(Language.atencao, "Dados copiados com sucesso");
  }

  openDetailAgendamento(id){

  }

  renderRows() {
      const list = this.state.list || [];

      return list.map(bc => {

        return (
          <tr key={bc.id}>
            <td>{bc.cliente}</td>
            <td>{bc.whatsapp}</td>
            <td>{bc.data_agendamento}</td>
            <td>{bc.qnt_days}</td>
            <td>
              <button type="button" className='btn btn-success' onClick={() => this.openModal('ModalCRM', bc.id_cliente)}>
                  <i className='fa fa-id-card'></i>
              </button>
            </td>
            <td>
              <button type="button" className='btn btn-success' onClick={() => this.openModal('modalInfo', bc.id_agendamento)}>
                  <i className='fa fa-calendar'></i>
              </button>
            </td>
            <td className="hideOnprint">
              {
                bc.whatsapp ?
                <button type="button" className='btn btn-success' onClick={() => this.callWhats(bc)}>
                    <i className='fa fa-whatsapp'></i>
                </button>
                : ''
              }
            </td>
          </tr>
        );
      })
  }

  openModal(modal, args) {
    let dados = {[modal]: true};
    dados.dadosAgendamento = '';
    dados.agendamento = '';
    dados.dadosCRM = '';

    if(modal === 'modalInfo'){
      dados.agendamento = args;
    }
    if(modal === 'ModalCRM'){
      dados.dadosCRM = args;
    }

    this.setState(dados);
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    if(modal === 'modalAgendamento'){

    }
    this.setState({[modal]: false});
  }

  renderTable(){
    return (
      <div>
      <Fragment>
      <ModalCRM
        className='Modal_CRM'
        isOpen={this.state['ModalCRM']}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={()=>this.closeModal('ModalCRM')}
        cliente={this.state.dadosCRM}
      />
      <ModalInfo
        className='Modal_Info'
        isOpen={this.state['modalInfo']}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={()=>this.closeModal('modalInfo')}
        agendamento={this.state.agendamento}
        token={this.props.token}
      />
        <ContentHeader title='Clientes inativos' small='' />
        <Content>
          <div class="description-report">
            Relatório com os clientes que estão a mais de 30 dias sem realizar um novo agendamento
          </div>
          <Box classes="box-success" title="Busca">
          {
            this.state.list.length ?
              <div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>Whatsapp</th>
                            <th>Data</th>
                            <th>Dias</th>
                            <th>CRM</th>
                            <th>Detalhe</th>
                            <th className='table-actions-single hideOnprint'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
                <Pagination setPage={this.changePage} onClick={this.changePage} dados={this.state.pagination} />
              </div>
            : <EmptyList />
          }
          </Box>
        </Content>
      </Fragment>
      <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </div>
    );
  }


  render() {
      const tempBox = this.state.loaded
      ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderTable())
      : <LoadingPage />;
      return (
          <div>
            {
              tempBox
            }
          </div>
      )
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
