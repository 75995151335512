import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import LabelAndSelect from '../../common/form/labelAndSelect';
import GroupRadio from '../../common/form/groupRadio';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import LabelAndInput from '../../common/form/labelAndInput';
import TitleModal from '../../common/form/titleModal';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';
import CONSTS from '../../config/consts';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import {convertToBr} from '../../utils/dateJS';
import ModalLoading from './modal-loading';

import './modal-cliente-ajax.scss';

ReactModal.setAppElement('#root');

const optionsSexo = CONSTS.SEXO;
const tipo_pessoa = CONSTS.TIPO_PESSOAS;
const optionsCivil = CONSTS.OPCOES_CIVIL;


const INITIAL_STATE = {
  tipo_pessoa: "F",
  nome:"",
  sobrenome:"",
  registro_profissional:"",
  rg:"",
  data_nascimento:"",
  email:"",
  telefone:"",
  whatsapp:"",
  clienteAjax: true
};

class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      ...INITIAL_STATE,
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value.toUpperCase();
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  handleSubmit(e){
    e.preventDefault();

    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.clientes.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.props.onRequestClose();
            self.props.onSuccess(data.cliente);
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};
    delete data.ModalLoading;

    if(data.data_nascimento){
      data.data_nascimento = convertToBr(data.data_nascimento);
    }

    if(data.sexo){
      data.sexo = this.state.sexo.value;
    }

    if(data.estado_civil){
      data.estado_civil = this.state.estado_civil.value;
    }

    return data;
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    const readOnly = false;

    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.props.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <form onSubmit={this.handleSubmit}>
              <div className='box-modal-visualizacao__wrapper'>
                <TitleModal title="Novo cliente" />
                <GroupRadio readOnly={readOnly} handleChange={this.handleInputChange} options={tipo_pessoa} value={this.state.tipo_pessoa} name="tipo_pessoa" label="Tipo pessoa" cols="12" />

                {
                  this.state.tipo_pessoa === "F" ?
                    <Fragment>
                    <LabelAndInput required name='nome' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.nome}
                        label='Nome' cols='12 6' placeholder='Digite aqui' />
                    <LabelAndInput required name='sobrenome'  type='text' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.sobrenome}
                        label='Sobrenome' cols='12 6' placeholder='Digite aqui' />
                      <LabelAndMaskInput required format='###.###.###-##' mask="_" name='cpf_cnpj' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                          label='CPF' cols='12 4' value={this.state.cpf_cnpj} placeholder='Digite aqui' />
                      <LabelAndInput name='rg'  value={this.state.rg}  readOnly={readOnly} handleChange={this.handleInputChange}
                          label='RG' cols='12 6' placeholder='Digite aqui' />
                    <LabelAndDate required name='data_nascimento' value={this.state.data_nascimento}  readOnly={readOnly} onChange={date => this.onChangeDate(date, 'data_nascimento')}
                        label='Data da nascimento' cols='12 4' placeholder='Digite aqui' date={this.state.data_nascimento} />
                    <LabelAndSelect required name='sexo' options={optionsSexo}  readOnly={readOnly} value={this.state.sexo} handleChange={this.handleSelect}
                        label='Sexo' cols='12 4' placeholder='Digite aqui' />
                    <LabelAndSelect name='estado_civil' options={optionsCivil}  readOnly={readOnly} value={this.state.estado_civil} handleChange={this.handleSelect}
                        label='Estado civil' cols='12 4' placeholder='Digite aqui' />
                        <LabelAndInput name='email' value={this.state.email}   readOnly={readOnly} handleChange={this.handleInputChange}
                            label='E-mail' cols='12 4' placeholder='Digite aqui' />
                        <LabelAndMaskInput format='(##) ####-####' mask="_" name='telefone' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                            label='Telefone convencional' cols='12 4' value={this.state.telefone} placeholder='Digite aqui' />
                        <LabelAndMaskInput format='(##) #####-####' mask="_" name='whatsapp' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                            label='Celular/Whatsapp' cols='12 4' value={this.state.whatsapp} placeholder='Digite aqui' />
                    </Fragment>
                  :
                    <Fragment>
                      <LabelAndInput required name='nome' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.nome}
                          label='Nome da empresa' cols='12 6' placeholder='Digite aqui' />
                      <LabelAndMaskInput required format='##.###.###/####-##' mask="_" name='cpf_cnpj' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                          label='CNPJ' cols='12 4' value={this.state.cpf_cnpj} placeholder='Digite aqui' />
                      <LabelAndInput name='razao_social'  value={this.state.razao_social}  readOnly={readOnly} handleChange={this.handleInputChange}
                          label='Razão social' cols='12 6' placeholder='Digite aqui' />
                      <LabelAndInput name='inscricao_municipal'   value={this.state.inscricao_municipal} readOnly={readOnly} handleChange={this.handleInputChange}
                          label='Inscrição Municipal' cols='12 6' placeholder='Digite aqui' />
                      <LabelAndInput name='inscricao_estadual'  value={this.state.inscricao_estadual}  readOnly={readOnly} handleChange={this.handleInputChange}
                          label='Incrição estadual' cols='12 6' placeholder='Digite aqui' />


                    </Fragment>
                }
              </div>
              <button type="submit" className="btn btn-block btn-success">Salvar</button>
            </form>
          </div>
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Modal);
