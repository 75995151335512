import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery';
import { connect } from 'react-redux';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import BoxInputFull from '../../common/layout/boxInputFull';
import GroupCheckbox from '../../common/form/groupCheckbox';
import CONSTS from '../../config/consts';
import MandatoryFields from '../../common/template/mandatoryFields';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalDelete from '../modals/modal-delete';
import ModalLoading from '../modals/modal-loading';
import PlanosServicos from './planos-servicos';
import LabelAndText from '../../common/form/labelAndText';
import { maskMoney, strPtBrMoneyToNumber } from '../../config/methods';
import { convertToken } from '../../config/methods';

const optionsTipo = CONSTS.TIPOS_PLANOS;

const INITIAL_STATE = {
  plano:"",
  tipo:"",
  valor:"",
  validade:"",
  observacoes:"",
  ativo: 1
};
const sim_nao = [{value: 1, label:'Sim', id:"ativo"}];

class Form extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: !this.props.detail,
      ModalLoading: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  initData(data){
    const response = data.data;

    if(response.tipo){
      response.tipo = optionsTipo.filter( ( elem, i, array ) => {
          return Number(elem.value) === Number(response.tipo);
      });
      if(response.tipo.length) response.tipo = response.tipo[0];
    }

    if(response.valor){
      response.valor = response.valor.formated;
    }

    response.loaded = true;

    this.setState({
      ...response
    });

    if(this.props.detail.statusCreated){
      $('html,body').animate({
        scrollTop: $("#content-ajax").offset().top},
        'slow');
    }
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.planos.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name==='valor'){
      value = maskMoney(value);
    }

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.update();
        break;
      default:
        this.create();
    }

    e.preventDefault();
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();

    this.callLoading(true);
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.planos.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};
    delete data.ModalLoading;
    delete data.errorLoadingPage;
    delete data.loaded;
    delete data.servicos;

    if(data.tipo){
      data.tipo = this.state.tipo.value;
    }

    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }

    return data;
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.planos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.keepCreating(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  fillAddress(address){
    const uf = CONSTS.ESTADOS.filter( ( elem, i, array ) => {
        return elem.value === address.uf;
    });

    this.setState({
      uf: uf.length ? uf[0] : null,
      bairro: address.bairro,
      cidade: address.localidade,
      endereco: address.logradouro
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    self.removeModalDeleteItem();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.planos.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  getServicos(){
    const token= this.props.user;
    const { readOnly } = this.props;
    return <PlanosServicos
      readOnly={readOnly}
      key={Math.random()}
      servicos={this.state.servicos}
      token={token}
      plano={this.props.detail.id}
    />;
  }

  renderContent() {
      const { readOnly } = this.props;
      const servicos = this.props.detail ? this.getServicos() : null;
      const ativo = this.state.ativo ? [{value:1, id:"ativo"}] : "";
      const data = convertToken(this.props.user);
      const tipo_usuario = data.par.tipo_usuario;

      return (
        <Fragment>
          <form onSubmit={this.handleSubmit}>
              <div className='box-body'>
                <Box classes="box-success" title="Dados gerais do plano">
                  <BoxInputFull>
                  <GroupCheckbox onChange={this.handleInputChange} name="ativo" value={ativo} options={sim_nao} label="Ativar plano" cols="12 3" />
                    <LabelAndInput required name='plano' readOnly={readOnly} value={this.state.plano}  handleChange={this.handleInputChange}
                        label='Nome' cols='12' placeholder='Informe o nome' />
                  </BoxInputFull>
                  <LabelAndSelect required name='tipo' value={this.state.tipo} options={optionsTipo} readOnly={readOnly} handleChange={this.handleSelect}
                      label='Tipo' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='valor' value={this.state.valor} readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Valor' required cols='12 3' placeholder='00,00' />
                  <LabelAndInput name='validade' type="number" readOnly={readOnly} value={this.state.validade}  handleChange={this.handleInputChange}
                      label='Validade(por mês)' cols='12 3' placeholder='' />
                  <LabelAndText name='observacoes' type='number' readOnly={readOnly} value={this.state.observacoes}  handleChange={this.handleInputChange}
                      label='Descrição/Observações' cols='12 6' placeholder='Digite aqui' />
                  <Clearfix />
                  <MandatoryFields />
                  <div className='box-footer'>
                    <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button type='button' className='btn btn-default'
                        onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
                  </div>
                </Box>
              </div>
          </form>
          {servicos}
        </Fragment>
      )
  }

  render() {
    const tempBox = this.state.loaded
                  ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                  : null;
    return (
        <div>
          <ModalDelete
            onCancel={ () => this.deleteItem() }
            className='Modal_Delete'
            isOpen={this.state['ModalDeleteItem']}
            onRequestClose={()=>this.removeModalDeleteItem()}
          />
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

          {
            this.state.loaded ?
            tempBox
            : <LoadingPage />
          }
        </div>
    )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
