import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import Box from '../../common/template/box';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import ModalServico from '../modals/modal-vendas-servico';
import ModalServicoAgendamentos from '../modals/modal-servicos-agendamentos';
import If from '../../common/operator/if';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import Loader from '../../common/template/Loader';

export default class PerfisServicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicos: props.servicos,
      loading: true,
      error: false,
      showLoading: false
    }
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChangeServicos = this.onChangeServicos.bind(this);
    this.loadPage = this.loadPage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.servicos !== prevProps.servicos) {
      this.setState({servicos: this.props.servicos});
    }
  }

  componentDidMount(){
    this.loadPage();  
  }

  loadPage(){
    const { token } = this.props;
    const self = this;
    const vendaId = this.props.venda;
    this.setState({loading: true, error: false});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.servicos}?venda=${vendaId}`,
      // data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({servicos: data.data.servicos, loading: false});
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setState({loading: false, error: true});
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setState({loading: false, error: true});
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({loading: false, error: true});
      }
    });
  }

  openModal(modal, item) {
    const data = {
      [modal]: true,
      itemModal: item
    };

    this.setState(data);
  }

  afterOpenModal() {
  }

  closeModal(modal, data) {
    const state = {[modal]: false};
    let histUpdated = [];

    if(data && data.servico){
      if (data.type && data.type === "update") {
        histUpdated = this.state.servicos.map(i =>
          {
            return i.id === data.servico.id ? data.servico : i;
          }
        );
      }else{
        histUpdated = [...this.state.servicos, data.servico];
      }
      state.servicos = histUpdated;
    }
    this.setState(state);
  }

  onChangeServicos(data){
    this.props.onChangeServicos(data);
  }

  loadServico(val){
    const { token } = this.props;
    const self = this;
    const data = {venda: this.props.venda, servico: val.value};
    self.setState({showLoading: true});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.vendasServicos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.onChangeServicos(data);
            toastr.success(Language.atencao, data.msg);
            self.addServico(data.data, 'servico', data.item);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
        self.setState({showLoading: false});
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({showLoading: false});
      }
    });
  }

  loadPlano(val){
    const { token } = this.props;
    const self = this;
    const data = {venda: this.props.venda, plano: val.value};
    self.setState({showLoading: true});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.vendasServicos.addByPlano}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.onChangeServicos(data);
            toastr.success(Language.atencao, data.msg);
            self.setState({plano: ''});
            self.addServico(data.data, 'plano');
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
        self.setState({showLoading: false});
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({showLoading: false});
      }
    });
  }

  onChangeSelect(val, name){

    if(name === 'servico'){
      this.loadServico(val);
    }

    if(name === 'plano'){
      this.loadPlano(val);
    }
  }

  addServico(item, tipo, id){
    this.setState({
      servico: null
    });

    if(tipo==='servico'){
      this.openModal('modalServico', id);
    }
  }

  removeItem(id){
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.vendasServicos.delete.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.onChangeServicos(data);
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeItemItem(id){
    const arr = this.state.servicos.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      servicos: arr
    });
  }

  renderRows() {
      const list = this.state.servicos || [];
      const showButtons = this.props.readOnly ? true :  false;//((this.props.dadosVenda && this.props.dadosVenda.avulso) ? true :

      return list.map(bc => (
          <tr key={bc.id} className={`${bc.cortesia ? "tr-cortesia": "" }`}>
              <td>{bc.servico.servico}</td>
              <td>{bc.plano ? bc.plano.label : '-'}</td>
              <td>{bc.cortesia ? <span className="badge bg-green">Sim</span> : ""}</td>
              <td>{bc.n_sessoes}</td>
              <td>{bc.atendidos}</td>
              <td>{bc.valor ? (bc.cortesia ? '-' : (bc.valor_servico ? bc.valor_servico.formated : '')) : "-"}</td>
              <td>{bc.tipo_desconto ? <span className="badge bg-yellow">{bc.tipo_desconto.value}</span> : "-"}</td>
              <td>{bc.desconto ? (bc.cortesia ? '-': bc.desconto) : "-"}</td>
              <td>{bc.total ? (bc.cortesia ? '-': bc.total.formated) : "-"}</td>
              <td>
                {bc.status
                  ? <span className={`${bc.status.id === 1 ? 'label label-info' : 'label label-danger'}`}>{bc.status.tipo}</span>
                  : "-"
                }
              </td>
              <td>
                {
                  !showButtons
                  ?
                    <span>
                      <button type="button" className='btn btn-danger' onClick={() => this.removeItem(bc.id)}>
                          <i className='fa fa-trash-o'></i>
                      </button>
                    </span>
                    : null
                }
                <span>
                  <button type="button" className='btn btn-warning' onClick={() => this.openModal('modalServico', bc.id)}>
                      <i className='fa fa-pencil'></i>
                  </button>
                  <button type="button" className='btn btn-info' onClick={() => this.openModal('ModalServicoAgendamentos', bc.id)}>
                      <i className='fa fa-eye'></i>
                  </button>
                </span>
              </td>

          </tr>
      ));
  }

  render(){
    const url = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const token = this.props.token;
    const urlPlano = `${api.API_URL}${api.API.planos.autocomplete}`;

    const showButtons = this.props.readOnly ? true : false;//((this.props.dadosVenda && this.props.dadosVenda.avulso) ? true : false)

    return(
      <div id="content-ajax">
        <Box classes="box-success" title="Serviços">
          <ModalServico
            venda={this.props.detail ? this.props.detail.id : null}
            item={this.state.itemModal}
            token={token}
            tipo={this.props.tipo}
            className='Modal_Servico'
            isOpen={this.state['modalServico']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={(data)=>this.closeModal('modalServico', data)}
            onChangeServicos={this.onChangeServicos}
          />
          <ModalServicoAgendamentos
            venda={this.props.detail ? this.props.detail.id : null}
            item={this.state.itemModal}
            token={token}
            className='ModalServicoAgendamentos'
            isOpen={this.state['ModalServicoAgendamentos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={(data)=>this.closeModal('ModalServicoAgendamentos', data)}
          />
          {/*
            <If test={this.props.dadosVenda && this.props.dadosVenda.avulso}>
              <div className="callout callout-warning">
                  <h4>Atenção!</h4>
                  <p>Os serviços de uma venda avulsa não podem ser alterados. Para vincular o serviço a um plano/pacote vá na seção de agendamentos e vincule o serviço do agendamento a um plano.</p>
                  <p>Obs.: o vínculo do serviço a um plano não poderá ser feito nos agendamentos dessa página, para isso, vá no menu > agendamentos > selecione o agendamento > vincular a um plano</p>
                </div>
            </If>
          */}
          <If test={!showButtons}>
            <LabelAndSelectAjax
              readOnly={this.props.readOnly}
              name='plano'
              value={this.state.plano}
              onChange={this.onChangeSelect}
              url={urlPlano}
              token={this.props.token}
              label='Selecione um plano'
              cols='12 4'
              placeholder='Digite aqui' />
            <LabelAndSelectAjax
              readOnly={this.props.readOnly}
              name='servico'
              value={this.state.servico}
              onChange={this.onChangeSelect}
              url={url}
              token={this.props.token}
              label='Adicionar serviço'
              cols='12 4'
              placeholder='Digite aqui' />
          </If>
          <div className="table-wrapper">
            <table className='table'>
                <thead>
                    <tr>
                      <th>Serviço</th>
                      <th>Plano</th>
                      <th className="">Cortesia</th>
                      <th className="th-valor-comissao">Nº sessões</th>
                      <th className="th-valor-comissao">Atendidos/Falharam</th>
                      <th className="">Valor serviço</th>
                      <th className="th-tipo-comissao">Tipo Desconto</th>
                      <th className="">Desconto</th>
                      <th className="">Total</th>
                      <th className="">Status</th>
                      <th className='table-actions-3'></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
            {
              this.state.error ?  <>
              <ErrorPage />
              <div style={{ display: 'flex', justifyContent: 'center'}}>
              <button onClick={this.loadPage} type="button" class="btn btn-primary">Tentar novamente</button>
              </div>
              </> : null
            }
            {
              this.state.loading ? <LoadingPage /> : null
            }
          </div>
        </Box>
        {this.state.showLoading && <Loader />}
      </div>
    );
  }
}
