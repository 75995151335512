import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import api from '../../api';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import ModalAgendamento from '../modals/modal-agendamento';
import ModalPlano from '../modals/modal-plano';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
import ItemList from './fechamentoPagamentoItemList';

const optionsStatus = CONSTS.STATUS_AGENDAMENTO;
const INITIAL_STATE_TABS = { agendamentos: [], loaded: false, status: optionsStatus[3], totalReceber: '' };

class Pagamentos extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);

    this.openModal = this.openModal.bind(this);
  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.agendamentos.map((value, i) => [
      value.data,
      value.hora_inicial,
      value.profissional ? value.profissional : '',
      value.cliente ? (value.cliente + (value.cliente_sobrenome ? ' '+value.cliente_sobrenome : '')) : '',
      value.servico ? value.servico : '',
      value.comissao ? value.comissao : '',
      value.tipo_comissao ? value.tipo_comissao : '',
      value.status ? value.status.tipo : ''
    ]);
    arr.unshift(["Data", "Hora inicial", "Profissional", "Cliente","Serviço", "Comissão", "Tipo de comissão", "Status"]);
    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "agendamentos.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {};

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    if(this.state.data_final){
      query.data_final = convertToBr(this.state.data_final);
    }

    if(this.state.cliente){
      query.cliente = this.state.cliente.value;
    }

    if(this.state.status){
      query.status = this.state.status.value;
    }

    if(this.state.brinde){
      query.brinde = this.state.brinde.value;
    }

    if(this.state.profissional && this.state.profissional !== ''){
      query.profissionais = [this.state.profissional.value];
    }

    if(this.state.servico && this.state.servico !== ''){
      const servicos = this.state.servico.map((v,i)=> v.value);
      query.servicos = servicos;
    }

    if(this.state.sala && this.state.sala !== ''){
      const salas = this.state.sala.map((v,i)=> v.value);
      query.salas = salas;
    }

    if(this.state.equipamento && this.state.equipamento !== ''){
      const equipamentos = this.state.equipamento.map((v,i)=> v.value);
      query.equipamentos = equipamentos;
    }

    if(this.state.check_plano){
      query.check_plano = this.state.check_plano.value;
    }

    return query;
  }

  loadData(item){

    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroDataAll);
      return;
    }
    if(!this.state.data_final){
      toastr.warning(Language.atencao, Language.escolhaFiltroDataAll);
      return;
    }
    if(!this.state.profissional){
      toastr.warning(Language.atencao, Language.camposObrigatorios);
      return;
    }
    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.pagamentos}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({agendamentos: [], totalReceber: data.total, loaded: true}, ()=>{
              self.setState({agendamentos: data.data});
            });
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  getBody(){
    return (
      <Content>
        <div className='clearfix' style={{paddingBottom: 20}}>
          <span className='pull-left' style={{fontSize: 20}}>Total a receber: <strong>{`${this.state.totalReceber ? this.state.totalReceber.formated : ''}`}</strong></span>
          <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
          <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
        </div>
        <Box classes="box-success" title="Resultado">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Hora inicial</th>
                        <th>Profissional</th>
                        <th>Cliente</th>
                        <th>Serviço</th>
                        <th>Comissão</th>
                        <th>Tipo Comissão</th>
                        <th>Status</th>
                        <th className='table-actions-single hideOnprint'></th>
                    </tr>
                </thead>
                <tbody>
                  {
                    this.state.agendamentos.map(bc => (
                      <ItemList data={bc} key={bc.id} modalHandler={this.openModal} token={this.props.token} />
                    ))
                  }
                </tbody>
            </table>
          </div>
        </Box>
      </Content>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    const urlCliente = `${api.API_URL}${api.API.clientes.autocomplete}`;
    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
    const urlServico = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const servicoQuery = {};
    const urlSala = `${api.API_URL}${api.API.salas.checkByAgendamento}`;
    const urlEquipamento = `${api.API_URL}${api.API.equipamentos.checkByAgendamento}`;

    return(
      <Fragment>
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          //tipo={'crm_detail'}
        />
        <ModalPlano
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Plano'
          isOpen={this.state['modalPlano']}
          onRequestClose={()=>this.closeModal('modalPlano')}
          dados={this.state.dadosPlano}
        />
        <ContentHeader title='Fechamento de pagamentos' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate required name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data inicial' cols='12 2' date={this.state.data_inicial} />
            <LabelAndDate required name='data_final' value={this.state.data_final} onChange={data => {this.onChangeDate(data, 'data_final');}}
                label='Data final' cols='12 2' date={this.state.data_final} />

            <LabelAndSelectAjax
              required
              name='profissional'
              value={this.state.profissional}
              onChange={this.handleSelect}
              url={urlVendedor}
              token={this.props.token}
              label='Selecione um profissional'
              cols='12 4'
              query={{tipo:2}}
              placeholder='Digite aqui' />

              <LabelAndSelect name='status' options={optionsStatus} value={this.state.status} handleChange={this.handleSelect}
              label='Status' cols='12 3' placeholder='Digite aqui' />

            <LabelAndSelectAjax
              name='servico'
              value={this.state.servico}
              onChange={this.handleSelect}
              url={urlServico}
              token={this.props.token}
              label='Selecione um serviço'
              cols='12 4'
              isMulti
              query={servicoQuery}
              placeholder='Digite aqui' />

              <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : null}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Pagamentos);
