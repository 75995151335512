import React, { Component } from 'react';
import ReactModal from 'react-modal';
import TitleModal from '../../common/form/titleModal';
import CanvasDraw from "react-canvas-draw";
import './modal-assinatura.scss';

ReactModal.setAppElement('#root');

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      date: null
    }

    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.clear = this.clear.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onAfterOpen(){

  }

  clear(){
    this.canvas.clear();
  }

  onSave(){
    const canvas = this.canvas.canvas.drawing;
    const self = this;
    canvas.toBlob(function(blob) {
      self.props.onSave(blob);
      self.props.onRequestClose();
    });
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    // const defaultProps = {
    //   loadTimeOffset: 5,
    //   lazyRadius: 30,
    //   brushRadius: 12,
    //   brushColor: "#444",
    //   catenaryColor: "#0a0302",
    //   gridColor: "rgba(150,150,150,0.17)",
    //   hideGrid: false,
    //   canvasWidth: 400,
    //   canvasHeight: 400,
    //   disabled: false,
    //   imgSrc: "",
    //   saveData: null,
    //   immediateLoading: false
    // };

    return(
      <ReactModal
      portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
      >
        <div className='box-modal-visualizacao'>
          <TitleModal title="Assinatura" />
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
          <CanvasDraw
            hideGrid
            ref={canvasDraw => (this.canvas = canvasDraw)}
            brushRadius={1}
            loadTimeOffset={1}
            lazyRadius={1}
            canvasWidth={400}
            canvasHeight={200}
          />
          </div>
          <button type="button" onClick={this.onSave} className="btn btn-success">Salvar</button>
          <button type="button" onClick={this.clear} className="btn btn-default">Limpar</button>
        </div>
      </ReactModal>
    )
  }
}
