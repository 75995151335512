import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import $ from 'jquery';

import LabelAndInput from '../../../common/form/labelAndInput';
import Clearfix from '../../../common/form/clearfix';
import Box from '../../../common/template/box';
import './form.scss';

import ModalDelete from '../../modals/modal-delete';
import ModalLoading from '../../modals/modal-loading';
import LoadingPage from '../../../common/template/loadingPage';
import ErrorPage from '../../../common/template/errorPage';
import Permissoes from './permissoes';
import api from '../../../api';
import Requisition from '../../../utils/requisitions/requisition';
import CONSTS from '../../../config/consts';
import Language from '../../../config/language';

const INITIAL_STATE = {
  nome: '',
  observacoes: '',
  servicos: []
}

class Form extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: !this.props.detail
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.update();
        break;
      default:
        this.create();
    }
    e.preventDefault();
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.tiposUsuarios.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};

    return data;
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.tiposUsuarios.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.keepCreating(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
        self.loaded();
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  getServicos(){
    const token= this.props.user;
    const { readOnly } = this.props;
    return <Permissoes readOnly={readOnly} key={Math.random()} permissoes={this.state.permissoes} token={token} tipoUsuario={this.props.detail.id} />;
  }

  initData(response){
    this.setState({
      ...response
    });

    if(this.props.detail.statusCreated){
      $('html,body').animate({
        scrollTop: $("#content-ajax").offset().top},
        'slow');
    }
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.tiposUsuarios.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            const response = data.data;
            self.initData(response);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response =>{
        self.loaded();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  renderContent() {
      const { readOnly } = this.props;
      const servicos = this.props.detail ? this.getServicos() : null;

      return (
        <div>
            <div className='box-body'>
              <form onSubmit={this.handleSubmit}>
                <Box classes="box-success" title="Dados gerais">
                  <LabelAndInput name='tipo_usuario' value={this.state.tipo_usuario} readOnly={readOnly}  handleChange={this.handleInputChange}
                    label='Tipo de usuário' cols='12 8' placeholder='Informe o tipo de usuário' />
                  <Clearfix />
                  <div className='box-footer'>
                      <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                          {this.props.submitLabel}
                      </button>
                      <button type='button' className='btn btn-default'
                          onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
                  </div>
                </Box>
              </form>
              {servicos}
            </div>
        </div>
      )
  }

  render() {
      const tempBox = this.state.loaded
      ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
      : <LoadingPage />;

      return (
          <div>
            <ModalDelete
              onCancel={ () => this.deleteItem() }
              className='Modal_Delete'
              isOpen={this.state['ModalDeleteItem']}
              onRequestClose={()=>this.removeModalDeleteItem()}
            />
            <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
            {
              tempBox
            }
          </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
