import React, {Component,Fragment} from 'react';
import { toastr } from 'react-redux-toastr';
import Language from '../../config/language';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Box from '../../common/template/box';
import ModalBloquear from '../modals/modal-bloquear-liberar';
import api from '../../api';

export default class Bloqueios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bloqueios: props.bloqueios || []
    }
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  openModal(modal) {
    let dados = {[modal]: true};

    this.setState(dados);
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  onSuccess(data){
    const bloqueios = this.state.bloqueios;
    switch (data.type) {
      case "create":
        const newData = [...bloqueios, data];
        this.setState({bloqueios:newData});
        break;
      case "update":
        const updated = this.state.bloqueios.map(i =>
          {
            return i.id === data.id ? data : i;
          }
        );
        this.setState({bloqueios:updated});
        break;
      default:

    }
  }

  remove(id){
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.bloqueios.url}${id}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeItemItem(id){
    const arr = this.state.bloqueios.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      bloqueios: arr
    });
  }

  render(){
    return(
      <Fragment>
        <ModalBloquear
          token={this.props.token}
          className='Modal_Bloquear modal-no-overflow'
          isOpen={this.state['modalBloquear']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalBloquear')}
          title="Bloqueio"
          parent={{field: 'id_key', id:this.props.sala}}
          url={`${api.API_URL}${api.API.bloqueios.url}`}
          tipoBloqueio={CONSTS.BLOQUEIOS.equipamentos.id}
          tipo='bloqueio'
          onSuccess={this.onSuccess}
          item={this.state.item}
        />
        <Box classes="box-danger" title="Datas de bloqueio">
          <button type="button" className="btn btn-block btn-success" onClick={()=>this.openModal('modalBloquear')}>
            <i className="fa fa-plus"></i> Adicionar bloqueio
          </button>
          <br /><br />
          {
            this.state.bloqueios.length ?
            <table className='table'>
                <thead>
                  <tr>
                      <th>Data início</th>
                      <th>Data fim</th>
                      <th>Hora início</th>
                      <th>Hora fim</th>
                      <th className='table-actions'></th>
                  </tr>
                </thead>
                  <tbody>
                  {
                    this.state.bloqueios.map(item=>
                  {
                    return(
                      <tr key={item.id}>
                          <td>{item.data_inicial}</td>
                          <td>{item.data_final}</td>
                          <td>{item.hora_inicial}</td>
                          <td>{item.hora_final}</td>
                          {
                            this.props.readOnly ? null :
                            <td>
                              <button type="button" className='btn btn-danger' onClick={() => this.remove(item.id)}>
                                  <i className='fa fa-trash-o'></i>
                              </button>
                            </td>
                          }
                      </tr>
                    );
                  })
                  }
                </tbody>
            </table> : null
          }

        </Box>
      </Fragment>
    );
  }
}
