import React, { Component } from 'react';

import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import Tabs from '../../common/tab/tabs';
import TabsHeader from '../../common/tab/tabsHeader';
import TabsContent from '../../common/tab/tabsContent';
import TabHeader from '../../common/tab/tabHeader';
import TabContent from '../../common/tab/tabContent';
import { showTabs } from '../../common/tab/tabActions';
import If from '../../common/operator/if';
import List from './List';
import Form from './Form';
import ModalBusca from './modal-buscar';

const INITIAL_STATE_TABS = { selected: '', visible: {} };

class Salas extends Component{
  constructor(props){
    super(props);
    this.state = {
      tabs: INITIAL_STATE_TABS
    }
    this.changeTab = this.changeTab.bind(this);
    this.showUpdate = this.showUpdate.bind(this);
    this.showDelete = this.showDelete.bind(this);

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSearch = this.onSearch.bind(this);

  }

  componentWillMount() {
    showTabs({ tabId: 'tabList', tabs: this.state.tabs, component: this });
  }

  onSearch(data){
    this.closeModal('modalBuscar');
    showTabs({tabId:'tabList', tabs: this.state.tabs, component: this, onComplete: () => {
      this.setState({query: data.query, loaded: false});
    }});
  }

  openModal(modal) {
    this.setState({[modal]: true});
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  changeTab(tabId='tabList'){
    showTabs({tabId:tabId, tabs: this.state.tabs, component: this});
  }

  showUpdate(args){
    this.setState({
      detail: args
    });
    showTabs({tabId:'tabUpdate', tabs: this.state.tabs, component: this});
  }

  showDelete(args){
    this.setState({
      detail: args
    });
    showTabs({tabId:'tabDelete', tabs: this.state.tabs, component: this});
  }

  render(){
    const tabs = this.state.tabs;

    return(
      <div>
      <ModalBusca
        onSearch={this.onSearch}
        className='Modal_Busca'
        isOpen={this.state['modalBuscar']}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={()=>this.closeModal('modalBuscar')}
      />
      <ContentHeader title='Equipamentos' small='' />
          <Content>
              <Tabs>
                  <TabsHeader>
                      <TabHeader
                        label='Listar'
                        icon='bars'
                        target='tabList'
                        selectTab={this.changeTab}
                        tabs={tabs}
                        component={this}
                      />
                      <TabHeader
                        label='Incluir'
                        icon='plus'
                        target='tabCreate'
                        selectTab={this.changeTab}
                        tabs={tabs}
                        component={this}
                      />
                      <TabHeader
                        label='Alterar'
                        icon='pencil'
                        target='tabUpdate'
                        selectTab={this.changeTab}
                        tabs={tabs}
                        component={this}
                      />
                      <TabHeader
                        label='Excluir'
                        icon='trash-o'
                        target='tabDelete'
                        selectTab={this.changeTab}
                        tabs={tabs}
                        component={this}
                      />
                      <TabHeader
                        label='Buscar'
                        icon='search'
                        target='tabSearch'
                        selectTab={()=>this.openModal('modalBuscar')}
                        tabs={tabs}
                        component={this}
                      />
                  </TabsHeader>
                  <TabsContent>
                      <TabContent id='tabList' tabs={tabs}>
                        <If test={tabs.selected === 'tabList'}>
                          <List
                          query={this.state.query}
                          showUpdate={this.showUpdate}
                          showDelete={this.showDelete} />
                        </If>
                      </TabContent>
                      <TabContent id='tabCreate' tabs={tabs}>
                        <If test={tabs.selected === 'tabCreate'}>
                          <Form
                            changeTab={this.changeTab}
                            type={'create'}
                            cancelarHandler={this.changeTab}
                            submitLabel='Incluir'
                            submitClass='primary' />
                        </If>

                      </TabContent>
                      <TabContent id='tabUpdate' tabs={tabs}>
                        <If test={tabs.selected === 'tabUpdate'}>
                          <Form
                            changeTab={this.changeTab}
                            detail={this.state.detail}
                            type={'update'}
                            onSubmit={this.props.update}
                            cancelarHandler={this.changeTab}
                            submitLabel='Alterar' submitClass='info' />
                        </If>
                      </TabContent>
                      <TabContent id='tabDelete' tabs={tabs}>
                        <If test={tabs.selected === 'tabDelete'}>
                          <Form
                            changeTab={this.changeTab}
                            readOnly={true}
                            type={'delete'}
                            detail={this.state.detail}
                            cancelarHandler={this.changeTab}
                            submitLabel='Excluir' submitClass='danger' />
                        </If>
                      </TabContent>
                  </TabsContent>
              </Tabs>
          </Content>
      </div>
    );
  }
}

export default Salas;
