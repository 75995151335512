import React, { Component } from 'react';
import $ from 'jquery';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndTimePicker from '../../common/form/labelAndTimerPicker';
import TitleModal from '../../common/form/titleModal';
import api from '../../api';
import { urlParamsConverter } from '../../config/methods';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import {convertToBr, dateTimeToStr, stringToTime} from '../../utils/dateJS';
import ModalLoading from '../modals/modal-loading';
import CONSTS from '../../config/consts';
import './modal-agendamentos-ajax.scss';

ReactModal.setAppElement('#root');


const INITIAL_STATE = {
  date: new Date(),
  hora_final: null,
  hora_inicial: null,
  profissionaisList: [],
  equipamentosList: [],
  salasList: [],
  profissional: null,
  sala: null,
  equipamento: null
};

class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {...INITIAL_STATE};

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.aplicarAgendamento = this.aplicarAgendamento.bind(this);
    this.horaSelecionada = null;
  }

  aplicarAgendamento(){
    if(!this.horaSelecionada){
      toastr.warning(Language.atencao, Language.selecioneHorarioDinamico);
      return;
    }else{
      const {sala, profissional, equipamento} = this.state;
      this.props.callAgendamentoDinamico({
        hora_inicial: this.horaSelecionada,
        sala,
        profissional,
        equipamento,
        data: convertToBr(this.state.date)
      });
    }
  }

  onAfterOpen(){
    this.setState({...INITIAL_STATE});
    this.horaSelecionada = null;
  }

  onChange = date => this.setState({ date });

  onChangeTime(time, item){
    this.setState({ [item] : time });
  }

  handleClick(el){
    $(el.target).addClass('active_hour_dinamic');
  }

  handleSelect(item, name){
    const date = convertToBr(this.state.date);
    const hri = dateTimeToStr(this.state.hora_inicial);
    const hrf = dateTimeToStr(this.state.hora_final);
    const data = {
      date: date,
      list: item.value,
      hri: hri,
      hrf: hrf
    };
    switch (name) {
      case "profissional":
        data.type = 'profissionais';
        this.setState({ [name] : item }, this.loadData(data));
        break;
      case "sala":
      data.type = 'salas';
      this.setState({ [name] : item }, this.loadData(data));
        break;
      case "equipamento":
        data.type = 'equipamentos';
        this.setState({ [name] : item }, this.loadData(data));
        break;
      default:

    }
  }

  loadData(args){
    const token = this.props.token;
    const self = this;

    const query = `?${urlParamsConverter(args)}`;

    self.setState({ModalLoading: true});

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.agendamentos.list}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.fillHorarios(data.data, args.type);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.setState({ModalLoading: false});
      }
    });
  }

  fillHorarios(data, type){
    if(data.length){
      const item = data[0];
      const agendamentos = item.agendamentos;
      switch (type) {
        case 'profissionais':
          this.setState({profissionaisList: agendamentos}, this.callHandlerHour);
          break;
        case 'salas':
          this.setState({salasList: agendamentos}, this.callHandlerHour);
          break;
        case 'equipamentos':
          this.setState({equipamentosList: agendamentos}, this.callHandlerHour);
          break;
        default:

      }
    }else{

    }
  }

  callHandlerHour(){
    const self = this;

    $('.tr-hour-dinamic').on('click', (el) => {
      $('.active_hour_dinamic').removeClass('active_hour_dinamic');
      $(el.currentTarget).addClass('active_hour_dinamic');
      const hour = $(el.currentTarget).find('.hour').text();
      self.horaSelecionada = hour;
      
    });
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
    const urlSala = `${api.API_URL}${api.API.salas.checkByAgendamento}`;
    const urlEquipamento = `${api.API_URL}${api.API.equipamentos.checkByAgendamento}`;
    const readOnly = this.state.hora_inicial && this.state.hora_final && this.state.date ? false : true;

    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <div className='box-modal-visualizacao__wrapper'>
              <TitleModal title="Agendamentos" />
              <div className="clearfix">
                <LabelAndDate required name='data' value={this.state.date} onChange={data => this.onChange(data, 'date')}
                    label='Data inicial' cols='12 4' date={this.state.date} />
                <LabelAndTimePicker required name='hora_inicial' handleChange={time => this.onChangeTime(time, 'hora_inicial')}
                  label='Hora inicial' cols='12 4' placeholder='Digite aqui' startDate={this.state.hora_inicial} />
                <LabelAndTimePicker required name='hora_final' handleChange={time => this.onChangeTime(time, 'hora_final')}
                  label='Hora final' cols='12 4' placeholder='Digite aqui' startDate={this.state.hora_final} />
              </div>
              <div className="box-button-aplicar-agendamento clearfix">
                <button type="submit" onClick={this.aplicarAgendamento} className="btn pull-right btn-danger">Aplicar agendamento</button>
              </div>
              <div className="box-body">
                <table className="table table-striped table-bordered">
                  <thead className="thead-agendamento-ajax bg-light-blue">
                    <tr>
                      <th style={{width: '33.33%'}}>Profissional</th>
                      <th style={{width: '33.33%'}}>Sala</th>
                      <th style={{width: '33.33%'}}>Equipamento</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <LabelAndSelectAjax
                          name='profissional'
                          readOnly={readOnly}
                          required
                          value={this.state.profissional}
                          onChange={this.handleSelect}
                          url={urlVendedor}
                          token={this.props.token}
                          label=''
                          cols='12'
                          query={{tipo:2}}
                          placeholder='Digite aqui' />
                        </td>
                        <td>
                        <LabelAndSelectAjax
                         readOnly={readOnly}
                          name='sala'
                          required
                          value={this.state.sala}
                          onChange={this.handleSelect}
                          url={urlSala}
                          token={this.props.token}
                          label=''
                          cols='12'
                          placeholder='Digite aqui' />
                        </td>
                      <td>
                        <LabelAndSelectAjax
                          readOnly={readOnly}
                          name='equipamento'
                          value={this.state.equipamento}
                          onChange={this.handleSelect}
                          url={urlEquipamento}
                          token={this.props.token}
                          label=''
                          cols='12'
                          placeholder='Digite aqui' />
                        </td>
                    </tr>
                    <tr>
                      <td style={{padding: 0}}>
                        <table className='table table-striped'>
                          <tbody>
                          {
                            this.state.profissionaisList.map((v,i) => {
                              return (
                                v.agendamento ? null :
                                <tr key={Math.random().toString(36).slice(2)} className="tr-hour-dinamic">
                                  <td className="td-hour-dinamic"><strong className="hour">{v.hora}</strong></td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                      </td>
                      <td style={{padding: 0}}>
                        <table className='table table-striped'>
                          <tbody>
                          {
                            this.state.salasList.map((v,i) => {
                              return (
                                v.agendamento ? null :
                                <tr key={Math.random().toString(36).slice(2)} className="tr-hour-dinamic">
                                <td className="td-hour-dinamic"><strong className="hour">{v.hora}</strong></td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                      </td>
                      <td style={{padding: 0}}>
                        <table className='table table-striped'>
                          <tbody>
                          {
                            this.state.equipamentosList.map((v,i) => {
                              return (
                                v.agendamento ? null :
                                <tr key={Math.random().toString(36).slice(2)} className="tr-hour-dinamic">
                                <td className="td-hour-dinamic"><strong className="hour">{v.hora}</strong></td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br /><br />
              </div>
            </div>
          </div>
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </div>
    )
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Modal);
