import React, { Component, Fragment } from 'react';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import CONSTS from '../../config/consts';
import { maskMoney, maskNumber, maskPercent, strPtBrMoneyToNumber } from '../../config/methods';

import './historico-agendamento.scss';

const options = CONSTS.TIPO_COMISSAO;
const optionsTiposPagamento = CONSTS.METODOS_PAGAMENTO;
const parcelas = CONSTS.PARCELAS;

const INITIAL_STATE = {
  valor: '',
  tipo_pagamento: optionsTiposPagamento[0],
  qnt_parcelas: parcelas[0],
  tipo_desconto: '',
  desconto: '',
  total: '',
  n_sessoes: 1
};

export default class FinanceiroAgendamento extends Component{
  constructor(props){
    super(props);

    this.state = {...INITIAL_STATE};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount(){
    this.initData();
  }

  handleSelect(item, name){
    //se a quantidade de parcelas foi limpada, forçamos ela a ser 1
    if(name === 'qnt_parcelas' && !item){
      item = parcelas[0];
    }

    this.handleInputChange({
      target: {value:item, name:name}
    });
  }

  handleInputChange(event) {
    this.checkValues(event);
  }

  checkValues(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let convertedValue = value;
    let newState = {};

    switch (name) {
      case "valor":
        convertedValue = maskMoney(convertedValue);
        newState = { [name]: convertedValue }
        break;
        case "desconto":
          //se o tipo de comissão for 2, ou seja, dinheiro, convertemos o valor para dinheiro
          if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 2)){
            newState = { [name]: maskMoney(convertedValue)}
          } else if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 1)){
            newState = { [name]: maskPercent(convertedValue)}
          }else{
            newState = { [name]: maskNumber(convertedValue)}
          }
          break;
      default:
        newState = {[name]: value};
    }

    newState = this.checkTotal(newState, this.state);

    //se o tipo de pagamento for dinheiro ou transferência, a quantidade de parcelas será somente 1
    if(newState.tipo_pagamento && newState.tipo_pagamento.maxParcelas){
      newState.qnt_parcelas = parcelas[0];
    }

    this.props.changeFinanceiro({
      valor: strPtBrMoneyToNumber(newState.valor),
      tipo_pagamento: newState.tipo_pagamento,
      qnt_parcelas: newState.qnt_parcelas,
      tipo_desconto: newState.tipo_desconto,
      desconto: strPtBrMoneyToNumber(newState.desconto),
      total: newState.total,
      n_sessoes: newState.n_sessoes
    });

    this.setState(newState);
  }

  getTotal(total, nParcelas){
    let newTotal = Number(total)/Number(nParcelas);
    newTotal = nParcelas +" x de "+ String(newTotal.toFixed(2)).replace('.',',');
    return newTotal;
  }

  checkTotal(prevState, currentState){
    let newState = {...currentState, ...prevState};

    if(newState.n_sessoes && newState.valor){
      newState.total = strPtBrMoneyToNumber(newState.valor) * newState.n_sessoes;
      newState.total = this.getTotal(newState.total, newState.qnt_parcelas.value);
    }

    if(newState.n_sessoes && newState.tipo_desconto  && (newState.tipo_desconto.value !== '') && newState.desconto && newState.valor){
      const tipo_desconto = newState.tipo_desconto.value;
      const valor = Number(strPtBrMoneyToNumber(newState.valor));
      const desconto = Number(strPtBrMoneyToNumber(newState.desconto));
      const n_sessoes = newState.n_sessoes;

      let total = (tipo_desconto === 1)
                        ? (valor * n_sessoes) - ((valor * n_sessoes) * (desconto/100))
                        : ((valor * n_sessoes) - desconto);
      //total = Number(total).toFixed(1);
      //newState.total = newState.qnt_parcelas.value +" x de "+ String(total+"0").replace('.',',');
      newState.total = this.getTotal(total, newState.qnt_parcelas.value);
    }

    return newState;
  }

  componentDidUpdate(prevProps) {
    if ((this.props.data !== prevProps.data)) {
      this.initData();
    }
  }

  initData(){
    if(this.props.data && this.props.data.update && this.props.data.valor){
      const value = this.props.data.valor.formated;
      this.handleInputChange({
        target: {value , name: 'valor'}
      });
    }
  }

  render(){
    const readOnly = false;
    let blockParcelas = false;

    //se for dinheiro ou transferência, bloqueamos as parcelas a 1 apenas
    if(this.state.tipo_pagamento && this.state.tipo_pagamento.maxParcelas){
      blockParcelas = true;
    }else{
      blockParcelas = readOnly;
    }

    return(
      <Fragment>
        <Box classes="box-success" title="Financeiro">
          <span className="text-red" style={{marginBottom: 20, display: 'block', paddingLeft: 15}}>Selecione o serviço para que o valor seja preenchido automaticamente</span>
          <div className='clearfix'></div>
          <LabelAndInput required name='valor' readOnly={true} value={this.state.valor}  handleChange={this.handleInputChange}
              label='Valor' cols='12 4' />
          <LabelAndSelect required name='tipo_pagamento' options={optionsTiposPagamento} value={this.state.tipo_pagamento} readOnly={readOnly} handleChange={this.handleSelect}
            label='Tipo de pagamento' cols='12 4' placeholder='Digite aqui' />
          <LabelAndSelect name='qnt_parcelas' options={parcelas}  readOnly={blockParcelas} value={this.state.qnt_parcelas} handleChange={this.handleSelect}
            label='Qnt. parcelas' cols='12 2' />
          <Clearfix />
          <LabelAndSelect name='tipo_desconto' options={options}  readOnly={readOnly} value={this.state.tipo_desconto} handleChange={this.handleSelect}
            label='Tipo de desconto' cols='12 3' placeholder='Digite aqui' />
          <LabelAndInput name='desconto' readOnly={readOnly} value={this.state.desconto}  handleChange={this.handleInputChange}
              label='Desconto' cols='12 3' />
          <LabelAndInput name='total'  value={this.state.total} readOnly
              label='Total do serviço' cols='12 4' placeholder='' />
          {
            /*<If test={!this.props.readOnly}>
              <ButtonAddAjax type="button" cols="1" icon="plus" onClick={this.addFinanceiro} />
            </If>*/
          }
          {
            /*
            <Clearfix />
            <Separador />
            <table className='table'>
                <thead>
                    <tr>
                      <th className="th-parcela">Parcela</th>
                      <th>Data</th>
                      <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                  [0,1,2,3].map(()=>
                  <tr key={1}>
                    <td>1</td>
                    <td>10/10/2019</td>
                    <td>R$100,00</td>
                  </tr>)
                </tbody>
            </table>
            <Separador />
            <Clearfix />
            <Separador />
            */
          }
        </Box>
      </Fragment>
    );
  }
}
