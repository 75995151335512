import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import { connect } from 'react-redux';
import LoadingPage from '../../common/template/loadingPage';
import ModalDelete from '../modals/modal-delete';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import ErrorPage from '../../common/template/errorPage';
import ModalLoading from '../modals/modal-loading';
import MandatoryFields from '../../common/template/mandatoryFields';
import Bloqueios from './bloqueios';
import LabelAndSelect from '../../common/form/labelAndSelect';

const INITIAL_STATE = {
  equipamento: "",
  tempo: 1,
  qnt_atendimentos: ""
};
const optionsTempoAtendimento = CONSTS.TEMPO_ATENDIMENTO;
class Form extends Component {
  constructor(props){
    super(props);

    this.state = {
      loaded: !this.props.detail,
      ...INITIAL_STATE
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  initData(data){
    const response = data.data;
    response.loaded = true;

    if(response.id_patrimonio){
      response.id_patrimonio = {value: response.id_patrimonio.id, label: response.id_patrimonio.nome}
    }

    if(response.tempo){
      const tA = CONSTS.TEMPO_ATENDIMENTO.filter( ( elem, i, array ) => {
          return String(elem.value) === String(response.tempo);
      });
      if(tA.length){
        response.tempo = tA[0];
      }
    }

    this.setState({
      ...response
    });
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.equipamentos.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    this.callLoading(true);
    self.removeModalDeleteItem();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.equipamentos.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.equipamentos.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};
    delete data.ModalLoading;
    delete data.loaded;

    if(this.state.id_patrimonio){
      data.id_patrimonio = this.state.id_patrimonio.value;
    }

    if(this.state.tempo){
      data.tempo = data.tempo.value;
    }

    return data;
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.equipamentos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.callLoading(true);
        this.update();
        break;
      default:
        this.callLoading(true);
        this.create();
    }

    e.preventDefault();
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  getBloqueios(){
    const token= this.props.user;
    const { readOnly } = this.props;
    return <Bloqueios
      readOnly={readOnly}
      key={Math.random()}
      bloqueios={this.state.bloqueios}
      token={token}
      sala={this.props.detail.id}
    />;
  }

  renderContent() {
      const { readOnly } = this.props;
      const url = `${api.API_URL}${api.API.patrimoniosAportes.autocomplete}`;
      const bloqueios = this.props.detail ? this.getBloqueios() : null;


      return (
        <div>
          <form onSubmit={this.handleSubmit}>
              <div className='box-body'>
                <LabelAndInput required value={this.state.equipamento} name='equipamento' component={LabelAndInput} readOnly={readOnly} handleChange={this.handleInputChange}
                  label='Nome do equipamento*' cols='12' placeholder='Informe o nome' />
                <LabelAndSelectAjax
                  readOnly={this.props.readOnly}
                  name='id_patrimonio'
                  value={this.state.id_patrimonio}
                  onChange={this.handleSelect}
                  url={url}
                  token={this.props.user}
                  label='Vincular equipamento a um patrimônio'
                  cols='12 4'
                  placeholder='Digite aqui' />
                {/*
                  <LabelAndSelect name='tempo' required options={optionsTempoAtendimento} readOnly={readOnly} handleChange={this.handleSelect}
                    label='Tempo de atendimento*' cols='12 4' placeholder='Digite aqui' value={this.state.tempo} />
                */}
                <LabelAndInput type='number' required name='qnt_atendimentos' value={this.state.qnt_atendimentos} component={LabelAndInput} readOnly={readOnly} handleChange={this.handleInputChange}
                  label='Qnt. atendimentos simultâneos*' cols='3' placeholder='' />
              </div>
              <MandatoryFields />
              <div className='box-footer'>
                  <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                      {this.props.submitLabel}
                  </button>
                  <button type='button' className='btn btn-default'
                      onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
              </div>
          </form>
          {
            bloqueios
          }
        </div>
      )
  }

  render() {
      const tempBox = this.state.loaded
                    ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                    : null;

      return (
        <div>
          <ModalDelete
            onCancel={ () => this.deleteItem() }
            className='Modal_Delete'
            isOpen={this.state['ModalDeleteItem']}
            onRequestClose={()=>this.removeModalDeleteItem()}
          />
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

          {
            this.state.loaded ?
            tempBox
            : <LoadingPage />
          }

        </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
