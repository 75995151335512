import React, { Component } from 'react';
import ReactModal from 'react-modal';

import LabelAndCounter from '../../common/form/labelAndCounter';
import TitleModal from '../../common/form/titleModal';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';

import './modal-servico.scss';

ReactModal.setAppElement('#root');

const options = [
  {value:'1',label:'Porcentagem (%)'},
  {value:'2',label:'Dinheiro ($)'}
];

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      date: null
    }
  }

  onChange = date => this.setState({ date });

  render(){
    const readOnly = false;
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <ReactModal
        portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
      >
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Serviço" />
            <div className="box-body">
              <LabelAndSelectAjax label='year'
                label='Serviço' cols='9' placeholder='Digite aqui' />
              <LabelAndCounter label='code_number' component={LabelAndCounter} type='number'
                  label='Nº de sessões' cols='12 3' placeholder='' />
            </div>
          </div>
          <button type="button" onClick={this.props.onRequestClose} className="btn btn-block btn-success">Salvar</button>

        </div>
      </ReactModal>
    )
  }
}
