import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import api from '../../api';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import ModalAgendamento from '../modals/modal-agendamento';
import ModalPlano from '../modals/modal-plano';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
import Pagination from '../../common/template/pagination';
import LabelAndInput from '../../common/form/labelAndInput';

const INITIAL_STATE_TABS = { agendamentos: [], loaded: false };


const optionsTipos = [
  {value: '',label:'Todos'},
  {value:'1',label:'Agendamentos'},
  {value:'2',label:'Vendas'},
  {value:'3',label:'Planos/Combos/Promoções'},
  {value:'4',label:'Clientes'},
  {value:'5',label:'Salas'},
  {value:'6',label:'Equipamentos'},
  {value:'7',label:'Profissionais'},
  {value:'8',label:'Usuários'},
  {value:'10',label:'Estoque'},
  {value:'11',label:'Serviços'},
  {value:'12',label:'Patrimônios e aportes'},
  {value:'13',label:'Bloqueios'},
  {value:'14',label:'Liberações'},
];

class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
    this.changePage = this.changePage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.agendamentos.map((value, i) => [
      value.data,
      value.hora_inicial,
      value.hora_final,
      value.profissional ? value.profissional : '',
      value.cliente ? (value.cliente + (value.cliente_sobrenome ? ' '+value.cliente_sobrenome : '')) : '',
      value.sala ? value.sala : '',
      value.servico ? value.servico : '',
      value.equipamento ? value.equipamento : ''
    ]);
    arr.unshift(["Data", "Hora inicial", "Hora final", "Profissional", "Cliente", "Sala", "Serviço", "Equipamento"]);
    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "agendamentos.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }


  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {};

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    if(this.state.data_final){
      query.data_final = convertToBr(this.state.data_final);
    }

    if(this.state.tipo){
      query.tipo = this.state.tipo.value;
    }

    if(this.state.palavra){
      query.palavra = this.state.palavra;
    }

    if(this.state.query_page){
      query.page = this.state.query_page.page;
    }

    return query;
  }

  loadData(item){

    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroData);
      return;
    }
    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.controle.list}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({agendamentos: data.data, pagination: data.pagination, loaded: true});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  changePage(goTo){
    const page = goTo==='next' ? this.state.pagination.next : this.state.pagination.prev;
    this.setState({
      query_page: {page}
    }, () => this.loadData());
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  getBody(){

    return (
      <Content>

        <Box classes="box-success" title="">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Mensagem</th>
                        <th>Usuário</th>
                        <th>Detalhe</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    this.state.agendamentos.map(bc => {
                      let m = [];
                      const data = bc.data;

                      if (data) {
                        const keys = Object.values(data);
                        for (const key of keys) {
                          if(key) m.push(<div>{key}</div>);
                        }
                      }

                      return (
                        <tr key={bc.id}>
                          <td>{bc.created_at}</td>
                          <td>{bc.mensagem}</td>
                          <td>{bc.usuario}</td>
                          <td>{m}</td>

                        </tr>
                      );
                    } )
                  }
                </tbody>
            </table>
            <Pagination onClick={this.changePage} dados={this.state.pagination} />
          </div>
        </Box>
      </Content>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){

    return(
      <Fragment>
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          //tipo={'crm_detail'}
        />
        <ModalPlano
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Plano'
          isOpen={this.state['modalPlano']}
          onRequestClose={()=>this.closeModal('modalPlano')}
          dados={this.state.dadosPlano}
        />
        <ContentHeader title='Controle de atividades' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate required name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data inicial' cols='12 2' date={this.state.data_inicial} />
            <LabelAndDate name='data_final' value={this.state.data_final} onChange={data => {this.onChangeDate(data, 'data_final');}}
                label='Data final' cols='12 2' date={this.state.data_final} />
            <LabelAndSelect name='tipo' options={optionsTipos} value={this.state.tipo}handleChange={this.handleSelect}
              label='Tipo' cols='12 3' placeholder='Digite aqui' />
            <LabelAndInput name='palavra' value={this.state.palavra} handleChange={this.handleInputChange}
                label='Busca por palavra' cols='12 4' placeholder='Digite aqui' />
              <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : null}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
