import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './modal-alert.scss';

ReactModal.setAppElement('#root');

export default class Modal extends Component{

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    return(
      <ReactModal
        portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      </ReactModal>
    )
  }
}
