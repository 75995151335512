import React, {Component,Fragment} from 'react';
import { toastr } from 'react-redux-toastr';
import Language from '../../../config/language';
import Requisition from '../../../utils/requisitions/requisition';
import CONSTS from '../../../config/consts';
import Box from '../../../common/template/box';
import api from '../../../api';
import LabelAndInput from '../../../common/form/labelAndInput';
import Grid from '../../../common/layout/grid';
import ModalLoading from '../../../sections/modals/modal-loading';
import ButtonAddAjax from '../../../common/form/buttonAddAjax';
export default class Arquivos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arquivos: props.arquivos || []
    }
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal(modal) {
    let dados = {[modal]: true};

    this.setState(dados);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]:value});
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  onSuccess(data){
    const arquivos = this.state.arquivos;
    switch (data.type) {
      case "create":
        const newData = [...arquivos, data];
        this.setState({arquivos:newData});
        break;
      case "update":
        const updated = this.state.arquivos.map(i =>
          {
            return i.id === data.id ? data : i;
          }
        );
        this.setState({arquivos:updated});
        break;
      default:

    }
  }

  remove(id){
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.contas.removerArquivo}${id}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeItemItem(id){
    const arr = this.state.arquivos.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      arquivos: arr
    });
  }

  handleSubmit(file){
    if(!file){
      toastr.warning(Language.atencao, Language.noFileAdded);
      return;
    }

    var data = new FormData();
    data.append('file', this.state.file);
    data.append('nome', this.state.nome);
    data.append('conta', this.props.conta);

    //-------
    const token = this.props.token;
    const self = this;
    self.callLoading(true);
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.contas.arquivo}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({arquivos:data.data});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro03);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro04);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  onErrorImage(error){
    toastr.warning(Language.atencao, Language.errorGenerateImage);
  }

  onChange(el){
    this.setState({file:el.target.files[0]});
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

   download(e, file){
    e.preventDefault();

    var link = document.createElement('a');
    link.href = file;
    link.download = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
   }

  render(){

    return(
      <Fragment>
      <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

        <Box classes="box-danger" title="Arquivos/Anexos">
        <form>
          <LabelAndInput required name='nome'  type="text" value={this.state.nome}  handleChange={this.handleInputChange}
            label='Título' cols='12 7' placeholder='' />
          <Grid cols="12 4"><input className="input-arquivo-conta" type="file" onChange={this.onChange} /></Grid>
          {/*}<span type="button" className="btn btn-block btn-success box-anexo" onClick={()=>this.openModal('modalBloquear')}>
            <input type="file" onChange={this.onChange} />
            <i className="fa fa-plus"></i> Adicionar Arquivo
          </span>*/}
          <ButtonAddAjax type="submit" cols="1" icon="plus" onClick={this.handleSubmit} />
        </form>

          {/*

          */}
          <br /><br />
          {
            this.state.arquivos.length ?
            <table className='table'>
                <thead>
                  <tr>
                      <th>Titulo</th>
                      <th className='table-actions'></th>
                  </tr>
                </thead>
                  <tbody>
                  {
                    this.state.arquivos.map(item=>
                  {
                    return(
                      <tr key={item.id}>
                          <td>{item.nome}</td>
                          {
                            this.props.readOnly ? null :
                            <td>

                              <a target="_blank" rel="noopener noreferrer" href={`${api.API_URL}${api.API.download.arquivoConta}?file=${item.arquivo}&apiKey=${CONSTS.API_KEY_VALUE}&token=${this.props.token}`} className='btn btn-success'  >
                                <i className='fa fa-download'></i>
                              </a>
                              <button type="button" className='btn btn-danger' onClick={() => this.remove(item.id)}>
                                  <i className='fa fa-trash-o'></i>
                              </button>
                            </td>
                          }
                      </tr>
                    );
                  })
                  }
                </tbody>
            </table> : null
          }

        </Box>
      </Fragment>
    );
  }
}
