import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import $ from 'jquery';
import LabelAndSelectAjax from '../../../common/form/labelAndSelectAjax';
import LabelAndInput from '../../../common/form/labelAndInput';
import LabelAndText from '../../../common/form/labelAndText';
import Clearfix from '../../../common/form/clearfix';
import Box from '../../../common/template/box';
import './form.scss';
import { maskMoney, strPtBrMoneyToNumber } from '../../../config/methods';
import LabelAndDate from '../../../common/form/labelAndDatePicker';
import ModalDelete from '../../modals/modal-delete';
import ModalLoading from '../../modals/modal-loading';
import LoadingPage from '../../../common/template/loadingPage';
import ErrorPage from '../../../common/template/errorPage';
import Financeiro from './financeiro';
import api from '../../../api';
import Requisition from '../../../utils/requisitions/requisition';
import CONSTS from '../../../config/consts';
import Language from '../../../config/language';
import {convertToBr, brToDate} from '../../../utils/dateJS';
import Arquivos from './arquivos';
import LabelAndSelect from '../../../common/form/labelAndSelect';
const INITIAL_STATE = {
  nome: '',
  descricao: '',
  servicos: []
}
const optionsTipo = CONSTS.TIPOS_CONTAS;
class Form extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: !this.props.detail
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  handleSelect(item, name){

    //se for profissional, resetamos o colaborador
    if (name === 'profissional') {
      this.setState({colaborador: ''});
    }
    //se for colaborador, resetamos o profissional
    if (name === 'colaborador') {
      this.setState({profissional: ''});
    }

    this.setState({ [name] : item });
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name==='valor'){
      value = maskMoney(value);
    }

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.update();
        break;
      default:
        this.create();
    }

    e.preventDefault();
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();


    if (!data.valor) {
      toastr.warning(Language.atencao, Language.camposObrigatorios);
      return false;
    }

    if (!data.vencimento) {
      toastr.warning(Language.atencao, Language.camposObrigatorios);
      return false;
    }

    if (!data.nome) {
      toastr.warning(Language.atencao, Language.camposObrigatorios);
      return false;
    }

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.contas.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};

    if(data.vencimento){
      data.vencimento = convertToBr(data.vencimento);
    }

    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }

    if(data.profissional){
      data.profissional = data.profissional.value;
    }

    if(data.fornecedor){
      data.fornecedor = data.fornecedor.value;
    }

    if(data.colaborador){
      data.colaborador = data.colaborador.value;
    }

    if(data.tipo_conta){
      data.tipo_conta = data.tipo_conta.value;
    }

    return data;
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.contas.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.keepCreating(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
        self.loaded();
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  getFinanceiro(){
    const token= this.props.user;
    const { readOnly } = this.props;
    return <Financeiro readOnly={readOnly} key={Math.random()} financeiro={this.state.financeiro} token={token} conta={this.props.detail.id} />;
  }

  initData(response){

    if(response.valor){
      response.valor = response.valor.formated;
    }

    if(response.vencimento){
      response.vencimento = brToDate(response.vencimento);
    }

    this.setState({
      ...response
    });


    if(this.props.detail.statusCreated){
      $('html,body').animate({
        scrollTop: $("#content-ajax").offset().top},
        'slow');
    }
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.contas.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            const response = data.data;
            self.initData(response);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response =>{
        self.loaded();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    this.callLoading(true);
    self.removeModalDeleteItem();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.contas.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        self.callLoading(false);
      }
    });
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  getArquivos(){
    const token= this.props.user;
    const { readOnly } = this.props;
    return <Arquivos
      readOnly={readOnly}
      key={Math.random()}
      arquivos={this.state.arquivos}
      conta={this.props.detail.id}
      token={token}
    />;
  }

  renderContent() {
      const { readOnly } = this.props;
      const financeiro = this.props.detail ? this.getFinanceiro() : null;
      const arquivos = this.props.detail ? this.getArquivos() : null;
      const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
      const urlFornecedor = `${api.API_URL}${api.API.fornecedores.autocomplete}`;
      return (
        <div>
            <div className='box-body'>
              <div id="content-ajax">
                <form onSubmit={this.handleSubmit}>
                  <Box classes="box-success" title="Dados gerais">
                    <LabelAndInput name='nome' required value={this.state.nome} readOnly={readOnly}  handleChange={this.handleInputChange}
                      label='Conta' cols='12 6' placeholder='Informe a conta' />
                      <LabelAndInput name='valor' required  readOnly={readOnly} handleChange={this.handleInputChange}
                          label='Valor*' cols='12 3' placeholder='Informe o valor' value={this.state.valor} />
                      <LabelAndDate required name='vencimento' value={this.state.vencimento}  readOnly={readOnly} onChange={date => this.onChangeDate(date, 'vencimento')}
                          label='Data de vencimento/pagamento' cols='12 3' placeholder='Digite aqui' date={this.state.vencimento} />
                      <LabelAndSelect required name='tipo_conta' options={optionsTipo} value={this.state.tipo_conta} readOnly={readOnly} handleChange={this.handleSelect}
                        label='Tipo de conta' cols='12 3' placeholder='Digite aqui' />
                      <LabelAndSelectAjax
                        readOnly={readOnly}
                        name='profissional'
                        value={this.state.profissional}
                        onChange={this.handleSelect}
                        url={urlVendedor}
                        token={this.props.user}
                        label='Vincular conta a um profissional'
                        cols='12 3'
                        query={{tipo:2}}
                        placeholder='Digite aqui' />
                      <LabelAndSelectAjax
                        readOnly={readOnly}
                        name='colaborador'
                        value={this.state.colaborador}
                        onChange={this.handleSelect}
                        url={urlVendedor}
                        token={this.props.user}
                        label='Vincular conta a um funcionário'
                        cols='12 3'
                        query={{tipo:1}}
                        placeholder='Digite aqui' />
                      <LabelAndSelectAjax
                        readOnly={readOnly}
                        name='fornecedor'
                        value={this.state.fornecedor}
                        onChange={this.handleSelect}
                        url={urlFornecedor}
                        token={this.props.user}
                        label='Vincular conta a um fornecedor'
                        cols='12 3'
                        query={{tipo:1}}
                        placeholder='Digite aqui' />

                    <LabelAndText name='descricao' value={this.state.descricao} readOnly={readOnly}  handleChange={this.handleInputChange}
                      label='Observações' cols='12 6' placeholder='Digite aqui' />
                    <Clearfix />
                    <div className='box-footer'>
                        <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                            {this.props.submitLabel}
                        </button>
                        <button type='button' className='btn btn-default'
                            onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
                    </div>
                  </Box>
                </form>
                {financeiro}
                {arquivos}
              </div>
            </div>
        </div>
      )
  }

  render() {
      const tempBox = this.state.loaded
      ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
      : <LoadingPage />;

      return (
          <div>
            <ModalDelete
              onCancel={ () => this.deleteItem() }
              className='Modal_Delete'
              isOpen={this.state['ModalDeleteItem']}
              onRequestClose={()=>this.removeModalDeleteItem()}
            />
            <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
            {
              tempBox
            }
          </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
