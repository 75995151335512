import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import LabelAndInput from '../../common/form/labelAndInput';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import ModalLoading from '../modals/modal-loading';
import LabelAndText from '../../common/form/labelAndText';
import BoxServicos from '../venda-planos/box-servicos';
import BoxFinanceiro from '../venda-planos/box-financeiro';
import BoxFichas from '../venda-planos/box-fichas';
import If from '../../common/operator/if';
import './modal-plano.scss';

ReactModal.setAppElement('#root');

const INITIAL_STATE = {

}
export default class Modal extends Component{
  constructor(props){
    super(props);

    this.state = this.initalState();
    this.onAfterOpen = this.onAfterOpen.bind(this);
  }

  initalState(){
    return {
      ...INITIAL_STATE,
      loaded: false
    };
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.dados;

    if(!id)
    {
      this.setState({
        loaded: true,
        ...INITIAL_STATE
      });

      return false;
    }

    this.setState(this.initalState());

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }

  initData(data){
    const response = {...data};

    response.loaded = true;

    this.setState({
      ...response
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  renderContent(){
    const boxes = this.getBoxes();

    return(
      <form onSubmit={this.handleSubmit}>
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Dados do Plano" />
            <div className="box-body">
              <LabelAndInput type="text" readOnly={true} value={this.state.id} label='Id da venda' cols='12 4' />
              <LabelAndInput type="text" readOnly={true} value={this.state.data_venda} label='Data da venda' cols='12 4' />
              <div className="clearfix"></div>
              <LabelAndInput type="text" readOnly={true} value={this.state.cliente.label} label='Cliente' cols='12 6' />
              <LabelAndInput type="text" readOnly={true} value={this.state.vendedor.label} label='Vendedor' cols='12 6' />
              <LabelAndInput type="text" readOnly={true} value={this.state.status.label} label='Status' cols='12 6' />
              <LabelAndInput type="text" readOnly={true} value={this.state.tipo_venda ? this.state.tipo_venda.label : ''} label='Parceria/Brinde/Cortesia/Outros' cols='12 6' />
              <If test={this.state.observacoes}>
                <LabelAndText name='observacoes' readOnly={true} value={this.state.observacoes}
                  label='Observações' cols='12' />
              </If>
            </div>
            <div className='box-body'>
              <div id="content-ajax">{boxes}</div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  getBoxes(){
    const readOnly = true;
    return(
      <Fragment>
        <BoxServicos
          onChangeServicos={this.onChangeServicos}
          readOnly={readOnly}
          servicos={this.state.servicos}
          token={this.props.token}
          venda={this.state.id}
          />
        <BoxFinanceiro
          onChangeFinanceiro={this.onChangeFinanceiro}
          readOnly={readOnly}
          financeiro={this.state.financeiro}
          token={this.props.token}
          venda={this.state.id}
          />
        <BoxFichas fichas={this.state.fichas} />

      </Fragment>
    );
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
        {
          this.state.loaded ?
          this.renderContent()
          : <LoadingPage />
        }
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    )
  }
}
