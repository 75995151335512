import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import GroupRadio from '../../common/form/groupRadio';
import BoxUser from '../../common/form/boxUser';
import Box from '../../common/template/box';
import Grid from '../../common/layout/grid';
import LabelAndText from '../../common/form/labelAndText';
import Cep from '../../common/template/cep';
import MandatoryFields from '../../common/template/mandatoryFields';
import CONSTS from '../../config/consts';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalDelete from '../modals/modal-delete';
import ModalLoading from '../modals/modal-loading';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';

const options = CONSTS.ESTADOS;

const tipo_pessoa = CONSTS.TIPO_PESSOAS;

const INITIAL_STATE = {
  nome:"",
  tipo_pessoa:"J",
  cpf_cnpj:"",
  rg:"",
  inscricao_municipal:"",
  inscricao_estadual:"",
  cep:"",
  uf:"",
  cidade:"",
  endereco:"",
  bairro:"",
  numero:"",
  complemento:"",
  email:"",
  telefone:"",
  whatsapp:"",
  foto:"",
  razao_social:"",
  observacoes:""
};

class Form extends Component {
  constructor(props){
    super(props);

    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: !this.props.detail,
      ModalLoading: false
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fillAddress = this.fillAddress.bind(this);
    this.imageChanged = this.imageChanged.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  initData(response){
    if(response.uf){
      response.uf = CONSTS.ESTADOS.filter( ( elem, i, array ) => {
          return elem.value === response.uf;
      });

      if(response.uf.length) response.uf = response.uf[0];
    }
    response.loaded = true;

    this.setState({
      ...response
    });

  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  imageChanged(file){
    this.setState({
      foto: file
    });
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.fornecedores.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            const response = data.data;
            self.initData(response);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name==="tipo_pessoa"){
      const data = {[name]:value};
      data.cpf_cnpj = "";

      if (value==="J") {
        data.rg = "";
      }else{
        data.razao_social = "";
        data.inscricao_municipal = "";
        data.inscricao_estadual = "";
      }
      this.setState(data);
    }else{
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.callLoading(true);
        this.update();
        break;
      default:
        this.callLoading(true);
        this.create();
    }

    e.preventDefault();
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.fornecedores.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};
    delete data.ModalLoading;

    if(data.uf){
      data.uf = data.uf.value;
    }

    return data;
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.fornecedores.create}`,
      data: data,
      success: response => {
        const data = response.data;

        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  fillAddress(address){
    const uf = CONSTS.ESTADOS.filter( ( elem, i, array ) => {
        return elem.value === address.uf;
    });

    this.setState({
      uf: uf.length ? uf[0] : null,
      bairro: address.bairro,
      cidade: address.localidade,
      endereco: address.logradouro
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    self.removeModalDeleteItem();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.fornecedores.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  renderContent() {
      const { readOnly } = this.props;

      return (
          <form onSubmit={this.handleSubmit}>
              <div classlabel='box-body'>
                <Box classes="box-success" title="Dados gerais">
                  <BoxUser file={this.state.foto} token={this.props.user} onChange={this.imageChanged}  />
                  <Grid  cols='12 8' >
                    <LabelAndInput required name='nome' value={this.state.nome}  readOnly={readOnly} handleChange={this.handleInputChange}
                        label='Nome*' cols='12' placeholder='Digite aqui' />

                    <GroupRadio readOnly={readOnly} handleChange={this.handleInputChange} options={tipo_pessoa} value={this.state.tipo_pessoa} name="tipo_pessoa" label="Tipo pessoa" cols="12" />
                    {
                      this.state.tipo_pessoa === "F" ?
                        <Fragment>
                          <LabelAndMaskInput format='###.###.###-##' mask="_" name='cpf_cnpj' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                              label='CPF' cols='12 4' value={this.state.cpf_cnpj} placeholder='Digite aqui' />
                          <LabelAndInput name='rg'  value={this.state.rg}  readOnly={readOnly} handleChange={this.handleInputChange}
                              label='RG' cols='12 6' placeholder='Digite aqui' />
                        </Fragment>
                      :
                        <Fragment>
                          <LabelAndMaskInput format='##.###.###/####-##' mask="_" name='cpf_cnpj' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                              label='CNPJ' cols='12 4' value={this.state.cpf_cnpj} placeholder='Digite aqui' />
                          <LabelAndInput name='razao_social'  value={this.state.razao_social}  readOnly={readOnly} handleChange={this.handleInputChange}
                              label='Razão social' cols='12 6' placeholder='Digite aqui' />
                          <LabelAndInput name='inscricao_municipal'   value={this.state.inscricao_municipal} readOnly={readOnly} handleChange={this.handleInputChange}
                              label='Inscrição Municipal' cols='12 6' placeholder='Digite aqui' />
                          <LabelAndInput name='inscricao_estadual'  value={this.state.inscricao_estadual}  readOnly={readOnly} handleChange={this.handleInputChange}
                              label='Incrição estadual' cols='12 6' placeholder='Digite aqui' />
                        </Fragment>
                    }

                  </Grid>
                </Box>
                <Box classes="box-danger" title="Endereço">
                  <Cep
                    readOnly={readOnly}
                    handleChange={this.handleInputChange}
                    value={this.state.cep}
                    token={this.props.user}
                    onSuccess={this.fillAddress}
                  />
                  <LabelAndSelect name='uf' value={this.state.uf} options={options}  readOnly={readOnly} handleChange={this.handleSelect}
                      label='UF' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='cidade'  value={this.state.cidade}  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Cidade' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='endereco'   value={this.state.endereco} readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Endereço' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='bairro'  value={this.state.bairro}  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Bairro' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='numero'  value={this.state.numero}  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Nº' cols='12 2' placeholder='Digite aqui' />
                  <LabelAndInput name='complemento'  value={this.state.complemento}  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Complemento' cols='12 4' placeholder='Digite aqui' />
                </Box>
                <Box classes="box-danger" title="Contato">
                  <LabelAndInput name='email'  value={this.state.email} readOnly={readOnly} handleChange={this.handleInputChange}
                      label='E-mail' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndMaskInput format='(##) ####-####' mask="_" name='telefone' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Telefone convencional' cols='12 4' value={this.state.telefone} placeholder='Digite aqui' />
                  <LabelAndMaskInput format='(##) #####-####' mask="_" name='whatsapp' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Celular/Whatsapp' cols='12 4' value={this.state.whatsapp} placeholder='Digite aqui' />
                  <LabelAndText name='observacoes'   value={this.state.observacoes} readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Observações' cols='12 6' placeholder='Digite aqui' />
                </Box>
                {/*
                  criar uma possibilidade de inserção ajax de contas bancárias
                */}
                {/*
                  <Box classes="box-success" title="Dados bancários">
                    <LabelAndInput name='banco'  value={this.state.banco} readOnly={readOnly} handleChange={this.handleInputChange}
                        label='Nome do Banco' cols='12 4' placeholder='Digite aqui' />
                    <LabelAndInput name='banco_titular'  value={this.state.banco} readOnly={readOnly} handleChange={this.handleInputChange}
                        label='Nome do titular' cols='12 4' placeholder='Digite aqui' />
                    <LabelAndInput name='banco_cnpj'  value={this.state.banco_cnpj} readOnly={readOnly} handleChange={this.handleInputChange}
                        label='CNPJ/CPF' cols='12 4' placeholder='Digite aqui' />
                    <LabelAndInput name='banco_agencia'  value={this.state.banco_agencia} readOnly={readOnly} handleChange={this.handleInputChange}
                        label='Agência' cols='12 4' placeholder='Digite aqui' />
                    <LabelAndInput name='banco_conta'  value={this.state.banco_conta} readOnly={readOnly} handleChange={this.handleInputChange}
                        label='Nº da conta' cols='12 4' placeholder='Digite aqui' />
                    <LabelAndInput name='banco_codigo'  value={this.state.banco_codigo} readOnly={readOnly} handleChange={this.handleInputChange}
                        label='Código do banco' cols='12 4' placeholder='Digite aqui' />
                  </Box>
                */}
              </div>
              <MandatoryFields />
              <div className='box-footer'>
                  <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                      {this.props.submitLabel}
                  </button>
                  <button type='button' className='btn btn-default'
                      onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
              </div>
          </form>
      )
  }

  render() {
      const tempBox = this.state.loaded
                    ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                    : null;

      return (
          <div>
            <ModalDelete
              onCancel={ () => this.deleteItem() }
              className='Modal_Delete'
              isOpen={this.state['ModalDeleteItem']}
              onRequestClose={()=>this.removeModalDeleteItem()}
            />
            <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

            {
              this.state.loaded ?
              tempBox
              : <LoadingPage />
            }
          </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
