import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import LabelAndTimePicker from '../../common/form/labelAndTimerPicker';
import LabelAndText from '../../common/form/labelAndText';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import { dateTimeToStr, convertToBr } from '../../utils/dateJS';
import GroupCheckbox from '../../common/form/groupCheckbox';
import ModalAlert from '../modals/modal-alert';
ReactModal.setAppElement('#root');

const INITIAL_STATE = {
  hora_inicial:'',
  hora_final: '',
  data_inicial:'',
  data_final:'',
  descricao: '',
  bloquear_todos: ''
}
const sim_nao = [{value: 0, label:'Bloquear todos', id:"bloquear_todos"}];

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = this.initalState();
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  closeModal(modal) {
    this.setState({[modal]: false, contentAlert:null, });
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  onChangeTime(time, item){
    this.setState({ [item] : time });
  }

  initalState(){
    return {
      ...INITIAL_STATE,
      loaded: true
    };
  }

  beforeSend(){
    const data = {...this.state};
    const response = {};

    response.hora_inicial = dateTimeToStr(data.hora_inicial);
    response.descricao = data.descricao;
    response.hora_final = dateTimeToStr(data.hora_final);
    response.data_inicial = convertToBr(data.data_inicial);
    response.data_final = convertToBr(data.data_final);
    response[this.props.parent.field] = this.props.parent.id;
    response.day_week = this.props.week;
    response.bloquear_todos = data.bloquear_todos;

    if(this.props.tipo && this.props.tipo === 'bloqueio'){
      //se for bloqueio nós passamos o tipo de bloqueio (1- profissionais/ 2-salas/ 3-equipamentos)
      response.tipo = this.props.tipoBloqueio;
    }

    return response;
  }

  handleSubmit(e){
    e.preventDefault();

    const {hora_final, hora_inicial, data_inicial, data_final} = this.state;

    if (!hora_final && !hora_inicial && !data_inicial && !data_final) {
      toastr.warning(Language.atencao, Language.camposObrigatorios);
      return false;
    }

    //-------
    const dados = this.props.item;
    if(dados && dados.id){
      this.update();
    }else{
      this.create();
    }
  }

  create(){
    const data = this.beforeSend();
    const token = this.props.token;
    const self = this;
    const url = this.props.url;

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: url,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            //caso exista a propriedade onCreate, invocamos ela para recarregar os agendamentos com a nova listagem
            if(self.props.onSuccess){
              data.data.type = 'create';
              self.props.onSuccess(data.data);
            }
            self.props.onRequestClose();
          }else{
            if (data.data) {
              self.setState({"ModalAlert":true, contentAlert: self.getContentAlert(data)});
            }else{
              toastr.warning(Language.atencao, data.msg);
            }
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  update(){
    const data = this.beforeSend();
    const token = this.props.token;
    const self = this;
    const url = this.props.url;

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${url}${this.props.item.id}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            //caso exista a propriedade onCreate, invocamos ela para recarregar os agendamentos com a nova listagem
            if(self.props.onSuccess){
              data.data.type = 'update';
              self.props.onSuccess(data.data);
            }
            self.props.onRequestClose();
          }else{
            //toastr.warning(Language.atencao, data.msg);
            self.setState({"ModalAlert":true, contentAlert: self.getContentAlert(data)});
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  getContentAlert(data){
    return (
      <div>
        <TitleModal title="Atenção" />
        <div className="box-body">
          {data.msg}
          {
            data.data.length ? this.getWarnings(data) : null
          }
        </div>
      </div>
    );
  }

  getWarnings(data){
    const content = <div className="box-warnings-alert">
      {
        data.data.map((item, i) =>
          <div className="item-warning" key={i}>
            <div className="label bg-blue">{item.profissional}</div>
            <div className="item-warning__data">
              <strong className="txt-blue">{item.data} <span>({item.hora_inicial} - {item.hora_final})</span></strong>
            </div>
          </div>
        )
      }
    </div>;

    return(content);
  }

  onAfterOpen(){
    if(!this.props.item)
    {
      this.setState({
        ...INITIAL_STATE
      });
    }else{
      /*const item = {...this.props.item};

      item.hora_inicial = stringToTime(item.hora_inicial);
      item.hora_final = stringToTime(item.hora_final);

      this.setState({
        ...INITIAL_STATE,
        ...item
      });*/
    }
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    //const { minTime, maxTime } = this.props.token ? getTimer(this.props.token) : null;
    const ativo = this.state.bloquear_todos ? [{value:1, id: "bloquear_todos"}] : [];

    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
        <ModalAlert
          content={this.state.contentAlert}
          className='Modal_Alert'
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          isOpen={this.state['ModalAlert']}
          onRequestClose={()=>this.closeModal('ModalAlert')}
        />
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <TitleModal title={this.props.title} />
            <form onSubmit={this.handleSubmit}>
              <div className='box-modal-visualizacao__wrapper'>
                <div>
                {
                  this.props.tipo === 'bloqueio' ?
                  <GroupCheckbox  onChange={this.handleInputChange} value={ativo} options={sim_nao} label="Usuário Ativo" cols="12" />
                  : null
                }
                </div>
                <LabelAndDate required name='data' value={this.state.data_inicial} onChange={data => this.onChangeDate(data, 'data_inicial')}
                    label='Data inicial' cols='12 6' date={this.state.data_inicial} />
                <LabelAndDate required name='data' value={this.state.data_final}  onChange={data => this.onChangeDate(data, 'data_final')}
                    label='Data final' cols='12 6' date={this.state.data_final} />
                <LabelAndTimePicker required name='hora_inicial' handleChange={time => this.onChangeTime(time, 'hora_inicial')}
                  label='Hora inicial' cols='12 6' placeholder='Digite aqui' startDate={this.state.hora_inicial} />
                <LabelAndTimePicker required name='hora_final' handleChange={time => this.onChangeTime(time, 'hora_final')}
                  label='Hora final' cols='12 6' placeholder='Digite aqui' startDate={this.state.hora_final} />
                <LabelAndText name='descricao' value={this.state.descricao}  handleChange={this.handleInputChange}
                  label='Descrição' cols='12' placeholder='Digite aqui' />
              </div>
              <button type="submit" className="btn btn-block btn-success">Salvar</button>
            </form>
          </div>
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </div>

    )
  }
}
