import React, { Component } from 'react';
import ReactModal from 'react-modal';
import TitleModal from '../../common/form/titleModal';
import './modal-alert-force-pay.scss';

ReactModal.setAppElement('#root');

export default class Modal extends Component{

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    return(
      <ReactModal
        portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
      >
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title={this.props.title} />
            <div className="box-body">
              <span style={{fontSize: 20}}>Opsss. Detectamos que o cliente ainda não efetuou o pagamento, deseja pagar agora?</span>
              <div className="box-buttons-financeiro-pagamento">
                <button type="button" className="btn btn-block btn-danger" onClick={()=>this.props.onChoose(1)}>Sim</button>
                <button type="button" className="btn btn-block btn-default" onClick={()=>this.props.onChoose()}>Não</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}
