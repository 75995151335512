import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import './modal-caixa.scss';
import Language from '../../config/language';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';
import LabelAndText from '../../common/form/labelAndText';
import GroupRadio from '../../common/form/groupRadio';
import CONSTS from '../../config/consts';
import LabelAndSelect from '../../common/form/labelAndSelect';
import { maskMoney, strPtBrMoneyToNumber } from '../../config/methods';
import api from '../../api';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import Requisition from '../../utils/requisitions/requisition';
import ModalLoading from '../modals/modal-loading';
import LabelAndInput from '../../common/form/labelAndInput';
const parcelas = CONSTS.PARCELAS;

ReactModal.setAppElement('#root');

const INITIAL_STATE = {
  tipo: 1,
  valor: '',
  tipo_pagamento: '',
  observacoes: '',
  tipo_caixa: '',
  venda: '',
  cliente: '',
  qnt_parcelas: {label: '1', value: 1},
};
const optionsTiposPagamento = CONSTS.METODOS_PAGAMENTO;
const optionsModalidadesCaixa = CONSTS.MODALIDADES_CAIXA;
const tipo = CONSTS.TIPO_CAIXA;


export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      ...INITIAL_STATE
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  onAfterOpen(){
    this.setState(INITIAL_STATE);
    //this.props.onAfterOpen();

    const { token } = this.props;
    const self = this;
    const id = this.props.id;

    if (!id) {
      return false;
    }

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.estoqueRetiradas.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }

  initData(data){
    const converted = {...data};

    if(data.valor){
      converted.valor = data.valor.formated;
    }

    this.setState(converted);
  }

  handleSelect(item, name){
    if(name==='cliente' && item){
      this.setState({venda: ''});
      this.loadVendasCliente(item.value);
    }

    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name==='valor'){
      value = maskMoney(value);
    }

    this.setState({[name]: value});
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  loadVendasCliente(cliente){
    const token = this.props.token;
    const self = this;
    self.setState({array_venda: []});

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.allByCliente}?cliente=${cliente}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          self.setState({array_venda: data.data});
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};
    delete data.array_venda;
    delete data.ModalLoading;
    delete data.cliente;

    data.estoque = this.props.item;
    data.quantidade = data.caixas * data.itensCaixa;

    return data;
  }

  handleSubmit(e){
    e.preventDefault();

    const token = this.props.token;
    const self = this;
    const data = this.beforeSend();
    const type = this.props.id ? 'put' : 'post';
    const url = this.props.id ? `${api.API_URL}${api.API.estoqueRetiradas.update.replace(/{id}/,this.props.id )}` : `${api.API_URL}${api.API.estoqueRetiradas.create}`;

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: type,
      url,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.onCreate(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }


  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <form onSubmit={this.handleSubmit}>
              <div className='box-modal-visualizacao__wrapper'>
                <TitleModal title="Retirada" />
                <LabelAndInput required value={this.state?.caixas} name='caixas' handleChange={this.handleInputChange}
                  label='Quantidade*' type='number' cols='12' placeholder='Número de caixas por exemplo' />
                  <LabelAndInput required value={this.state?.itensCaixa} name='itensCaixa' handleChange={this.handleInputChange}
                  label='Itens por caixa*' type='number' cols='12' placeholder='Digite a quantidade' />
                  
                <LabelAndText name='observacoes' type='number' value={this.state.observacoes}  handleChange={this.handleInputChange}
                    label='Descrição/Observações' cols='12' placeholder='Digite aqui' />
              </div>
              <button type="submit" className="btn btn-block btn-success">Salvar</button>
            </form>
          </div>
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </div>
    )
  }
}
