import React, { Component } from 'react';
import Navbar from './navbar';
import Logo from '../../assets/images/simbolo.png';
import CONSTS from '../../config/consts';
import api from '../../api';
import { convertToken } from '../../config/methods';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
class Header extends Component{
  render(){
    const data = convertToken(this.props.user);
    const par = data.par;
    const separador = CONSTS.AMBIENTES[api.AMBIENTE].value !== '' ? ' - ' : '';
    const nome = `${CONSTS.AMBIENTES[api.AMBIENTE].value} ${par.nome_unidade ? (separador + par.nome_unidade) : ""}`;

    return <header className='main-header'>
        <a href='/#/' className='logo'>
            <span className='logo-mini'><img width="30" alt='Símbolo' src={Logo} /></span>
            <span className='logo-lg'>
                <img width="50" alt='Símbolo' src={Logo} />
            </span>
        </a>
        <nav className='navbar navbar-static-top'>
            <button type='button' className='sidebar-toggle bt-side-toggle-menu' data-toggle='offcanvas'></button>
            <div className='ambiente-sistema ambiente-sistema--white'>{nome}</div>
            <Navbar />
        </nav>
    </header>;
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default withRouter(connect(mapStateToProps)(Header));
