class PrintPdf{
  static print(printable){
    var sOption="toolbar=yes,location=no,directories=yes,menubar=yes,scrollbars=yes,width=900,height=768";
    var sWinHTML = printable;
    var winprint = window.open("","",sOption);
        winprint.document.open();
        winprint.document.write('<html><head><style type="text/css">');
        winprint.document.write('body {-webkit-print-color-adjust: exact;}tbody tr:nth-child(odd) {background-color: #dddddd;}');
        winprint.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:14px;color:#000000}');
        winprint.document.write('td{ padding: 5px 10px;}');
        winprint.document.write('.btn{display: none;}');
        winprint.document.write('.hideOnprint{display: none;}');
        winprint.document.write('</style></head><body>');
        winprint.document.write(sWinHTML);
        winprint.document.write('</body></html>');
        winprint.print();
        winprint.close();
  }
}

export default PrintPdf;
