import React from 'react';
import './Loader.css';

export default props => {
  return (
    <div className="loader">
      <div className='loader__wrapper'>
        <div className='loader__content'>
          <div className='loader__box'>
          <i class="fa fa-refresh fa-spin fa-3x fa-fw" aria-hidden="true"></i>
          </div>
          {/* <span>Carregando os dados</span> */}
        </div>
      </div>
    </div>
  );
};
