import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import Box from '../../common/template/box';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import ModalServico from '../modals/modal-planos-servico';
import { convertToken } from '../../config/methods';

export default class PerfisServicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicos: props.servicos
    }
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount(){
  }

  openModal(modal, item) {

    const data = {
      [modal]: true,
      itemModal: item
    };

    this.setState(data);
  }

  afterOpenModal() {
  }

  closeModal(modal, data) {
    const state = {[modal]: false};
    let histUpdated = [];

    if(data && data.servico){
      if (data.type && data.type === "update") {
        histUpdated = this.state.servicos.map(i =>
          {
            return i.id === data.servico.id ? data.servico : i;
          }
        );
      }else{
        histUpdated = [...this.state.servicos, data.servico];
      }
      state.servicos = histUpdated;
    }
    this.setState(state);
  }

  onChangeSelect(val, name){
    const { token } = this.props;
    const self = this;
    const data = {plano: this.props.plano, servico: val.value};

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.planosServicos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.addServico(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  addServico(item){
    const servicos = [...this.state.servicos,...item];
    this.setState({
      servicos,
      servico: null
    });
    this.openModal('modalServico', item[0].id);
  }

  removeItem(id){
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.planosServicos.delete.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeItemItem(id){
    const arr = this.state.servicos.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      servicos: arr
    });
  }

  renderRows() {
      const data = convertToken(this.props.token);
      const list = this.state.servicos || [];
      return list.map(bc => (
        <tr key={bc.id} className={`${bc.cortesia ? "tr-cortesia": "" }`}>
            <td>{bc.servico.servico}</td>
            <td>{bc.cortesia ? <span className="badge bg-green">Sim</span> : ""}</td>
            <td>{bc.n_sessoes}</td>
            <td>{bc.valor ? bc.valor.formated : null}</td>
            {
              /*
              <td>{bc.valor ? bc.valor.formated : "-"}</td>
              <td>{bc.tipo_desconto ? <span className="badge bg-yellow">{bc.tipo_desconto.value}</span> : "-"}</td>
              <td>{bc.desconto ? bc.desconto : "-"}</td>
              <td>{bc.total ? bc.total.formated : "-"}</td>
              */
            }
            {
              !this.props.readOnly
              ? <td>
                  <div>
                    <button type="button" className='btn btn-warning' onClick={() => this.openModal('modalServico', bc.id)}>
                        <i className='fa fa-pencil'></i>
                    </button>
                    <button type="button" className='btn btn-danger' onClick={() => this.removeItem(bc.id)}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                  </div>
                </td>
                : null
            }
        </tr>
      ));
  }

  render(){
    const url = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const token = this.props.token;

    return(
      <div id="content-ajax">
        <Box classes="box-success" title="Serviços">
          <ModalServico
            plano={this.props.detail ? this.props.detail.id : null}
            item={this.state.itemModal}
            token={token}
            className='Modal_Servico'
            isOpen={this.state['modalServico']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={(data)=>this.closeModal('modalServico', data)}
          />
          <LabelAndSelectAjax
            readOnly={this.props.readOnly}
            name='servico'
            value={this.state.servico}
            onChange={this.onChangeSelect}
            url={url}
            token={this.props.token}
            label='Adicionar serviço'
            cols='12 4'
            placeholder='Digite aqui' />
          <div>
            <table className='table'>
                <thead>
                    <tr>
                      <th>Serviço</th>
                      <th className="">Cortesia</th>
                      <th className="th-valor-comissao">Nº sessões</th>
                      <th className="">Valor serviço</th>
                      {
                        /*

                        <th className="th-tipo-comissao">Tipo Desconto</th>
                        <th className="">Desconto</th>
                        <th className="">Total</th>
                        */
                      }
                      { !this.props.readOnly ? <th className='table-actions'></th> : null}
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
          </div>
        </Box>
      </div>
    );
  }
}
