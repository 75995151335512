import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import api from '../../api';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import If from '../../common/operator/if';
import ModalAgendamento from '../modals/modal-agendamento';
import ModalPlano from '../modals/modal-plano';
import ClienteDados from '../clientes/cliente-dados';

const INITIAL_STATE_TABS = { tabActive: 'tab_agendamentos' };

class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount(){
    if(this.props.cliente){
      this.loadData(this.props.cliente);
    }
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }

    if(modal === "modalPlano"){
      data.dadosPlano = args.id;
    }

    this.setState(data);
  }

  handleSelect(item, name){
    if(item){
      this.setState({status: false, agendamentos: [], vendas: []}, this.loadData(item.value));
    }
  }

  loadData(item){
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.crm.read.replace(/{id}/,item)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({...data});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  getBody(){
    return (
      <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
              <li className={`${this.state.tabActive === 'tab_agendamentos' ? 'active' : ''}`}>
                <a href="#tab_agendamentos" onClick={e=>this.changeTab(e,'tab_agendamentos')}>Agendamentos</a>
              </li>
              <li className={`${this.state.tabActive === 'tab_planos_avulsos' ? 'active' : ''}`}>
                <a href="#tab_planos_avulsos" onClick={e=>this.changeTab(e,'tab_planos_avulsos')}>Vendas avulsas</a>
              </li>
              <li className={`${this.state.tabActive === 'tab_planos' ? 'active' : ''}`}>
                <a href="#tab_planos" onClick={e=>this.changeTab(e,'tab_planos')}>Vendas de planos</a>
              </li>
              <li className={`${this.state.tabActive === 'tab_cliente' ? 'active' : ''}`}>
                <a href="#tab_cliente" onClick={e=>this.changeTab(e,'tab_cliente')}>Cliente</a>
              </li>
            </ul>
            <div className="tab-content">
              <If test={this.state.tabActive === 'tab_agendamentos'}>
                <div className={`"tab-pane" ${this.state.tabActive === 'tab_agendamentos' ? 'active' : ''}`}>
                  <div className="table-wrapper">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Hora inicial</th>
                                <th>Hora final</th>
                                <th>Profissional</th>

                                <th>Serviço</th>
                                <th>Sala</th>
                                <th>Status</th>
                                <th className='table-actions-single'></th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.agendamentos.map(bc => (
                              <tr key={bc.id}>
                                <td>{bc.data}</td>
                                <td>{bc.hora_inicial}</td>
                                <td>{bc.hora_final}</td>
                                <td>{bc.profissional ? bc.profissional.label : ''}</td>
                                <td>{bc.servico ? bc.servico.label : ''}</td>
                                <td>{bc.sala ? bc.sala.label : ''}</td>
                                <td>{bc.status ? bc.status.tipo : ''}</td>
                                <td>
                                  <button type="button" className='btn btn-success' onClick={() => this.openModal('modalAgendamento', bc)}>
                                      <i className='fa fa-eye'></i>
                                  </button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                    </table>
                  </div>
                </div>
              </If>

              <If test={this.state.tabActive === 'tab_planos_avulsos'}>
                <div className={`"tab-pane" ${this.state.tabActive === 'tab_planos_avulsos' ? 'active' : ''}`}>
                  <table className='table'>
                      <thead>
                          <tr>
                              <th>Id</th>
                              <th>Serviços</th>
                              <th>Data</th>
                              <th>Status</th>
                              <th className='table-actions'></th>
                          </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.vendas.avulsos.map(bc => (
                            <tr key={bc.id}>
                              <td>{bc.id}</td>
                              <td>{bc.venda}</td>
                              <td>{bc.data_venda}</td>
                              <td>{bc.status.label}</td>
                              <td>
                                <button type="button" className='btn btn-success' onClick={() => this.openModal('modalPlano', bc)}>
                                    <i className='fa fa-eye'></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                  </table>
                </div>
              </If>

              <If test={this.state.tabActive === 'tab_planos'}>
                <div className={`"tab-pane" ${this.state.tabActive === 'tab_planos' ? 'active' : ''}`}>
                  <table className='table'>
                      <thead>
                          <tr>
                              <th>Id</th>
                              <th>Serviços</th>
                              <th>Data</th>
                              <th>Status</th>
                              <th className='table-actions'></th>
                          </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.vendas.planos.map(bc => (
                            <tr key={bc.id}>
                              <td>{bc.id}</td>
                              <td>{bc.venda}</td>
                              <td>{bc.data_venda}</td>
                              <td>{bc.status.label}</td>
                              <td>
                                <button type="button" className='btn btn-success' onClick={() => this.openModal('modalPlano', bc)}>
                                    <i className='fa fa-eye'></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                  </table>
                </div>
              </If>

              <If test={this.state.tabActive === 'tab_cliente'}>
                <div className={`"tab-pane" ${this.state.tabActive === 'tab_cliente' ? 'active' : ''}`}>
                  <ClienteDados dados={this.state.cliente} />
                </div>
              </If>
            </div>
          </div>
    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    const urlCliente = `${api.API_URL}${api.API.clientes.autocomplete}`;

    return(
      <Fragment>
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          //tipo={'crm_detail'}
        />
        <ModalPlano
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Plano'
          isOpen={this.state['modalPlano']}
          onRequestClose={()=>this.closeModal('modalPlano')}
          dados={this.state.dadosPlano}
        />
        <ContentHeader title='CRM' small='' />
          <Content>
            <div className="nav-tabs-custom">
              <div className="tab-content">
                <Box classes="box-success" title="Busca">
                  <LabelAndSelectAjax
                    name='cliente'
                    required
                    value={this.state.cliente}
                    onChange={this.handleSelect}
                    url={urlCliente}
                    token={this.props.token}
                    label='Selecione o cliente'
                    cols='12 6'
                    obs="Busque por cpf/nome/sobrenome"
                    placeholder='Digite aqui' />
                  <Clearfix />
                </Box>
                {this.state.status ? this.getBody() : null}
              </div>
            </div>
          </Content>
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
