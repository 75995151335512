import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import Box from '../../common/template/box';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import ModalServico from '../modals/modal-usuarios-servico';
import LabelAndInput from '../../common/form/labelAndInput';
import ModalLoading from '../modals/modal-loading';

export default class EstoqueItems extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ModalLoading: false,
      quantidade: 0,
      estoque: '',
      estoque_items: props.estoque || []
    }
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  openModal(modal, item) {

    const data = {
      [modal]: true,
      itemModal: item
    };

    this.setState(data);
  }

  afterOpenModal() {
  }

  closeModal(modal, data) {
    const state = {[modal]: false};
    let histUpdated = [];

    if(data && data.servico){
      if (data.type && data.type === "update") {
        histUpdated = this.state.servicos.map(i =>
          {
            return i.id === data.servico.id ? data.servico : i;
          }
        );
      }else{
        histUpdated = [...this.state.servicos, data.servico];
      }
      state.servicos = histUpdated;
    }
    this.setState(state);
  }


  onChangeSelect(val, name){
    this.setState({
      [name]: val
    });
  }

  onSubmit(){
    const check = this.state.estoque_items.filter(item => item.id === this.state.estoque.value)
    
    if (check.length) {
      toastr.warning(Language.atencao, "Item do estoque já adicionado!");
      return
    }

    const { token } = this.props;
    const self = this;
    const data = {id_usuario: this.props.usuario, estoque: this.state.estoque.value, quantidade: this.state.quantidade, servico: this.props.servicoId};
    self.setState({ModalLoading:true});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.servicoEstoques.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.addServico(data.data, false);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
        self.setState({ModalLoading:false});
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({ModalLoading:false});
      }
    });
  }

  addServico(item){
    this.setState({estoque_items:item, estoque: null, quantidade: null});
  }

  removeItem(id){
    const { token } = this.props;
    const self = this;
    self.setState({ModalLoading:true});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.servicoEstoques.delete.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
        self.setState({ModalLoading:false});
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({ModalLoading:false});
      }
    });
  }

  removeItemItem(id){
    const arr = this.state.estoque_items.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      estoque_items: arr
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderRows() {
      const list = this.state.estoque_items || [];
      return list.map(bc => (
          <tr key={bc.id}>
              <td>{bc.estoque}</td>
              <td>{bc.quantidade}</td>
              
              <td>
              {
                /*
                <button type="button" className='btn btn-warning' onClick={() => this.openModal('modalServico', bc.id)}>
                    <i className='fa fa-pencil'></i>
                </button>
                */
              }
              <button type="button" className='btn btn-danger' onClick={() => this.removeItem(bc.id)}>
                  <i className='fa fa-trash-o'></i>
              </button>
              </td>
          </tr>
      ));
  }

  render(){
    const url = `${api.API_URL}${api.API.estoque.autocomplete}`;
    const token = this.props.token;

    return(
      <div id="content-ajax">
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        <Box classes="box-success" title="Estoque">
          <ModalServico
            patrimonio={this.props.detail ? this.props.detail.id : null}
            item={this.state.itemModal}
            token={token}
            className='Modal_Servico'
            isOpen={this.state['modalServico']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={(data)=>this.closeModal('modalServico', data)}
          />
          
            <LabelAndSelectAjax
                  name='estoque'
                  value={this.state.estoque}
                  onChange={this.onChangeSelect}
                  url={url}
                  required
                  token={this.props.token}
                  label='Estoque'
                  cols='12 4'
                  placeholder='Digite aqui' />
          <LabelAndInput name='quantidade' required  handleChange={this.handleInputChange}
                      label='Quantidade utilizada*' cols='12 4' placeholder='Quantidade utilizada por sessão' value={this.state.quantidade} />
          <div class="col-xs-1">
            <div class="box-button-add-ajax">
              <button type="button" onClick={this.onSubmit} class="btn btn-primary "><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <div>
            <table className='table'>
                <thead>
                    <tr>
                      <th>Estoque</th>
                      <th className="th-valor-comissao">Quantidade</th>
                      
                      <th className='table-actions'></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
          </div>
        </Box>
      </div>
    );
  }
}
