import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Box from '../../common/template/box';
import { connect } from 'react-redux';
import ItemFoto from './itemFoto';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import LabelAndInput from '../../common/form/labelAndInput';
import Grid from '../../common/layout/grid';
import PastaFotos from './PastaFotos';
import ModalLoading from '../modals/modal-loading';
import ModalAlterarPasta from '../modals/modal-alterar-pasta';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import If from '../../common/operator/if';
import {compressImage} from "../../config/methods";
import requisition from '../../utils/requisitions/requisition';
import ErrorPage from '../../common/template/errorPage';
import LoadingPage from '../../common/template/loadingPage';

class BoxServicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      pastas: props.pastas || [],
      nome: '',
      pastaStatus: false,
      fotos: [],
      photoIndex: 0,
      zoomOpen: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);

    this.createFolder = this.createFolder.bind(this);
    this.openPasta = this.openPasta.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.deleteFolder = this.deleteFolder.bind(this);
    this.onUpdateFolder = this.onUpdateFolder.bind(this);
    this.openFotoZoom = this.openFotoZoom.bind(this);
    this.loadPage = this.loadPage.bind(this);
  }


  componentDidMount(){
    this.loadPage();
  }

  loadPage(){
    const { token } = this.props;
    const self = this;
    const vendaId = this.props.venda;
    this.setState({loading: true, error: false});
    requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.fotosPastas}?venda=${vendaId}`,
      // data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({pastas: data.data.pastas_fotos, loading: false});
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setState({loading: false, error: true});
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setState({loading: false, error: true});
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({loading: false, error: true});
      }
    });
  }


  openFotoZoom(index){
    this.setState({ photoIndex: index, zoomOpen: true })
  }

  saveImage(file){
  	var mime_types = [ 'image/jpeg', 'image/png' ];

    if(!file){
      toastr.warning(Language.atencao, Language.noFileAdded);
      return;
    }
  	// Validate MIME type
  	if(mime_types.indexOf(file.type) === -1) {
      toastr.warning(Language.atencao, Language.incorrectTypeFile);
      return;
  	}

    var data = new FormData();
    data.append('file', file);
    data.append('id_pasta', this.state.pastaAtiva);

    //-------
    const token = this.props.token;
    const self = this;
    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.fotosProcedimentos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({ fotos: data.data });
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro03);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro04);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  onErrorImage(error){
    toastr.warning(Language.atencao, Language.errorGenerateImage);
  }

  onChange(el){
    compressImage({e: el, onComplete: this.saveImage, onError:this.onErrorImage, maxWidth: 1000});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  openPasta(id){
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendasPastasFotos.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({pastaStatus: true, fotos: data.data.fotos, pastaAtiva:id});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {};
    data.nome = this.state.nome;
    data.venda = this.props.venda;
    return data;
  }

  createFolder(){

    if(!this.state.nome){
      toastr.warning(Language.atencao, Language.camposObrigatorios);
      return;
    }

    const token = this.props.token;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.vendasPastasFotos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.setState({pastas:data.pastas, nome: ''});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  update(){
    const token = this.props.token;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.salas.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        this.callLoading(false);
      }
    });
  }

  deleteFolder(){
    const token = this.props.token;
    const self = this;
    const id = this.state.pastaAtiva;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.vendasPastasFotos.delete.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removePastaItem();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  removePastaItem(){
    const pastas = this.state.pastas.filter((v,i) => Number(v.id) !== Number(this.state.pastaAtiva));
    this.setState({pastaStatus: false, pastas: pastas, pastaAtiva: null});
  }

  onUpdateFolder(args){
    const pastas = this.state.pastas.map((v,i) => {
      if(Number(v.id) === Number(this.state.pastaAtiva)){
        v.nome = args.nome;
      }
      return v;
    });
    this.setState({modalAlterarPasta:false, pastas})
  }

  render(){
    const { photoIndex, zoomOpen } = this.state;
    const images = this.state.fotos;

    return(
      <Box classes="box-success" title="Fotos do(a) paciente">
        <LabelAndInput required name='nome' handleChange={this.handleInputChange} value={this.state.nome}
          label='Nome da pasta' cols='12 11' placeholder='' />
        <ButtonAddAjax cols="1" icon="plus" onClick={this.createFolder} />
        <br />
          <div className="clearfix">
            <Grid cols="12 3">
            {
              this.state.pastas.map((v,i) => <PastaFotos onClick={this.openPasta} data={v}></PastaFotos>)
            }
            </Grid>
            {
              this.state.error ?  <>
              <ErrorPage />
              <div style={{ display: 'flex', justifyContent: 'center'}}>
              <button onClick={this.loadPage} type="button" class="btn btn-primary">Tentar novamente</button>
              </div>
            </> : null
            }
            {
              this.state.loading ? <LoadingPage /> : null
            }
            <Grid cols="12 8">
            <If test={this.state.pastaStatus}>
              <div className='clearfix wrapper-remove-folder'>
                <span class="btn btn-warning btn-xs btn-import-file">
                  <input type="file" onChange={this.onChange}  accept="image/jpeg, image/png" />
                  Adicionar imagem
                </span>
                <button onClick={() => this.setState({modalAlterarPasta:true})} type="button" class="btn btn-success btn-xs">Alterar nome da pasta</button>
                <button onClick={this.deleteFolder} type="button" class="btn btn-danger btn-xs">Remover pasta</button>
              </div>
              {
                this.state.fotos.map((v,i) => <ItemFoto key={i} index={i} data={v} onClick={this.openFotoZoom} />)
              }
            </If>
            </Grid>
          </div>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        <ModalAlterarPasta
          id={this.state.pastaAtiva}
          token={this.props.token}
          className='ModalAlterarPasta'
          isOpen={this.state.modalAlterarPasta}
          onRequestClose={()=>this.setState({modalAlterarPasta:false})}
          onUpdateFolder={this.onUpdateFolder}
        />
        {zoomOpen && (
          <Lightbox
            mainSrc={images[photoIndex].foto}
            nextSrc={images[(photoIndex + 1) % images.length].foto}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].foto}
            onCloseRequest={() => this.setState({ zoomOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </Box>
    );
  }
}

const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(BoxServicos);
