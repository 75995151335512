import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Box from '../../common/template/box';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import ModalCaixa from './modal-caixa';
import ModalLoading from '../modals/modal-loading';
export default class PerfisServicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalLoading: false,
      ...props.data
    }
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  componentDidMount(){
  }

  openModal(modal, item) {

    const data = {
      [modal]: true,
      itemModal: item
    };

    this.setState(data);
  }

  afterOpenModal() {
  }

  closeModal(modal, data) {
    const state = {[modal]: false};
    let histUpdated = [];

    if(data && data.servico){
      if (data.type && data.type === "update") {
        histUpdated = this.state.historico.map(i =>
          {
            return i.id === data.servico.id ? data.servico : i;
          }
        );
      }else{
        histUpdated = [...this.state.historico, data.servico];
      }
      state.historico = histUpdated;
    }
    this.setState(state);
  }

  onChangeSelect(val, name){
    const { token } = this.props;
    const self = this;
    const data = {plano: this.props.plano, servico: val.value};

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.planosServicos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.addServico(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  addServico(item){
    const historico = [...this.state.historico,...item];
    this.setState({
      historico,
      servico: null
    });
    this.openModal('modalServico', item[0].id);
  }

  removeItem(id){
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.estoqueItens.delete.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeItemItem(id){
    const arr = this.state.historico.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      historico: arr
    });
  }

  renderRows() {
    
      const list = this.state.historico || [];
      return list.map(bc => (
        <tr key={bc.id}>
          <td>{bc.nome || '-'}</td>
          <td>{bc.quantidade || '-'}</td>
          <td>{bc.quantidade_items || '-'}</td>
          <td>{bc.rendimento || '-'}</td>
          <td>{(bc.quantidade_items * bc.rendimento * bc.quantidade) || '-'}</td>
          <td>{bc.consumido || '-'}</td>
            <td>
                  <div>
                    <button type="button" className='btn btn-warning' onClick={() => this.openModal('modalCaixa', bc.id)}>
                        <i className='fa fa-pencil'></i>
                    </button>
                    <button type="button" className='btn btn-danger' onClick={() => this.removeItem(bc.id)}>
                        <i className='fa fa-trash-o'></i>
                    </button>
                  </div>
                </td>
        </tr>
      ));
  }

  onCreate(itens){
    this.setState({modalCaixa: false, historico: itens?.historico ? [...itens?.historico]: itens});
  }

  render(){
    return(
      <div id="content-ajax">
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        <Box classes="box-success" title="Itens do estoque">
          <ModalCaixa
            token={this.props.token}
            className='Modal_Caixa'
            isOpen={this.state['modalCaixa']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('modalCaixa')}
            item={this.props.item}
            onCreate={data => this.onCreate(data)}
            id={this.state.itemModal}
          />
          <div>
          {
            !this.props.readOnly ? <button type="button" className="btn btn-block btn-success" onClick={()=>this.openModal('modalCaixa')}>
              <i className="fa fa-plus"></i>  Novo item
            </button> : null
          }

            <br />
            <br />
          </div>
          
          <br />
          <br />
          <div>
            <table className='table'>
                <thead>
                    <tr>
                      <th>Nome/marca</th>
                      <th className="">Quantidade</th>
                      <th className="">Itens por caixa</th>
                      <th className="">Rendimento por unidade</th>
                      <th className="">Rendimento x Qnt. x Itens</th>
                      <th className="">Consumido</th>
                      <th className='table-actions'></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
          </div>
        </Box>

      </div>
    );
  }
}
