import React, { Component, Fragment } from 'react';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import api from '../../api';
import ModalLoading from '../../sections/modals/modal-loading';

export default class Cep extends Component{
  constructor(props) {
    super(props);

    this.state = {};
  }

  searchCep(e){

    if (!this.props.value) {
      toastr.warning(Language.atencao, Language.preenchaOCep);
      return;
    }

    const token = this.props.token;
    const self = this;

    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.cep.search}?cep=${this.props.value}`,
      success: response => {
        const data = response.data;

        if(data){
          if(data.status){
            self.props.onSuccess(data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
        self.callLoading(false);
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  render(){
    return(
      <Fragment>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
        <LabelAndMaskInput
          format='#####-###'
          mask="_"
          name='cep'
          type='text'
          readOnly={this.props.readOnly}
          handleChange={this.props.handleChange}
          label='CEP'
          cols='12 4'
          value={this.props.value}
          placeholder='Digite aqui'
        />
        <ButtonAddAjax readOnly={this.props.readOnly}  cols="1" icon="search" onClick={()=>this.searchCep()} />
      </Fragment>
    );
  }
}
