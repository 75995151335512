import React, {Component} from 'react';
import Grid from '../layout/grid';
import AsyncSelect from 'react-select/async';
import './labelAndSelect.scss';

import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';

type State = {
  inputValue: string,
};

const filterColors = (inputValue, data) => {
  return data.filter(i =>
    {
      return i.label.toLowerCase().includes(inputValue.toLowerCase());
    }
  );
};

export default class LabelAndSelectAjax extends Component<*, State> {
  constructor(props){
    super(props);
    this.state = { inputValue: '' };
    this.timeSearch = null;

    this.loadOptions = this.loadOptions.bind(this);
  }

  loadOptions = (inputValue, callback) => {

    clearTimeout(this.timerSearch);

    this.timerSearch = setTimeout(() => {

      let { token, url } = this.props;
      url += "?query="+inputValue;
  
      if(this.props.query){
        const q = new URLSearchParams(this.props.query).toString();
        url += `&${q}`;
      }
  
      Requisition({
        header: {
          [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
          'Auth' : 'Bearer '+token
        },
        type: 'get',
        url: url,
        success: response => {
          const data = response.data;
          if(data){
            //callback(filterColors(inputValue,data));
            callback(data);
          }
        },
        error: response => {
  
        }
      });
    }, 1000);
  };

  render() {
    return (
      <Grid cols={this.props.cols}>
          <div className={`${this.props.required ? "field-mandatory" : "" } form-group form-group__select`}>
              <label htmlFor={this.props.name}>{this.props.label}</label>
              <AsyncSelect
                isClearable
                isDisabled={this.props.readOnly}
                name={this.props.name}
                isMulti={this.props.isMulti}
                cacheOptions={this.props.cacheOptions}
                loadOptions={this.loadOptions}
                value={this.props.value}
                onChange={(value) => this.props.onChange(value, this.props.name)}
              />
              {this.props.obs ? <span className="obs-input">{this.props.obs}</span> : ''}
          </div>
      </Grid>
    );
  }
}
