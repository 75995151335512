import React from 'react';
import Grid from '../layout/grid';
import './labelAndCounter.scss';

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group form-group__counter'>
          <label htmlFor={props.name}>{props.label}</label>
          <div className="counter-box">
            <input {...props.input} className='form-control'
                 placeholder={props.placeholder}
                 readOnly={props.readOnly} type={props.type} />
            <div className="counter">
              <button><i className="fa fa-chevron-up"></i></button>
              <button><i className="fa fa-chevron-down"></i></button>
            </div>
          </div>
        </div>
    </Grid>
)
