import React from 'react';
import DatePicker, { registerLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

import Grid from '../layout/grid';
import { subDays } from 'date-fns';
//import './labelAndDate.scss';
registerLocale('pt-BR', ptBR);

export default props => (
    <Grid cols={props.cols}>
        <div className={`${props.required ? "field-mandatory" : "" } form-group form-group__date-picker`}>
            <label htmlFor={props.name}>{props.label}</label>
            <div className=''>
              <DatePicker
                className='form-control'
                disabled={props.readOnly}
                dateFormat="dd/MM/yyyy"
                selected={props.date}
                onChange={props.onChange}
                locale="pt-BR"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={props.minDate !== undefined ? subDays(new Date(), props.minDate) : null}
              />
            </div>
        </div>
    </Grid>
)
