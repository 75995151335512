import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';

import TitleModal from '../../common/form/titleModal';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import MandatoryFields from '../../common/template/mandatoryFields';
import ModalLoading from '../modals/modal-loading';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';

import './modal-servico.scss';

ReactModal.setAppElement('#root');

const options = CONSTS.TIPO_COMISSAO;

const INITIAL_STATE = {
  fornecedor:'',
  valor: '',
  n_os:'',
  data_envio: '',
  data_retorno: '',
  forma_pagamento: '',
  forma_envio: '',
  relatorio: '',
  observacoes: '',
  motivo: '',
  id_patrimonio: ''
}

export default class Modal extends Component{
  constructor(props){
    super(props);

    this.state = this.initalState();
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    /*this.handleInputChange = this.handleInputChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);*/
    this.onAfterOpen = this.onAfterOpen.bind(this);
  }

  onChangeSelect(val, name){
    this.setState({
      [name]: val
    });
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  initalState(){
    return {
      ...INITIAL_STATE,
      loaded: false
    };
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.item;

    if(!id)
    {
      this.setState({
        loaded: true,
        ...INITIAL_STATE
      });

      return false;
    }

    this.setState(this.initalState());

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.perfisServicos.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }


  renderContent(){
    const url = `${api.API_URL}${api.API.servicos.autocomplete}`;

    return(
      <form onSubmit={this.handleSubmit}>
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Serviço" />
            <div className="box-body">
              <LabelAndSelectAjax
                readOnly={this.props.readOnly}
                name='area'
                value={this.state.servico}
                onChange={this.onChangeSelect}
                url={url}
                token={this.props.token}
                label='Selecione o serviço'
                cols='8'
                placeholder='Digite aqui' />
              <LabelAndMaskInput thousandSeparator={true} name='valor' type='text'  handleChange={this.handleInputChange}
                  label='Valor' cols='12 4' value={this.state.valor} placeholder='Digite aqui' />
              <LabelAndInput name='n_sessoes'  type="number" value={this.state.n_sessoes}  handleChange={this.handleInputChange}
                  label='Nº de sessões' cols='12 3' placeholder='' />
              <LabelAndSelect name='tipo_desconto' options={options} value={this.state.tipo_desconto}   handleChange={this.handleSelect}
                label='Tipo de desconto' cols='12 3' placeholder='Digite aqui' />
              <LabelAndInput name='desconto'  value={this.state.desconto}  handleChange={this.handleInputChange}
                  label='Desconto' cols='12 3' placeholder='' />
              <LabelAndInput name='total'  value={this.state.total} readOnly handleChange={this.handleInputChange}
                  label='Total' cols='12 3' placeholder='' />
            </div>
            <MandatoryFields />

          </div>
          <button type="button" onClick={this.props.onRequestClose} className="btn btn-block btn-success">Salvar</button>

        </div>
      </form>
    )
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
        {
          this.state.loaded ?
          this.renderContent()
          : <LoadingPage />
        }
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    )
  }
}
