import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import BoxInputFull from '../../common/layout/boxInputFull';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import LabelAndTimePicker from '../../common/form/labelAndTimerPicker';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndButton from '../../common/form/labelAndButton';
import LabelAndSelect from '../../common/form/labelAndSelect';
import CONSTS from '../../config/consts';
import Separador from '../../common/form/separador';
import GroupCheckbox from '../../common/form/groupCheckbox';
import LabelAndText from '../../common/form/labelAndText';
import FinanceiroAgendamento from './financeiro-agendamento';
import Clearfix from '../../common/form/clearfix';
import api from '../../api';
import { addTimes } from '../../config/methods';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import {convertToBr, brToDate, dateTimeToStr, stringToTime } from '../../utils/dateJS';
import ModalLoading from '../modals/modal-loading';
import ModalAssinatura from '../modals/modal-assinatura';
import ModalAlert from '../modals/modal-alert';
import ModalAlertFinanceiro from '../modals/modal-alert-financeiro';
import ModalAlertForcePay from '../modals/modal-alert-force-pay';
import ModalFinanceiroAgendamento from '../modals/modal-financceiro-agendamento';
import TitleModal from '../../common/form/titleModal';
import If from '../../common/operator/if';
import { convertToken } from '../../config/methods';
//------
const optionsType = CONSTS.TIPOS_AGENDAMENTO;
const optionsStatus = CONSTS.STATUS_AGENDAMENTO;
const optionsTipo = CONSTS.TIPOS_VENDA_CORTESIA;
const sim_nao = [{value: '', label:'Sim', id:'tipo_agendamento'}];
const preferencial_item = [{value: '', label:'Preferência', id:'preferencia'}];
const optionsTempoAtendimento = CONSTS.TEMPO_ATENDIMENTO;

const INITIAL_STATE = {
  array_venda: [],
  status: optionsStatus[0],
  modo_agendamento: optionsType[1],
  assinatura:'',
  data:'',
  hora_inicial:'',
  profissional:'',
  servico:'',
  tempo_atendimento:'',
  hora_final:'',
  cliente:'',
  venda:'',
  sala:'',
  equipamento:'',
  brinde:'',
  brinde_outros:'',
  observacoes:'',
  contentAlert: '',
  tipo_agendamento: '',
  preferencia: '',
  observacoes_esteticista: '',
  agendamentos_detalhes: [],
  financeiroAvulso: null,//responsável por mudar o financeiro caso seja um agendamento avulso,
}

export default class ContentAgendamento extends Component{
  constructor(props){
    super(props);

    this.state = {modalFinanceiroAgendamento:1, ...INITIAL_STATE, loaded: false};

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.openAssinatura = this.openAssinatura.bind(this);
    this.changeFinanceiro = this.changeFinanceiro.bind(this);
    this.onChooseFincanceiroPagamento = this.onChooseFincanceiroPagamento.bind(this);
    this.onChooseForcePagamento = this.onChooseForcePagamento.bind(this);
    this.onSaveAssinatura = this.onSaveAssinatura.bind(this);
    this.deleteDetalheAgendamento = this.deleteDetalheAgendamento.bind(this);
    this.checkPermissaoDeleteDetalheAgendamento = this.checkPermissaoDeleteDetalheAgendamento.bind(this);
    //const checkBtStatus = this.checkPermissaoAtendidos();
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.clienteAjax !== this.props.clienteAjax) {
      if(nextProps.clienteAjax){
        this.setState({cliente: nextProps.clienteAjax});
      }
    }
  }

  componentDidMount(){
    const dados = this.props.dados;
    if(dados){
      //não existe o id do agendamento, então apenas preenchemos os valores com os valores passados
      if (!dados.id) {
        //-------
        const data = {...dados};

        if(data.hora_inicial){
          data.hora_inicial = stringToTime(data.hora_inicial);
        }

        if (!data.data) {
          data.data = new Date ();
        }else{
          data.data = brToDate(data.data);
        }

        switch (this.props.tipo) {
          case CONSTS.BLOQUEIOS.profissionais.tipo:
            data.profissional = data.dados;
            break;
          case CONSTS.BLOQUEIOS.salas.tipo:
            data.sala = data.dados;
            break;
          case CONSTS.BLOQUEIOS.equipamentos.tipo:
            data.equipamento = data.dados;
            break;
          default:
        }

        if(data.dateSelected){
          data.data = typeof(data.dateSelected) === 'object' ? data.dateSelected : brToDate(data.dateSelected);
        }

        this.setState(data);
        //-------
      }else{
        this.callLoading(true);
        this.loadPage(dados.id);
      }
    }
  }

  deleteDetalheAgendamento(item){
    const token = this.props.token;
    const self = this;
    const { id } = item;

    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.agendamentos.detalheAgendamentoDelete}`,
      data: { id },
      success: response => {
        const data = response.data;
        try {
          if(data){
            if(data.status){
              const items = self.state.agendamentos_detalhes.filter((v) => {
                return v.id !== id;
              });
              self.setState({ agendamentos_detalhes: items });
            }else{
              toastr.warning(Language.atencao, data.msg);
            }
          }else{
            toastr.error(Language.erroTitle, Language.erro01);
          }
        } catch (e) {
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  onSaveAssinatura(file){
    var data = new FormData();
    data.append('file', file);
    data.append('agendamento', this.props.dados.id);

    const token = this.props.token;
    const self = this;
    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.agendamentos.saveAssinatura}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({ assinatura: data.file });
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro03);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro04);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  onChooseForcePagamento(choice){
    this.setState({ModalForcePay: false, ModalFinanceiroAgendamento: choice});
  }

  onChooseFincanceiroPagamento(choice){
    const self = this;
    this.setState({ModalAlertFinanceiro: false, payNow: choice}, ()=>{
      if(this.state.servico){
        self.fillFinanceiroByServico(this.state.servico);
      }
    });
  }

  changeFinanceiro(data){
    this.setState({financeiroAvulso: data});
  }

  openAssinatura(e){
    this.setState({'modalAssinatura':true});
    e.preventDefault();
  }

  initData(data){
    const response = {...data};

    if(response.status){
      response.status = {label: response.status.tipo, value: response.status.id};
    }

    if(response.brinde){
      response.brinde = {label: response.brinde.tipo, value: response.brinde.id};
    }

    if(response.modo_agendamento){
      response.modo_agendamento = {label: response.modo_agendamento.tipo, value: response.modo_agendamento.id};
    }

    if(response.data){
      response.data = brToDate(response.data);
    }

    if(response.hora_inicial){
      response.hora_inicial = stringToTime(response.hora_inicial);
    }

    if(response.hora_final){
      const t = response.hora_final.split(":");
      response.hora_final = t.length ? t[0]+":"+t[1] : response.hora_final;
    }

    if(response.cliente){
      this.loadVendasCliente(response.cliente.value);
    }

    if(response.tempo_atendimento){
      const tA = CONSTS.TEMPO_ATENDIMENTO.filter( ( elem, i, array ) => {
          return String(elem.value) === String(response.tempo_atendimento);
      });
      if(tA.length){
        response.tempo_atendimento = tA[0];
      }
    }

    response.ModalForcePay = response.forcePay;

    response.loaded = true;

    this.setState({
      ...response
    });
  }

  loadPage(id){
    const token = this.props.token;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.agendamentos.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        try {
          if(data){
            if(data.status){
              self.initData(data.data);
            }else{
              toastr.warning(Language.atencao, data.msg);
              self.props.onRequestClose();
            }
          }else{
            toastr.error(Language.erroTitle, Language.erro01);
            self.props.onRequestClose();
          }
        } catch (e) {
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'tipo_agendamento'){
      //se o agendamento vier da página de venda não fazemos nada,
      //caso contrário, a venda fica vazia já que é avulso
      if(this.checkAgendamentoByVenda()){
        //do nothing
      }else{
        const st = {venda: '', financeiroAvulso: null};

        if(value){
          st.ModalAlertFinanceiro = true;
        }else{
          st.payNow = false;
        }

        this.setState(st);
      }
    }

    this.setState({
      [name]: value
    });
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  onChangeTime(time, item){
    this.callHoraFinal(time, this.state.tempo_atendimento);
    this.setState({ [item] : time});
  }

  callHoraFinal(hora_inicial, duracao){
    if(hora_inicial && duracao){
      //a duração pode vim em formato de objeto ou número
      duracao = typeof(duracao) === 'object' ? duracao.value : duracao;
      const timer = this.getHoraInicial(hora_inicial);
      const hora_final = addTimes(timer,"00:"+duracao);
      const hf = hora_final.split(':');

      //let endTime = {label: '22:00', integer: 22};

      /*if(convertToken(this.props.token)){
        const blockTimer = convertToken(this.props.token).par.block_timer;
        const {value} = getWeekDay();
        endTime = value === 6 ? blockTimer.saturday.end : blockTimer.weekDays.end;
      }*/

      if(hf.length){
        /*if(hf[0] >= endTime.integer && hf[1] >= 0){
          this.setState({ ModalAlert : true, alertTitle: Language.erroTitle, alertMessage: `O horário ultrapassou o limite (${endTime.label})!` });
          return true;
        }else{
          this.setState({ hora_final : hora_final });
        }*/
        this.setState({ hora_final : hora_final });
      }

      return false;
    }
  }

  getHoraInicial(hora_inicial){
    return hora_inicial.getHours() + ":" + hora_inicial.getMinutes() + ":00";
  }

  fillFinanceiroByServico(item){
    let block = false;

    const tA = CONSTS.TEMPO_ATENDIMENTO.filter( ( elem, i, array ) => {
        return String(elem.value) === String(item.tempo);
    });
    if(tA.length){
      //mudamos o financeiroAvulso para alterar o valor no financeiro caso ele esteja ativo com o agendamento avulso
      this.setState({
        tempo_atendimento:tA[0],
        financeiroAvulso: {valor: item.valor, update: true}
      });
      block = this.callHoraFinal(this.state.hora_inicial, item.tempo);
    }

    return block;
  }

  handleSelect(item, name){
    let block = false;

    if (name === "profissional") {
      //this.checkProfissionalDisponibilidade(item.value);
    }

    if (name === "brinde") {
      if (this.state.servico) {
        //se o brinde for escolhido, precisamos limpar o serviço para que não dê problema na venda
        //imagine a seguinte situação, uma venda foi venda de um pacote...
        //marcaram o agendamento, depois mudaram o agendamento para cortesia... assim, o serviço vinculado a venda já não vale mais...
        //precisamos limpar ele e pedir para adicionar novamente
        this.setState({ ModalAlert : true, alertTitle: Language.atencao, alertMessage:'Selecione novamente o serviço para atualizar o agendamento'});
      }
      this.setState({venda: '', tipo_agendamento: true, servico: '', financeiroAvulso: null});

    }

    if (name ==="servico") {
      if(item){
        block = this.fillFinanceiroByServico(item);

        if(item.observacoes_esteticista){
          this.setState({observacoes_esteticista: item.observacoes_esteticista});
        }
      }
    }

    //carrega as vendas do cliente
    if(name==='cliente' && item){
      this.setState({venda: '', servico: '', tempo_atendimento: ''});
      this.loadVendasCliente(item.value);
    }

    //checa a disponibilidade da sala
    if(name==='sala' && item){
      block = this.checkSalaDisponibilidade(item.value);
    }

    //checa se a venda escolhida tem o serviço escolhido para o profissional fazer
    if(name==='venda' && item){
      //block = this.checkServicoVenda(item.value);
      this.setState({ servico : '' });
    }

    //checa a disponibilidade do aparelho
    if(name==='equipamento' && item){
      this.checkAparelhoDisponibilidade(item.value);
    }

    if(name === 'tempo_atendimento'){
      this.callHoraFinal(this.state.hora_inicial, item.value);
    }

    if(!block) this.setState({ [name] : item });
  }

  openModal(modal) {
    this.setState({[modal]: true});
  }

  afterOpenModal() {

  }

  closeModal(modal) {
    this.setState({[modal]: false, contentAlert:null, });
  }
//checa a disponibilidade da sala
//na api checamos todos os agendamentos no dia e na hora com a sala escolhida
//depois procuramos a quantidade de atendimentos simultâneos na sala, se a quantidade for menor
//podemos escolher a sala, senão, dá mensagem para escolher outra sala
  checkSalaDisponibilidade(sala){
    if(sala && this.state.data && this.state.hora_inicial && this.state.tempo_atendimento){
      const token = this.props.token;
      const self = this;
      const hora = dateTimeToStr(this.state.hora_inicial);
      const data = convertToBr(this.state.data);
      const tempo_atendimento = this.state.tempo_atendimento.value;
      const query = `?data=${data}&hora=${hora}&sala=${sala}&tempo_atendimento=${tempo_atendimento}`;

      self.callLoading(true);

      Requisition({
        header: {
          [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
          'Auth' : 'Bearer '+token
        },
        type: 'get',
        url: `${api.API_URL}${api.API.agendamentos.checkPlaceByDate}${query}`,
        data: {},
        success: response => {
          const data = response.data;
          if(data){
            if(data.status){
            }else{
              self.setState({sala:'', "ModalAlert":true, contentAlert: self.getContentAlert(data)});
            }
          }else{
            toastr.error(Language.erroTitle, Language.erro01);
          }
        },
        error: response => {
          toastr.error(Language.erroTitle, Language.erro02);
        },
        complete: response => {
          self.callLoading(false);
        }
      });
    }else{
      toastr.warning(Language.atencao, Language.erroDisponibilidadeSala);
      return true;
    }
  }

  checkServicoVenda(venda){
    if(venda && this.state.servico){
      const token = this.props.token;
      const self = this;
      const tempo_atendimento = this.state.tempo_atendimento.value;
      const query = `?servico=${this.state.servico.value}&venda=${venda}&tempo_atendimento=${tempo_atendimento}`;

      self.callLoading(true);

      Requisition({
        header: {
          [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
          'Auth' : 'Bearer '+token
        },
        type: 'get',
        url: `${api.API_URL}${api.API.agendamentos.checkPlanoServico}${query}`,
        data: {},
        success: response => {
          const data = response.data;
          if(data){
            if(data.status){
            }else{
              this.setState({ venda: '', ModalAlert : true, alertTitle: Language.atencao, alertMessage:data.msg });
            }
          }else{
            toastr.error(Language.erroTitle, Language.erro01);
          }
        },
        error: response => {
          toastr.error(Language.erroTitle, Language.erro02);
        },
        complete: response => {
          self.callLoading(false);
        }
      });
    }else{
      toastr.warning(Language.atencao, Language.erroDisponibilidadeSala);
      return true;
    }
  }

  checkAparelhoDisponibilidade(equipamento){
    if(equipamento && this.state.data && this.state.hora_inicial){
      const token = this.props.token;
      const self = this;
      const hora = dateTimeToStr(this.state.hora_inicial);
      const data = convertToBr(this.state.data);
      const tempo_atendimento = this.state.tempo_atendimento.value;
      const query = `?data=${data}&hora=${hora}&equipamento=${equipamento}&tempo_atendimento=${tempo_atendimento}`;

      self.callLoading(true);

      Requisition({
        header: {
          [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
          'Auth' : 'Bearer '+token
        },
        type: 'get',
        url: `${api.API_URL}${api.API.agendamentos.checkToolByDate}${query}`,
        data: {},
        success: response => {
          const data = response.data;
          if(data){
            if(data.status){
            }else{
              this.setState({ equipamento:'', "ModalAlert":true, contentAlert: self.getContentAlert(data)});
            }
          }else{
            toastr.error(Language.erroTitle, Language.erro01);
          }
        },
        error: response => {
          toastr.error(Language.erroTitle, Language.erro02);
        },
        complete: response => {
          self.callLoading(false);
        }
      });
    }else{
      toastr.warning(Language.atencao, Language.erroDisponibilidadeSala);
    }
  }

  loadVendasCliente(cliente){
    const token = this.props.token;
    const self = this;
    const date = this.state.data ? convertToBr(this.state.data) : '';
    self.setState({array_venda: []});

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.getByClienteAgendamento}?cliente=${cliente}&date=${date}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          self.setState({array_venda: data.data});
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};

    delete data.array_venda;
    delete data.modalFinanceiroAgendamento;
    delete data.loaded;
    delete data.ModalLoading;
    delete data.dados;
    delete data.contentAlert;

    if(this.state.brinde){
      data.brinde = data.brinde.value;
    }

    if(this.state.cliente){
      data.cliente = data.cliente.value;
    }

    if(this.state.plano_vinculo){
      data.plano_vinculo = data.plano_vinculo.value;
    }

    if(this.state.data){
      data.data = convertToBr(data.data);
    }

    if(this.state.modo_agendamento){
      data.modo_agendamento = data.modo_agendamento.value;
    }

    if(this.state.profissional){
      data.profissional = data.profissional.value;
    }

    if(this.state.sala){
      data.sala = data.sala.value;
    }

    if(this.state.servico){
      //se tivermos a venda, é preciso passar o id do serviço na venda para computarmos os agendamentos
      if(this.state.venda){
        data.servico = data.servico.id_servico;
        data.venda_servico = this.state.servico.value;
      }else{
        data.servico = data.servico.value;
      }
    }

    if(this.state.status){
      data.status = data.status.value;
    }

    if(this.state.venda){
      data.venda = data.venda.value;
    }

    if(this.state.equipamento){
      data.equipamento = data.equipamento.value;
    }

    if (this.state.hora_inicial) {
      data.hora_inicial = dateTimeToStr(this.state.hora_inicial);
    }

    if(this.state.tempo_atendimento){
      data.tempo_atendimento = data.tempo_atendimento.value;
    }

    if(this.checkAgendamentoByVenda()){
      data.agendamento_by_venda = 1;
    }

//se o cliente for pagar agora o agendamento e ele for avulso
    if (data.payNow) {
      //se for um agendamento avulso, pegamos os dados do financeiro e salvamos
      if(data.tipo_agendamento && data.financeiroAvulso && !(this.props.dados && this.props.dados.id)){
        data.valor = data.financeiroAvulso.valor;
        data.tipo_pagamento = data.financeiroAvulso.tipo_pagamento.value;
        data.qnt_parcelas = data.financeiroAvulso.qnt_parcelas.value;
        data.tipo_desconto = data.financeiroAvulso.tipo_desconto ? data.financeiroAvulso.tipo_desconto.value : "";
        data.desconto = data.financeiroAvulso.desconto;
        data.total = data.financeiroAvulso.total;
        //data.n_sessoes = data.financeiroAvulso.n_sessoes;

        delete data.financeiroAvulso;
      }
    }

    return data;
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  submitHandler(e){
    const dados = this.props.dados;
    if(dados && dados.id){
      this.update();
    }else{
      this.create();
    }

    e.preventDefault();
  }

  update(){
    const data = this.beforeSend();
    const token = this.props.token;
    const self = this;
    const id = this.props.dados.id;

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.agendamentos.update.replace(/{id}/,id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            try {
              toastr.success(Language.atencao, data.msg);

              //caso exista a propriedade onCreate, invocamos ela para recarregar os agendamentos com a nova listagem
              if(self.props.onCreate){
                self.props.onCreate(data);
              }
              self.props.onRequestClose();
            } catch (e) {
            }
          }else{
            if(data.forceAlert){
              this.setState({
                ModalAlert : true,
                alertTitle: Language.atencao,
                alertMessage: data.msg,
              });
            }else{
              toastr.warning(Language.atencao, data.msg);
            }
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  checkPermissaoDeleteDetalheAgendamento(){
    const data = convertToken(this.props.token);
    const par = data.par;
    const tu = Number(par.tipo_usuario);

    //se o usuário for master, retornamos true
    if(tu === CONSTS.USUARIO_MASTER ||
    tu === CONSTS.USUARIO_GERENTE ||
    tu === CONSTS.USUARIO_SUPERVISOR)
    {
      return true;
    }
  }

  checkPermissaoAtendidos(){
    const data = convertToken(this.props.token);
    const par = data.par;
    const permissoes = par.permissoes;

    //se o usuário for master, retornamos true
    if(Number(par.tipo_usuario) === CONSTS.USUARIO_MASTER){
      return true;
    }

    // se o tipo de usuário não for master, checamos se ele tem a permissão 23 q
    //que é a de atualizar agendamentos com status atendido
    //assim, aparecerá o botão de atualizar
    let check = false;
    for (let i = 0; i < permissoes.length; i++) {
      const v = permissoes[i];
      if(Number(v.id) === Number(23)){
        check = v.value ? true : false;
        break;
      }
    }
    return check;
  }

  create(){
    // if(this.state.tipo_agendamento){
    //   if(!this.state.financeiroAvulso){
    //     this.setState({
    //       ModalAlert : true,
    //       alertTitle: Language.atencao,
    //       alertMessage: Language.financeiroObrigatorioAgendamentoAvulso,
    //     });
    //     return false;
    //   }
    // }


    if(this.state.venda){

    }else{
      //se não for brinde/voucher
      if(!this.state.brinde){
        if(!this.state.tipo_agendamento){
          if(this.state.modo_agendamento === String(CONSTS.AVALIACAO_ID)){
            this.setState({
              ModalAlert : true,
              alertTitle: Language.atencao,
              alertMessage: Language.agendamentoAvulsoOuVenda,
            });
            return;
          }
        }
      }
    }

    const data = this.beforeSend();
    const token = this.props.token;
    const self = this;

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.agendamentos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);

            //caso exista a propriedade onCreate, invocamos ela para recarregar os agendamentos com a nova listagem
            if(self.props.onCreate){
              self.props.onCreate(data);
            }
            self.props.onRequestClose();
          }else{
            if(data.data){
              self.setState({"ModalAlert":true, contentAlert: self.getContentAlert(data)});
            }else{
              toastr.warning(Language.atencao, data.msg);
            }
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  getWarnings(data){
    let content = '';
    switch (data.type) {
      case 'recorrente':
        content = <div className="box-warnings-alert">
        {
          data.data.map((item, i) =>
            <div className="item-warning" key={i}>
              <div className="item-warning__data">
                <div><strong className="txt-blue">Total: R${item.total}</strong></div>
                <div><strong className="txt-blue">Valor pago: R${item.pago}</strong></div>
                {
                  item.sessoes > 0 && <span>Sessoes: {item.sessoes}</span>
                }
              </div>
            </div>
          )
        }
        </div>;
        break;
      case 'bloqueio_profissional':
      content = <div className="box-warnings-alert">
        {
          data.data.map((item, i) =>
            <div className="item-warning" key={i}>
              <div className="item-warning__data">
                <div><strong className="txt-blue">{item.data_inicial} - {item.data_final}</strong></div>
                <div><strong className="txt-blue">{item.hora_inicial} - {item.hora_final}</strong></div>
                <span>{item.descricao}</span>
              </div>
            </div>
          )
        }
      </div>;
        break;
      default:
        content = <div className="box-warnings-alert">
          {
            data.data.map((item, i) =>
              <div className="item-warning" key={i}>
                <div className="label bg-blue">{item.servico}</div>
                <div className="item-warning__data">
                  <strong className="txt-blue">{item.data} <span>({item.hora_inicial} - {item.hora_final})</span></strong>
                </div>
                <div><i className="fa fa-user-o"></i> {item.nome_cliente} {item.sobrenome_cliente}</div>
              </div>
            )
          }
        </div>;
    }

    return(content);
  }

  getContentAlert(data){
    return (
      <div>
        <TitleModal title="Atenção" />
        <div className="box-body">
          {data.msg}
          {
            data.data.length ? this.getWarnings(data) : null
          }
        </div>
      </div>
    );
  }

  getContentAlertFinanceiroQuestion(){
    return (
      <div>
        <TitleModal title="Atenção" />
        <div className="box-body">
          <div>O cliente irá efetuar o pagamento agora ou depois?</div>
          <div>
            <button type="button" class="btn btn-block btn-primary">Agora</button>
            <button type="button" class="btn btn-block btn-default">Depois</button>
          </div>
        </div>
      </div>
    );
  }

  checkAgendamentoByVenda(){
    return this.props.tipo && this.props.tipo === "venda_agendamento";
  }

  removerAssinatura(){

    const token = this.props.token;
    const self = this;
    const id = this.props.dados.id;

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.agendamentos.removeAssinatura}`,
      data: {id},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.setState({assinatura: null});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  render(){
    let readOnly = this.props.readOnly;
    const ativo = this.state.tipo_agendamento ? [{value:1, id:"tipo_agendamento"}] : '';
    const preferencia = this.state.preferencia ? [{value:1, id:"preferencia"}] : '';
    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
    const urlServico = `${api.API_URL}${api.API.agendamentos.servicosAutocomplete}`;
    const servicoQuery = {};
    const urlCliente = `${api.API_URL}${api.API.clientes.autocomplete}`;
    const urlSala = `${api.API_URL}${api.API.salas.checkByAgendamento}`;
    const urlEquipamento = `${api.API_URL}${api.API.equipamentos.checkByAgendamento}`;
    const urlPlano = `${api.API_URL}${api.API.planos.autocomplete}`;

    let planoCheck = false;
    //liberar a linha de código abaixo para bloquear a edição de avulso/plano
    let editing = false;//this.props.dados && this.props.dados.id ? true : false;

    //se o agendamento estiver sendo feito da tela de venda, o plano, brinde, avulso e o cliente não podem ser alterados
    const checkAgendamentoByVenda = this.checkAgendamentoByVenda();

    //se tivermos o profissional adicionamos ele na query
    if(this.state.profissional){
      servicoQuery.profissional = this.state.profissional.value;
    }
    //se tivermos a venda, no autocomplete do profissional somnente trazemos o serviço se estiver na venda
    if(this.state.venda){
      servicoQuery.venda = this.state.venda.value;
    }

    let planoReadonly;

    if(this.state.brinde && this.state.brinde.value !== ""){
      planoReadonly = true;
    }else{
      //planoReadonly = checkAgendamentoByVenda ? true : (readOnly ? readOnly : this.state.tipo_agendamento);
    }

    if(ativo){
      planoCheck = checkAgendamentoByVenda ? true : (readOnly ? readOnly : this.state.tipo_agendamento);
    }else{
      //planoCheck = checkAgendamentoByVenda ? true : false;
      planoCheck = editing;
    }

    const readOnlyStatusAtendido = false;//(editing && this.state.status && (Number(this.state.status.value) === 5 )) ? true : false;

    let readOnlyServico = !this.state.profissional;
    let agendamentoCRMDetail = false;

    if(this.props.tipo && this.props.tipo === 'crm_detail'){
      agendamentoCRMDetail = true;
      readOnly = true;
      readOnlyServico = true;
    }

    //se o status for atendido, somente usuários com permissão poderão fazer a atualização do agendamento
    let permissaoEditar = true;

    if(this.props.dados && this.props.dados.id && (Number(this.props.dados.status.id) === Number(4))){
      //se o status for atendido somente aparece o botão para quem tem permissão
      if(this.state.status.value && (Number(this.state.status.value) === Number(4))){
        permissaoEditar = this.checkPermissaoAtendidos();
      }
    }

    let permisssaoDeletarDetalheProfissional = this.checkPermissaoDeleteDetalheAgendamento();

    //---------

    //se for avaliação não aparece o avulso nem o plano
    const checkAvaliacao = this.state.modo_agendamento ? (String(this.state.modo_agendamento.value) === String(CONSTS.AVALIACAO_ID)) : null;

    const financeiro = <FinanceiroAgendamento
        data={this.state.financeiroAvulso}
        changeFinanceiro={this.changeFinanceiro}
      />;
    return(
      <Fragment>
        <ModalAssinatura
          onSave={this.onSaveAssinatura}
          className='Modal_Assinatura'
          isOpen={this.state['modalAssinatura']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAssinatura')}
        />
        <ModalAlert
          content={this.state.contentAlert}
          className='Modal_Alert'
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          isOpen={this.state['ModalAlert']}
          onRequestClose={()=>this.closeModal('ModalAlert')}
        />
        <ModalAlertFinanceiro
          onChoose={this.onChooseFincanceiroPagamento}
          className='Modal_Alert_Financeiro'
          isOpen={this.state['ModalAlertFinanceiro']}
          onRequestClose={()=>this.closeModal('ModalAlertFinanceiro')}
        />
        <ModalAlertForcePay
          onChoose={this.onChooseForcePagamento}
          className='Modal_Alert_Force_Pay'
          isOpen={this.state['ModalForcePay']}
          onRequestClose={()=>this.closeModal('ModalForcePay')}
        />
       {this.props.token && this.state.venda && this.props.dados.id && this.state.servico.value &&  <ModalFinanceiroAgendamento
          className='Modal_Financeiro_Agendamento'
          isOpen={this.state['ModalFinanceiroAgendamento']}
          onRequestClose={()=>this.closeModal('ModalFinanceiroAgendamento')}
          token={this.props.token}
          venda={this.state.venda}
          agendamento={this.props.dados.id}
          idServico={this.state.servico.value}
        />}


        <form onSubmit={this.submitHandler} className="form-with-blocker">
          <If test={!permissaoEditar}>
            <div className="blocker"></div>
          </If>
          <If test={!permissaoEditar}>
            <div className="callout callout-warning callout-blocker">
                <p>O usuário não possui permissão para editar uma agendamento já atendido. Por favor, caso tenha que editar o agendamento, entre em contato com seu supervisor.</p>
              </div>
          </If>
          <BoxInputFull cols="12">
          <GroupCheckbox
          readOnly={editing}
          onChange={this.handleInputChange} value={preferencia} options={preferencial_item} label="" cols="12" />

            <LabelAndSelect name='status' options={optionsStatus} readOnly={readOnlyStatusAtendido} value={this.state.status} handleChange={this.handleSelect}
            label='Status' cols='12 3' placeholder='Digite aqui' />

            {/* se o agendamento for via venda não aparece o modo de agendamento */}
            <If test={!checkAgendamentoByVenda}>
              <LabelAndSelect name='modo_agendamento' options={optionsType} value={this.state.modo_agendamento} handleChange={this.handleSelect}
                label='Tipo' cols='12 3' placeholder='Digite aqui' />
            </If>

            {/* se o agendamento for via venda não aparece o brinde */}
            <If test={!checkAgendamentoByVenda}>
              <LabelAndSelect name='brinde' options={optionsTipo} value={this.state.brinde} handleChange={this.handleSelect}
                label='Parceria/Brinde/Cortesia' cols='12 3' placeholder='Digite aqui' />
            </If>

            {/*<LabelAndInput readOnly={readOnly} name='brinde_outros' handleChange={this.handleInputChange} value={this.state.brinde_outros}
                label='Outro? Qual?' cols='12 5' placeholder='' />*/}
                <If test={this.props.dados && this.props.dados.id}>
                <LabelAndButton onClick={this.openAssinatura}
                  label='' labelButton="Adicionar assinatura" cols='12 3' />
                </If>


            <Separador />
            <LabelAndDate minDate={0} required name='data' value={this.state.data}  readOnly={readOnly} onChange={data => {this.onChangeDate(data, 'data');}}
                label='Data' cols='12 3' date={this.state.data} />
            <LabelAndTimePicker timeIntervals={5} readOnly={readOnly} /*minTime={minTime} maxTime={maxTime}*/ required name='hora_inicial' handleChange={time => this.onChangeTime(time, 'hora_inicial')}
              label='Hora inicial(5 em 5min)' cols='12 3' placeholder='Digite aqui' startDate={this.state.hora_inicial} />

            <LabelAndInput name='hora_final' readOnly value={this.state.hora_final} handleChange={this.handleInputChange}
                label='Hora final' cols='12 3' placeholder='' />
          </BoxInputFull>
          <LabelAndSelectAjax
            readOnly={readOnly}
            name='profissional'
            required
            value={this.state.profissional}
            onChange={this.handleSelect}
            url={urlVendedor}
            token={this.props.token}
            label='Selecione um profissional'
            cols='10 11'
            query={{tipo:2}}
            placeholder='Digite aqui' />
            <If test={!agendamentoCRMDetail}>
              <ButtonAddAjax cols="2 1" icon="eye" onClick={()=>this.props.openModal('modalAgendamentosAjax')} />
            </If>
          <Separador />

          {
            /*
            se o agendamento for via venda não aparece o cliente
            */
          }
          <If test={!checkAgendamentoByVenda}>
            <LabelAndSelectAjax
              readOnly={checkAgendamentoByVenda ? true : editing}
              name='cliente'
              required
              value={this.state.cliente}
              onChange={this.handleSelect}
              url={urlCliente}
              token={this.props.token}
              label='Selecione o cliente'
              cols='10 11'
              placeholder='Digite aqui' />
              <If test={!editing}>
                <If test={!agendamentoCRMDetail}>
                  <ButtonAddAjax cols="2 1" icon="plus" onClick={()=>this.props.openModal('modalClienteAjax')} />
                </If>
              </If>
          </If>

          {
            /*
            se o agendamento for via venda não aparece a venda
            */
          }
          <If test={!checkAgendamentoByVenda}>
            <If test={!planoReadonly}>
              <If test={!checkAvaliacao}>
                <GroupCheckbox
                //readOnly={Number(this.state.n_agendamento) === 1 ? false : editing}
                readOnly={editing}
                onChange={this.handleInputChange} value={ativo} options={sim_nao} label="Agendamento avulso?" cols="12 3" />
                <LabelAndSelect
                  name='venda'
                  options={this.state.array_venda}
                  //readOnly={editing ? editing : planoCheck}
                  readOnly={planoCheck}
                  //readOnly={Number(this.state.n_agendamento) === 1 ? false : planoCheck}
                  value={this.state.tipo_agendamento ? null : this.state.venda}
                  handleChange={this.handleSelect}
                  label='Planos'
                  cols={(Number(this.state.n_agendamento) === 1  && ativo) ? '12 9' : '12 9'}
                  required={!this.state.tipo_agendamento}
                  placeholder='Digite aqui' />

                  {
                    /*
                    no primeiro agendamento o cliente pode escolher comprar um pacote,
                    e vai usar esse serviço realizado como umitem do pacote.
                    Ao clicar no botão de vinuclar plano, aparece o select dos planos
                    para que o atendente possa escolher o novo plano para o cliente
                    o botão somente aparece se for a primeira sessão de um agendamento avulso
                    */
                  }
                  {/*
                    <If test={Number(this.state.n_agendamento) === 1  && ativo}>
                      <LabelAndButton onClick={() => this.setState({planoVinculado:!this.state.planoVinculado})}
                        label='' labelButton="Vincular plano" cols='12 3' />
                    </If>
                  */}
              </If>
            </If>
          </If>
          {/*
            o select de plano vinculado pode aparecer se estivermos no primeiro agendamento
            e o cliente desejar vincular o serviço realizado a um plano qualquer
          */}
          {/*
            <If test={this.state.planoVinculado}>
              <div className="box-plano-vinculado">
                <LabelAndSelectAjax
                  name='plano_vinculo'
                  value={this.state.plano_vinculo}
                  onChange={this.handleSelect}
                  url={urlPlano}
                  token={this.props.token}
                  label='Selecione um plano para vincular o serviço realizado'
                  cols='12'
                  placeholder='Digite aqui' />
              </div>
            </If>
          */}
          <LabelAndSelectAjax
            readOnly={readOnlyServico}
            name='servico'
            required
            value={this.state.servico}
            onChange={this.handleSelect}
            url={urlServico}
            token={this.props.token}
            label='Selecione um serviço'
            cols='12 9'
            query={servicoQuery}
            obs='* O serviço somente será listado se estiver nas habilidades do profissional'
            placeholder='Digite aqui' />
          <LabelAndSelect required name='tempo_atendimento' options={optionsTempoAtendimento} readOnly={readOnly} handleChange={this.handleSelect}
            label='Tempo de atendimento' cols='12 3' placeholder='Digite aqui' value={this.state.tempo_atendimento} />

          <LabelAndSelectAjax
            readOnly={readOnly}
            name='sala'
            required
            value={this.state.sala}
            onChange={this.handleSelect}
            url={urlSala}
            token={this.props.token}
            label='Selecione a sala'
            cols='10 5'
            placeholder='Digite aqui' />
            <If test={!agendamentoCRMDetail}>
              <ButtonAddAjax cols="2 1" icon="eye" onClick={()=>this.props.openModal('modalAgendamentosAjax')} />
            </If>
          <LabelAndSelectAjax
            readOnly={readOnly}
            name='equipamento'
            value={this.state.equipamento}
            onChange={this.handleSelect}
            url={urlEquipamento}
            token={this.props.token}
            label='Selecione o equipamento'
            cols='10 5'
            placeholder='Digite aqui' />
            <If test={!agendamentoCRMDetail}>
              <ButtonAddAjax cols="2 1" icon="eye" onClick={()=>this.props.openModal('modalAgendamentosAjax')} />
            </If>
          <Separador />
          {/* ---------------- */}
          <Clearfix />
          {
            //ao iniciar um agendamento é perguntado se o cliente irá pagar na hora ou depois
            //se for na hora o stata payNow fica ativo abrindo o box de financeiro
            this.state.payNow
            ? financeiro
            : null
          }
          {/* ---------------- */}
          <LabelAndText name='observacoes' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.observacoes}
            label='Observações' cols='12' placeholder='Digite aqui' />
          <LabelAndText name='observacoes_esteticista' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.observacoes_esteticista}
            label='Observações para esteticista' cols='12' placeholder='Digite aqui' />
          <If test={this.state.agendamentos_detalhes}>

            <Clearfix />
            <div>
              {
                this.state.agendamentos_detalhes.map((v,i) => {
                  return(
                    <div className='item-detalhe-agendamento-profissional'>
                      <strong>{v.detalhe}</strong>
                      <div>Criado por: {v.profissional} - {v.created_at}</div>
                      <If test={permisssaoDeletarDetalheProfissional}>
                        <button type="button" onClick={()=>this.deleteDetalheAgendamento(v)}><i class="fa fa-times bt-remover-detalhe-agendamento" /></button>
                      </If>
                    </div>
                  )
                })
              }
            </div>
          </If>
          <If test={this.state.assinatura}>
            <Clearfix />
            <Separador />
            <div className="assinatura-cliente">
              <div><strong>Assinatura do(a) cliente</strong></div>
              <span className="content-assinatura">
                <img src={this.state.assinatura} alt="Assinatura cliente" />
                <button onClick={()=>this.removerAssinatura()} type="button" class="btn btn-remover-assinatura btn-block btn-danger btn-xs">Remover</button>
              </span>
            </div>
          </If>
          {
            agendamentoCRMDetail
            ? null
            : (permissaoEditar ? <button type="submit" className="btn btn-block btn-success">Salvar</button> : null)
           }

        </form>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    )
  }
}
