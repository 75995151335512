import React, { Component } from 'react';

import If from '../operator/if';

export default class TabHeader extends Component {
    render() {
        let {selected, visible} = this.props.tabs;
        const { target, label, icon } = this.props;
        selected = selected === target;
        visible = visible[target];
        let classNameTab = selected ? 'active' : '';
        classNameTab = this.props.classes ? classNameTab + this.props.classes : classNameTab;

        return (
              <If test={visible}>
                <li className={classNameTab}>
                    <a href='javascript:;'
                        data-toggle='tab'
                        onClick={() => this.props.selectTab(target)}
                        data-target={target}>
                        <i className={`fa fa-${icon}`}></i> {label}
                    </a>
                </li>
              </If>
        )
    }
}
