import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import GroupRadio from '../../common/form/groupRadio';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import BoxUser from '../../common/form/boxUser';
import Box from '../../common/template/box';
import Grid from '../../common/layout/grid';
import LabelAndText from '../../common/form/labelAndText';
import Cep from '../../common/template/cep';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';
import {convertToBr, brToDate} from '../../utils/dateJS';
import ModalDelete from '../modals/modal-delete';
import ModalLoading from '../modals/modal-loading';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ErrorPage from '../../common/template/errorPage';
import LoadingPage from '../../common/template/loadingPage';
import MandatoryFields from '../../common/template/mandatoryFields';
import CONSTS from '../../config/consts';

const optionsSexo = CONSTS.SEXO;
const tipo_pessoa = CONSTS.TIPO_PESSOAS;
const optionsCivil = CONSTS.OPCOES_CIVIL;
const optionsTipoSangue = CONSTS.TIPOS_SANGUES;
const estados = CONSTS.ESTADOS;
const grau_parentesco = CONSTS.GRAU_PARENTESCO;

const INITIAL_STATE = {
  tipo_pessoa: "F",
  nome:"",
  sobrenome:"",
  registro_profissional:"",
  rg:"",
  data_nascimento:"",
  cep:"",
  uf:"",
  cidade:"",
  endereco:"",
  bairro:"",
  numero:"",
  complemento:"",
  email:"",
  telefone:"",
  whatsapp:"",
  foto:"",
  nome_emergencia:"",
  telefone_emergencia:"",
  celular_emergencia:"",
  grau_parentesco:"",
  observacoes:"",
};

export default class Dados extends Component {
  constructor(props){
    super(props);
    const data = {...props.dados};

    if(data.uf){
      data.uf = CONSTS.ESTADOS.filter( ( elem, i, array ) => {
          return elem.value === data.uf;
      });

      if(data.uf.length) data.uf = data.uf[0];
    }

    if(data.data_nascimento){
      data.data_nascimento = brToDate(data.data_nascimento);
    }

    if(data.grau_parentesco){
      data.grau_parentesco = grau_parentesco.filter( ( elem, i, array ) => {
          return Number(elem.value) === Number(data.grau_parentesco);
      });
    }

    if(data.sexo){
      data.sexo = {label: data.sexo.tipo, value:data.sexo.id};
    }

    if(data.estado_civil){
      data.estado_civil = optionsCivil.filter( ( elem, i, array ) => {
          return Number(elem.value) === Number(data.estado_civil);
      });
    }

    this.state = {
      ...data
    };
  }

  render() {
    const readOnly = true;

      return(
        <div>
          <div>
              <div classlabel='box-body'>
              <div classlabel='box-body'>
                <Box classes="box-success" title="Dados gerais">
                  <BoxUser file={this.state.foto} token={this.props.user} onChange={this.imageChanged}  />
                  <Grid  cols='12 8' >
                  <GroupRadio readOnly={readOnly} handleChange={this.handleInputChange} options={tipo_pessoa} value={this.state.tipo_pessoa} name="tipo_pessoa" label="Tipo pessoa" cols="12" />

                  {
                    this.state.tipo_pessoa === "F" ?
                      <Fragment>
                      <LabelAndInput required name='nome' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.nome}
                          label='Nome' cols='12 6' placeholder='Digite aqui' />
                      <LabelAndInput required name='sobrenome'  type='text' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.sobrenome}
                          label='Sobrenome' cols='12 6' placeholder='Digite aqui' />
                        <LabelAndMaskInput required format='###.###.###-##' mask="_" name='cpf_cnpj' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                            label='CPF' cols='12 4' value={this.state.cpf_cnpj} placeholder='Digite aqui' />
                        <LabelAndInput name='rg'  value={this.state.rg}  readOnly={readOnly} handleChange={this.handleInputChange}
                            label='RG' cols='12 6' placeholder='Digite aqui' />
                      <LabelAndDate required name='data_nascimento' value={this.state.data_nascimento}  readOnly={readOnly} onChange={date => this.onChangeDate(date, 'data_nascimento')}
                          label='Data da nascimento' cols='12 4' placeholder='Digite aqui' date={this.state.data_nascimento} />
                      <LabelAndSelect required name='sexo' options={optionsSexo}  readOnly={readOnly} value={this.state.sexo} handleChange={this.handleSelect}
                          label='Sexo' cols='12 4' placeholder='Digite aqui' />
                      <LabelAndSelect name='estado_civil' options={optionsCivil}  readOnly={readOnly} value={this.state.estado_civil} handleChange={this.handleSelect}
                          label='Estado civil' cols='12 4' placeholder='Digite aqui' />
                      </Fragment>
                    :
                      <Fragment>
                        <LabelAndInput required name='nome' readOnly={readOnly} handleChange={this.handleInputChange} value={this.state.nome}
                            label='Nome da empresa' cols='12 6' placeholder='Digite aqui' />
                        <LabelAndMaskInput required format='##.###.###/####-##' mask="_" name='cpf_cnpj' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                            label='CNPJ' cols='12 4' value={this.state.cpf_cnpj} placeholder='Digite aqui' />
                        <LabelAndInput name='razao_social'  value={this.state.razao_social}  readOnly={readOnly} handleChange={this.handleInputChange}
                            label='Razão social' cols='12 6' placeholder='Digite aqui' />
                        <LabelAndInput name='inscricao_municipal'   value={this.state.inscricao_municipal} readOnly={readOnly} handleChange={this.handleInputChange}
                            label='Inscrição Municipal' cols='12 6' placeholder='Digite aqui' />
                        <LabelAndInput name='inscricao_estadual'  value={this.state.inscricao_estadual}  readOnly={readOnly} handleChange={this.handleInputChange}
                            label='Incrição estadual' cols='12 6' placeholder='Digite aqui' />
                      </Fragment>
                  }
                  </Grid>
                </Box>
                <Box classes="box-danger" title="Endereço">
                  <Cep
                    readOnly={readOnly}
                    handleChange={this.handleInputChange}
                    value={this.state.cep}
                    token={this.props.user}
                    onSuccess={this.fillAddress}
                  />
                  <LabelAndSelect name='uf' value={this.state.uf}  options={estados}  readOnly={readOnly} handleChange={this.handleSelect}
                      label='UF' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='cidade' value={this.state.cidade}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Cidade' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='endereco' value={this.state.endereco}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Endereço' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='bairro' value={this.state.bairro}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Bairro' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='numero' value={this.state.numero}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Nº' cols='12 2' placeholder='Digite aqui' />
                  <LabelAndInput name='complemento' value={this.state.complemento}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Complemento' cols='12 4' placeholder='Digite aqui' />
                </Box>
                <Box classes="box-danger" title="Contato">
                  <LabelAndInput name='email' value={this.state.email}   readOnly={readOnly} handleChange={this.handleInputChange}
                      label='E-mail' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndMaskInput format='(##) ####-####' mask="_" name='telefone' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Telefone convencional' cols='12 4' value={this.state.telefone} placeholder='Digite aqui' />
                  <LabelAndMaskInput format='(##) #####-####' mask="_" name='whatsapp' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Celular/Whatsapp' cols='12 4' value={this.state.whatsapp} placeholder='Digite aqui' />
                </Box>
                <Box classes="box-danger" title="Contato Emergencial">
                  <LabelAndInput name='nome_emergencia' value={this.state.nome_emergencia}   readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Nome' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndMaskInput format='(##) ####-####' mask="_" name='telefone_emergencia' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Telefone emergencial' cols='12 4' value={this.state.telefone_emergencia} placeholder='Digite aqui' />
                  <LabelAndMaskInput format='(##) #####-####' mask="_" name='celular_emergencia' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Celular emergencial' cols='12 4' value={this.state.celular_emergencia} placeholder='Digite aqui' />
                  <LabelAndSelect name='grau_parentesco' options={grau_parentesco} value={this.state.grau_parentesco}  readOnly={readOnly}
                    label='Grau de parentesco' cols='12 2' placeholder='Digite aqui' handleChange={this.handleSelect} />
                </Box>
                <Box classes="box-danger" title="Outros dados">
                  <LabelAndInput name='naturalidade' value={this.state.naturalidade}  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Naturalidade' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='nome_pai' value={this.state.nome_pai}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Nome do pai' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='nome_mae' value={this.state.nome_mae}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Nome da mãe' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='profissao'  value={this.state.profissao}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Profissão' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndInput name='local_trabalho'  value={this.state.local_trabalho}   type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Local de trabalho' cols='12 4' placeholder='Digite aqui' />
                  <LabelAndSelect name='tipo_sangue' value={this.state.tipo_sangue}  options={optionsTipoSangue}  readOnly={readOnly}  handleChange={this.handleSelect}
                      label='Tipo sanguíneo' cols='12 4' placeholder='Digite aqui' />
                </Box>
                <Box classes="box-danger" title="Observações">
                  <LabelAndText name='observacoes' value={this.state.observacoes}  type='number' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Observações' cols='12 6' placeholder='Digite aqui' />
                </Box>
              </div>

              </div>
          </div>
        </div>
      );
  }
}
