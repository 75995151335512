import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import LabelAndSelectAjax from '../../../common/form/labelAndSelectAjax';
import Box from '../../../common/template/box';
import api from '../../../api';
import Requisition from '../../../utils/requisitions/requisition';
import CONSTS from '../../../config/consts';
import Language from '../../../config/language';
import ModalServico from '../../modals/modal-perfis-servico';

export default class PerfisServicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissoes: props.permissoes
    }
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(modal, item) {

    const data = {
      [modal]: true,
      itemModal: item
    };

    this.setState(data);
  }

  afterOpenModal() {
  }

  closeModal(modal, data) {
    const state = {[modal]: false};
    let histUpdated = [];

    if(data && data.servico){
      if (data.type && data.type === "update") {
        histUpdated = this.state.servicos.map(i =>
          {
            return i.id === data.servico.id ? data.servico : i;
          }
        );
      }else{
        histUpdated = [...this.state.servicos, data.servico];
      }
      state.servicos = histUpdated;
    }
    this.setState(state);
  }

  onChangeSelect(val, name){
    const { token } = this.props;
    const self = this;
    const data = {tipo_usuario: this.props.tipoUsuario, id_permissao: val.value};

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.tiposUsuariosPermissoes.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.addArea(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  addArea(item){
    const permissoes = [...item];
    this.setState({permissoes, permissao: null});
  }

  removeArea(id){
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.tiposUsuariosPermissoes.delete.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removeAreaItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeAreaItem(id){
    const arr = this.state.permissoes.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      permissoes: arr
    });
  }

  renderRows() {
      const list = this.state.permissoes || []
      return list.map(bc => (
          <tr key={bc.id}>
              <td>{bc.permissao.permissao}</td>
              <td>
              <button type="button" className='btn btn-danger' onClick={() => this.removeArea(bc.id)}>
                  <i className='fa fa-trash-o'></i>
              </button>
              </td>
          </tr>
      ))
  }

  render(){
    const url = `${api.API_URL}${api.API.permissoes.autocomplete}`;
    const token = this.props.token;

    return(
      <div id="content-ajax">
        <Box classes="box-success" title="Permissões">
          <ModalServico
            patrimonio={this.props.detail ? this.props.detail.id : null}
            item={this.state.itemModal}
            token={token}
            className='Modal_Servico'
            isOpen={this.state['modalServico']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={(data)=>this.closeModal('modalServico', data)}
          />
          <LabelAndSelectAjax
            readOnly={this.props.readOnly}
            name='permissao'
            value={this.state.permissao}
            onChange={this.onChangeSelect}
            url={url}
            token={this.props.token}
            label='Selecione a permissão'
            cols='12 8'
            placeholder='Digite aqui' />
          <div>
            <table className='table'>
                <thead>
                    <tr>
                      <th>Permissão</th>
                      <th className='table-actions-single'></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
          </div>
        </Box>
      </div>
    );
  }
}
