import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import ModalLoading from '../modals/modal-loading';

import './modal-servicos-agendamentos.scss';

ReactModal.setAppElement('#root');

const optionsStatus = CONSTS.STATUS_SERVICO_VENDA;

const INITIAL_STATE = {
  servico:'',
  cortesia:'',
  n_sessoes:'',
  valor:'',
  tipo_desconto: '',
  desconto:'',
  total: '',
  regioes: [],
  status: optionsStatus[0]
}

export default class Modal extends Component{
  constructor(props){
    super(props);

    this.state = this.initalState();

    this.onAfterOpen = this.onAfterOpen.bind(this);
  }


  initalState(){
    return {
      ...INITIAL_STATE,
      loaded: false
    };
  }

  initData(response){
    response.agendamentos = response.data;
    response.loaded = true;

    this.setState({
      ...response
    });
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.item;

    if(!id)
    {
      this.setState({
        loaded: true,
        ...INITIAL_STATE
      });

      return false;
    }

    this.setState(this.initalState());

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.agendamentos.allByServico}?id=${this.props.item}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }


  renderContent(){
    return(
      <form onSubmit={this.handleSubmit}>
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Agendamentos" />
            <div className="box-body">
            <div className="panel box box-primary">
              <div className="box-body">
                <table className='table'>
                    <thead>
                        <tr>
                          <th>Marcado</th>
                          <th>Confirmado</th>
                          <th>Atendido</th>
                          <th>Falharam</th>
                          <th>Remarcados</th>
                          <th>Desmarcado</th>
                        </tr>
                    </thead>
                    <tbody>
                      <td className="text-center">{this.state.sessoes.marcado}</td>
                      <td className="text-center">{this.state.sessoes.confirmado}</td>
                      <td className="text-center">{this.state.sessoes.atendido}</td>
                      <td className="text-center">{this.state.sessoes.falhou}</td>
                      <td className="text-center">{this.state.sessoes.remarcado}</td>
                      <td className="text-center">{this.state.sessoes.desmarcado}</td>
                    </tbody>
                </table>
              </div>
            </div>
            <br /><br />
              <div className="panel box box-danger">
                <div className="box-body">
                  <div className="table-wrapper">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Hora inicial</th>
                                <th>Hora final</th>
                                <th>Profissional</th>
                                <th>Sala</th>
                                <th>Serviço</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.agendamentos.map(bc => (
                              <tr key={bc.id}>
                                <td >{bc.data}</td>
                                <td>{bc.hora_inicial}</td>
                                <td>{bc.hora_final}</td>
                                <td>{bc.profissional ? bc.profissional.label : ''}</td>
                                <td>{bc.sala ? bc.sala.label : ''}</td>
                                <td>{bc.servico ? bc.servico.label : ''}</td>
                                <td>{bc.status ? bc.status.tipo : ''}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
        {
          this.state.loaded ?
          this.renderContent()
          : <LoadingPage />
        }
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    )
  }
}
