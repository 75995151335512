import React, { Component } from 'react';
import If from '../operator/if';

class TabContent extends Component {
    render() {
        let {selected, visible} = this.props.tabs;
        const { id } = this.props;
        selected = selected === id;
        visible = visible[id];

        return (
          <If test={visible}>
              <div id={this.props.id}
                  className={`tab-pane ${selected ? 'active' : ''}`}>
                  {this.props.children}
              </div>
          </If>
        )
    }
}

export default TabContent;
