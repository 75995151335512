import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import { strPtBrMoneyToNumber, maskMoney, maskNumber, maskPercent } from '../../config/methods';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import MandatoryFields from '../../common/template/mandatoryFields';
import ModalLoading from '../modals/modal-loading';
import GroupCheckbox from '../../common/form/groupCheckbox';
import './modal-servico.scss';

ReactModal.setAppElement('#root');

const options = CONSTS.TIPO_COMISSAO;

const INITIAL_STATE = {
  servico:'',
  cortesia:'',
  n_sessoes:'',
  valor:'',
  tipo_desconto: '',
  desconto:'',
  total: ''
}
const cortesia = [{value: 0, label:'Sim', id:"cortesia"}];

export default class Modal extends Component{
  constructor(props){
    super(props);

    this.state = this.initalState();
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
  }

  onChangeSelect(val, name){
    this.setState({
      [name]: val
    });
  }

  handleSelect(item, name){
    this.handleInputChange({
      target: {value:item, name:name}
    });

    if(name === 'tipo_desconto' && item.value === ""){
      this.setState({desconto: ''});
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === "valor" || name === "desconto" || name === "tipo_desconto" || name === "n_sessoes"){
      this.checkValues(event);
    }else{
      const data = {[name]: value};
      if(name === 'cortesia'){
        data.valor = '';
        data.desconto = '';
        data.tipo_desconto = '';
        data.total = '';
      }
      this.setState(data);
    }
  }

  checkValues(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let convertedValue = value;
    let newState = {};

    switch (name) {
      case "valor":
        convertedValue = maskMoney(convertedValue);
        newState = { [name]: convertedValue }
        break;
      case "desconto":
        //se o tipo de comissão for 2, ou seja, dinheiro, convertemos o valor para dinheiro
        if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 2)){
          newState = { [name]: maskMoney(convertedValue)}
        } else if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 1)){
          newState = { [name]: maskPercent(convertedValue)}
        }else{
          newState = { [name]: maskNumber(convertedValue)}
        }
        break;
      default:
        newState = {[name]: value};
    }

    newState = this.checkTotal(newState, this.state);

    this.setState(newState);
  }

  checkTotal(prevState, currentState){
    let newState = {...currentState, ...prevState};
    newState.total = '';

    if(newState.n_sessoes && newState.valor){
      newState.total = strPtBrMoneyToNumber(newState.valor) * newState.n_sessoes;
      newState.total = Number(newState.total).toFixed(2);
      newState.total = String(newState.total).replace('.',',');
    }

    if(newState.n_sessoes && newState.tipo_desconto  && (newState.tipo_desconto.value !== '') && newState.desconto && newState.valor){
      const tipo_desconto = newState.tipo_desconto.value;
      const valor = Number(strPtBrMoneyToNumber(newState.valor));
      const desconto = Number(strPtBrMoneyToNumber(newState.desconto));
      const n_sessoes = newState.n_sessoes;

      let total = (tipo_desconto === 1)
                        ? (valor * n_sessoes) - ((valor * n_sessoes) * (desconto/100))
                        : ((valor * n_sessoes) - desconto);
      total = Number(total).toFixed(1);
      newState.total = String(total+"0").replace('.',',');
    }

    return newState;
  }

  initalState(){
    return {
      ...INITIAL_STATE,
      loaded: false
    };
  }

  initData(response){

    if(response.tipo_desconto){
      response.tipo_desconto = options.filter( ( elem, i, array ) => {
          return Number(elem.value) === Number(response.tipo_desconto.id);
      });

      response.tipo_desconto = response.tipo_desconto ? response.tipo_desconto[0] : null;
    }

    if(response.servico){
      response.servico = {label: response.servico.servico, value: response.servico.id};
    }

    if(response.valor){
      response.valor = response.valor.formated;
    }

    if(response.total){
      response.total = response.total.formated;
    }

    response.loaded = true;

    this.setState({
      ...response
    });
  }

  convertToDataBaseReturnTipoComissao(tipo){
    //convertemos o tipo de comissão para a estrutura retornada pelo banco de dados
    //assim podemos atualizar o item na tabela dos serviços
    let option = options.filter( ( elem, i, array ) => {
        return Number(elem.value) === Number(tipo);
    });

    return option ? {value: option[0].label, id: option[0].value} : null;
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.item;

    if(!id)
    {
      this.setState({
        loaded: true,
        ...INITIAL_STATE
      });

      return false;
    }

    this.setState(this.initalState());

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.planosServicos.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }

  handleSubmit(e){
    this.update();

    e.preventDefault();
  }

  beforeSend(){
    const data = {...this.state};

    if(data.tipo_desconto){
      data.tipo_desconto = data.tipo_desconto.value;
    }
    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }
    if(data.desconto){
      data.desconto = strPtBrMoneyToNumber(String(this.state.desconto));
    }
    if(data.total){
      data.total = strPtBrMoneyToNumber(String(this.state.total));
    }
    this.cleanPost(data);

    return data;
  }

  cleanPost(data){
    delete data.servico;
    delete data.loaded;
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  update(){
    const { token } = this.props;
    const self = this;
    const formData = this.beforeSend();
    const url = `${api.API_URL}${api.API.planosServicos.update.replace(/{id}/,this.props.item)}`;

    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: url,
      data: formData,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.onRequestClose({servico: data.data, type: 'update'});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  getBoxes(){
    //no cadastro dos serviços no pacote, o valor do serviço não importa, o que importa é o valor cheio do plano
    //porém, para ciência das atendentes, tem que aparecer o valor do pacote
    return (
      <div>
        <LabelAndInput classes="money-mask" name='valor'  type="text" value={this.state.valor}  handleChange={this.handleInputChange}
            label='Valor' cols='12 4' placeholder='' />
        <LabelAndSelect name='tipo_desconto' options={options} value={this.state.tipo_desconto}   handleChange={this.handleSelect}
          label='Tipo de desconto' cols='12 4' placeholder='Digite aqui' />
        <LabelAndInput name='desconto'  type="text" value={this.state.desconto}  handleChange={this.handleInputChange}
            label='Desconto' cols='12 4' placeholder='' />
        <LabelAndInput name='total'  value={this.state.total} readOnly handleChange={this.handleInputChange}
            label='Total do serviço' cols='12 4' placeholder='' />
      </div>
    );
  }

  renderContent(){
    const url = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const ativo = this.state.cortesia ? [{value:1, id:"cortesia"}] : [];
    //colocar readonly como true depois de dois meses com o sistema feito a migração
    //coloquei como false pois alguns agendamentos vvieram sem serviço na migração
    
    const readOnly = false;
    return(
      <form onSubmit={this.handleSubmit}>
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Serviço" />
            <div className="box-body">
              <LabelAndSelectAjax
                readOnly={readOnly}
                name='area'
                value={this.state.servico}
                onChange={this.onChangeSelect}
                url={url}
                token={this.props.token}
                label='Serviço'
                cols='8'
                placeholder='Digite aqui' />

              <GroupCheckbox
                onChange={this.handleInputChange}
                value={ativo}
                options={cortesia}
                label="Cortesia"
                cols="12" />
              <LabelAndInput
                required
                name='n_sessoes'
                type="number"
                value={this.state.n_sessoes}
                handleChange={this.handleInputChange}
                label='Nº de sessões'
                cols='12 4'
                placeholder='' />
                {
                  this.state.cortesia
                  ? null
                  : this.getBoxes()
                }
            </div>
            <MandatoryFields />

          </div>
          <button type="submit" className="btn btn-block btn-success">Salvar</button>
        </div>
      </form>
    )
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
        {
          this.state.loaded ?
          this.renderContent()
          : <LoadingPage />
        }
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    )
  }
}
