import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import $ from 'jquery';
import api from '../../api';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import {convertToBr, brToDate} from '../../utils/dateJS';
import Language from '../../config/language';

import LabelAndMaskInput from '../../common/form/labelAndMaskInput';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndText from '../../common/form/labelAndText';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import Box from '../../common/template/box';
import ModalHistoricoPatrimonio from '../modals/modal-historico-patrimonio';
import ModalDelete from '../modals/modal-delete';
import MandatoryFields from '../../common/template/mandatoryFields';
import ModalLoading from '../modals/modal-loading';
import { maskMoney, strPtBrMoneyToNumber } from '../../config/methods';


const optionsStatus = [
  {value:'1',label:'Ativo'},
  {value:'2',label:'Inativo'},
  {value:'3',label:'Vendido'},
  {value:'4',label:'Trocado'},
  {value:'5',label:'Quebrado'},
  {value:'6',label:'Perdido'},
  {value:'7',label:'Extraviado'},
];

const INITIAL_STATE = {
  nome: '',
  status: '',
  codigo: '',
  origem: '',
  valor: '',
  data_aquisicao: '',
  data_venda: '',
  observacoes: ''
}

class Form extends Component {
  constructor(props){
    super(props);

    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: !this.props.detail,
      historico: []
    }

    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  openModal(modal, item) {

    const data = {
      [modal]: true,
      itemModal: item
    };

    this.setState(data);
  }

  afterOpenModal() {
  }

  closeModal(modal, data) {
    const state = {[modal]: false};
    let histUpdated = [];

    if(data && data.historico){
      if (data.type && data.type === "update") {
        histUpdated = this.state.historico.map(i =>
          {
            return i.id === data.historico.id ? data.historico : i;
          }
        );
      }else{
        histUpdated = [...this.state.historico, data.historico];
      }
      state.historico = histUpdated;
    }

    this.setState(state);
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name==='valor'){
      value = maskMoney(value);
    }

    this.setState({
      [name]: value
    });
  }

  initData(response){
    if(response.data_venda){
      response.data_venda = brToDate(response.data_venda);
    }
    if(response.data_aquisicao){
      response.data_aquisicao = brToDate(response.data_aquisicao);
    }
    if(response.valor){
      response.valor = response.valor.formated;
    }
    if(response.status){
      response.status = optionsStatus.filter( ( elem, i, array ) => {
          return String(elem.value) === String(response.status);
      });
    }
    this.setState({
      ...response
    });

    if(this.props.detail.statusCreated){
      $('html,body').animate({
        scrollTop: $("#content-ajax").offset().top},
        'slow');
    }
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.patrimoniosAportes.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;

        if(data){
          if(data.status){
            const response = data.data;
            self.initData(response);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },complete: response =>{
        self.loaded();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    this.callLoading(true);
    self.removeModalDeleteItem();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.patrimoniosAportes.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        self.callLoading(false);
      }
    });
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.patrimoniosAportes.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.loaded();
      },
      complete: response =>{
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};

    if(data.data_aquisicao){
      data.data_aquisicao = convertToBr(data.data_aquisicao);
    }
    if(data.data_venda){
      data.data_venda = convertToBr(data.data_venda);
    }
    if(data.status){
      data.status = data.status.value;
    }
    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }

    return data;
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.patrimoniosAportes.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.keepCreating(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.loaded();
      },
      complete: response =>{
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.update();
        break;
      default:
        this.create();
    }

    e.preventDefault();
  }

  renderRows() {
      const list = this.state.historico || [];
      const { readOnly } = this.props;

      return list.map(bc => (
          <tr key={bc.id}>
              <td>{bc.motivo}</td>
              <td>{bc.data_envio}</td>
              <td>{bc.data_retorno}</td>
              <td>{bc.valor ? bc.valor.formated : '-'}</td>
              <td>{bc.n_os}</td>
              {
                readOnly ?
                null :
                <td>
                  <button type="button" className='btn btn-warning' onClick={() => this.openModal('ModalHistoricoPatrimonio', bc.id)}>
                      <i className='fa fa-pencil'></i>
                  </button>
                  <button type="button" className='btn btn-danger' onClick={() => this.setState({ModalDelete:true, itemHistoricoDelete:bc.id})}>
                      <i className='fa fa-trash-o'></i>
                  </button>
                </td>
              }
          </tr>
      ))
  }

  getHistorico(){
    const { readOnly } = this.props;

    return <Box classes="box-danger" title="Histórico">
      <div>
        <table className='table'>
            <thead>
                <tr>
                  <th className="">Motivo</th>
                  <th className="">Data envio</th>
                  <th className="">Data retorno</th>
                  <th className="">Valor</th>
                  <th className="">Nº OS</th>
                  {
                    readOnly ?
                    null :
                    <th className='table-actions'></th>
                  }
                </tr>
            </thead>
            <tbody>
                {this.renderRows()}
            </tbody>
        </table>
      </div>
      <div className='box-footer'>
      {
        readOnly ?
        null :
        <button type='button' className={`btn btn-success`} onClick={() => this.openModal('ModalHistoricoPatrimonio', null)}>
            Adicionar histórico
        </button>
      }

      </div>
    </Box>;
  }

  deleteItemHistorico(id){
    const arr = this.state.historico.filter((value, index, arr) => {
      return value.id != id;
    });
    this.setState({
      historico: arr
    });
  }

  removeItemHistorico(){
    const id = this.state.itemHistoricoDelete;
    const token = this.props.user;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.patrimonioHistorico.delete.replace(/{id}/,id)}`,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.deleteItemHistorico(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
    this.removeModalDelete();
  }

  removeModalDelete(){
    this.setState({ModalDelete: false, itemHistoricoDelete: null});
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }


  renderContent(){
    const { readOnly } = this.props;
    const token = this.props.user;
    const historico = this.props.detail ? this.getHistorico() : null;

    return (
      <div>
        <ModalHistoricoPatrimonio
          patrimonio={this.props.detail ? this.props.detail.id : null}
          item={this.state.itemModal}
          token={token}
          className='Modal_Servico'
          isOpen={this.state['ModalHistoricoPatrimonio']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={(data)=>this.closeModal('ModalHistoricoPatrimonio', data)}
        />

        <ModalDelete
          onCancel={ () => this.removeItemHistorico() }
          className='Modal_Delete'
          isOpen={this.state['ModalDelete']}
          onRequestClose={()=>this.removeModalDelete()}
        />

        <ModalDelete
          onCancel={ () => this.deleteItem() }
          className='Modal_Delete'
          isOpen={this.state['ModalDeleteItem']}
          onRequestClose={()=>this.removeModalDeleteItem()}
        />

        <form onSubmit={this.handleSubmit}>
            <div className='box-body'>
              <Box classes="box-success" title="Dados gerais">
                <LabelAndInput readOnly={readOnly} handleChange={this.handleInputChange}
                    label='Nome*' cols='12' placeholder='Digite aqui' name="nome" value={this.state.nome} />
                  <LabelAndSelect name='status' options={optionsStatus} readOnly={readOnly} handleChange={this.handleSelect}
                      label='Status' cols='12 4' value={this.state.status} placeholder='Digite aqui' />
                  <LabelAndInput name='codigo' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Código' cols='12 4' value={this.state.codigo} placeholder='Digite aqui' />
                  <LabelAndInput name='origem' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Origem' cols='12 4' value={this.state.origem} placeholder='Digite aqui' />
                  <LabelAndMaskInput thousandSeparator={true} name='valor' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Valor' cols='12 4' value={this.state.valor} placeholder='Digite aqui' />
                  <LabelAndDate name='data_aquisicao' readOnly={readOnly} onChange={date => this.onChangeDate(date, 'data_aquisicao')}
                      label='Data da aquisição' cols='12 4' placeholder='Digite aqui' date={this.state.data_aquisicao} />
                  <LabelAndDate name='data_venda' readOnly={readOnly} onChange={date => this.onChangeDate(date, 'data_venda')}
                      label='Data da venda (outros)' cols='12 4' date={this.state.data_venda}  />
                  <LabelAndText name='observacoes' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Observações' cols='12 6' value={this.state.observacoes} placeholder='Digite aqui' />
                  <MandatoryFields />
                  <Clearfix />
                  <div className='box-footer'>
                      <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                          {this.props.submitLabel}
                      </button>
                      <button type='button' className='btn btn-default'
                          onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
                  </div>
              </Box>
            </div>
            <div className='box-body' id="content-ajax">
              {historico}

            </div>
        </form>
      </div>
    );
  }
  render() {
      const tempBox = this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent();
      return (
          <div>
            <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
            {
              this.state.loaded ?
              tempBox
              : <LoadingPage />
            }
          </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
