import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import {convertToBr, brToDate} from '../../utils/dateJS';
import LoadingPage from '../../common/template/loadingPage';
import CONSTS from '../../config/consts';
import ModalLoading from '../modals/modal-loading';
import './modal-financeiro.scss';

ReactModal.setAppElement('#root');

const options = CONSTS.METODOS_PAGAMENTO;

const status = CONSTS.STATUS_PAGAMENTO;

const INITIAL_STATE = {
  valor:'',
  status:'',
  vencimento:'',
  pago_em:'',
  tipo_pagamento: ''
}

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      ...INITIAL_STATE
    }

    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSelect(item, name){
    this.handleInputChange({
      target: {value:item, name:name}
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  beforeSend(){
    const data = {...this.state};

    if(data.status){
      data.status = data.status.value;
    }

    if(data.vencimento){
      data.vencimento = convertToBr(data.vencimento);
    }

    if(data.pago_em){
      data.pago_em = convertToBr(data.pago_em);
    }

    if(data.tipo_pagamento){
      data.tipo_pagamento = data.tipo_pagamento.value;
    }

    delete data.valor;
    delete data.loaded;

    return data;
  }

  handleSubmit(e){
    const { token } = this.props;
    const self = this;
    const data = this.beforeSend();
    const id = this.props.id;

    this.setState({...INITIAL_STATE});
    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.financeiroParcelas.update.replace(/{id}/,id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.onRequestClose({parcelas: data.parcelas, type: 'update'});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      },
      complete: () => {
        self.callLoading(false);
      }
    });
    e.preventDefault();
  }

  initData(data){
    const converted = {};

    if(data.pago_em){
      converted.pago_em = brToDate(data.pago_em);
    }

    if(data.status){
      converted.status = {value:data.status.id, label:data.status.tipo};
    }

    if(data.tipo_pagamento){
      converted.tipo_pagamento = {value:data.tipo_pagamento.id, label:data.tipo_pagamento.tipo};
    }

    if(data.valor){
      converted.valor = data.valor.formated;
    }

    if(data.vencimento){
      converted.vencimento = brToDate(data.vencimento);
    }

    converted.loaded = 1;

    this.setState(converted);
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.id;

    this.setState({...INITIAL_STATE});

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.financeiroParcelas.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }

  onChangeDate = (date, name) => this.setState({ [name] : date });

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  renderContent(){
    const readOnly = false;
    return(
      <div className='box-modal-visualizacao'>
        <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
        <form onSubmit={this.handleSubmit}>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Financeiro" />
            <div className="box-body">
              <LabelAndInput readOnly classes="money-mask" name='valor' type="text" value={this.state.valor}  handleChange={this.handleInputChange}
                  label='Valor' cols='12 3' placeholder='' />
              <LabelAndSelect name='status' options={status} value={this.state.status} handleChange={this.handleSelect}
                label='Status do pagamento' cols='12 4' placeholder='Digite aqui' />
              <LabelAndDate name='vencimento' readOnly={readOnly} onChange={date => this.onChangeDate(date, 'vencimento')}
                  label='Vencimento' cols='12 4' placeholder='Digite aqui' date={this.state.vencimento} />
              <LabelAndDate name='pago_em' readOnly={readOnly} onChange={date => this.onChangeDate(date, 'pago_em')}
                  label='Pago em' cols='12 4' placeholder='Digite aqui' date={this.state.pago_em} />
              <LabelAndSelect name='tipo_pagamento' options={options} value={this.state.tipo_pagamento} handleChange={this.handleSelect}
                label='Tipo do pagamento' cols='12 4' placeholder='Digite aqui' />
            </div>
          </div>
          <button type="submit" className="btn btn-block btn-success">Salvar</button>
        </form>
      </div>
    );
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          {
            this.state.loaded ?
            this.renderContent()
            : <LoadingPage />
          }
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </div>
    )
  }
}
