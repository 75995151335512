import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndText from '../../common/form/labelAndText';
import LabelAndSelect from '../../common/form/labelAndSelect';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import Box from '../../common/template/box';
import $ from 'jquery';
import FooterPlan from './footerPlan';
import ModalServico from '../modals/modal-servico';
import ModalServicoBrinde from '../modals/modal-servico-brinde';
import Grid from '../../common/layout/grid';
import Clearfix from '../../common/form/clearfix';
import Separador from '../../common/form/separador';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import MandatoryFields from '../../common/template/mandatoryFields';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import { convertToken } from '../../config/methods';
import {convertToBr, brToDate} from '../../utils/dateJS';
import BoxServicos from './box-servicos';
import BoxFinanceiro from './box-financeiro';
import BoxAgendamentos from './box-agendamentos';
import BoxFichas from './box-fichas';
import BoxPastas from './box-pastas';
import ModalDelete from '../modals/modal-delete';
import ModalLoading from '../modals/modal-loading';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import If from '../../common/operator/if';
import ModalAlert from '../modals/modal-alert';
import ModalAssinatura from '../modals/modal-assinatura';
import GroupCheckbox from '../../common/form/groupCheckbox';
import './form.scss';

const optionsStatus = CONSTS.STATUS_VENDAS;
const optionsTipo = CONSTS.TIPOS_VENDA_CORTESIA;

const INITIAL_STATE = {
  servicos: [],
  financeiro: {
    parcelas: []
  },
  agendamentos: [],
  status: optionsStatus[0],
  finalizado: false,
  data_venda: new Date(),
  validade: 12,
  recorrente: false
};

const sim_nao = [{value: 0, label:'Sim', id:"recorrente"}];

class Form extends Component {
  constructor(props){
    super(props);

    const token = convertToken(this.props.token);
    let vendedor, canCreate;

    if(token.par){
      var par = token.par;
      vendedor = { label: `${par.nome} ${par.sobrenome}`, value: par.id_usuario};
      canCreate = !!par.permissoes.filter(permissao => permissao.id === 28 && permissao.value === 1).length;
    }

    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: !this.props.detail,
      ModalLoading: false,
      vendedor: vendedor,
      canCreate
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onChangeServicos = this.onChangeServicos.bind(this);
    this.onChangeFinanceiro = this.onChangeFinanceiro.bind(this);
    this.finalizarVenda = this.finalizarVenda.bind(this);
    this.callImprimirContrato = this.callImprimirContrato.bind(this);
    this.callImprimirFicha = this.callImprimirFicha.bind(this);
    this.callEnviarContratoEmail = this.callEnviarContratoEmail.bind(this);
    this.onSaveAssinatura = this.onSaveAssinatura.bind(this);
  }

  componentDidMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  onSaveAssinatura(file){
    var data = new FormData();
    data.append('file', file);
    data.append('venda', this.props.detail.id);

    const token = this.props.token;
    const self = this;
    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.vendas.saveAssinatura}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({ assinatura: data.file });
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro03);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro04);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  onChangeServicos(data){
    let pago;
    let a_pagar;
    let financeiro;
    let servicos = data.servicos || [];

    if(data.financeiro){
      pago = data.financeiro.pago;
      a_pagar = data.financeiro.a_pagar;
      financeiro = data.financeiro;
    }else{
      pago = data.total;
      a_pagar = data.total;
    }

    this.setState({
      servicos: servicos,
      financeiro: financeiro,
      pago: pago,
      a_pagar: a_pagar
    });
  }

  onChangeFinanceiro(data){console.log(data)
    this.setState({
      pago: data.financeiro.pago,
      a_pagar: data.financeiro.a_pagar,
    });
  }

  finalizarVenda(e){

    const token = this.props.token;
    const self = this;
    const data = {finalizado: !this.state.finalizado, venda:this.props.detail.id};

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.vendas.finalizar}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.initData(data);
          }else{
            //toastr.warning(Language.atencao, data.msg);
            this.setState({ venda: '', ModalAlert : true, alertTitle: Language.atencao, alertMessage:data.msg });
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });

    e.preventDefault();
  }

  initData(data){
    const response = data.data;

    if(response.data_venda){
      response.data_venda = brToDate(response.data_venda);
    }

    if(response.financeiro){
      response.a_pagar = response.financeiro.a_pagar;
      response.pago = response.financeiro.pago;
    }

    if(response.valor_temporario){
      response.a_pagar = response.valor_temporario;
      response.pago = response.valor_temporario;
    }

    response.loaded = true;

    this.setState({
      ...response
    });

    if(this.props.detail.statusCreated){
      $('html,body').animate({
        scrollTop: $("#content-ajax").offset().top},
        'slow');
    }
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  loadData(){
    const token = this.props.token;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.read.replace(/{id}/,this.props.detail.id)}?version=2`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.update();
        break;
      default:
        this.create();
    }

    e.preventDefault();
  }

  update(){
    const token = this.props.token;
    const self = this;
    const data = this.beforeSend();

    this.callLoading(true);
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.vendas.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            //self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  beforeSend(){
    const data = {...this.state};
    delete data.ModalLoading;
    delete data.errorLoadingPage;
    delete data.loaded;

    if(data.cliente){
      data.cliente = this.state.cliente.value;
    }

    /*if(data.plano){
      data.plano = this.state.plano.value;
    }*/

    if(data.status){
      data.status = this.state.status.value;
    }

    if(data.tipo_venda){
      data.tipo_venda = this.state.tipo_venda.value;
    }

    if(data.vendedor){
      data.vendedor = this.state.vendedor.value;
    }

    if(data.avaliador){
      data.avaliador = this.state.avaliador.value;
    }

    if(data.data_venda){
      data.data_venda = convertToBr(data.data_venda);
    }

    if(data.recorrente){
      data.recorrente = 1;
    }

    return data;
  }

  create(){
    const token = this.props.token;
    const self = this;
    const data = this.beforeSend();

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.vendas.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.keepCreating(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  deleteItem(){
    const token = this.props.token;
    const self = this;
    self.removeModalDeleteItem();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.vendas.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  openModal(modal) {
    this.setState({[modal]: true});
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  renderRowsBrinde(){
    const list = this.state.servicos || []
    return list.map(bc => (
        <tr key={bc.id}>
            <td>{bc.servico}</td>
            <td>{bc.amount} </td>
            <td>
              <button className='btn btn-warning' onClick={() => this.openModal('modalServicoBrinde', bc.id)}>
                  <i className='fa fa-pencil'></i>
              </button>
              <button className='btn btn-danger' onClick={() => null}>
                  <i className='fa fa-trash-o'></i>
              </button>
            </td>
        </tr>
    ))
  }

  getBoxes(){
    const readOnly = this.state.finalizado;

    return(
      <Fragment>
        <BoxServicos
          onChangeServicos={this.onChangeServicos}
          readOnly={readOnly}
          servicos={this.state.servicos}
          token={this.props.token}
          venda={this.props.detail.id}
          dadosVenda={this.state}
          tipo={this.props.tipo}
          />
        <If test={!this.state.tipo_venda}>
          <BoxFinanceiro
            onLoad={this.loadFinanceiroHandler}
            onChangeFinanceiro={this.onChangeFinanceiro}
            readOnly={readOnly}
            financeiro={this.state.financeiro}
            token={this.props.token}
            venda={this.props.detail.id}
            recorrente={this.state.recorrente}
            />
        </If>
        {
          this.state.tipo_venda
          ? this.getBoxAgendamentos() : <If test={readOnly}>{this.getBoxAgendamentos()}</If>
        }

        <BoxPastas venda={this.props.detail.id} pastas={this.state.pastas_fotos} />
        <BoxFichas token={this.props.token} fichas={this.state.fichas} venda={this.props.detail.id}  />

      </Fragment>
    );
  }

  getBoxAgendamentos(){
    const readOnly = this.state.finalizado;

    return <BoxAgendamentos
      readOnly={readOnly}
      agendamentos={this.state.agendamentos}
      token={this.props.token}
      venda={this.state.id}
      data={{cliente: this.state.cliente, venda:{label: this.state.name_plano, value: this.state.id}}}
      />;
  }

  callImprimirContrato(){
    const url = `${api.API_URL}${api.API.vendas.imprimirContrato}?id=${this.props.detail.id}&token=${this.props.token}`;
    window.open(url, '_blank', 'location=yes,height=500,width=1024,scrollbars=yes,status=yes');
  }

  callImprimirFicha(){
    const url = `${api.API_URL}${api.API.vendas.imprimirFicha}?id=${this.props.detail.id}&token=${this.props.token}`;
    window.open(url, '_blank', 'location=yes,height=500,width=1024,scrollbars=yes,status=yes');
  }

  callEnviarContratoEmail(){
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.sendMail}?id=${this.props.detail.id}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  getBoxButtons(){
    return (
      <Box classes="box-danger" title="Ações">
        <div className='clearfix'>
          <Grid cols="12 4 3">
            <button type="button" className="btn btn-block btn-success btn-v-p" onClick={this.callImprimirContrato}>
              <i className="fa fa-print"></i><span className="txt">Imprimir Contrato</span>
            </button>
          </Grid>
          <Grid cols="12 4 3">
            <button type="button" className="btn btn-block btn-warning btn-v-p" onClick={this.callImprimirFicha}>
              <i className="fa fa-print"></i><span className="txt">Imprimir ficha</span>
            </button>
          </Grid>
          <Grid cols="12 4 3">
            <button type="button" className="btn btn-block btn-danger btn-v-p" onClick={this.callEnviarContratoEmail}>
              <i className="fa fa-envelope-o"></i><span className="txt">Enviar contrato por email</span>
            </button>
          </Grid>
          {
            /*
            <Grid cols="12 2">
              <button type="button" class="btn btn-block btn-info btn-v-p" onClick={()=>this.openModal('modalAgendamento')}>
                <i className="fa fa-envelope-o"></i><span className="txt">Enviar ficha por email</span>
              </button>
            </Grid>
            */
          }
          {
            this.props.detail
            ? <Grid cols="12 4 3">
                <button type="button" className="btn btn-block btn-default btn-v-p" onClick={()=>this.openModal('modalAssinatura')}>
                  <i className="fa fa-pencil"></i><span className="txt">Adicionar assinatura digital</span>
                </button>
              </Grid>
            : null
          }

        </div>
      </Box>
    );
  }

  removerAssinatura(){
    this.setState({assinatura: null});
  }

  renderContent() {
      const readOnly = this.state.finalizado;
      const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,.8)'
        },
      };
      const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;
      const urlCliente = `${api.API_URL}${api.API.clientes.autocomplete}`;
      const boxes = this.props.detail ? this.getBoxes() : null;
      const boxButtons = this.props.detail ? this.getBoxButtons() : null;
      const recorrente = this.state.recorrente ? [{value:1, id:"recorrente"}] : [];

      return (
        <Fragment>
          <If test={!this.state.canCreate && this.props.type === 'create'}>
          <div className="callout callout-warning callout-blocker">
                <p>O usuário não possui permissão para criar novos planos. Por favor, caso tenha que criar um novo plano, entre em contato com seu supervisor.</p>
              </div>
          </If>
          <ModalAssinatura
            onSave={this.onSaveAssinatura}
            className='Modal_Assinatura'
            isOpen={this.state['modalAssinatura']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('modalAssinatura')}
          />
          <ModalAlert
            content={this.state.contentAlert}
            className='Modal_Alert'
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            isOpen={this.state['ModalAlert']}
            onRequestClose={()=>this.closeModal('ModalAlert')}
          />
          <ModalServico
            className='Modal_Servico'
            isOpen={this.state['modalServico']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('modalServico')}
            styles={customStyles}
          />
          <ModalServicoBrinde
            className='Modal_Servico'
            isOpen={this.state['modalServicoBrinde']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('modalServicoBrinde')}
            styles={customStyles}
          />

          <form onSubmit={this.handleSubmit}>
              <div className='box-body'>
                {boxButtons}
                <Box classes="box-danger" title="Dados gerais da venda">
                  {
                    this.props.detail
                        ? <LabelAndInput name='id' readOnly={true} value={this.state.id}
                          label='Id da venda' cols='12 3' placeholder='' />
                        : null
                  }
                  {
                    this.props.detail && this.state.id_integracao
                        ? <LabelAndInput name='id' readOnly={true} value={this.state.id_integracao}
                          label='Id Belle' cols='12 3' placeholder='' />
                        : null
                  }
                  <div className="clearfix"></div>
                  <GroupCheckbox  onChange={this.handleInputChange} value={recorrente} options={sim_nao} label="Plano recorrente?" cols="12" />

                  <LabelAndSelectAjax
                    readOnly={readOnly}
                    name='cliente'
                    required
                    value={this.state.cliente}
                    onChange={this.handleSelect}
                    url={urlCliente}
                    token={this.props.token}
                    label='Selecione um cliente'
                    cols='12 6 4'
                    placeholder='Digite aqui' />
                  <LabelAndSelectAjax
                    readOnly={readOnly}
                    name='vendedor'
                    required
                    value={this.state.vendedor}
                    onChange={this.handleSelect}
                    url={urlVendedor}
                    token={this.props.token}
                    label='Usuário'
                    cols='12 6 4'
                    query={{tipo:1}}
                    placeholder='Digite aqui' />
                  <LabelAndSelectAjax
                    readOnly={readOnly}
                    name='avaliador'
                    value={this.state.avaliador}
                    onChange={this.handleSelect}
                    url={urlVendedor}
                    token={this.props.token}
                    label='Selecione um avaliador'
                    cols='12 6 4'
                    // query={{tipo:1}}
                    placeholder='Digite aqui' />
                  <LabelAndSelect required name='status' options={optionsStatus} value={this.state.status} readOnly={readOnly} handleChange={this.handleSelect}
                      label='Status' cols='12 6 4' placeholder='Digite aqui' />
                  <LabelAndDate minDate={0} required name='data_venda' value={this.state.data_venda}  readOnly={readOnly} onChange={date => this.onChangeDate(date, 'data_venda')}
                      label='Data da venda' cols='12 6 4' placeholder='Digite aqui' date={this.state.data_venda} />
                  <LabelAndInput name='validade' type="number" readOnly={readOnly} value={this.state.validade}  handleChange={this.handleInputChange}
                    label='Validade (mês)' cols='12 6 3' placeholder='' />
                  <Clearfix />
                  <Separador />
                  <LabelAndSelect name='tipo_venda' options={optionsTipo} value={this.state.tipo_venda} readOnly={readOnly} handleChange={this.handleSelect}
                    label='Parceria/Brinde/Cortesia/Outros' cols='12 6 5' placeholder='Digite aqui' />
                  <LabelAndInput name='tipo_venda_outros'  readOnly={readOnly} value={this.state.tipo_venda_outros}  handleChange={this.handleInputChange}
                      label='Outro? Qual?' cols='12 6 5' placeholder='' />
                  <LabelAndText name='observacoes' readOnly={readOnly} value={this.state.observacoes}  handleChange={this.handleInputChange}
                    label='Observações' cols='12 6' placeholder='Digite aqui' />

                  <Clearfix />
                  <If test={this.state.assinatura}>
                    <Grid cols="12 6 4">
                      <div className="assinatura-cliente">
                        <strong>Assinatura do(a) cliente</strong>
                        <img src={this.state.assinatura} alt="Assinatura cliente" />
                        {
                          !this.state.finalizado
                          ? <button onClick={()=>this.removerAssinatura()} type="button" class="btn btn-remover-assinatura btn-block btn-danger btn-xs">Remover</button>
                          : null
                        }

                      </div>
                    </Grid>
                  </If>
                  <If test={!readOnly}>
                    <MandatoryFields />
                    <div className='box-footer'>
                    <If test={this.state.canCreate || this.props.type !== 'create'}>
                      <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                          {this.props.submitLabel}
                      </button>
                      <button type='button' className='btn btn-default'
                          onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
                      </If>
                    </div>
                  </If>

                </Box>
              </div>
          </form>
          <div className='box-body'>
            <div id="content-ajax">{boxes}</div>
          </div>
          <br /><br /><br /><br />
          {
            this.props.detail
            ? <FooterPlan
                        tipoVenda={this.state.tipo_venda}
                        readOnly={readOnly}
                        a_pagar={this.state.a_pagar}
                        pago={this.state.pago}
                        finalizarVenda={this.finalizarVenda}
                        />
            : null
          }
        </Fragment>
      )
  }

  render() {
      const tempBox = this.state.loaded
                    ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                    : null;
      return (
          <div>
            <ModalDelete
              onCancel={ () => this.deleteItem() }
              className='Modal_Delete'
              isOpen={this.state['ModalDeleteItem']}
              onRequestClose={()=>this.removeModalDeleteItem()}
            />
            <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

            {
              this.state.loaded ?
              tempBox
              : <LoadingPage />
            }
          </div>
      )
  }
}

const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Form);
