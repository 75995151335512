import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import LabelAndInput from '../../common/form/labelAndInput';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import './modal-dados-login.scss';

ReactModal.setAppElement('#root');

const INITIAL_STATE = { ModalLoading: false, usuario:'', senha:'', confirmar_senha: ''};

export default class Modal extends Component{
  constructor(props){
    super(props);
    const { usuario } = this.props;
    this.state = { ...INITIAL_STATE, usuario };
    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
  }

  onAfterOpen(){
    //this.setState({ ...INITIAL_STATE });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'usuario') {
      value = value.replace(/\s+/g,"");
    }

    if (name === 'senha') {
      value = value.replace(/\s+/g,"");
    }

    if (name === 'confirmar_senha') {
      value = value.replace(/\s+/g,"");
    }

    this.setState({
      [name]: value
    });
  }

  submit(){
    const { token } = this.props;
    const self = this;

    this.setState({ ModalLoading: true });

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.dadosAcesso.save.replace(/{id}/,this.props.user.id)}`,
      data: {usuario: this.state.usuario, senha: this.state.senha},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.onRequestClose();
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      },
      complete: () => {
        self.hideLoading();
      }
    });
  }

  hideLoading(){
    this.setState({ModalLoading: false});
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      }
    };

    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <form onSubmit={this.submit}>
              <div className='box-modal-visualizacao__wrapper'>
                <TitleModal title="Alteração de dados de usuário" />
                <div className="box-body">
                  <LabelAndInput value={this.state.usuario}  name='usuario' handleChange={this.handleInputChange}
                      label='Usuário' cols='12' placeholder='Digite aqui' />
                  <LabelAndInput value={this.state.senha}  name='senha' handleChange={this.handleInputChange}
                      label='Nova senha' cols='12' type="password" placeholder='Digite aqui' />
                  <LabelAndInput value={this.state.confirmar_senha}  name='confirmar_senha'  type='password'handleChange={this.handleInputChange}
                      label='Confirmar nova senha' cols='12' placeholder='Digite aqui' />
                </div>
              </div>
              <button type="button" onClick={this.submit} className="btn btn-info">Alterar</button>
              <button type="button" onClick={this.props.onRequestClose} className="btn btn-default">Cancelar</button>
            </form>
          </div>
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

      </div>

    )
  }
}
