import React from 'react';
import Grid from '../layout/grid';
import './buttonAddAjax.scss';

export default props => (
    <Grid cols={props.cols}>
      <div className="box-button-add-ajax">
        <button type="button" className={`btn btn-primary ${props.readOnly ? "disabled" : ""}`} onClick={props.readOnly ? null : props.onClick}>
          <i className={`fa fa-${props.icon}`}></i>
        </button>
      </div>
    </Grid>
)
