import React from 'react';
import DatePicker, { registerLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

import Grid from '../layout/grid';
import './labelAndDate.scss';
registerLocale('pt-BR', ptBR);


export default props => (
    <Grid cols={props.cols}>
        <div className={`${props.required ? "field-mandatory" : "" } form-group form-group__date-picker`}>
            <label htmlFor={props.name}>{props.label}</label>
            <div className=''>
              <DatePicker
                className='form-control'
                locale="pt-BR"
                selected={props.startDate}
                onChange={props.handleChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={props.timeIntervals ? props.timeIntervals : 10}
                timeCaption="Time"
                dateFormat="H:mm"
                minTime={props.minTime}
                maxTime={props.maxTime}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                readOnly={props.readOnly}
              />
            </div>
        </div>
    </Grid>
)
