import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import { connect } from 'react-redux';
import LoadingPage from '../../common/template/loadingPage';
import ModalDelete from '../modals/modal-delete';
import LabelAndInput from '../../common/form/labelAndInput';
import ErrorPage from '../../common/template/errorPage';
import ModalLoading from '../modals/modal-loading';

class Form extends Component {
  constructor(props){
    super(props);

    this.state = {
      loaded: !this.props.detail,
      nome: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.areasCorpo.read.replace(/{id}/,this.props.detail.id_regiao)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({
              nome : data.data.nome,
              loaded: true
            });
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    this.callLoading(true);
    self.removeModalDeleteItem();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.areasCorpo.delete.replace(/{id}/,this.props.detail.id_regiao)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  update(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.areasCorpo.update.replace(/{id}/,this.props.detail.id_regiao)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  create(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.areasCorpo.create}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.callLoading(true);
        this.update();
        break;
      default:
        this.callLoading(true);
        this.create();
    }

    e.preventDefault();
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  renderContent(){
    const { readOnly } = this.props;

    return(
      <form onSubmit={this.handleSubmit}>
        <div>
        <div className='box-body'>
          <LabelAndInput name='nome' component={LabelAndInput} readOnly={readOnly} handleChange={this.handleInputChange}
            label='Nome' cols='12' value={this.state.nome} placeholder='Informe o nome' />
        </div>
        <div className='box-footer'>
            <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                {this.props.submitLabel}
            </button>
            <button type='button' className='btn btn-default'
                onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
        </div>
      </div>
      </form>
    );
  }

  render() {
      const tempBox = this.state.loaded
                    ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                    : null;

      return (
        <div>
          <ModalDelete
            onCancel={ () => this.deleteItem() }
            className='Modal_Delete'
            isOpen={this.state['ModalDeleteItem']}
            onRequestClose={()=>this.removeModalDeleteItem()}
          />
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

          {
            this.state.loaded ?
            tempBox
            : <LoadingPage />
          }

        </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
