import $ from 'jquery';
import 'jquery-mask-plugin';
import {getWeekDay } from '../utils/dateJS';

const strPtBrMoneyToNumber = value => {
  if(!value){
    console.warn('strPtBrMoneyToNumber needs a value to be converted');
    return false;
  }

  value = String(value);

  if(value.includes(",")){
    //se o valor tiver , trocamos o . por #
    //trocamos a vírgula por .
    //torcamos a # por ''
    if(value.includes(".") && value.includes(",")){
      value = value.replace(/\./g, '#');//remove . e troca por #
      value = value.replace(/,/g, '.');//remove a , e muda para .
      value = value.replace(/#/g, '');//remove a # e muda para ""
    }else{
      value = value.replace(/,/g, '.');//remove a , e muda para .
    }
  }

  return value;
}

const maskMoney = value => {
  return $("<input />").mask("#.##0,00", {reverse: true}).masked(value);
}

const maskNumber = value => {
  return $("<input />").mask("#").masked(value);
}

const maskTime = value => {
  return $("<input />").mask("99:99").masked(value);
}

const maskPercent = value => {
  const converted = $("<input />").mask('##0.00', {reverse: true}).masked(value);
  return (Number(converted) >= 100) ? 100 : converted;
}

const addTimes = (startTime, endTime, showSeconds) => {
  var times = [ 0, 0, 0 ]
  var max = times.length

  var a = (startTime || '').split(':')
  var b = (endTime || '').split(':')

  // normalize time values
  for (var i = 0; i < max; i++) {
    a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
    b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
  }

  // store time values
  for (var j = 0; j < max; j++) {
    times[j] = a[j] + b[j]
  }

  var hours = times[0]
  var minutes = times[1]
  var seconds = times[2]

  if (seconds >= 60) {
    var m = (seconds / 60) << 0
    minutes += m
    seconds -= 60 * m
  }

  if (minutes >= 60) {
    var h = (minutes / 60) << 0
    hours += h
    minutes -= 60 * h
  }

  return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + (showSeconds ? ':' + ('0' + seconds).slice(-2) : '')
}

const convertToken = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    alert("Ocorreu um erro ao retorna os dados do usuário. Por favor, recarregue a página!");
  }
  return false;
}

const getTimer = (token) => {
  const {value} = getWeekDay();
  const blockTimer = convertToken(token).par.block_timer;
  const endTiming = value === 6 ? blockTimer.saturday.end : blockTimer.weekDays.end;
  const beginTime = value === 6 ? blockTimer.saturday.begin : blockTimer.weekDays.begin;

  let initialTime = beginTime.label;
  let endTime = endTiming.label;

  let minTime = new Date (new Date().toDateString() + ' ' +initialTime);
  let maxTime = new Date (new Date().toDateString() + ' '+ endTime);

  return {minTime,maxTime};
}

const urlParamsConverter = (obj) =>{
  return new URLSearchParams(obj).toString();
}

const compressImage = (args) => {
  const {e, onComplete, onError, maxWidth } = args;

  let width = maxWidth;
  const fileName = e.target.files[0].name;
  const reader = new FileReader();

  reader.readAsDataURL(e.target.files[0]);

  reader.onload = event => {
    const img = new Image();
    img.src = event.target.result;
    img.onload = () => {
      width = (img.width > width) ? width : img.width;
      const elem = document.createElement('canvas');
      const scaleFactor = width / img.width;
      elem.width = width;
      elem.height = img.height * scaleFactor;

      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
      ctx.canvas.toBlob((blob) => {
          const file = new File([blob], fileName, {
              type: 'image/jpeg',
              lastModified: Date.now()
          });
          onComplete(file);
      }, 'image/jpeg', 1);
    };
  }
  reader.onerror = error => onError(error);
}

const getUserAgedamentoOnly = (user) => {
  const data = convertToken(user);
  const par = data.par;
  return Number(par.tipo_usuario) === 7 ? 1 : null;
}

export { getUserAgedamentoOnly, strPtBrMoneyToNumber, maskMoney, compressImage, maskNumber, maskPercent, addTimes, convertToken, getTimer, urlParamsConverter, maskTime };
