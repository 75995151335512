import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react";//https://xdsoft.net/jodit/play.html

import api from '../../../api';
import CONSTS from '../../../config/consts';
import Requisition from '../../../utils/requisitions/requisition';
import Language from '../../../config/language';
import { connect } from 'react-redux';
import LoadingPage from '../../../common/template/loadingPage';
import ModalDelete from '../../modals/modal-delete';

import ErrorPage from '../../../common/template/errorPage';
import ModalLoading from '../../modals/modal-loading';

const CONFIG = {
  buttons:"bold,underline,italic,|,|,ul,ol,|,fontsize",
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  readonly: false
};

class Form extends Component {
  constructor(props){
    super(props);

    this.state = {
      loaded: !this.props.detail
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePerfil = this.onChangePerfil.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  componentWillMount() {
    this.loadData();
  }


  onChangePerfil(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.contrato.read}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({content: data.data.texto});
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  update(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.contrato.update}`,
      data: {
        texto:self.state.content
      },
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  handleSubmit(e){
    this.callLoading(true);
    this.update();

    e.preventDefault();
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  updateContent(value) {
    this.setState({content:value})
  }

  renderContent(){
    return(
      <form onSubmit={this.handleSubmit}>
        <div>
        <div className='box-body'>
          <JoditEditor
            value={this.state.content}
            config = {CONFIG}
            onChange={this.updateContent}
          />
        </div>
        <div className='box-footer'>
            <button type='submit' className={`btn btn-primary`}>
                Salvar
            </button>

        </div>
      </div>
      </form>
    );
  }

  render() {
      const tempBox = this.state.loaded
                    ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                    : null;

      return (
        <div>
          <ModalDelete
            onCancel={ () => this.deleteItem() }
            className='Modal_Delete'
            isOpen={this.state['ModalDeleteItem']}
            onRequestClose={()=>this.removeModalDeleteItem()}
          />
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

          {
            this.state.loaded ?
            tempBox
            : <LoadingPage />
          }

        </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
