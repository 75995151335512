import React, { Component } from 'react';
import Grid from '../../common/layout/grid';
import LabelAndText from '../../common/form/labelAndText';

class FichaCriolipolise extends Component {
  constructor(props) {
    super(props);
    this.state = props.data;
    this.onChange = this.onChange.bind(this);
  }

  onChange(el){
    this.props.onChange(el);

    const target = el.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){
    return(
      <div>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.medicamento_uso} name="medicamento_uso" onChange={this.onChange} />
                Medicamento em uso?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.alteracao_hormonal} name="alteracao_hormonal" onChange={this.onChange} />
                Alteração hormonal?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.cirurgia_recente} name="cirurgia_recente" onChange={this.onChange} />
                Cirurgia recente na área tratada?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.insuficiencia_circulatoria} name="insuficiencia_circulatoria" onChange={this.onChange} />
                Insuficiência circulatória periférica?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.disfuncao_renal} name="disfuncao_renal" onChange={this.onChange} />
                Disfunção renal?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.gestante} name="gestante" onChange={this.onChange} />
                Gestante?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.protese_metalica} name="protese_metalica" onChange={this.onChange} />
                Prótese metálica?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.lactante} name="lactante" onChange={this.onChange} />
                Lactante?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.hipersensibilidade_frio} name="hipersensibilidade_frio" onChange={this.onChange} />
                Hipersensibilidade ao frio?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.doenca_grave} name="doenca_grave" onChange={this.onChange} />
                Já teve alguma doença grave?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.tratamento_medico} name="tratamento_medico" onChange={this.onChange} />
                Tratamento médico atual?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.diastase_abdominal} name="diastase_abdominal" onChange={this.onChange} />
                Diástase abdominal?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.hernias} name="hernias" onChange={this.onChange} />
                Hérnias?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.disfuncao_hepatica} name="disfuncao_hepatica" onChange={this.onChange} />
                Disfunção Hepática?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.hipertensao} name="hipertensao" onChange={this.onChange} />
                Hipertensão?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.marca_passo} name="marca_passo" onChange={this.onChange} />
                Portador de marca-passo?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.neoplasias} name="neoplasias" onChange={this.onChange} />
                Neoplasias (câncer)?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.colesteral_alto} name="colesteral_alto" onChange={this.onChange} />
                Colesterol alto?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.doenca_sistemica} name="doenca_sistemica" onChange={this.onChange} />
                Doença sistêmica?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.alergia_medicamentos} name="alergia_medicamentos" onChange={this.onChange} />
                Alergia a medicamento?
              </label>
            </div>
          </div>
        </Grid>

        <LabelAndText name='anamnese_observacoes' handleChange={this.onChange} value={this.state.anamnese_observacoes}
          label='Observações' cols='12' placeholder='Digite aqui' />
      </div>
    );
  }
}

export default FichaCriolipolise;
