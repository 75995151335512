import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import LoadingPage from './loadingPage';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Scrollbar from 'react-scrollbars-custom';

ReactModal.setAppElement('#root');

const INITIAL_STATE = {
  loaded: false,
  ativo: null,
  itens: [],
  typeSearch: 'usuarios'
};

class Filter extends Component{
  constructor(props){
    super(props);
    this.state = INITIAL_STATE;
    this.loadPage = this.loadPage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeAllCheckboxes = this.changeAllCheckboxes.bind(this);
    this.search = this.search.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
  }

  componentDidMount(){
    this.loadPage();
  }

  search(){
    var checkedBoxes = document.querySelectorAll('.checkboxes_itens:checked');
    let selecteds = [];

    for (let i = 0; i < checkedBoxes.length; i++) {
      const item = checkedBoxes[i];
      selecteds.push(item.value);
    }
    this.props.searchList(selecteds);
  }

  closeHandler(){
    this.setState(INITIAL_STATE);
  }

  changeAllCheckboxes(event, item){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const data = {[name]: value, ativo: name};
    this.setState(data);

    if(this.state.typeSearch === 'usuarios'){
      item.profissionais.map((v) => {
        const _n = `${item.id}_${v.id}`;
        const data = {[_n]: value};
        this.setState(data);
        return true;
      });
    }else{

    }
  }

  loadPage(){
    const { token } = this.props;
    const self = this;

    this.setState(INITIAL_STATE);

    const typeSearch = this.state.typeSearch;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API[typeSearch].visualizacao}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({
              loaded: true,
              itens: data.data,
              visible: true
            });

            if(data.data.length){
              //limpamos o state com os checkboxes iniciais
              if(typeSearch === 'usuarios'){
                data.data.forEach((item)=>{
                  self.setState({[`${item.id}`]:false});
                  item.profissionais.forEach((item2) => {
                    self.setState({[`${item.id}_${item2.id}`]:false});
                  });
                });
              }else{
                data.data.forEach((item)=>{
                  self.setState({[`${item.id}`]:false});
                });
              }
            }

          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const data = {[name]: value, ativo: name};
    this.setState(data);
  }

  getUsuarios(){
    return this.state.itens.map((v,i) =>{
      let check = false;
      return(
        <div className='box-modal-visualizacao__wrapper' key={i}>
          <span className='box-modal-visualizacao__title'>
          <input checked={this.state[v.id]} onChange={(event) => this.changeAllCheckboxes(event, v)} type='checkbox' name={v.id} /> {v.label}</span>
          {
            v.profissionais.map((v2,i2) => {
              let checked = check ? check : this.state[`${v.id}_${v2.id}`];
              return(
                <p key={i2} className='box-modal-visualizacao__item'>
                  <input className="checkboxes_itens" checked={checked} type='checkbox' value={v2.id} name={`${v.id}_${v2.id}`} onChange={this.handleInputChange} /> <span>{`${v2.nome} ${v2.sobrenome}`}</span>
                </p>
              )
            })
          }
        </div>
      );
    });
  }

  getOutros(){
    return this.state.itens.map((v,i) =>{
      return(
        <div className='box-modal-visualizacao__wrapper' key={i}>
          <p key={i} className='box-modal-visualizacao__item'>
            <input className="checkboxes_itens" checked={this.state[v.id]} type='checkbox' value={v.id} name={`${v.id}`} onChange={this.handleInputChange} /> <span>{`${v.label}`}</span>
          </p>
        </div>
      );
    });
  }

  renderContent(){
    return (
      <div className='box-modal-visualizacao'>
        <Scrollbar noScrollX style={{ width: 200, height: 150 }}>
        {
          this.state.itens.length ?
          this.getUsuarios() : null
        }
        </Scrollbar>
        <button type="button" onClick={this.search} className="btn btn-block btn-success">Buscar</button>
      </div>
    );
  }

  render(){
    return(
      <Fragment>
        {
          this.state.loaded ?
          this.renderContent()
          : <LoadingPage />
        }
      </Fragment>
    )
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default withRouter(connect(mapStateToProps)(Filter));
