import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '../../auth/authActions'
import { convertToken } from '../../config/methods';
import ModalDadosLogin from '../../sections/modals/modal-dados-login';


class Navbar extends Component {
    constructor(props) {
      super(props)
      this.state = {
        open: false ,
        notificationStatus: false
      }
    }

    changeOpen() {
        this.setState({ open: !this.state.open })
    }

    logout(e){
      e.preventDefault();
      this.props.logout();
    }

    callNotification(e){
      this.setState({ notificationStatus: !this.state.notificationStatus });
      if(e) e.preventDefault();
    }

    callOverlayDadosUsuario(status){
      this.setState({
        modal: status
      });
    }

    render() {
        //const notificatioMenu = this.state.notificationStatus ? ' open ' : "";
        const data = convertToken(this.props.user);
        const par = data.par;

        return (
            <div className="navbar-custom-menu">
                <ModalDadosLogin
                  usuario={par.usuario}
                  user={{id:par.id_usuario}}
                  token={this.props.user}
                  onCancel={ ()=>this.callOverlayDadosUsuario(false) }
                  className='Modal_Dados_login'
                  isOpen={this.state.modal}
                  onRequestClose={()=>this.callOverlayDadosUsuario(false)}
                />
                <ul className="nav navbar-nav">

                  {/*
                    <li className={`${notificatioMenu} dropdown notifications-menu navbar-notification-header`}>
                      <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={e=>this.callNotification(e)}>
                        <i className="fa fa-envelope-o"></i>
                        <span className="label label-warning">10</span>
                      </a>
                      <ul className="dropdown-menu">
                        <li className="header">Você tem 10 mensagens não lidas</li>
                        <li>
                          <ul className="menu">
                            <li>
                              <a href="#" >
                                <div>
                                  <i className="fa fa-user text-red"></i>
                                  <strong className="name-user-notification">Jhonnatan</strong>
                                </div>
                                <span>5 new members joined today</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" >
                                <div>
                                  <i className="fa fa-user text-red"></i>
                                  <strong className="name-user-notification">Jhonnatan</strong>
                                </div>
                                <span>5 new members joined today</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" >
                                <div>
                                  <i className="fa fa-user text-red"></i>
                                  <strong className="name-user-notification">Jhonnatan</strong>
                                </div>
                                <span>5 new members joined today</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" >
                                <div>
                                  <i className="fa fa-user text-red"></i>
                                  <strong className="name-user-notification">Jhonnatan</strong>
                                </div>
                                <span>5 new members joined today</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" >
                                <div>
                                  <i className="fa fa-user text-red"></i>
                                  <strong className="name-user-notification">Jhonnatan</strong>
                                </div>
                                <span>5 new members joined today</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="footer"  onClick={()=>this.callNotification()}>
                          <Link to="/mensagens">
                            Ver todas
                          </Link>
                        </li>
                      </ul>
                    </li>
                  */}

                  <li>
                    <a href="/" onClick={(e)=>{
                      this.callOverlayDadosUsuario(!this.state.modal);
                      e.preventDefault();
                    }}>
                      <i className="fa fa-user"></i>
                      <span style={{paddingLeft: 10}} className="hidden-xs">{`${par.nome} ${par.sobrenome}`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="/" onClick={(e)=>this.logout(e)} data-toggle="control-sidebar"><i className="fa fa-sign-out"></i></a>
                  </li>
                    {/*
                      <li onMouseLeave={() => this.changeOpen()}
                          className={`dropdown user user-menu ${this.state.open ? 'open' :
                              ''}`}>
                          <a href="javascript:;" onClick={() => this.changeOpen()}
                              aria-expanded={this.state.open ? 'true' : 'false'}
                              className="dropdown-toggle"
                              data-toggle="dropdown">
                              <img src="http://lorempixel.com/160/160/abstract"
                                  className="user-image" alt="User Image" />
                              <span className="hidden-xs">{name}</span>
                          </a>
                          <ul className="dropdown-menu">
                              <li className="user-header">
                                  <img src="http://lorempixel.com/160/160/abstract"
                                      className="img-circle" alt="User Image" />
                                  <p>{name}<small>{email}</small></p>
                              </li>
                              <li className="user-footer">
                                  <div className="pull-right">
                                      <a href="#" onClick={this.props.logout}
                                          className="btn btn-default btn-flat">Sair</a>
                                  </div>
                              </li>
                          </ul>
                      </li>
                    */}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => ({ user: state.auth.user })
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
