import React, { Component } from 'react';
import Box from '../../common/template/box';
import ModalAgendamento from '../modals/modal-agendamento';
import If from '../../common/operator/if';
import requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import Language from '../../config/language';
import ErrorPage from '../../common/template/errorPage';
import LoadingPage from '../../common/template/loadingPage';

export default class BoxServicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      agendamentos: props.agendamentos || []
    }
    this.onCreate = this.onCreate.bind(this);
    this.loadPage = this.loadPage.bind(this);
  }


  componentDidMount(){
    this.loadPage();
  }

  loadPage(){
    const { token } = this.props;
    const self = this;
    const vendaId = this.props.venda;
    this.setState({loading: true, error: false});
    requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendas.agendamentos}?venda=${vendaId}`,
      // data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({agendamentos: data.data.agendamentos, loading: false});
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setState({loading: false, error: true});
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setState({loading: false, error: true});
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setState({loading: false, error: true});
      }
    });
  }

  openModal(modal, args) {
    const data = {[modal]: true};

    if(modal === "modalAgendamento"){
      const dataSend = args ? args : {...this.props.data};
      data.dadosAgendamento = dataSend;
    }
    this.setState(data);
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  onCreate(data){
    this.setState({agendamentos:data.data});
  }

  renderRows() {
    const list = this.state.agendamentos || [];

    return list.map(bc => (
      <tr key={bc.id}>
        <td>{bc.data}</td>
        <td>{bc.hora_inicial}</td>
        <td>{bc.hora_final}</td>
        <td>{bc.tempo_atendimento}</td>
        <td>{bc.servico.value || ''} {bc.servico.label || bc.servico.servico}</td>
        <td>{bc.status.tipo}</td>
        <td>
          <button className='btn btn-warning' onClick={() => this.openModal('modalAgendamento', bc)}>
              <i className='fa fa-pencil'></i>
          </button>
          {/*
            <button className='btn btn-danger' onClick={() => null}>
                <i className='fa fa-trash-o'></i>
            </button>
          */}
        </td>
      </tr>
    ));
  }

  render(){
    return(
      <Box classes="box-success" title="Agendamentos">
        <ModalAgendamento
          key={Math.floor(Math.random() * 256)}
          token={this.props.token}
          className='Modal_Agendamento'
          isOpen={this.state['modalAgendamento']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamento')}
          dados={this.state.dadosAgendamento}
          onCreate={this.onCreate}
          tipo={'venda_agendamento'}
        />
        {/*
          <If test={!this.props.readOnly}>

          </If>
        */}
        <button type="button" className="btn btn-block btn-success" onClick={()=>this.openModal('modalAgendamento')}>
          <i className="fa fa-plus"></i>  Novo agendamento
        </button>
        <br />
        <table className='table'>
            <thead>
                <tr>
                  <th>Data</th>
                  <th>Início</th>
                  <th>Fim</th>
                  <th>Duração</th>
                  <th>Serviço</th>
                  <th>Status</th>
                  <th className='table-actions-single'></th>
                </tr>
            </thead>
            <tbody>
              {
                this.renderRows()
              }
            </tbody>
        </table>
        {
          this.state.error ? <>
            <ErrorPage />
            <div style={{ display: 'flex', justifyContent: 'center'}}>
            <button onClick={this.loadPage} type="button" class="btn btn-primary">Tentar novamente</button>
            </div>
          </> : null
        }
        {
          this.state.loading ? <LoadingPage /> : null
        }
      </Box>
    );
  }
}
