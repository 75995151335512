import React, { Component } from 'react';
import {
  PieChart, Pie, Legend, Tooltip, Cell
} from 'recharts';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import Row from  '../../common/layout/row';
import Scrollbar from 'react-scrollbars-custom';
import { connect } from 'react-redux';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import LOGO from "../../assets/images/logo-excelencia.png";
import ImageLoading from "../../assets/images/ajax-loader.gif";
import './dashboard.scss';
import { convertToBr } from '../../utils/dateJS';
import { urlParamsConverter } from '../../config/methods';

class Dashboard extends Component {
  constructor(){
    super();
    this.state = {
      loaded: false,
      agendamentos: [],
      clientesMaisCompram: []
    }
    this.initData = this.initData.bind(this);
  }
  componentWillMount() {
    this.loadData();
    this.loadClientesMaisCompram();
  }

  loadClientesMaisCompram(){
    const token = this.props.user;
    const self = this;

    let query = {};
    const lastDay = new Date();
    const fisrtDay = new Date();
    fisrtDay.setDate(fisrtDay.getDate() - 7);
    query.data_inicial = convertToBr(fisrtDay);
    query.data_final = convertToBr(lastDay);
    query = `?${urlParamsConverter(query)}`;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.clientesClassificacao}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            const _data = [];
            // retornar apenas os 5 primeiros clientes que mais compraram
            for(let i=0; i < 10; i++){
              if (data.data[i]) {
                _data.push({
                  name: `${data.data[i].cliente.nome} ${data.data[i].cliente.sobrenome}`,
                  value: data.data[i].total.amount,
                  label: data.data[i].total.formated
                })
              }
            }
            self.setState({clientesMaisCompram: _data});
          }
        }
      },
      error: response => {console.log(response)
      },
      complete: response => {
      }
    });
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.dashboard.read}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data);
          }
        }else{

        }
      },
      error: response => {

      },
      complete: response =>{
      }
    });
  }

  initData(response){
    this.setState({loaded: true, ...response});
  }

  getBody(){
    const data01 = this.state.clientesMaisCompram;
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#36F500', '#F62701', '#D6E80C', '#CC79F5', '#7577F5', '#F500BB'];
    const width = window.innerWidth ? (window.innerWidth - 500) : 500;
    return (
      <div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-red" > <i className="fa fa-calendar-check-o"></i></span>
            <div className="info-box-content">
              <span className="info-box-text">Total</span>
              <span className="info-box-number">{this.state.agendamentos.length}<small></small></span>
            </div>
          </div>
          
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon" style={{background:'#1abc9c'}}> <i className="fa fa-calendar"></i></span>
            <div className="info-box-content">
              <span className="info-box-text">Marcados</span>
              <span className="info-box-number">{this.state.marcados}<small></small></span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon "  style={{background:'#2ecc71'}}><i className="fa fa-thumbs-o-up"></i></span>

            <div className="info-box-content">
              <span className="info-box-text">Confirmados</span>
              <span className="info-box-number">{this.state.confirmados}<small></small></span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          
          <div className="info-box">
            <span className="info-box-icon bg-aqua"><i className="fa fa-users"></i></span>

            <div className="info-box-content">
              <span className="info-box-text">Pacientes</span>
              <span className="info-box-number">{this.state.pacientes.homens} <small>{this.state.pacientes.homens > 1 ? 'homens' : 'homem'}</small></span>
              <span className="info-box-number">{this.state.pacientes.mulheres} <small>{this.state.pacientes.mulheres > 1 ? 'mulheres' : 'mulher'}</small></span>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="info-box">
          <div style={{position: 'relative', padding: 20}}>
          <h3 className="box-title">Clientes que mais compraram no período de 7 dias</h3>
            <PieChart height={380} width={width}>
              {/* <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" /> */}
              <Pie isAnimationActive={false} data={data01} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label >
              {
                data01.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
              }
              </Pie>
              <Tooltip />
              <Legend verticalAlign="top" align="left" layout="vertical"/>
            </PieChart>
          </div>
          </div>
        </div>
        <div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Serviços agendados</h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div className="box-body">
              <Scrollbar noScrollX style={{ height: 200 }}>
                {
                  this.state.servicos.map((v,i)=><div className="progress-group"  key={`se_${i}`} style={{paddingRight: 10}}>
                    <span className="progress-text">{v.servico}</span>
                    <span className="progress-number"><b>{v.qnt}</b>/{this.state.agendamentos.length}</span>
                    <div className="progress sm">
                      <div className="progress-bar progress-bar-aqua" style={{width: `${(v.qnt*100)/this.state.agendamentos.length}%`}}></div>
                    </div>
                  </div>)
                }
              </Scrollbar>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="box box-danger">
              <div className="box-header with-border">
                <h3 className="box-title">Serviço por profissional</h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div className="box-body">
              <Scrollbar noScrollX style={{ height: 200 }}>
              {
                this.state.profissionais.map((v,i) => <div key={`pr_${i}`} className="progress-group" style={{paddingRight: 10}}>
                  <span className="progress-text">{v.nome}</span>
                  <span className="progress-number"><b>{v.qnt}</b>/{this.state.agendamentos.length}</span>
                  <div className="progress sm">
                    <div className="progress-bar progress-bar-aqua" style={{width: `${(v.qnt*100)/this.state.agendamentos.length}%`}}></div>
                  </div>
                </div>)
              }
              </Scrollbar>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Aniversariantes do mês</h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div className="box-body">
              <Scrollbar noScrollX style={{ height: 200 }}>
              <div className="table-responsive">
                <table className="table no-margin">
                  <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Data</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.aniversariantes_mes.map((v, i)=>(
                      <tr key={`am_${i}`}>
                        <td>{v.nome}</td>
                        <td>{v.data_nascimento}</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              </Scrollbar>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Aniversariantes do dia</h3>
                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div className="box-body">
              <Scrollbar noScrollX style={{ height: 200 }}>
              <div className="table-responsive">
                <table className="table no-margin">
                  <thead>
                  <tr>
                    <th>Nome</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.aniversariantes_dia.map((v, i)=>(
                      <tr key={`ad_${i}`}>
                        <td>{v.nome}</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              </Scrollbar>
              </div>
            </div>
          </div>
        </div>

      <div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Agendamentos - <strong>{this.state.agendamentos.length}</strong></h3>

                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div className="box-body">
                <Scrollbar noScrollX style={{ height: 300 }}>
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                      <tr>
                        <th>Cliente</th>
                        <th>Profissional</th>
                        <th>Hora</th>
                        <th>Serviço</th>
                        <th>Sala</th>
                        <th>Equipamento</th>
                      </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.agendamentos.map((v, i)=>(
                            <tr  key={`ag${i}`}>
                              <td>{v.nome_cliente}</td>
                              <td>{v.nome_profissional}</td>
                              <td>{v.hora_inicial}</td>
                              <td>{v.servico}</td>
                              <td>{v.sala}</td>
                              <td>{v.equipamento}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </Scrollbar>
              </div>
            </div>
        </div>
      </div>
      </div>
    );
  }

  render() {
      return (
          <div>
            <ContentHeader title='' small='' />

              <Content>
                  <Row>
                  {
                    this.state.loaded ? this.getBody() : <div>
                      <div className="box-image-dashboard">
                        <span style={{ background: '#ffffff', padding: 20, borderRadius: 8 }}>
                        <img alt='Logomarca Clínica Excelência' src={LOGO} />
                        </span>
                        <span>Seja bem vindo(a)</span>
                        <div style={{marginTop: 30}}>
                          <img src={ImageLoading} alt="" />
                        </div>
                      </div>
                    </div>
                  }
                  </Row>
              </Content>
          </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Dashboard);
