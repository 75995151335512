import React from 'react';
import Grid from '../layout/grid';
import Select from 'react-select';
import './labelAndSelect.scss';


const customStyles = {
  menu: (provided, state) => {
    return { ...provided, height: 210 };
  },
  menuList: (provided, state) => {
    return { ...provided, height: 210 };
  }
}

export default props => (
    <Grid cols={props.cols}>
        <div className={`${props.required ? "field-mandatory" : "" } form-group form-group__select`}>
            <label htmlFor={props.name}>{props.label}</label>
            <Select
            styles={customStyles}
              isMulti={props.isMulti}
              isDisabled={props.readOnly}
              value={props.value}
              options={props.options}
              isClearable
              onChange={ value => {
                props.handleChange(value,props.name);
              }} />
        </div>
    </Grid>
)
