import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import api from '../../api';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import Pagination from '../../common/template/pagination';
import EmptyList from '../../common/template/emptyList';
import { urlParamsConverter } from '../../config/methods';
import ModalLoading from '../modals/modal-loading';
import LabelAndSelect from '../../common/form/labelAndSelect';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import {convertToBr} from '../../utils/dateJS';

const QUERY_INIT = { page: 1 };
const options = [
  {value:1,label:'Avulsos'},
  {value:2,label:'Pacotes'}
];
const maxResults = [
  {value:10,label:'10'},
  {value:20,label:'20'},
  {value:30,label:'30'},
  {value:40,label:'40'},
  {value:50,label:'50'},
  {value:60,label:'60'},
  {value:70,label:'70'},
  {value:80,label:'80'},
  {value:90,label:'90'},
  {value:100,label:'100'},
];

class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      list: [],
      ModalLoading: false,
      pageActive: 1,
      query: {...QUERY_INIT, ...props.query},
      errorLoadingPage: false,
      pagination: CONSTS.PAGINATE
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.changePage = this.changePage.bind(this);
    this.loadPage = this.loadPage.bind(this);

  }

  componentDidMount() {
    this.loadPage();
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  getQuery(){
    const query = {...this.state.query};
    if(this.state.tipo){
      query.tipo = this.state.tipo.value;
    }

    if(this.state.maxResults){
      query.maxResults = this.state.maxResults.value;
    }

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    return query;
  }

  loadPage(){
    const token = this.props.token;
    const self = this;
    const queryData = this.getQuery();
    const query = `?${urlParamsConverter(queryData)}`;
    this.setState({loaded:false});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.vendasClientes}${query}`,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();

          if(data.status){
            self.setState({
              ModalLoading: false,
              list: data.data,
              pagination: data.pagination
            });
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setErrorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setErrorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.loaded();
        self.setErrorLoadingPage();
      }
    });
  }

  changePage(goTo){
    let page;
    if (goTo==='next') {
      page = this.state.pagination.next;
    }else if(goTo==='prev'){
      page = this.state.pagination.prev;
    }else{
      page = goTo;
    }

    this.setState({
      ModalLoading: true,
      query: {...this.state.query, page}
    }, () => this.loadPage());
  }

  setErrorLoadingPage(){
    this.setState({
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  callWhats(item){
    const url = `https://api.whatsapp.com/send?phone=55${item.whatsapp}&text=/`;
    var node = document.createElement('textarea');
    var selection = document.getSelection();

    node.textContent = url;
    document.body.appendChild(node);

    selection.removeAllRanges();
    node.select();
    document.execCommand('copy');

    selection.removeAllRanges();
    document.body.removeChild(node);

    toastr.success(Language.atencao, "Dados copiados com sucesso");
  }

  renderRows() {
      const list = this.state.list || [];

      return list.map(bc => {

        return (
          <tr key={bc.id}>
            <td>{bc.cliente}</td>
            <td>{bc.cpf_cnpj}</td>
            <td>{bc.whatsapp}</td>
            <td>{bc.avulsos}</td>
            <td>{bc.planos}</td>
            <td>{bc.data_venda}</td>
            <td>{bc.unidade}</td>
            <td className="hideOnprint">
              {
                bc.whatsapp ?
                <button type="button" className='btn btn-success' onClick={() => this.callWhats(bc)}>
                    <i className='fa fa-whatsapp'></i>
                </button>
                : ''
              }
            </td>
          </tr>
        );
      })
  }

  renderTable(){
    return (
      <div>
      <Fragment>
        <ContentHeader title='Vendas por cliente' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
          {
            this.state.list.length ?
              <div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>Cpf/Cnpj</th>
                            <th>Celular</th>
                            <th>Avulsos</th>
                            <th>Pacotes</th>
                            <th>Última venda</th>
                            <th className='table-actions-single hideOnprint'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
                <Pagination setPage={this.changePage} onClick={this.changePage} dados={this.state.pagination} />
              </div>
            : <EmptyList />
          }
          </Box>
        </Content>
      </Fragment>
      <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </div>
    );
  }

  getBody(){
    return (
      <Content>
        {/*
          <div className='clearfix' style={{paddingBottom: 20}}>
            <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
            <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
          </div>
        */}
        <Box classes="box-success" title="Resultado">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Cpf/Cnpj</th>
                        <th>Celular</th>
                        <th>Avulsos</th>
                        <th>Pacotes</th>
                        <th>Última venda</th>
                        <th className='table-actions-single hideOnprint'></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
            <Pagination setPage={this.changePage} onClick={this.changePage} dados={this.state.pagination} />
          </div>
        </Box>
      </Content>
    );
  }

  render(){
    return(
      <Fragment>
        <ContentHeader title='Vendas por cliente' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
              <LabelAndDate name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                  label='Data' cols='12 2' date={this.state.data_inicial} />
              <LabelAndSelect name='tipo' options={options} value={this.state.tipo} handleChange={this.handleSelect}
                label='Ordenar por vendas avulsas ou pacotes' cols='10 3' placeholder='Digite aqui' />
              <LabelAndSelect name='maxResults' options={maxResults} value={this.state.maxResults} handleChange={this.handleSelect}
                label='Quantidade de resultados' cols='10 3' placeholder='Digite aqui' />
              <ButtonAddAjax cols="2 1" icon="search" onClick={this.loadPage} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : <LoadingPage />}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }

  // render() {
  //     const tempBox = this.state.loaded
  //     ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderTable())
  //     : <LoadingPage />;
  //     return (
  //         <div>
  //           {
  //             tempBox
  //           }
  //         </div>
  //     )
  // }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
