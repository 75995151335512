import React from 'react';
import Grid from '../layout/grid';
import './labelAndButton.scss';

export default props => (
    <Grid cols={props.cols}>
        <div className={`form-group label-button`}>
            <button type="button" onClick={props.onClick} className="btn btn-block btn-success">{props.labelButton}</button>
        </div>
    </Grid>
)
