import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Dashboard from '../sections/dashboard/dashboard';
// import Usuarios from '../sections/usuarios/usuarios';
// import Agendamentos from '../sections/agendamentos/agendamentos';
// import Unidades from '../sections/unidades/unidades';
// import Profissionais from '../sections/profissionais/profissionais';
// import PerfisComissionamento from '../sections/perfis-comissionamento/perfisComissionamento';
// import Clientes from '../sections/clientes/clientes';
import Servicos from '../sections/servicos/servicos';
import Salas from '../sections/salas/salas';
import AreasCorpo from '../sections/areas-corpo/areas';
import Equipamentos from '../sections/equipamentos/equipamentos';
import Fornecedores from '../sections/fornecedores/fornecedores';
import Patromonio from '../sections/patrimonio/patrimonio';
import Planos from '../sections/planos/planos';
import VendaPlanos from '../sections/venda-planos/planos';
import VendaPlanosAvulsos from '../sections/venda-planos/planos';
import Controle from '../sections/controle/controle';
import Caixa from '../sections/caixa/caixa';
import Relatorios from '../sections/relatorios/relatorios';
import Estoque from '../sections/estoque/estoque';
//import Financeiro from '../sections/financeiro/financeiro';
//import Mensagens from '../sections/mensagens/mensagens';
import Crm from '../sections/crm/crm';
import ReportsAgendamentos from '../sections/reports/agendamentos';
import ReportsAniversariantes from '../sections/reports/aniversariantes';
import VendasClientes from '../sections/reports/vendas-clientes';
import ClientesAtivos from '../sections/reports/vendas-clientes-ativos';
import PermissoesUsuarios from '../sections/configuracoes/permissoesUsuarios';
import TiposProfissionais from '../sections/configuracoes/tiposProfissionais';
import Contrato from '../sections/configuracoes/contrato';
import ProcedimentosMaisVendidos from '../sections/reports/procedimentos-mais-vendidos';

import MensagensAgendamentos from '../sections/mensagens/agendamentos';
import MensagensPlanos from '../sections/mensagens/planos';
import MensagensAniversariantes from '../sections/mensagens/aniversariantes';

import FechamentoPagamento from '../sections/financeiro/fechamento-pagamento';
import VendasPagamento from '../sections/financeiro/vendas';
import VendasRealizadas from '../sections/financeiro/vendas-realizadas';
import ContasPagar from '../sections/financeiro/contas/contasPagar';
import PagamentosColaboradores from '../sections/financeiro/pagamentos-colaboradores';
import PagamentosProfissionais from '../sections/financeiro/pagamentos-profissionais';
import PagamentosFornecedores from '../sections/financeiro/pagamentos-fornecedores';
import Parcerias from '../sections/financeiro/vendas-parcerias';
import RelatoriosContas from '../sections/financeiro/relatorios-contas';
import RelatoriosCaixa from '../sections/financeiro/relatorios-caixa';

const AgendamentosSuspense = React.lazy(() => import("../sections/agendamentos/agendamentos"));
const UsuariosSuspense = React.lazy(() => import('../sections/usuarios/usuarios'));
const UnidadesSuspense = React.lazy(() => import('../sections/unidades/unidades'));
const ProfissionaisSuspense = React.lazy(() => import('../sections/profissionais/profissionais'));
const PerfisComissionamentoSuspense = React.lazy(() => import('../sections/perfis-comissionamento/perfisComissionamento'));
const ClientesSuspense = React.lazy(() => import('../sections/clientes/clientes'));

const LoadingPage = () => <>Carregando</>

export default props => (
    <div className='content-wrapper'>
        <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/usuarios' component={() => <React.Suspense fallback={<LoadingPage />}><UsuariosSuspense /></React.Suspense>} />
            <Route exact path='/agendamentos' component={() => <React.Suspense fallback={<LoadingPage />}><AgendamentosSuspense /></React.Suspense>} />
            <Route exact path='/unidades' component={() => <React.Suspense fallback={<LoadingPage />}><UnidadesSuspense /></React.Suspense>} />
            <Route exact path='/profissionais' component={() => <React.Suspense fallback={<LoadingPage />}><ProfissionaisSuspense /></React.Suspense>} />
            <Route exact path='/perfis-comissionamento' component={() => <React.Suspense fallback={<LoadingPage />}><PerfisComissionamentoSuspense /></React.Suspense>} />
            <Route exact path='/clientes' component={() => <React.Suspense fallback={<LoadingPage />}><ClientesSuspense /></React.Suspense>} />
            <Route exact path='/servicos' component={Servicos} />
            <Route exact path='/salas' component={Salas} />
            <Route exact path='/equipamentos' component={Equipamentos} />
            <Route exact path='/areas-corpo' component={AreasCorpo} />
            <Route exact path='/fornecedores' component={Fornecedores} />
            <Route exact path='/patrimonio-aportes' component={Patromonio} />
            <Route exact path='/planos' component={Planos} />
            <Route exact path='/venda-planos/:tipo' key="venda-planos" component={VendaPlanos} />
            <Route exact path='/vendas-avulsas/:tipo' key="vendas-avulsas" component={VendaPlanosAvulsos} />
            <Route exact path='/controle' component={Controle} />
            <Route exact path='/caixa' component={Caixa} />
            <Route exact path='/relatorios' component={Relatorios} />
            <Route exact path='/estoque' component={Estoque} />
            <Route exact path='/crm' component={Crm} />
            <Route exact path='/reports/agendamentos' component={ReportsAgendamentos} />
            <Route exact path='/reports/aniversariantes/' component={ReportsAniversariantes} />
            <Route exact path='/reports/procedimentos-mais-vendidos/' component={ProcedimentosMaisVendidos} />
            <Route exact path='/reports/vendas-clientes/' component={VendasClientes} />
            <Route exact path='/reports/vendas-clientes-ativos/' component={ClientesAtivos} />
            <Route exact path='/configuracoes/permisoes-usuarios' component={PermissoesUsuarios} />
            <Route exact path='/configuracoes/tipos-profissionais' component={TiposProfissionais} />
            <Route exact path='/configuracoes/contrato' component={Contrato} />

            <Route exact path='/mensagens/agendamentos' component={MensagensAgendamentos} />
            <Route exact path='/mensagens/planos' component={MensagensPlanos} />
            <Route exact path='/mensagens/aniversariantes' component={MensagensAniversariantes} />

            <Route exact path='/financeiro/pagamentos' component={FechamentoPagamento} />
            <Route exact path='/financeiro/vendas' component={VendasPagamento} />
            <Route exact path='/financeiro/vendas-realizadas' component={VendasRealizadas} />
            <Route exact path='/financeiro/contas-pagar' component={ContasPagar} />
            <Route exact path='/financeiro/pagamentos-colaboradores' component={PagamentosColaboradores} />
            <Route exact path='/financeiro/pagamentos-profissionais' component={PagamentosProfissionais} />
            <Route exact path='/financeiro/pagamentos-fornecedores' component={PagamentosFornecedores} />
            <Route exact path='/financeiro/parcerias' component={Parcerias} />
            <Route exact path='/financeiro/relatorios-contas' component={RelatoriosContas} />
            <Route exact path='/financeiro/relatorios-caixa' component={RelatoriosCaixa} />


            <Redirect from='*' to='/' />
        </Switch>
    </div>
);
