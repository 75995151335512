import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import api from '../../api';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import Pagination from '../../common/template/pagination';
import { urlParamsConverter } from '../../config/methods';
import ModalLoading from '../modals/modal-loading';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import ModalCRM from '../modals/modal-crm';
import LabelAndSelect from '../../common/form/labelAndSelect';
const QUERY_INIT = { page: 1 };

const maxResults = [
  {value:-1,label:'Todos'},
  {value:10,label:'10'},
  {value:20,label:'20'},
  {value:30,label:'30'},
  {value:40,label:'40'},
  {value:50,label:'50'},
  {value:100,label:'60'},
];
class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      list: [],
      ModalLoading: false,
      pageActive: 1,
      query: {...QUERY_INIT, ...props.query},
      errorLoadingPage: false,
      pagination: CONSTS.PAGINATE
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.changePage = this.changePage.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
  }

  componentDidMount() {
    this.loadPage();
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  getQuery(){
    const query = {...this.state.query};
    if(this.state.servico && this.state.servico !== ''){
      const servicos = this.state.servico.value;
      query.servicos = servicos;
    }

    if(this.state.maxResults){
      query.maxResults = this.state.maxResults.value;
    }

    return query;
  }


  getArrayExcel(){
    let arr = [];

    arr = this.state.list.map((value, i) => [
      value.id_venda,
      value.servicos,
      value.n_sessoes,
      value.qnt_agendamentos,
      value.nome_cliente,
      value.whatsapp,
      value.data
    ]);
    arr.unshift(["Id venda", "Serviços", "Nº sessões", "Atendidos/Falhou", "Cliente", "Whatsapp", "Data venda"]);
    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "procedimentos-ativos.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  loadPage(){
    const token = this.props.token;
    const self = this;
    const queryData = this.getQuery();
    const query = `?${urlParamsConverter(queryData)}`;
    this.setState({loaded:false});
    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.procedimentosAtivos}${query}`,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();

          if(data.status){
            self.setState({
              ModalLoading: false,
              list: data.data,
              pagination: data.pagination
            });
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setErrorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setErrorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.loaded();
        self.setErrorLoadingPage();
      }
    });
  }

  changePage(goTo){
    let page;
    if (goTo==='next') {
      page = this.state.pagination.next;
    }else if(goTo==='prev'){
      page = this.state.pagination.prev;
    }else{
      page = goTo;
    }

    this.setState({
      ModalLoading: true,
      query: {...this.state.query, page}
    }, () => this.loadPage());
  }

  setErrorLoadingPage(){
    this.setState({
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  callWhats(item){
    const url = `https://api.whatsapp.com/send?phone=55${item.whatsapp}&text=/`;
    var node = document.createElement('textarea');
    var selection = document.getSelection();

    node.textContent = url;
    document.body.appendChild(node);

    selection.removeAllRanges();
    node.select();
    document.execCommand('copy');

    selection.removeAllRanges();
    document.body.removeChild(node);

    toastr.success(Language.atencao, "Dados copiados com sucesso");
  }

  renderRows() {
      const list = this.state.list || [];

      return list.map(bc => {

        return (
          <tr key={bc.id}>
            <td>{bc.id_venda}</td>
            <td>{bc.servicos}</td>
            <td>{bc.n_sessoes}</td>
            <td>{bc.qnt_agendamentos}</td>
            <td>{bc.nome_cliente}</td>
            <td>{bc.whatsapp}</td>
            <td>{bc.data}</td>
            <td className="hideOnprint">
            <button type="button" className='btn btn-success' onClick={() => this.openModal('ModalCRM', bc.id_cliente)}>
                <i className='fa fa-id-card'></i>
            </button>
            </td>
          </tr>
        );
      })
  }

  getBody(){
    return (
      <Content>
        
          <div className='clearfix' style={{paddingBottom: 20}}>
            <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
            <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
          </div>
       
        <Box classes="box-success" title="Resultado">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Id venda</th>
                        <th>Serviços</th>
                        <th>Nº sessões</th>
                        <th>Atendidos/Falhou</th>
                        <th>Cliente</th>
                        <th>Whatsapp</th>
                        <th>Data venda</th>
                        <th className='table-actions-single hideOnprint'>CRM</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
            <Pagination setPage={this.changePage} onClick={this.changePage} dados={this.state.pagination} />
          </div>
        </Box>
      </Content>
    );
  }

  openModal(modal, args) {
    let dados = {[modal]: true};
    dados.dadosAgendamento = '';
    dados.agendamento = '';
    dados.dadosCRM = '';

    if(modal === 'modalInfo'){
      dados.agendamento = args;
    }
    if(modal === 'ModalCRM'){
      dados.dadosCRM = args;
    }

    this.setState(dados);
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    if(modal === 'modalAgendamento'){

    }
    this.setState({[modal]: false});
  }

  render(){
    const urlServico = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const servicoQuery = {};

    return(
      <Fragment>
      <ModalCRM
        className='Modal_CRM'
        isOpen={this.state['ModalCRM']}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={()=>this.closeModal('ModalCRM')}
        cliente={this.state.dadosCRM}
      />
        <ContentHeader title='Procedimentos ativos' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
          <LabelAndSelectAjax
            name='servico'
            value={this.state.servico}
            onChange={this.handleSelect}
            url={urlServico}
            token={this.props.token}
            label='Selecione um serviço'
            cols='12 4'
            query={servicoQuery}
            placeholder='Digite aqui' />
            <LabelAndSelect name='maxResults' options={maxResults} value={this.state.maxResults} handleChange={this.handleSelect}
                label='Quantidade de resultados' cols='10 3' placeholder='Digite aqui' />
              <ButtonAddAjax cols="2 1" icon="search" onClick={this.loadPage} />
            <Clearfix />
          </Box>

        </Content>
        {this.state.loaded ? this.getBody() : <LoadingPage />}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }

}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
