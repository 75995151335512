import React, { Component } from 'react';

import ContentHeader from '../../../common/template/contentHeader';
import Content from '../../../common/template/content';
import Tabs from '../../../common/tab/tabs';
import TabsHeader from '../../../common/tab/tabsHeader';
import TabsContent from '../../../common/tab/tabsContent';
import TabHeader from '../../../common/tab/tabHeader';
import TabContent from '../../../common/tab/tabContent';
import { showTabs } from '../../../common/tab/tabActions';
import If from '../../../common/operator/if';
import Form from './Form';

const INITIAL_STATE_TABS = { selected: '', visible: {} };

class Areas extends Component{
  constructor(props){
    super(props);
    this.state = {
      tabs: INITIAL_STATE_TABS,
      detail: null,
      query: null
    }

    this.changeTab = this.changeTab.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentWillMount() {
    showTabs({ tabId: 'tabList', tabs: this.state.tabs, component: this });
  }

  onSearch(data){
    this.closeModal('modalBuscar');
    showTabs({tabId:'tabList', tabs: this.state.tabs, component: this, onComplete: () => {
      this.setState({query: data.query, loaded: false});
    }});
  }

  openModal(modal) {
    this.setState({[modal]: true});
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  changeTab(tabId='tabList'){
    showTabs({tabId:tabId, tabs: this.state.tabs, component: this});
  }

  render(){
    const tabs = this.state.tabs;

    return(
      <div>
      <ContentHeader title='Contrato' small='' />
          <Content>
              <Tabs>
                  <TabsHeader>
                      <TabHeader
                        label='Dados'
                        icon='id-card-o'
                        target='tabList'
                        selectTab={this.changeTab}
                        tabs={tabs}
                        component={this}
                      />


                  </TabsHeader>
                  <TabsContent>
                      <TabContent id='tabList' tabs={tabs}>
                        <If test={tabs.selected === 'tabList'}>
                          <Form />
                        </If>
                      </TabContent>
                  </TabsContent>
              </Tabs>
          </Content>
      </div>
    );
  }
}

export default Areas;
