import React, { Component } from 'react';
import Grid from '../../common/layout/grid';
import LabelAndText from '../../common/form/labelAndText';

class FichaJatoplasma extends Component {
  constructor(props) {
    super(props);
    this.state = props.data;
    this.onChange = this.onChange.bind(this);
  }

  onChange(el){
    this.props.onChange(el);

    const target = el.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){
    return(
      <div>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.diastase_abdominal} name="diastase_abdominal" onChange={this.onChange} />
                Diástase Abdominal?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.lesao_aberta} name="lesao_aberta" onChange={this.onChange} />
                Lesão aberta na pele (furúnculo/dermatites, eczema)?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.lupus} name="lupus" onChange={this.onChange} />
                Lúpus eritematoso sistêmico?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.cancer_pele} name="cancer_pele" onChange={this.onChange} />
                Câncer de Pele?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.insuficiencia_circulatoria} name="insuficiencia_circulatoria" onChange={this.onChange} />
                Insuficiência circulatória periférica (varizes graves)?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.anticoagulante} name="anticoagulante" onChange={this.onChange} />
                Uso de anticoagulante?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.cirurgia_recente} name="cirurgia_recente" onChange={this.onChange} />
                Cirurgia recente na área a ser tratada?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.diabetico} name="diabetico" onChange={this.onChange} />
                Diabético?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.renal_cronica} name="renal_cronica" onChange={this.onChange} />
                Renal Crônica?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.protese_metalica} name="protese_metalica" onChange={this.onChange} />
                Prótese Metálica?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.gestante} name="gestante" onChange={this.onChange} />
                Gestante?
              </label>
            </div>
          </div>
        </Grid>
        <Grid cols="12 4">
          <div className="form-group">
            <div className="checkbox">
              <label className="">
                <input type="checkbox" checked={this.state.cardiopata} name="cardiopata" onChange={this.onChange} />
                Cardiopatas?
              </label>
            </div>
          </div>
        </Grid>

        <LabelAndText name='anamnese_observacoes' handleChange={this.onChange} value={this.state.anamnese_observacoes}
          label='Observações' cols='12' placeholder='Digite aqui' />
      </div>
    );
  }
}

export default FichaJatoplasma;
