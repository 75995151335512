import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Box from '../../../common/template/box';
import LabelAndInput from '../../../common/form/labelAndInput';
import LabelAndSelect from '../../../common/form/labelAndSelect';
import ButtonAddAjax from '../../../common/form/buttonAddAjax';
import CONSTS from '../../../config/consts';
import { maskMoney, maskNumber, maskPercent, strPtBrMoneyToNumber } from '../../../config/methods';
import api from '../../../api';
import Requisition from '../../../utils/requisitions/requisition';
import Language from '../../../config/language';
import ModalFinanceiro from '../../modals/modal-financeiro-conta';
import If from '../../../common/operator/if';

const optionsTiposPagamento = CONSTS.METODOS_PAGAMENTO;
const parcelas = CONSTS.PARCELAS;

export default class BoxServicos extends Component {
  constructor(props) {
    super(props);
    const data = this.beforeRender();
    this.state = {
      desconto: '',
      tipo_pagamento: '',
      qnt_parcelas: {label: '1', value: 1},
      tipo_desconto: '',
      total: '',
      ...data
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onChangeFinanceiro = this.onChangeFinanceiro.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.financeiro !== prevProps.financeiro) {
      if(this.props.financeiro){
        this.setState({parcelas: this.props.financeiro.parcelas});
      }
    }
  }

  beforeRender(){
    const financeiro = { parcelas: []};
    const data = this.props.financeiro;

    if(data){
      if(data.qnt_parcelas){
        financeiro.qnt_parcelas = {value: data.qnt_parcelas, label:data.qnt_parcelas};
      }

      if(data.tipo_pagamento){
        financeiro.tipo_pagamento = {value: data.tipo_pagamento.id, label: data.tipo_pagamento.tipo};
      }

      if(data.tipo_desconto){
        financeiro.tipo_desconto = {value: data.tipo_desconto.id, label:data.tipo_desconto.value};
      }

      financeiro.desconto = data.desconto;

      financeiro.parcelas = data.parcelas;
    }

    return financeiro;
  }

  onChangeFinanceiro(data){
    //this.props.onChangeFinanceiro(data);
  }

  handleSelect(item, name){
    const state = {...this.state, ...{[name] : item} };
    //se o tipo de pagamento for dinheiro ou transferência, a quantidade de parcelas será somente 1
    if(state.tipo_pagamento && state.tipo_pagamento.maxParcelas){
      state.qnt_parcelas = parcelas[0];
    }

    if(name === 'tipo_desconto' && item.value === ""){
      this.setState({desconto: ''});
    }

    //se a quantidade de parcelas foi limpada, forçamos ela a ser 1
    if(name === 'qnt_parcelas' && !item){
      state.qnt_parcelas = parcelas[0];
    }

    this.setState(state);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    /*if(name === "desconto"){
      if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 2)){
        state = { [name]: maskMoney(value)}
      } else if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 1)){
        state = { [name]: maskPercent(value)}
      }else{
        state = { [name]: maskNumber(value)}
      }
    }*/

    if(name === "valor" || name === "desconto" || name === "tipo_desconto" || name === "n_sessoes"){
      this.checkValues(event);
    }else{
      const data = {[name]: value};
      if(name === 'cortesia'){
        data.valor = '';
        data.desconto = '';
        data.tipo_desconto = '';
        data.total = '';
      }
      this.setState(data);
    }
  }

  checkValues(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let convertedValue = value;
    let newState = {};

    switch (name) {
      case "valor":
        convertedValue = maskMoney(convertedValue);
        newState = { [name]: convertedValue }
        break;
        case "desconto":
          //se o tipo de comissão for 2, ou seja, dinheiro, convertemos o valor para dinheiro
          if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 2)){
            newState = { [name]: maskMoney(convertedValue)}
          } else if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 1)){
            newState = { [name]: maskPercent(convertedValue)}
          }else{
            newState = { [name]: maskNumber(convertedValue)}
          }
          break;
      default:
        newState = {[name]: value};
    }

    newState = this.checkTotal(newState, this.state);

    this.setState(newState);
  }

  checkTotal(prevState, currentState){
    let newState = {...currentState, ...prevState};

    if(newState.n_sessoes && newState.valor){
      newState.total = strPtBrMoneyToNumber(newState.valor) * newState.n_sessoes;
      newState.total = Number(newState.total).toFixed(2);
      newState.total = String(newState.total).replace('.',',');    }

    if(newState.n_sessoes && newState.tipo_desconto  && (newState.tipo_desconto.value !== '') && newState.desconto && newState.valor){
      const tipo_desconto = newState.tipo_desconto.value;
      const valor = Number(strPtBrMoneyToNumber(newState.valor));
      const desconto = Number(strPtBrMoneyToNumber(newState.desconto));
      const n_sessoes = newState.n_sessoes;

      let total = (tipo_desconto === 1)
                        ? (valor * n_sessoes) - ((valor * n_sessoes) * (desconto/100))
                        : ((valor * n_sessoes) - desconto);
      total = Number(total).toFixed(1);
      newState.total = String(total+"0").replace('.',',');
    }

    return newState;
  }

  handleSubmit(e){
    this.create();
    e.preventDefault();
  }


  beforeSend(){
    const data = {...this.state};
    if(data.qnt_parcelas){
      data.qnt_parcelas = data.qnt_parcelas.value;
    }
    if(data.tipo_desconto){
      data.tipo_desconto = data.tipo_desconto.value;
    }
    if(data.tipo_pagamento){
      data.tipo_pagamento = data.tipo_pagamento.value;
    }
    if(data.desconto){
      data.desconto = strPtBrMoneyToNumber(String(this.state.desconto));
    }

    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }

    data.conta = this.props.conta;

    if (this.props.agendamento) {
      data.agendamento = this.props.agendamento;
    }

    delete data.parcelas;

    return data;
  }

  create(){
    const { token } = this.props;
    const self = this;
    const data = this.beforeSend();

    if(!data.valor || !data.tipo_pagamento){
      toastr.warning(Language.atencao, Language.preenchaValor);
      return;
    }

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.contaFinanceiro.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.createParcelas(data.data);
            self.onChangeFinanceiro(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  createParcelas(data){
    const { parcelas } = data;
    this.setState({
      parcelas
    });
  }

  openModal(modal, id) {
    this.setState({[modal]: true, idParcela: id});
  }

  afterOpenModal() {
  }

  closeModal(modal, response) {
    if(response && response.type==='update'){
      this.setState({parcelas: response.parcelas});
    }
    this.setState({[modal]: false});
  }

  renderRows() {
    const list = this.state.parcelas || []
    return list.map(bc => (
      <tr key={bc.id}>
        <td>{bc.parcela}</td>
        <td>{bc.vencimento}</td>
        <td>{bc.pago_em}</td>
        <td>{bc.valor.formated ? `R$ ${bc.valor.formated }`: "Grátis"}</td>
        <td>{bc.tipo_pagamento ? bc.tipo_pagamento.tipo : ''}</td>
        <td>{bc.status ? bc.status.tipo : ''}</td>
        <td className='table-actions'>
          <If test={!this.props.readOnly}>
            <button type="button" className='btn btn-warning' onClick={() => this.openModal('modalFinanceiro', bc.id)}>
                <i className='fa fa-edit'></i>
            </button>
            <button type="button" className='btn btn-danger' onClick={() => this.removeItem(bc.id)}>
                <i className='fa fa-trash-o'></i>
            </button>
          </If>
        </td>
      </tr>
    ));
  }

  removeItem(id){
    const { token } = this.props;
    const self = this;
    //se vier do overlay de fiannceiro do agendamento passamos o id do agendamento para computar no histório do agendamento
    const args = this.props.agendamento ? ('?agendamento='+this.props.agendamento) : '';

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.contaParcelas.delete.replace(/{id}/,id)}${args}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.onChangeFinanceiro(data.data);
            toastr.success(Language.atencao, data.msg);
            self.removeItemItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeItemItem(id){
    const list = this.state.parcelas || []

    const arr = list.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      parcelas: arr
    });
  }

  render(){
    const { readOnly } = this.props;
    let blockParcelas = false;

    //se for dinheiro ou transferência, bloqueamos as parcelas a 1 apenas
    if(this.state.tipo_pagamento && this.state.tipo_pagamento.maxParcelas){
      blockParcelas = true;
    }else{
      blockParcelas = readOnly;
    }

    return(
      <Box classes="box-success" title="Financeiro">
        <ModalFinanceiro
          id={this.state.idParcela}
          token={this.props.token}
          className='Modal_Financeiro'
          isOpen={this.state['modalFinanceiro']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={parcelas=>this.closeModal('modalFinanceiro', parcelas)}
        />
        <form onSubmit={this.handleSubmit}>
          <If test={!readOnly}>
            <LabelAndInput required name='valor'  type="text" value={this.state.valor}  handleChange={this.handleInputChange}
                label='Valor' cols='12 3' placeholder='' />
            <LabelAndSelect required name='tipo_pagamento' options={optionsTiposPagamento} value={this.state.tipo_pagamento} readOnly={readOnly} handleChange={this.handleSelect}
              label='Tipo de pagamento' cols='12 3' placeholder='Digite aqui' />
            <LabelAndSelect name='qnt_parcelas' options={parcelas}  readOnly={blockParcelas} value={this.state.qnt_parcelas} handleChange={this.handleSelect}
              label='Qnt. parcelas' cols='12 3' />
            {
              /*
              <LabelAndSelect name='tipo_desconto' options={options}  readOnly={readOnly} value={this.state.tipo_desconto} handleChange={this.handleSelect}
                label='Tipo de desconto' cols='12 2' placeholder='Digite aqui' />
              <LabelAndInput name='desconto' readOnly={readOnly} value={this.state.desconto}  handleChange={this.handleInputChange}
                  label='Desconto' cols='12 2' />
              */
            }
              <ButtonAddAjax type="submit" cols="1" icon="plus" onClick={this.handleSubmit} />
          </If>
        </form>
        <table className='table'>
            <thead>
                <tr>
                  <th className="th-parcela">Parcela</th>
                  <th>Vencimento</th>
                  <th>Pago em:</th>
                  <th>Valor</th>
                  <th>Tipo pagamento</th>
                  <th>Status</th>
                  <th className='table-actions-delete'></th>
                </tr>
            </thead>
            <tbody>
              {
                this.renderRows()
              }
            </tbody>
        </table>
      </Box>
    );
  }
}
