import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TitleModal from '../../common/form/titleModal';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import { strPtBrMoneyToNumber, maskMoney, maskNumber, maskPercent } from '../../config/methods';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import MandatoryFields from '../../common/template/mandatoryFields';
import ModalLoading from '../modals/modal-loading';
import ServicosAreas from '../venda-planos/servicosAreas';
import LabelAndText from '../../common/form/labelAndText';
import Box from '../../common/template/box';
import './modal-servico.scss';
import FichaCriolipolise from "../fichasAnamnese/FichaCriolipolise";
import FichaMicroagulhamento from "../fichasAnamnese/FichaMicroagulhamento";
import FichaJatoPlasma from "../fichasAnamnese/FichaJatoPlasma";
import ModalAssinatura from '../modals/modal-assinatura';
import LabelAndButton from '../../common/form/labelAndButton';
import If from '../../common/operator/if';
import Separador from '../../common/form/separador';
import Clearfix from '../../common/form/clearfix';

ReactModal.setAppElement('#root');

const options = CONSTS.TIPO_COMISSAO;
const optionsStatus = CONSTS.STATUS_SERVICO_VENDA;
const optionsTipo = CONSTS.TIPOS_VENDA_CORTESIA;

const INITIAL_STATE = {
  servico:'',
  cortesia:'',
  n_sessoes:'',
  valor:'',
  tipo_desconto: '',
  desconto:'',
  total: '',
  regioes: [],
  status: optionsStatus[0],
  assinatura:'',
}

class Modal extends Component{
  constructor(props){
    super(props);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.state = this.initalState();
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.callImprimirTermoCompromisso = this.callImprimirTermoCompromisso.bind(this);
    this.onSaveAssinatura = this.onSaveAssinatura.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openAssinatura = this.openAssinatura.bind(this);
  }

  closeModal(modal) {
    this.setState({[modal]: false, contentAlert:null, });
  }

  afterOpenModal() {

  }

  callImprimirTermoCompromisso(){
    const id = this.props.item;
    const url = `${api.API_URL}${api.API.vendasServicos.imprimirTermoCompromisso}?id=${id}&token=${this.props.token}`;
    window.open(url, '_blank', 'location=yes,height=500,width=1024,scrollbars=yes,status=yes');
  }

  onChangeSelect(val, name){
    this.setState({
      [name]: val
    });
  }

  handleSelect(item, name){
    this.handleInputChange({
      target: {value:item, name:name}
    });

    if(name === 'tipo_desconto' && item.value === ""){
      this.setState({desconto: ''});
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === "valor" || name === "desconto" || name === "tipo_desconto" || name === "n_sessoes"){
      this.checkValues(event);
    }else{
      const data = {[name]: value};
      if(name === 'cortesia'){
        data.valor = '';
        data.desconto = '';
        data.tipo_desconto = '';
        data.total = '';
      }
      this.setState(data);
    }
  }

  checkValues(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let convertedValue = value;
    let newState = {};

    switch (name) {
      case "valor":
        convertedValue = maskMoney(convertedValue);
        newState = { [name]: convertedValue }
        break;
        case "desconto":
          //se o tipo de comissão for 2, ou seja, dinheiro, convertemos o valor para dinheiro
          if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 2)){
            newState = { [name]: maskMoney(convertedValue)}
          } else if(this.state.tipo_desconto && (this.state.tipo_desconto.value === 1)){
            newState = { [name]: maskPercent(convertedValue)}
          }else{
            newState = { [name]: maskNumber(convertedValue)}
          }
          break;
      default:
        newState = {[name]: value};
    }

    newState = this.checkTotal(newState, this.state);

    this.setState(newState);
  }

  checkTotal(prevState, currentState){
    let newState = {...currentState, ...prevState};

    if(newState.n_sessoes && newState.valor){
      //se vier de um plano o numero de sessoes não importa, o que importa é o valor do plano
      newState.total = newState.plano ? strPtBrMoneyToNumber(newState.valor) : (strPtBrMoneyToNumber(newState.valor) * newState.n_sessoes);
      newState.total = Number(newState.total).toFixed(2);
      newState.total = String(newState.total).replace('.',',');
    }

    if(newState.n_sessoes && newState.tipo_desconto  && (newState.tipo_desconto.value !== '') && newState.desconto && newState.valor){
      const tipo_desconto = newState.tipo_desconto.value;
      const valor = Number(strPtBrMoneyToNumber(newState.valor));
      const desconto = Number(strPtBrMoneyToNumber(newState.desconto));
      //se tivermos um plano o número de sessões não importa, apenas importa o valor do plano
      //assim podemos adicionar um desconto que influirá apenas no valor total sem levar em consideração a quantidade de sessões
      const n_sessoes = newState.plano ? 1 : newState.n_sessoes;

      let total = (tipo_desconto === 1)
                        ? (valor * n_sessoes) - ((valor * n_sessoes) * (desconto/100))
                        : ((valor * n_sessoes) - desconto);
      total = Number(total).toFixed(1);
      newState.total = String(total+"0").replace('.',',');
    }

    return newState;
  }

  initalState(){
    return {
      ...INITIAL_STATE,
      loaded: false
    };
  }

  initData(response){
    response.request = {...response};

    if(response.tipo_desconto){
      response.tipo_desconto = options.filter( ( elem, i, array ) => {
          return Number(elem.value) === Number(response.tipo_desconto.id);
      });

      response.tipo_desconto = response.tipo_desconto ? response.tipo_desconto[0] : null;
    }

    if(response.servico){
      response.servico = {label: response.servico.servico, value: response.servico.id};
    }

    if(response.status){
      response.status = {label: response.status.tipo, value: response.status.id};
    }

    if(response.cortesia){
      response.cortesia = {label: response.cortesia.tipo, value: response.cortesia.id};
    }

    if(response.valor){
      response.valor = response.valor.formated;
    }

    if(response.total){
      response.total = response.total.formated;
    }

    response.loaded = true;

    for (let [key, value] of Object.entries(response.ficha_anamnese)) {
      response[key] = value;
    }

    this.setState({
      ...response
    });
  }

  convertToDataBaseReturnTipoComissao(tipo){
    //convertemos o tipo de comissão para a estrutura retornada pelo banco de dados
    //assim podemos atualizar o item na tabela dos serviços
    let option = options.filter( ( elem, i, array ) => {
        return Number(elem.value) === Number(tipo);
    });

    return option ? {value: option[0].label, id: option[0].value} : null;
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.item;

    if(!id)
    {
      this.setState({
        loaded: true,
        ...INITIAL_STATE
      });

      return false;
    }

    this.setState(this.initalState());

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendasServicos.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }

  handleSubmit(e){
    this.update();

    e.preventDefault();
  }

  beforeSend(){
    const data = {...this.state};

    if(data.tipo_desconto){
      data.tipo_desconto = data.tipo_desconto.value;
    }
    if(data.status){
      data.status = data.status.value;
    }
    if(data.cortesia){
      data.cortesia = data.cortesia.value;
    }
    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }
    if(data.desconto){
      data.desconto = strPtBrMoneyToNumber(String(this.state.desconto));
    }
    if(data.plano){
      data.plano = data.plano.value;
    }
    if(data.servico){
      data.servico = data.servico.value;
    }

    this.cleanPost(data);

    return data;
  }

  cleanPost(data){
    //delete data.servico;
    delete data.loaded;
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  onChangeServicos(data){
    this.props.onChangeServicos(data);
  }

  update(){
    const { token } = this.props;
    const self = this;
    const formData = this.beforeSend();
    const url = `${api.API_URL}${api.API.vendasServicos.update.replace(/{id}/,this.props.item)}`;

    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: url,
      data: formData,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.onChangeServicos(data);
            toastr.success(Language.atencao, data.msg);
            self.props.onRequestClose({servico: data.data, type: 'update'});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  getBoxes(){
    //se tiver plano o valor não pode ser mudado, para mudar um valor basta adiconar um desconto a ele
    const readOnly = this.state.plano;

    const layout = (
      <div>
        <LabelAndInput readOnly={readOnly} required classes="money-mask" name='valor'  type="text" value={this.state.valor}  handleChange={this.handleInputChange}
            label='Valor' cols='12 4' placeholder='' />
        <LabelAndSelect name='tipo_desconto' options={options} value={this.state.tipo_desconto}   handleChange={this.handleSelect}
          label='Tipo de desconto' cols='12 4' placeholder='Digite aqui' />
        <LabelAndInput name='desconto'  type="text" value={this.state.desconto}  handleChange={this.handleInputChange}
            label='Desconto' cols='12 4' placeholder='' />
        <LabelAndInput name='total'  value={this.state.total} readOnly handleChange={this.handleInputChange}
            label='Total do serviço' cols='12 4' placeholder='' />
      </div>
    );

    return layout;
  }

  getAreas(){
    const token= this.props.token;
    const { readOnly } = this.props;
    return <ServicosAreas
        readOnly={readOnly}
        key={Math.random()}
        areas={this.state.regioes}
        token={token}
        servico={this.props.item}
      />;
  }

  getFichaAnamnese(){
    if(!this.state.request.servico.termo_conscentimento) return false;
    //seleciona a ficha de anamnese de acordo com o termo de consentimento do serviço
    switch (this.state.request.servico.termo_conscentimento) {
      case CONSTS.TERMOS_CONSCENTIMENTO_LABEL.ficha_criolipolise:
        return <FichaCriolipolise data={this.state.request.ficha_anamnese} onChange={this.handleInputChange} />;
      case CONSTS.TERMOS_CONSCENTIMENTO_LABEL.ficha_microagulhamento:
        return <FichaMicroagulhamento data={this.state.request.ficha_anamnese} onChange={this.handleInputChange} />;
      case CONSTS.TERMOS_CONSCENTIMENTO_LABEL.ficha_jato_plasma:
        return <FichaJatoPlasma data={this.state.request.ficha_anamnese} onChange={this.handleInputChange} />;
      default:

    }
  }

  onSaveAssinatura(file){
    var data = new FormData();
    data.append('file', file);
    data.append('vendaServico', this.props.item);

    const token = this.props.token;
    const self = this;
    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.vendasServicos.saveAssinatura}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({ assinatura: data.file });
            toastr.success(Language.atencao, data.msg);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro03);
        }
      },
      error: response => {console.log(response)
        toastr.error(Language.erroTitle, Language.erro04);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  openAssinatura(e){
    this.setState({'modalAssinatura':true});
    e.preventDefault();
  }


  removerAssinatura(){

    const token = this.props.token;
    const self = this;
    const id = this.props.item;

    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.vendasServicos.removeAssinatura}`,
      data: {id},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.setState({assinatura: null});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  renderContent(){
    const url = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const areas = this.getAreas();
    const ficha_anamnese = this.getFichaAnamnese();
    const n_sessoesReadOnly = this.props.tipo === 'vendas_planos' ? this.state.plano : true;
    return(
      <form onSubmit={this.handleSubmit}>
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Serviço" />
            <div className="">
              <Box classes="box-success" title="Dados do serviço">
                {
                  this.state.request.servico.termo_compromisso ? (<button type="button" className="btn btn-block btn-success btn-v-p" onClick={this.callImprimirTermoCompromisso}>
                    <i className="fa fa-print"></i><span className="txt">Imprimir Termo de responsabilidade</span>
                  </button>) : null
                }
                <LabelAndSelectAjax
                  name='servico'
                  value={this.state.servico}
                  onChange={this.onChangeSelect}
                  url={url}
                  token={this.props.token}
                  label='Serviço'
                  cols='8'
                  placeholder='Digite aqui' />
                <LabelAndSelect name='status' options={optionsStatus} value={this.state.status}   handleChange={this.handleSelect}
                  label='Status' cols='12 4' placeholder='Digite aqui' />
                <LabelAndSelect name='cortesia' options={optionsTipo} value={this.state.cortesia} readOnly={this.state.plano} handleChange={this.handleSelect}
                  label='Parceria/Brinde/Cortesia/Outros' cols='12 5' placeholder='Digite aqui' />
                <LabelAndInput
                  required
                  name='n_sessoes'
                  type="number"
                  value={this.state.n_sessoes}
                  handleChange={this.handleInputChange}
                  label='Nº de sessões'
                  cols='12 4'
                  readOnly={n_sessoesReadOnly}
                  placeholder='' />
                  {
                    this.state.cortesia && this.state.cortesia.value !== ''
                    ? null
                    : this.getBoxes()
                  }
                  <LabelAndText name='observacoes_esteticista' handleChange={this.handleInputChange} value={this.state.observacoes_esteticista}
                    label='Observações para esteticista' cols='12' placeholder='Digite aqui' />
              </Box>

            </div>
            {areas}
            <Box classes="box-success" title="Ficha de Anamnese">
              {ficha_anamnese}
            </Box>

            <If test={this.state.assinatura}>
              <Clearfix />
              <Separador />
              <div className="assinatura-cliente">
                <div><strong>Assinatura do(a) cliente</strong></div>
                <span className="content-assinatura">
                  <img src={this.state.assinatura} alt="Assinatura cliente" />
                  <button onClick={()=>this.removerAssinatura()} type="button" class="btn btn-remover-assinatura btn-block btn-danger btn-xs">Remover</button>
                </span>
              </div>
            </If>
            <If test={!this.state.assinatura}>
              <LabelAndButton onClick={this.openAssinatura}
                  label='' labelButton="Adicionar assinatura" cols='12 3' />
            </If>

            <ModalAssinatura
              onSave={this.onSaveAssinatura}
              className='Modal_Assinatura'
              isOpen={this.state['modalAssinatura']}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={()=>this.closeModal('modalAssinatura')}
            />
            <MandatoryFields />
          </div>
          <button type="submit" className="btn btn-block btn-success">Salvar</button>
        </div>
      </form>
    )
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
        {
          this.state.loaded ?
          this.renderContent()
          : <LoadingPage />
        }
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    )
  }
}

const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Modal);
