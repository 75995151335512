import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import LabelAndDate from '../../../common/form/labelAndDatePicker';
import TitleModal from '../../../common/form/titleModal';
import {convertToBr} from '../../../utils/dateJS';
import Language from '../../../config/language';
import LabelAndSelectAjax from '../../../common/form/labelAndSelectAjax';
import api from '../../../api';

ReactModal.setAppElement('#root');

const INITIAL_STATE = {query:''};

class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      query: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  onChangeDate (date, item){
    this.setState({ [item] : date });
  }

  onAfterOpen(){
    this.setState(INITIAL_STATE);
    this.props.onAfterOpen();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  beforeSend(){
    const data = {...this.state};

    if(this.state.dti){
      data.dti = convertToBr(this.state.dti);
    }

    if(this.state.dtf){
      data.dtf = convertToBr(this.state.dtf);
    }

    if(data.profissional){
      data.profissional = data.profissional.value;
    }

    if(data.colaborador){
      data.colaborador = data.colaborador.value;
    }

    return data;
  }

  handleSubmit(e){
    e.preventDefault();

    if(!this.state.dti && !this.state.dtf){
      toastr.warning(Language.atencao, Language.escolhaFiltroDataAll);
      return false;
    }
    const data = this.beforeSend();
    data.maxResults = -1;

    const query = { ...data};

    this.props.onSearch({query: query});
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    const urlVendedor = `${api.API_URL}${api.API.usuarios.autocomplete}`;

    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <form onSubmit={this.handleSubmit}>
              <div className='box-modal-visualizacao__wrapper'>
                <TitleModal title="Buscar" />
                <LabelAndDate required  name='dti' value={this.state.dti}   onChange={date => this.onChangeDate(date, 'dti')}
                    label='Data inicial' cols='12 6' placeholder='Digite aqui' date={this.state.dti} />
                <LabelAndDate required  name='dtf' value={this.state.dtf}   onChange={date => this.onChangeDate(date, 'dtf')}
                    label='Data final' cols='12 6' placeholder='Digite aqui' date={this.state.dtf} />

                <LabelAndSelectAjax
                  name='profissional'
                  value={this.state.profissional}
                  onChange={this.handleSelect}
                  url={urlVendedor}
                  token={this.props.user}
                  label='Vincular conta a um profissional'
                  cols='12'
                  query={{tipo:2}}
                  placeholder='Digite aqui' />

                <LabelAndSelectAjax
                  name='colaborador'
                  value={this.state.colaborador}
                  onChange={this.handleSelect}
                  url={urlVendedor}
                  token={this.props.user}
                  label='Vincular conta a um colaborador'
                  cols='12'
                  query={{tipo:1}}
                  placeholder='Digite aqui' />


              </div>
              <button type="submit" className="btn btn-block btn-success">Buscar</button>
            </form>
          </div>
        </ReactModal>
      </div>
    )
  }
}


const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Modal);
