import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import Box from '../../common/template/box';
import BoxInputFull from '../../common/layout/boxInputFull';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';

export default class ServicosAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: props.areas
    }
    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  onChangeSelect(val, name){
    const { token } = this.props;
    const self = this;
    const data = {id_servico: this.props.servico, id_regiao: val.value};

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.servicosRegioes.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.addArea(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  addArea(item){
    const areas = [...this.state.areas,item];

    this.setState({areas, area: null});
  }

  removeArea(id){
    const { token } = this.props;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.servicosRegioes.delete.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.removeAreaItem(id);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      }
    });
  }

  removeAreaItem(id){
    const arr = this.state.areas.filter((value, index, arr) => {
      return value.id !== id;
    });
    this.setState({
      areas: arr
    });
  }

  render() {
    const urlFornecedor = `${api.API_URL}${api.API.areasCorpo.autocomplete}`;

    return(
      <div id="content-ajax">
      <Box classes="box-success" title="Áreas/Regiões do corpo">
        <BoxInputFull>
          <LabelAndSelectAjax
            readOnly={this.props.readOnly}
            name='area'
            value={this.state.area}
            onChange={this.onChangeSelect}
            url={urlFornecedor}
            token={this.props.token}
            label='Escolha a área'
            cols='8'
            placeholder='Digite aqui' />
          <table className='table'>
              <thead>
                <tr>
                  <th>Área/Região</th>
                  {
                    this.props.readOnly ? null : <th className='table-actions-single'></th>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  this.state.areas.map(item=>
                {
                  return   <tr key={item.id}>
                        <td>{item.regiao.nome}</td>
                        {
                          this.props.readOnly ? null :
                          <td>
                            <button type="button" className='btn btn-danger' onClick={() => this.removeArea(item.id)}>
                                <i className='fa fa-trash-o'></i>
                            </button>
                          </td>
                        }
                    </tr>;
                })
                }
              </tbody>
          </table>
        </BoxInputFull>
      </Box>
      </div>

    );
  }
}
