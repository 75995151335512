import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import LabelAndInput from '../../common/form/labelAndInput';
import TitleModal from '../../common/form/titleModal';

import Language from '../../config/language';
ReactModal.setAppElement('#root');

const INITIAL_STATE = {query:''};

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      query: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onAfterOpen(){
    this.setState(INITIAL_STATE);
    this.props.onAfterOpen();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  beforeSend(){
    const data = {...this.state};

    return data;
  }

  handleSubmit(e){
    e.preventDefault();


    if(!this.state.query){
      toastr.warning(Language.atencao, Language.searchEmpty);
      return false;
    }

    const query = {servico:this.state.query, maxResults: -1};

    this.props.onSearch({query: query});
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    return(
      <div>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
          <div className='box-modal-visualizacao'>
            <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
            <form onSubmit={this.handleSubmit}>
              <div className='box-modal-visualizacao__wrapper'>
                <TitleModal title="Buscar" />
                <LabelAndInput name='query'  type="text" value={this.state.query}  handleChange={this.handleInputChange}
                    label='Serviço' cols='12' placeholder='' />
              </div>
              <button type="submit" className="btn btn-block btn-success">Buscar</button>
            </form>
          </div>
        </ReactModal>
      </div>
    )
  }
}
