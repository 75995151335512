import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react";//https://xdsoft.net/jodit/play.html
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndText from '../../common/form/labelAndText';
import BoxInputFull from '../../common/layout/boxInputFull';
import LabelAndSelect from '../../common/form/labelAndSelect';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { strPtBrMoneyToNumber, maskMoney, maskNumber, maskPercent } from '../../config/methods';
import MandatoryFields from '../../common/template/mandatoryFields';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';
import CONSTS from '../../config/consts';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalDelete from '../modals/modal-delete';
import ModalLoading from '../modals/modal-loading';
import GroupCheckbox from '../../common/form/groupCheckbox';
import EstoqueItems from './estoque-items';
const optionsRetorno = CONSTS.SIM_NAO;
const optionsTipoComissao = CONSTS.TIPO_COMISSAO;
const optionsTempoAtendimento = CONSTS.TEMPO_ATENDIMENTO;
const optionsTermoConscentimento = CONSTS.TERMOS_CONSCENTIMENTO;

const INITIAL_STATE = {
  servico: '',
  valor: '',
  custo: '',
  tempo: '',
  retorno: '',
  dias_retorno: '',
  tipo_comissao: optionsTipoComissao[1],
  comissao: '',
  observacoes: '',
  regioes: [],
  salas: [],
  salasOptions: [],
  equipamentos: [],
  equipamentosOptions: [],
  estoque: []
}

const CONFIG = {
  buttons:"bold,underline,italic,|,|,ul,ol,|,fontsize",
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  readonly: false
};

class Form extends Component {
  constructor(props){
    super(props);

    this.state = {
      ...INITIAL_STATE,
      errorLoadingPage: false,
      loaded: false,
      termo_compromisso: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initCheckboxes = this.initCheckboxes.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }else{
      this.loadPage();
    }
  }

  loadPage(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.servicos.loadPage}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initCheckboxes(data.salas, 'salas');
            self.initCheckboxes(data.equipamentos, 'equipamentos');
            
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  initCheckboxes(data, type){
    let dados = [];

    for (let i = 0; i < data.length; i++) {
      dados.push({ label: data[i]["nome"], id: Number(data[i]["id"]), cols: "12 3", value: data[i]["value"]});
    }

    const state = {
      [type]: dados,
      [`${type}Options`]: dados,
      loaded: true
    };

    this.setState(state);
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name==='valor' || name === 'custo'){
      value = maskMoney(value);
    }

    if(name === 'comissao'){
      //se o tipo de comissão for 2, ou seja, dinheiro, convertemos o valor para dinheiro
      if(this.state.tipo_comissao && (this.state.tipo_comissao.value === 2)){
        value = maskMoney(value);
      } else if(this.state.tipo_comissao && (this.state.tipo_comissao.value === 1)){
        value = maskPercent(value);
      }else{
        value = maskNumber(value);
      }
    }

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.update();
        break;
      default:
        this.create();
    }

    e.preventDefault();
  }

  deleteItem(){
    const token = this.props.user;
    const self = this;
    this.callLoading(true);
    self.removeModalDeleteItem();

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'delete',
      url: `${api.API_URL}${api.API.servicos.delete.replace(/{id}/,this.props.detail.id)}`,
      data: {nome:self.state.nome},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {

        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        this.callLoading(false);
      }
    });
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  beforeSend(){
    const data = {...this.state};
    delete data.salasOptions;
    delete data.equipamentosOptions;

    if(data.retorno){
      data.retorno = data.retorno.value;
    }

    if(data.tipo_comissao){
      data.tipo_comissao = data.tipo_comissao.value;
    }

    if(data.termo_conscentimento){
      data.termo_conscentimento = data.termo_conscentimento.value;
    }

    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }

    if(data.comissao){
      data.comissao = strPtBrMoneyToNumber(String(this.state.comissao));
    }

    if(data.custo){
      data.custo = strPtBrMoneyToNumber(String(this.state.custo));
    }

    if(this.state.tempo){
      data.tempo = data.tempo.value;
    }

    return data;
  }

  updateContent(value) {
    this.setState({termo_compromisso:value})
  }

  create(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.servicos.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
            //self.props.keepCreating(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        this.callLoading(false);
      }
    });
  }

  update(){
    const token = this.props.user;
    const self = this;
    const data = this.beforeSend();
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.servicos.update.replace(/{id}/,this.props.detail.id)}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          self.loaded();
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        this.callLoading(false);
      }
    });
  }

  initData(response){
    if(response.retorno){
      response.retorno = optionsRetorno.filter( ( elem, i, array ) => {
          return elem.value === Number(response.retorno);
      });
    }
    if(response.tipo_comissao){
      response.tipo_comissao = {label: response.tipo_comissao.value, value: response.tipo_comissao.id};
    }

    if(response.valor){
      response.valor = response.valor.formated;
    }

    if(response.custo){
      response.custo = response.custo.formated;
    }

    if(response.comissao){
      response.comissao = response.comissao.formated;
    }
    if(response.tempo){
      const tA = CONSTS.TEMPO_ATENDIMENTO.filter( ( elem, i, array ) => {
          return String(elem.value) === String(response.tempo);
      });
      if(tA.length){
        response.tempo = tA[0];
      }
    }

    if(response.termo_conscentimento){
      const tA = CONSTS.TERMOS_CONSCENTIMENTO.filter( ( elem, i, array ) => {
          return String(elem.value) === String(response.termo_conscentimento);
      });
      if(tA.length){
        response.termo_conscentimento = tA[0];
      }
    }

    this.setState({
      ...response
    });

    /*if(this.props.detail.statusCreated){
      $('html,body').animate({
        scrollTop: $("#content-ajax").offset().top},
        'slow');
    }*/
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.servicos.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            const response = data.data;
            self.initCheckboxes(data.salas, 'salas');
            self.initCheckboxes(data.equipamentos, 'equipamentos');
            self.setState({estoque: data.estoque});
            self.initData(response);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      },
      complete: response =>{
        self.loaded();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  boxTipoComissao(){
    const { readOnly } = this.props;

    const input = <LabelAndInput type="number" name='comissao'  readOnly={readOnly} handleChange={this.handleInputChange}
        label='Comissão' cols='12 2' placeholder='0' value={this.state.comissao} />;
    const maskedInput = <LabelAndMaskInput thousandSeparator={true} name='comissao' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
        label='Comissão' cols='12 2' value={this.state.comissao} placeholder='Digite aqui' />;

    if(this.state.tipo_comissao){
      return this.state.tipo_comissao.value === 1 ? input : maskedInput;
    }else{
      return input;
    }
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  changeCheckbox(event, type) {
    const target = event.target;
    const name = target.name;
    const value = target.checked;
    let array = [...this.state[type]];

    for (var j = 0; j < array.length; j++) {
      const el = array[j];
      if(Number(name) === Number(el.id)){
        el.value = value;
      }
    }

    this.setState({
      [type] : array
    });
  }

  getEstoque(){
    console.log(this.state)
    const token= this.props.user;
    const { readOnly } = this.props;
    return <EstoqueItems
      readOnly={readOnly}
      key={Math.random()}
      estoque={this.state.estoque}
      token={token}
      servicoId={this.props.detail.id}
    />;
  }

  renderContent() {
      const { readOnly } = this.props;

      return (
        <div>
          <form onSubmit={this.handleSubmit}>
              <div className='box-body'>
                <Box classes="box-success" title="Geral">
                  <BoxInputFull>
                    <LabelAndInput name='servico' required  readOnly={readOnly} handleChange={this.handleInputChange}
                        label='Nome*' cols='12 9' placeholder='Informe o nome' value={this.state.servico} />
                  </BoxInputFull>
                  <LabelAndInput name='valor' required  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Valor*' cols='12 4' placeholder='Informe o valor' value={this.state.valor} />
                  <LabelAndInput name='custo'  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Custo' cols='12 4' placeholder='Informe o custo' value={this.state.custo} />
                  <LabelAndSelect name='tempo' required options={optionsTempoAtendimento} readOnly={readOnly} handleChange={this.handleSelect}
                    label='Tempo de atendimento*' cols='12 4' placeholder='Digite aqui' value={this.state.tempo} />
                  <LabelAndSelect handleChange={this.handleSelect} name='retorno' options={optionsRetorno}  readOnly={readOnly}
                      label='Retorno' cols='12 2' placeholder='Digite aqui' value={this.state.retorno} />
                  <LabelAndInput name='dias_retorno' type="number"  readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Dias de retorno' cols='12 2' placeholder='Informe a quant.' value={this.state.dias_retorno} />
                  {/*
                    <LabelAndSelect label='year' options={optionsTipo}  readOnly={readOnly}
                        label='Tipo de serviço' cols='12 4' placeholder='Digite aqui' />
                  */}
                  <LabelAndSelect handleChange={this.handleSelect} name='tipo_comissao' options={optionsTipoComissao}  readOnly={readOnly}
                      label='Tipo de comissão' cols='12 2' placeholder='Digite aqui' value={this.state.tipo_comissao} />
                  <LabelAndInput name='comissao'  readOnly={readOnly ? readOnly : (this.state.tipo_comissao && this.state.tipo_comissao.value !== '') ? false : true} handleChange={this.handleInputChange}
                      label='Comissão' cols='12 2' placeholder='0' value={this.state.comissao} />

                  <LabelAndSelect handleChange={this.handleSelect} name='termo_conscentimento' options={optionsTermoConscentimento}  readOnly={readOnly}
                      label='Termo conscentimento' cols='12 4' placeholder='Digite aqui' value={this.state.termo_conscentimento} />

                      <Clearfix />
                  <LabelAndText name='observacoes'  type='number' readOnly={readOnly} handleChange={this.handleInputChange}
                      label='Descrição' cols='12 6' placeholder='Digite aqui' value={this.state.observacoes} />
                  <Clearfix />
                  <div className="form-group">
                    <label>Termo de compromisso</label>
                  </div>
                  <JoditEditor
                    value={this.state.termo_compromisso}
                    config = {CONFIG}
                    onChange={this.updateContent}
                  />
                  <br /><br />
                  <Box classes="box-danger" title="Salas">
                    <div class="box-check-itens">
                      <GroupCheckbox
                        onChange={(event)=>this.changeCheckbox(event, "salas")}
                        value={this.state.salas}
                        options={this.state.salasOptions}
                        label=""
                        cols="12"
                        />
                    </div>
                  </Box>
                  <Box classes="box-danger" title="Equipamentos">
                    <div class="box-check-itens">
                      <GroupCheckbox
                      onChange={(event)=>this.changeCheckbox(event, "equipamentos")}
                        value={this.state.equipamentos}
                        options={this.state.equipamentosOptions}
                        label=""
                        cols="12"
                        />
                    </div>
                  </Box>
                  {this.props.detail ? this.getEstoque() : null}
                  <MandatoryFields />
                  <div className='box-footer'>
                      <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                          {this.props.submitLabel}
                      </button>
                      <button type='button' className='btn btn-default'
                          onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
                  </div>
                </Box>
              </div>
          </form>
        </div>
      )
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  render() {
    const tempBox = this.state.loaded
                  ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                  : null;

      return (
          <div>
            <ModalDelete
              onCancel={ () => this.deleteItem() }
              className='Modal_Delete'
              isOpen={this.state['ModalDeleteItem']}
              onRequestClose={()=>this.removeModalDeleteItem()}
            />
            <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
            {
              this.state.loaded ?
              tempBox
              : <LoadingPage />
            }
          </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
