import React, { Component } from 'react';
import ReactModal from 'react-modal';
import TitleModal from '../../common/form/titleModal';
import BoxFinanceiro from '../venda-planos/box-financeiro';
import LoadingPage from '../../common/template/loadingPage';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';


import './modal-financceiro-agendamento.scss';

ReactModal.setAppElement('#root');

export default class Modal extends Component{
  constructor(props){
    super(props);

    this.state = {
      financeiro: [],
      box: null
    };

    this.onRequestClose = this.onRequestClose.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.onChangeFinanceiro = this.onChangeFinanceiro.bind(this);
  }

  onChangeFinanceiro(data){

  }

  onRequestClose(){
    this.setState({box: null});
    this.props.onRequestClose();
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    
    this.setState({box:'', loaded: false});

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.vendasServicos.vendaServico}?id=${this.props.venda.value}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      },
      complete: () =>{
      }
    });
  }

  initData(data){
    this.setState({...data, box: this.getBox(), loaded: true});
  }

  getBox(){

    return (
      <div>
        {
          this.state.valor && this.state.servico ?
          <div className="box-servico-valor">{this.state.servico} : R$ {this.state.valor.formated}</div>
          : null
        }
        <div className="n-venda-financeiro-servico">Venda nº {this.props.venda.value}</div>
        {/* <div className="bg-green color-palette msg-financeiro-servico">Caso seja necessário dar um desconto ao cliente, faça uma busca pelo id da venda e adicione o desconto ao serviço.</div> */}
        {this.state.financeiro && 
        this.props.token &&
        this.props.venda.value &&
        this.props.agendamento &&
        this.props.idServico &&
        <BoxFinanceiro
          onChangeFinanceiro={this.onChangeFinanceiro}
          financeiro={this.state.financeiro}
          token={this.props.token}
          venda={this.props.venda.value}
          agendamento={this.props.agendamento}
          showDesconto={true}
          idServico={this.props.idServico}
          openedByAgendamento={true}
          servico={{servico: this.state?.servico || '', valor: this.state?.valor || ''}}
          />}
      </div>
    );
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    return(
      <ReactModal
        portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.onAfterOpen}
        onRequestClose={this.onRequestClose}
        style={customStyles}
      >
        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title={this.props.title} />
            <div className="box-body">
            {
              this.state.loaded ?
              this.getBox()
              : <LoadingPage />
            }
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}
