import React, {Component} from 'react';
import './pasta-foto.scss';

export default class PastaFotos extends Component {
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e){
    this.props.onClick(this.props.data.id);
    e.preventDefault();
  }

  render(){
    return(
      <a href="/" onClick={this.onClick} className="box-pasta-foto"><i className="fa fa-folder"></i> <span className="txt-folder-name">{this.props.data.nome}</span></a>
    );
  }
}
