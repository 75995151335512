import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import { maskMoney, strPtBrMoneyToNumber } from '../../config/methods';
import TitleModal from '../../common/form/titleModal';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndSelect from '../../common/form/labelAndSelect';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import LabelAndText from '../../common/form/labelAndText';
import LabelAndMaskInput from '../../common/form/labelAndMaskInput';
import './modal-historico-patrimonio.scss';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import { convertToBr, brToDate } from '../../utils/dateJS';
import LoadingPage from '../../common/template/loadingPage';
import MandatoryFields from '../../common/template/mandatoryFields';
import ModalLoading from '../modals/modal-loading';

ReactModal.setAppElement('#root');

const optionsTiposPagamento = CONSTS.METODOS_PAGAMENTO;

const INITIAL_STATE = {
  fornecedor:'',
  valor: '',
  n_os:'',
  data_envio: '',
  data_retorno: '',
  forma_pagamento: '',
  forma_envio: '',
  relatorio: '',
  observacoes: '',
  motivo: '',
  id_patrimonio: ''
}

export default class Modal extends Component{
  constructor(props){
    super(props);

    this.state = this.initalState();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
  }

  initData(response){

    if(response.data_retorno){
      response.data_retorno = brToDate(response.data_retorno);
    }
    if(response.data_envio){
      response.data_envio = brToDate(response.data_envio);
    }
    if(response.forma_pagamento){
      response.forma_pagamento = optionsTiposPagamento.filter( ( elem, i, array ) => {
          return elem.value === response.forma_pagamento;
      });
    }
    if(response.valor){
      response.valor = response.valor.formated;
    }
    if(response.fornecedor){
      response.fornecedor = {label: response.fornecedor.nome, value: response.fornecedor.id};
    }
    response.loaded = true;

    this.setState({
      ...response
    });
  }

  initalState(){
    return {
      ...INITIAL_STATE,
      loaded: false
    };
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.item;

    if(!id)
    {
      this.setState({
        loaded: true,
        ...INITIAL_STATE
      });

      return false;
    }

    this.setState(this.initalState());

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.patrimonioHistorico.read.replace(/{id}/,id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name==='valor'){
      value = maskMoney(value);
    }

    this.setState({
      [name]: value
    });
  }

  onChange = (date, name) => this.setState({ [name]: date });

  onChangeSelect(val, name){
    this.setState({
      [name]: val
    });
  }

  handleSubmit(e){
    if (this.props.item) {
      this.update();
    }else{
      this.save();
    }

    e.preventDefault();
  }

  beforeSend(){
    const data = {...this.state};

    if(data.data_envio){
      data.data_envio = convertToBr(data.data_envio);
    }
    if(data.data_retorno){
      data.data_retorno = convertToBr(data.data_retorno);
    }
    if(data.fornecedor){
      data.fornecedor = data.fornecedor.value;
    }
    if(data.forma_pagamento){
      data.forma_pagamento = data.forma_pagamento.value;
    }
    if(data.valor){
      data.valor = strPtBrMoneyToNumber(String(this.state.valor));
    }

    data.id_patrimonio = this.props.patrimonio;

    return data;
  }


  update(){
    const { token } = this.props;
    const self = this;
    const formData = this.beforeSend();
    const url = `${api.API_URL}${api.API.patrimonioHistorico.update.replace(/{id}/,this.props.item)}`;
    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: url,
      data: formData,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.onRequestClose({historico: data.data, type: 'update'});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.loaded();
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  save(){
    const { token } = this.props;
    const self = this;
    const data = this.beforeSend();
    self.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.patrimonioHistorico.create}`,
      data: data,
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.onRequestClose({historico: data.data});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response =>{
        self.callLoading(false);
      }
    });
  }

  renderContent(){
    const readOnly = false;
    const urlFornecedor = `${api.API_URL}${api.API.fornecedores.autocomplete}`;

    return (
      <div className='box-modal-visualizacao'>
        <form onSubmit={this.handleSubmit}>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Histórico" />
            <div className="box-body">
              <LabelAndInput name='motivo' value={this.state.motivo}  readOnly={readOnly} handleChange={this.handleInputChange}
                label='Motivo*' cols='12' placeholder='' />
              <LabelAndSelectAjax name='fornecedor' value={this.state.fornecedor} onChange={this.onChangeSelect} url={urlFornecedor} token={this.props.token}
                label='Fornecedor' cols='8' placeholder='Digite aqui' />
              <LabelAndMaskInput thousandSeparator={true} name='valor' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                  label='Valor da manutenção' cols='12 4' value={this.state.valor} placeholder='Digite aqui' />
              <LabelAndInput name='n_os' value={this.state.n_os}  readOnly={readOnly} handleChange={this.handleInputChange}
                  label='Nº Ordem de serviço' cols='12 4' placeholder='' />
              <LabelAndDate name='data_envio' onChange={date => this.onChange(date,'data_envio' )}
                  label='Data envio' cols='12 4' placeholder='Digite aqui' date={this.state.data_envio} />
              <LabelAndDate name='data_retorno' onChange={date => this.onChange(date, 'data_retorno')}
                  label='Data retorno' cols='12 4' placeholder='Digite aqui' date={this.state.data_retorno} />
              <LabelAndSelect handleChange={this.onChangeSelect} value={this.state.forma_pagamento}  name='forma_pagamento' options={optionsTiposPagamento}  readOnly={readOnly}
                label='Forma de pagamento' cols='12 6' placeholder='Digite aqui' />
              <LabelAndInput name='forma_envio' value={this.state.forma_envio} handleChange={this.handleInputChange}
                  label='Forma de envio' cols='12 6' placeholder='' />
              <LabelAndText name='relatorio' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                  label='Relatório da manutenção' cols='12' value={this.state.relatorio} placeholder='Digite aqui' />
              <LabelAndText name='observacoes' type='text' readOnly={readOnly} handleChange={this.handleInputChange}
                  label='Observações' cols='12' value={this.state.observacoes} placeholder='Digite aqui' />

              <MandatoryFields />
            </div>
          </div>
          <button type="submit" className="btn btn-block btn-success">Salvar</button>
        </form>
      </div>
    );
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <Fragment>
        <ReactModal
          portalClassName={`${this.props.className || ''} ReactModalPortal`}
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          onRequestClose={this.props.onRequestClose}
          style={customStyles}
        >
        {
          this.state.loaded ?
          this.renderContent()
          : <LoadingPage />
        }
        </ReactModal>
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

      </Fragment>
    )
  }
}
