import React from 'react';
import Grid from '../layout/grid';
import './labelAndText.scss';

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
            <label htmlFor={props.name}>{props.label}</label>
            <textarea {...props.input} className='form-control'
                name={props.name}
                 placeholder={props.placeholder}
                 value={props.value ? props.value : ""}
                 onChange={props.handleChange}
                 readOnly={props.readOnly} type={props.type}></textarea>
        </div>
    </Grid>
)
