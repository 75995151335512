import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import LoadingPage from '../../common/template/loadingPage';
import ErrorPage from '../../common/template/errorPage';

ReactModal.setAppElement('#root');

const INITIAL_STATE = {
  loaded: false,
  ativo: null,
  itens: []
};

class Modal extends Component{
  constructor(props){
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount(){
    if(this.props.dados && this.props.dados.id){
      this.loadPage();
    }else{
      this.setState({loaded: true});
    }
  }

  loadPage(){
    const { token } = this.props;
    const self = this;
    const id = this.props.dados.id;
    const query = `?id=${id}`;

    this.setState(INITIAL_STATE);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.historicoAgendamento.list}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({itens: data.data});
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.setErrorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.setErrorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.setErrorLoadingPage();
      },
      complete: response => {
        self.loaded();
      }
    });
  }

  setErrorLoadingPage(){
    this.setState({
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  renderContent(){
    return (
      <div className='box-modal-visualizacao'>
      <Fragment>
        {
          this.state.itens.length ? this.state.itens.map((v,i)=>
            <div key={i} className="item-historico-agendamento">
              <span className="i-h-a__date">{v.data}</span>
              <span className="i-h-a__profissional text-green">{v.usuario} - </span>
              <span className="i-h-a__profissional text-green">{v.titulo}</span>
              <strong className="i-h-a__text">{
                v.mensagem
                ? v.mensagem.split(';').map((v,i)=> <p className="line-hist">{v}</p>)
                : ''
              }</strong>
            </div>) : <span style={{padding: 60, display: 'block', textAlign: 'center'}}>Nenhum dado encontrado</span>
        }
      </Fragment>
      </div>
    );
  }

  render(){
    const tempBox = this.state.loaded
    ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
    : <LoadingPage />;
    return (
        <div>
          {
            tempBox
          }
        </div>
    )
  }
}

const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Modal);
