import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactModal from 'react-modal';
import TitleModal from '../../common/form/titleModal';
import LoadingPage from '../../common/template/loadingPage';
import api from '../../api';
import Requisition from '../../utils/requisitions/requisition';
import CONSTS from '../../config/consts';
import Language from '../../config/language';
import If from '../../common/operator/if';

ReactModal.setAppElement('#root');

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {};

    this.onAfterOpen = this.onAfterOpen.bind(this);
  }

  onAfterOpen(){
    const { token } = this.props;
    const self = this;
    const id = this.props.agendamento;

    this.setState({agendamento:'', venda:'', cliente: '', loaded: false});

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.agendamentos.info}?id=${id}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.initData(data.data);
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.props.onRequestClose();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.props.onRequestClose();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.props.onRequestClose();
      }
    });
  }

  initData(data){
    const newData = { loaded: true ,...data};
    this.setState(newData);
  }

  getAgendamento(){
    return (
      <div className="box box-danger">
        <div className="box-header with-border">
          <h3 className="box-title">Dados do agendamento</h3>
        </div>
        <div className="box-body">
          <div className="row">
            <div className="col-xs-12">
              <If test={this.state.agendamento.id}>
                <p><strong>Id: </strong>{this.state.agendamento.id}</p>
              </If>
              <If test={this.state.agendamento.profissional}>
                <p><strong>Profissional: </strong>{this.state.agendamento.profissional ? this.state.agendamento.profissional.label : null}</p>
              </If>
              <If test={this.state.agendamento.data}>
                <p><strong>Data: </strong>{this.state.agendamento.data}</p>
              </If>
              <If test={this.state.agendamento.hora_inicial}>
                <p><strong>Hora inicial: </strong>{this.state.agendamento.hora_inicial}</p>
              </If>
              <If test={this.state.agendamento.hora_inicial}>
                <p><strong>Hora final: </strong>{this.state.agendamento.hora_final}</p>
              </If>
              <If test={this.state.agendamento.tempo_atendimento}>
                <p><strong>Tempo de atendimento: </strong>{this.state.agendamento.tempo_atendimento}</p>
              </If>
              <If test={this.state.agendamento.servico}>
                <p><strong>Serviço: </strong>{this.state.agendamento.servico ? this.state.agendamento.servico.label : null}</p>
              </If>
              <If test={this.state.agendamento.status}>
                <p><strong>Status: </strong>{this.state.agendamento.status ? this.state.agendamento.status.tipo : null}</p>
              </If>
              <If test={this.state.agendamento.status}>
                <p><strong>Tipo: </strong>{this.state.agendamento.modo_agendamento.tipo}</p>
              </If>
              <If test={this.state.agendamento.brinde}>
                <p><strong>Brinde: </strong>{this.state.agendamento.brinde.tipo ? this.state.agendamento.brinde.tipo : ''}</p>
              </If>
              <If test={this.state.agendamento.sala}>
                <p><strong>Sala: </strong>{this.state.agendamento.sala ? this.state.agendamento.sala.label : null}</p>
              </If>
              <If test={this.state.agendamento.equipamento}>
                <p><strong>Equipamento: </strong>{this.state.agendamento.equipamento ? this.state.agendamento.equipamento.label : null}</p>
              </If>
              <If test={this.state.agendamento.venda}>
                <p><strong>Venda: </strong>{this.state.agendamento.venda ? this.state.agendamento.venda.label : null}</p>
              </If>
              <If test={this.state.agendamento.brinde_outros}>
                <p><strong>Outros brindes: </strong>{this.state.agendamento.brinde_outros}</p>
              </If>
              <If test={this.state.agendamento.observacoes}>
                <p><strong>Observações: </strong>{this.state.agendamento.observacoes}</p>
              </If>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCliente(){
    return (
      <div className="box box-danger">
        <div className="box-header with-border">
          <h3 className="box-title">Dados do cliente</h3>
        </div>
        <div className="box-body">
          <div className="row">
            <div className="col-xs-12">
              <If test={this.state.cliente.nome}>
                <p><strong>Nome: </strong>{this.state.cliente.nome} {this.state.cliente.sobrenome ? this.state.cliente.sobrenome : ''}</p>
              </If>
              <If test={this.state.cliente.data_nascimento}>
                <p><strong>Data nascimento: </strong>{this.state.cliente.data_nascimento} </p>
              </If>
              <If test={this.state.cliente.sexo}>
                <p><strong>Sexo: </strong>{this.state.cliente.sexo.tipo} </p>
              </If>
              <If test={this.state.cliente.cpf_cnpj}>
                <p><strong>Cpf/Cnpj: </strong>{this.state.cliente.cpf_cnpj} </p>
              </If>
              <If test={this.state.cliente.uf}>
                <p><strong>UF: </strong>{this.state.cliente.uf} </p>
              </If>
              <If test={this.state.cliente.cidade}>
                <p><strong>Cidade: </strong>{this.state.cliente.cidade} </p>
              </If>
              <If test={this.state.cliente.bairro}>
                <p><strong>Bairro: </strong>{this.state.cliente.bairro} </p>
              </If>
              <If test={this.state.cliente.endereco}>
                <p><strong>Endereço: </strong>{this.state.cliente.endereco} </p>
              </If>
              <If test={this.state.cliente.email}>
                <p><strong>E-mail: </strong>{this.state.cliente.email} </p>
              </If>
              <If test={this.state.cliente.telefone}>
                <p><strong>Telefone: </strong>{this.state.cliente.telefone} </p>
              </If>
              <If test={this.state.cliente.whatsapp}>
                <p><strong>Whatsapp: </strong>{this.state.cliente.whatsapp} </p>
              </If>
              <If test={this.state.cliente.nome_emergencia}>
                <p><strong>Nome emergência: </strong>{this.state.cliente.nome_emergencia} </p>
              </If>
              <If test={this.state.cliente.telefone_emergencia}>
                <p><strong>Tel. emergência: </strong>{this.state.cliente.telefone_emergencia} </p>
              </If>
              <If test={this.state.cliente.celular_emergencia}>
                <p><strong>Cel. emergência: </strong>{this.state.cliente.celular_emergencia} </p>
              </If>

            </div>
          </div>
        </div>
      </div>
    );
  }

  getVenda(){

    return (
      <div className="box box-danger">
        <div className="box-header with-border">
          <h3 className="box-title">Dados da venda</h3>
        </div>
        <div className="box-body">
          <div className="row">
            <div className="col-xs-12">
              {
                this.state.venda.tipo_venda ? <p><span className="label label-danger">{this.state.venda.tipo_venda.label}</span></p> : null
              }
              <If test={this.state.venda.id}>
                <p><strong>Id: </strong>{this.state.venda.id}</p>
              </If>
              <If test={this.state.venda.status}>
                <p><strong>Status: </strong>{this.state.venda.status.label}</p>
              </If>
              <If test={this.state.venda.data_venda}>
                <p><strong>Data da venda: </strong>{this.state.venda.data_venda}</p>
              </If>

              <If test={this.state.venda.vendedor}>
                <p><strong>Vendedor: </strong>{this.state.venda.vendedor.label}</p>
              </If>

              <If test={this.state.venda.servicos}>
                <p><strong>Serviços: </strong></p>
                {
                  this.state.venda.servicos.map((v,i)=><div>
                    <p>{v.servico.servico}</p>
                  </div>)
                }
              </If>
              <If test={this.state.venda.observacoes}>
                <p><strong>Observações: </strong>{this.state.venda.observacoes}</p>
              </If>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent(){
    return(
      <div className='box-modal-visualizacao'>
        <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
        <TitleModal title="Agendamento" />
        <div className='box-modal-visualizacao__wrapper'>
          { this.state.agendamento ? this.getAgendamento() : null}
          { this.state.cliente ? this.getCliente() : null}
          { this.state.venda ? this.getVenda() : null}
        </div>
      </div>
    );
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };

    return(
      <ReactModal
      portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
      >
      {
        this.state.loaded ?
        this.renderContent()
        : <LoadingPage />
      }
      </ReactModal>
    )
  }
}
