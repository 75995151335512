import React, {Component} from 'react';

import './footerPlan.scss';

export default class FooterPlan extends Component{
  constructor(props){
    super(props);
    this.state = {...props};
  }

  componentDidUpdate(prevProps) {

  }

  render(){
    const { readOnly } = this.props;

    return(
      <div className='bottom-planos'>
        <div className='bottom-planos__box'>
          <div className='box-body bottom-plano-box-body'>
            <div className="center-bottom-planos">
            {
              this.props.showButtons ?
              <button type="button" className="bounce btn btn-primary btn-warning" onClick={this.props.handlerStatusCaixa}>{`${readOnly ? "Reabrir caixa" : "Fechar caixa"}`}</button>
              : <p style={{paddingTop: 18}}>O usuário não possui permissão para reabrir o caixa</p>
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
