import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '../../../api';
import LabelAndSelectAjax from '../../../common/form/labelAndSelectAjax';
import CONSTS from '../../../config/consts';
import Requisition from '../../../utils/requisitions/requisition';
import Language from '../../../config/language';
import { connect } from 'react-redux';
import LoadingPage from '../../../common/template/loadingPage';
import ModalDelete from '../../modals/modal-delete';
import LabelAndInput from '../../../common/form/labelAndInput';
import ErrorPage from '../../../common/template/errorPage';
import ModalLoading from '../../modals/modal-loading';

class Form extends Component {
  constructor(props){
    super(props);

    this.state = {
      loaded: !this.props.detail,
      tipo_profissional: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangePerfil = this.onChangePerfil.bind(this);
  }

  componentWillMount() {
    if(this.props.detail){
      this.loadData();
    }
  }

  onChangePerfil(item, name){
    this.setState({ [name] : item });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  loadData(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.tiposProfissionais.read.replace(/{id}/,this.props.detail.id)}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({
              tipo_profissional : data.data.tipo_profissional,
              perfil_comissionamento : (data.data.perfil_comissionamento ? data.data.perfil_comissionamento : null),
              loaded: true
            });
          }else{
            toastr.warning(Language.atencao, data.msg);
            self.errorLoadingPage();
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
          self.errorLoadingPage();
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
        self.errorLoadingPage();
      }
    });
  }

  errorLoadingPage(){
    this.setState({
      loaded: true,
      errorLoadingPage: true
    });
  }

  loaded(){
    this.setState({
      loaded: true
    });
  }

  update(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'put',
      url: `${api.API_URL}${api.API.tiposProfissionais.update.replace(/{id}/,this.props.detail.id)}`,
      data: {
        tipo_profissional:self.state.tipo_profissional,
        perfil_comissionamento: this.state.perfil_comissionamento ? this.state.perfil_comissionamento.value : null
      },
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  create(){
    const token = this.props.user;
    const self = this;

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'post',
      url: `${api.API_URL}${api.API.tiposProfissionais.create}`,
      data: {tipo_profissional:self.state.tipo_profissional},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            toastr.success(Language.atencao, data.msg);
            self.props.changeTab();
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }

  handleSubmit(e){
    switch (this.props.type) {
      case 'delete':
        this.setState({ModalDeleteItem:true});
        break;
      case 'update':
        this.callLoading(true);
        this.update();
        break;
      default:
        this.callLoading(true);
        this.create();
    }

    e.preventDefault();
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  removeModalDeleteItem(){
    this.setState({ModalDeleteItem: false});
  }

  renderContent(){
    const { readOnly } = this.props;
    const urlPerfil = `${api.API_URL}${api.API.perfisComissionamento.autocomplete}`;

    return(
      <form onSubmit={this.handleSubmit}>
        <div>
        <div className='box-body'>
          <LabelAndInput name='tipo_profissional' component={LabelAndInput} readOnly={readOnly} handleChange={this.handleInputChange}
            label='Nome' cols='12 6' value={this.state.tipo_profissional} placeholder='Informe o nome' />
            <LabelAndSelectAjax
              readOnly={this.props.readOnly}
              name='perfil_comissionamento'
              value={this.state.perfil_comissionamento}
              url={urlPerfil}
              onChange={this.onChangePerfil}
              token={this.props.user}
              label='Adicionar perfil de comissionamento'
              cols='12 4'
              placeholder='Digite aqui' />
        </div>
        <div className='box-footer'>
            <button type='submit' className={`btn btn-${this.props.submitClass}`}>
                {this.props.submitLabel}
            </button>
            <button type='button' className='btn btn-default'
                onClick={()=>this.props.cancelarHandler()}>Cancelar</button>
        </div>
      </div>
      </form>
    );
  }

  render() {
      const tempBox = this.state.loaded
                    ? (this.state.errorLoadingPage ? <ErrorPage /> : this.renderContent())
                    : null;

      return (
        <div>
          <ModalDelete
            onCancel={ () => this.deleteItem() }
            className='Modal_Delete'
            isOpen={this.state['ModalDeleteItem']}
            onRequestClose={()=>this.removeModalDeleteItem()}
          />
          <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

          {
            this.state.loaded ?
            tempBox
            : <LoadingPage />
          }

        </div>
      )
  }
}

const mapStateToProps = store => ({
  user: store.auth.user
});

export default connect(mapStateToProps)(Form);
