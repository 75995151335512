const tabCreate = {show:['tabList', 'tabCreate', 'tabSearch'], tabId:'tabList' };

export const tabsList = {
  tabList: tabCreate,
  tabCreate,
  tabDelete: {show:['tabDelete','tabList'], tabId:'tabDelete' },
  tabUpdate: {show:['tabCreate', 'tabUpdate','tabList'], tabId:'tabUpdate' },
  tabHistoricoAgendamento: {show:['tabGeralAgendamento','tabHistoricoAgendamento'], tabId:'tabHistoricoAgendamento' },
  tabGeralAgendamento: {show:['tabGeralAgendamento','tabHistoricoAgendamento'], tabId:'tabGeralAgendamento' }
};

export function showTabs(args) {
  const tabsToShow = {}
  const tabs = args.tabs;
  const tabIds = tabsList[args.tabId].show;

  tabIds.forEach(e => tabsToShow[e] = true);

  args.component.setState({
   tabs: { ...tabs, visible: tabsToShow, selected:args.tabId }
 }, args.onComplete);
};
