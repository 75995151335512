import React, { Component } from 'react';

import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';

class Relatorios extends Component{
  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentWillMount() {
  }

  openModal(modal) {
    this.setState({[modal]: true});
  }

  afterOpenModal() {
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){

    return(
      <div>
      <ContentHeader title='Relatórios' small='' />
          <Content>

          </Content>
      </div>
    );
  }
}

export default Relatorios;
