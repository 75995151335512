export const convertToBr = props => {
  return props.getDate()  + "/" + (props.getMonth()+1) + "/" + props.getFullYear();
};

export const brToDate = props => {
  let parts = props.split('/');
  return new Date(parts[2], parts[1]-1, parts[0]);
};

export const dateTimeToStr = props => {
  return addZero(props.getHours()) + ":" + addZero(props.getMinutes());
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export const getWeekDay = props => {
  const now = new Date();
  const arrayWeekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  const week = now.getDay();
  return {name:arrayWeekDays[week], value: week};
}

export const stringToTime = props => {
  let time = new Date (new Date().toDateString() + ' ' + props);
  return time;
}
