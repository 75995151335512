const INITIAL_STATE = {
    value: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'FILTER':
          const data = { ...state, value: action.payload};
            return data;
        default:
            return state
    }
}
