import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import api from '../../api';
import Clearfix from '../../common/form/clearfix';
import Box from '../../common/template/box';
import { toastr } from 'react-redux-toastr';
import CONSTS from '../../config/consts';
import Requisition from '../../utils/requisitions/requisition';
import Language from '../../config/language';
import ModalLoading from '../modals/modal-loading';
import LabelAndDate from '../../common/form/labelAndDatePicker';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import { urlParamsConverter } from '../../config/methods';
import {convertToBr} from '../../utils/dateJS';
import XLSX from 'xlsx';
import PrintPdf from '../../utils/print/PrintPdf';
import ModalInfo from '../agendamentos/modal-info-agendamento';
import ModalCRM from '../modals/modal-crm';
import LabelAndSelect from '../../common/form/labelAndSelect';
import LabelAndSelectAjax from '../../common/form/labelAndSelectAjax';
import Pagination from '../../common/template/pagination';

const QUERY_INIT = { page: 1 };

const INITIAL_STATE_TABS = { agendamentos: [], loaded: false, pageActive: 1, pagination: CONSTS.PAGINATE, query: {...QUERY_INIT}, };
const optionsPlanosAvulsos = [
  {value: '',label:'Tanto faz'},
  {value:'planos',label:'Pacotes'},
  {value:'avulsos',label:'Avulsos'},
];
class Crm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_TABS };
    this.handleSelect = this.handleSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.creatExcel = this.creatExcel.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  getArrayExcel(){
    let arr = [];

    arr = this.state.agendamentos.map((value, i) => [
      `${value.nome} ${value.sobrenome}`,
      value.whatsapp,
      value.email,
      value.data_nascimento
    ]);
    arr.unshift(["Cliente", "Whatsapp"]);
    return arr;
  }

  creatExcel(e){
    e.preventDefault();
    const arr = this.getArrayExcel();
    const ws = XLSX.utils.aoa_to_sheet(arr);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "retornos.xlsx");
  }

  printDiv(e){
    e.preventDefault();
    var sWinHTML = document.getElementById('printable').innerHTML;
    PrintPdf.print(sWinHTML);
    return false;
  }

  handleSelect(item, name){
    this.setState({ [name] : item });
  }

  getQuery(){
    const query = {...this.state.query};

    if(this.state.data_inicial){
      query.data_inicial = convertToBr(this.state.data_inicial);
    }

    if(this.state.check_plano){
      query.check_plano = this.state.check_plano.value;
    }

    if(this.state.servico && this.state.servico !== ''){
      query.servicos = this.state.servico.value;
    }

    return query;
  }

  loadData(){

    if(!this.state.data_inicial){
      toastr.warning(Language.atencao, Language.escolhaFiltroData);
      return;
    }
    const dataQuery = this.getQuery();
    const query = `?${urlParamsConverter(dataQuery)}`;
    const token = this.props.token;
    const self = this;
    this.callLoading(true);

    Requisition({
      header: {
        [CONSTS.API_KEY ]: CONSTS.API_KEY_VALUE,
        'Auth' : 'Bearer '+token
      },
      type: 'get',
      url: `${api.API_URL}${api.API.reports.retornos}${query}`,
      data: {},
      success: response => {
        const data = response.data;
        if(data){
          if(data.status){
            self.setState({agendamentos: data.data, loaded: true, pagination: data.pagination});
          }else{
            toastr.warning(Language.atencao, data.msg);
          }
        }else{
          toastr.error(Language.erroTitle, Language.erro01);
        }
      },
      error: response => {
        toastr.error(Language.erroTitle, Language.erro02);
      },
      complete: response => {
        self.callLoading(false);
      }
    });
  }


  changePage(goTo){
    let page;
    if (goTo==='next') {
      page = this.state.pagination.next;
    }else if(goTo==='prev'){
      page = this.state.pagination.prev;
    }else{
      page = goTo;
    }

    this.setState({
      ModalLoading: true,
      query: {...this.state.query, page}
    }, () => this.loadData());
  }

  callLoading(show){
    this.setState({ModalLoading:show});
  }

  changeTab(e,tab){
    this.setState({tabActive: tab});
    e.preventDefault();
  }

  onChangeDate (date, item){
    this.setState({ [item] : date});
  }

  callWhats(item){
    const url = `https://api.whatsapp.com/send?phone=55${item.whatsapp}&text=/`;
    var node = document.createElement('textarea');
    var selection = document.getSelection();

    node.textContent = url;
    document.body.appendChild(node);

    selection.removeAllRanges();
    node.select();
    document.execCommand('copy');

    selection.removeAllRanges();
    document.body.removeChild(node);

    toastr.success(Language.atencao, "Dados copiados com sucesso");
  }

  openModal(modal, args) {
    let dados = {[modal]: true};
    dados.dadosAgendamento = '';
    dados.agendamento = '';
    dados.dadosCRM = '';

    if(modal === 'modalInfo'){
      dados.agendamento = args;
    }
    if(modal === 'ModalCRM'){
      dados.dadosCRM = args;
    }

    this.setState(dados);
  }

  getBody(){
    return (
      <Content>
      <ModalCRM
        className='Modal_CRM'
        isOpen={this.state['ModalCRM']}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={()=>this.closeModal('ModalCRM')}
        cliente={this.state.dadosCRM}
      />
      <ModalInfo
        className='Modal_Info'
        isOpen={this.state['modalInfo']}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={()=>this.closeModal('modalInfo')}
        agendamento={this.state.agendamento}
        token={this.props.token}
      />
        <div className='clearfix' style={{paddingBottom: 20}}>
        <span className="total_reports pull-left">Total: {this.state.agendamentos.length}</span>
          <button onClick={this.creatExcel} className="btn btn-primary pull-right">Gerar excel</button>
          <button onClick={this.printDiv} className="btn btn-success pull-right">Gerar Pdf</button>
        </div>
        <Box classes="box-success" title="Resultado">
          <div id="printable">
            <table className='table'>
                <thead>
                    <tr>
                      <th>Cliente</th>
                      <th>Dias</th>
                      <th>Serviço</th>
                      <th>Whatsapp</th>
                      <th>Tipo</th>
                      <th>CRM</th>
                      <th>Detalhe</th>
                      <th className='table-actions-single hideOnprint'></th>
                    </tr>

                </thead>
                <tbody>
                  {
                    this.state.agendamentos.map(bc => (
                      <tr key={bc.id}>
                        <td>{bc.nome_cliente}</td>
                        <td>{bc.data_diff}</td>
                        <td>{bc.servico}</td>
                        <td>{bc.whatsapp}</td>
                        <td>{
                          bc.tipo_agendamento === "Pacote" ?
                          <span className="label label-warning">{bc.tipo_agendamento}</span>
                          :
                          <span className="label label-danger">{bc.tipo_agendamento}</span>
                        }</td>
                        <td>
                          <button type="button" className='btn btn-success' onClick={() => this.openModal('ModalCRM', bc.id_cliente)}>
                              <i className='fa fa-id-card'></i>
                          </button>
                        </td>
                        <td>
                          <button type="button" className='btn btn-success' onClick={() => this.openModal('modalInfo', bc.id_agendamento)}>
                              <i className='fa fa-calendar'></i>
                          </button>
                        </td>
                        <td className="hideOnprint">
                          {
                            bc.whatsapp ?
                            <button type="button" className='btn btn-success' onClick={() => this.callWhats(bc)}>
                                <i className='fa fa-whatsapp'></i>
                            </button>
                            : ''
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
            </table>
            <Pagination setPage={this.changePage} onClick={this.changePage} dados={this.state.pagination} />
          </div>
        </Box>
      </Content>

    );
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  render(){
    const urlServico = `${api.API_URL}${api.API.servicos.autocomplete}`;
    const servicoQuery = {};
    return(
      <Fragment>
        <ContentHeader title='Retornos' small='' />
        <Content>
          <Box classes="box-success" title="Busca">
            <LabelAndDate required name='data_inicial' value={this.state.data_inicial} onChange={data => {this.onChangeDate(data, 'data_inicial');}}
                label='Data' cols='12 2' date={this.state.data_inicial} />
            <LabelAndSelect name='check_plano' options={optionsPlanosAvulsos} value={this.state.check_plano}handleChange={this.handleSelect}
              label='Pacotes ou avulsos' cols='12 3' placeholder='Digite aqui' />
            <LabelAndSelectAjax
              name='servico'
              value={this.state.servico}
              onChange={this.handleSelect}
              url={urlServico}
              token={this.props.token}
              label='Selecione um serviço'
              cols='12 4'
              required
              query={servicoQuery}
              placeholder='Digite aqui' />
              <ButtonAddAjax cols="1" icon="search" onClick={this.loadData} />
            <Clearfix />
          </Box>
        </Content>
        {this.state.loaded ? this.getBody() : null}
        <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />
      </Fragment>
    );
  }
}


const mapStateToProps = store => ({
  token: store.auth.user
});

export default connect(mapStateToProps)(Crm);
